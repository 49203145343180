/**
 * Import React Libraries.
 */
import React, {useEffect, useState} from 'react';

/**
 * Import third-party libraries.
 */
import {
  BackButton,
  COLORS,
  HorizontalTabs,
  PageWidth,
  Size,
  ToastColor,
  ToastPosition,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Navigate, Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import {useParams} from 'react-router';

/**
 * Import custom pages.
 */
import ServiceGeneralDetailsPage from './pages/ServiceGeneralDetailsPage';
import ServiceOrganizationsPage from './pages/ServiceOrganizationsPage';
import ServiceUsersPage from './pages/ServiceUsersPage';

/**
 * Import custom utils.
 */
import Api from '../../utils/api';

/**
 * Import custom types.
 */
import {Service} from '../../types';
import ServiceDocumentsPage from './pages/ServiceDocumentsPage';
import {Roles} from '../../components/authorization/Roles';
import {useRolesAuthorization} from '../../components/authorization/RolesAuthorization';
import {useToastContext} from '../../userContext';

/**
 * Add custom styles.
 */
const StyledPageWidth = styled(PageWidth)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  
  h6 {
    color: ${COLORS.neutral_600};
  }
`;

const HeaderTitle = styled.h1`
  font-size: 48px;
  color: ${COLORS.black};
  font-weight: 700;
`;

/**
 * Create custom types.
 */
type TParams = { id: string };

interface HorizontalTab {
  value: string;
  selected: boolean;
  to: string;
  disabled?: boolean;
}

const ServicePage = () => {
  // Generally used variables within the component
  const {t} = useTranslation('Services');
  const [service, setService] = useState<Service | null>(null);
  const [tabs, setTabs] = useState<HorizontalTab[]>([]);
  const {addToast} = useToastContext();
  const params = useParams<TParams>();
  const navigate = useNavigate();
  const location = useLocation();

  const {isInRole} = useRolesAuthorization();

  /**
   * Does all required pre-requisites and tries to retrieve the service from the API.
   */
  useEffect(() => {
    Api.GetService(params.id!)
      .then((response) => setService(response))
      .catch(() => {
        addToast(t('Service could not be found.'), {
          color: ToastColor.RED,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Does all required pre-requisites and setup the tabs for the page.
   */
  useEffect(() => {
    if (service) {
      const tabs = [
        {
          value: t('General'),
          to: `/service/${service.id}/general`,
          selected: ['organizations', 'users', 'documents'].indexOf(location.pathname.split('/').reverse()[0]) === -1,
          disabled: false,
        },
        {
          value: t('Organizations'),
          to: `/service/${service.id}/organizations`,
          selected: ['organizations'].indexOf(location.pathname.split('/').reverse()[0]) > -1,
          disabled: false,
        },
        {
          value: t('Users'),
          to: `/service/${service.id}/users`,
          selected: ['users'].indexOf(location.pathname.split('/').reverse()[0]) > -1,
          disabled: false,
        }
      ];

      if (isInRole([Roles.Admin, Roles.ToSManager])) {
        tabs.push({
          value: t('Documents'),
          to: `/service/${service.id}/documents`,
          selected: ['documents'].indexOf(location.pathname.split('/').reverse()[0]) > -1,
          disabled: false,
        });
      }

      setTabs(tabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  /**
   * Switches the current tab in the service page.
   * @param url - New url to which to navigate.
   */
  const onTabSwitch = (url: string): void => {
    // Update selected tab
    setTabs([
      ...tabs.map((tab: HorizontalTab) => {
        if (tab.to !== url && tab.selected) {
          tab.selected = false;
        } else if (tab.to === url) {
          tab.selected = true;
        }

        return tab;
      }),
    ]);

    // Let's navigate to the according page
    navigate(url);
  };

  return (
    <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
      <BackButton size={Size.Small} onClick={() => navigate('/service')}>
        {t('Back to Services')}
      </BackButton>

      <h3>{service?.name}</h3>

      <HorizontalTabs onTabChange={(to: string) => onTabSwitch(to)} size={Size.Large} variant={'floating'} tabs={tabs}/>

      {service && (
        <Routes>
          <Route path={`general`} element={<ServiceGeneralDetailsPage service={service}/>}/>
          <Route path={`organizations`} element={<ServiceOrganizationsPage service={service}/>}/>
          <Route path={`users`} element={<ServiceUsersPage service={service}/>}/>
          <Route path={`documents`} element={<ServiceDocumentsPage serviceId={service?.id}/>}/>
          <Route index element={<Navigate replace to={`/service/${service?.id}/general`}/>}/>
        </Routes>
      )}
    </StyledPageWidth>
  );
};

export default ServicePage;
