import React, {Dispatch, useEffect, useState} from 'react';
import {
  ActionChip,
  Banner,
  Button,
  COLORS,
  InputLabel,
  Size,
  SystemIcons,
  TextField,
  ToastColor,
  ToastPosition,
} from '@laerdal/life-react-components';
import {AccessLevel, UpdateUser, User} from '../../types';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {ActionType} from './UserPage';
import Api from '../../utils/api';
import {useLocation} from 'react-router';
import ResetPassword from './components/ResetPassword/ResetPassword';
import {useToastContext, useUserContext} from '../../userContext';
import VerifyEmailModal from './components/modals/VerifyEmailModal';
import PromptOnLeave from '../../components/PromptOnLeave';

const OrgDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 560px;
  gap: 32px;

  h6 {
    color: ${COLORS.neutral_600};
  }
`;

const GroupContainer = styled.div` 
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;


interface UserDetailsPage {
  user: User;
  savedUser: User;
  setSavedUser: (user: User) => void;
  dispatch: Dispatch<ActionType>;
}

const UserDetailsPage = ({user, savedUser, setSavedUser, dispatch}: UserDetailsPage) => {
  const {t} = useTranslation('User');
  const {addToast} = useToastContext();
  const location = useLocation();
  const {accessLevel} = useUserContext();
  const [userDetailsEdited, setUserDetailsEdited] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [shouldBlockNavigation, setsShouldBlockNavigation] = useState<boolean>(false);
  const [verifyEmailModalOpen, setVerifyEmailModalOpen] = useState<boolean>(false);

  const readOnly = accessLevel != AccessLevel.Full;

  const onCancel = () => {
    if (user && savedUser) {
      dispatch({type: 'string', value: savedUser.firstName, field: 'firstName'});
      dispatch({type: 'string', value: savedUser.lastName, field: 'lastName'});
    }

    setUserDetailsEdited(false);
  };
  const onUpdate = () => {
    if (user) {
      const updateUser: UpdateUser = {
        uid: user?.identityId || user?.email,
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
      };
      setIsSaving(true);

      Api.UpdateUserDetails(updateUser)
        .then(() => {
          setUserDetailsEdited(false);
          setSavedUser(user);
          addToast(t('User details updated successfully'), {
            color: ToastColor.GREEN,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
          setIsSaving(false);
        })
        .catch(() => {
          addToast(t('There was a problem updating the user details'), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
          setIsSaving(false);
        });
    }
  };

  useEffect(() => {
    if (savedUser && !isSaving) {
      var edited = user?.firstName !== savedUser?.firstName || user?.lastName !== savedUser?.lastName || user?.email !== savedUser?.email;
      setUserDetailsEdited(edited);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.firstName, user?.lastName, user?.email, savedUser, isSaving]);

  useEffect(() => {
    if (location.hash !== `/user/${user.id}/details`) {
      setsShouldBlockNavigation(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <VerifyEmailModal isOpen={verifyEmailModalOpen}
                        onClose={() => setVerifyEmailModalOpen(false)}
                        user={user}
                        dispatch={dispatch}/>
      <PromptOnLeave prompt={shouldBlockNavigation && userDetailsEdited}/>
      <OrgDetailsWrapper>
        <h3>{t('User details')}</h3>
        <ActionsContainer>
          {
            user?.isVerified === false &&
              <ActionChip text={'Verify email'}
                          icon={<SystemIcons.Mail/>}
                          onClick={() => setVerifyEmailModalOpen(true)}
                          disabled={readOnly}
                          size={Size.Large}/>
          }
          <ResetPassword firstName={user?.firstName} lastName={user?.lastName} email={user?.email}/>
        </ActionsContainer>
        <GroupContainer>
          <h6>{t('General')}</h6>
          <div>
            <InputLabel inputId='FirstName' text='First name' required={false}
                        showCopyButton={Boolean(user?.firstName)}/>
            <TextField
              disabled={readOnly}
              id="FirstName"
              value={user?.firstName ?? ''}
              onChange={(e) => {
                dispatch({type: 'string', value: e, field: 'firstName'});
              }}
            />
          </div>
          <div>
            <InputLabel inputId='LastName' text='Last name' required={false}
                        showCopyButton={Boolean(user?.lastName)}/>
            <TextField
              id="LastName"
              disabled={readOnly}
              value={user?.lastName ?? ''}
              onChange={(e) => {
                dispatch({type: 'string', value: e, field: 'lastName'});
              }}
            />
          </div>
          <div>
            <InputLabel inputId='Email' text='Email' required={false}
                        showCopyButton={Boolean(user?.email)}/>
            <TextField id="Email" disabled={readOnly}
                       value={user?.email ?? ''}
                       readOnly={true}
                       note={user?.isVerified === false ? {
                         icon: <SystemIcons.Information/>,
                         message: 'Unverified email address - check with user'
                       } : undefined}
            />
          </div>
          <ButtonWrapper>
            <Button
              id={'SaveButton'}
              size={Size.Large}
              variant="primary"
              onClick={() => {
                if (!isSaving) {
                  onUpdate();
                }
              }}
              loading={isSaving && userDetailsEdited}
              disabled={readOnly || !userDetailsEdited || isSaving}>
              {t('Save changes')}
            </Button>
            <Button
              id={'CancelButton'}
              onClick={() => {
                if (!isSaving) {
                  onCancel();
                }
              }}
              size={Size.Large}
              variant="tertiary"
              disabled={readOnly || isSaving}>
              {t('Cancel')}
            </Button>
          </ButtonWrapper>
        </GroupContainer>
        <GroupContainer>
          <h6>{t('About this user')}</h6>
          <div>
            <InputLabel inputId={"CompanyRoles"} text={"Role(s)"}/>
            <TextField
              id="CompanyRoles"
              value={user?.userCompanyRoles?.map((r) => r.name).join(', ') ?? ''}
              readOnly={true}
            />
          </div>
          <Banner type={'neutral'} size={Size.Small} icon={<SystemIcons.Information/>}>
            {t('These details are set by the user and cannot be changed in Cloud Control.')}
          </Banner>
        </GroupContainer>
      </OrgDetailsWrapper>
    </>
  );
};

export default UserDetailsPage;
