/**
 * Import React Libraries.
 */
import React, { useEffect, useState } from 'react';

/**
 * Import third-party libraries.
 */
import { SearchBar } from '@laerdal/life-react-components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

/**
 * Add custom styles.
 */

/**
 * Add custom types.
 */
interface ServiceUserSearchProps {
  onSearch: (query: string) => void;
  initialValue?: string;
}

const ServiceUserSearch = (props: ServiceUserSearchProps) => {
  // Globally used variables within the organization search component
  const [query, setQuery] = useState<string>('');
  const { t } = useTranslation('Services');

  /**
   * Sets the initial value of the search.
   */
  useEffect(() => {
    if (props.initialValue) {
      setQuery(props.initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the form submit.
   * @param event - React form event object.
   */
  const onFormSubmit = (event: React.FormEvent) => {
    // Prevent the default action
    event.preventDefault();

    // Let's pass the query to the parent
    props.onSearch(query);
  };

  /**
   * Clears the search and resets the table.
   */
  const clearSearch = () => {
    // Let's clear the query
    setQuery('');

    // Let's pass the query to the parent
    props.onSearch('');
  };

  return (
    <form onSubmit={onFormSubmit}>
      <SearchBar
        id="ServiceUserSearchBar"
        setSearchTerm={(q) => setQuery(q)}
        enterSearch={onFormSubmit}
        removeSearch={() => clearSearch()}
        searchTerm={query}
        placeholder={t('Search by name, email address, or organization')}
      />
    </form>
  );
};

export default ServiceUserSearch;
