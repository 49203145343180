import { SystemIcons, Checkbox, ChipDropdownInput, COLORS, InputLabel } from '@laerdal/life-react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CheckboxWrapper } from '../../../../components/modals/ModalStyles';
import { OrganizationMember, User } from '../../../../types';

type InviteUserStep1Props = {
  users: User[];
  emails: string[];
  currentInput: string;
  loading: boolean;
  hasAlreadyInvited: string[];
  removeFromOtherOrgsCheckbox: boolean;
  createSFContactsCheckbox: boolean;
  exisitingUserEmails: string[];
  existsInSF: boolean;
  members: OrganizationMember[];
  setHasAlreadyInvited: (data: string[]) => void;
  setEmails: (data: string[]) => void;
  setExisitingUserEmails: (data: string[]) => void;
  setCurrentInput: (data: string) => void;
  setRemoveFromOtherOrgsCheckbox: (data: boolean) => void;
  setCreateSFContactsCheckbox: (data: boolean) => void;
};

const AddUsersWarningBox = styled.div`
  background-color: ${COLORS.warning_100};
  padding: 12px 16px;
  p {
    font-size: 16px;
    color: ${COLORS.warning_700};
    margin: 0;
    &:last-child {
      margin: 12px 0 0 0;
    }
  }
`;

const WarningMessage = styled.div`
  font-size: 14px;
  line-height: 140%;

  & * {
    vertical-align: middle;
    display: inline-block;
  }

  svg {
    margin-right: 5px;
  }
`;

const AlreadyInvitedWarning = styled(WarningMessage)`
  color: ${COLORS.warning_500};
`;

export const InviteUserStep1: React.FunctionComponent<InviteUserStep1Props> = ({
  users,
  emails,
  currentInput,
  loading,
  hasAlreadyInvited,
  removeFromOtherOrgsCheckbox: addUsersCheckbox,
  createSFContactsCheckbox,
  exisitingUserEmails,
  members,
  existsInSF,
  setHasAlreadyInvited,
  setEmails,
  setExisitingUserEmails,
  setCurrentInput,
  setRemoveFromOtherOrgsCheckbox: setAddUsersCheckbox,
  setCreateSFContactsCheckbox,
}: InviteUserStep1Props) => {
  const { t } = useTranslation('Organization');

  const getUserList = () => {
    return users.map((u) => {
      const hasName = Boolean(u.firstName) || Boolean(u.lastName);
      return {
        label: (hasName ? `${u.firstName} ${u.lastName}` : u?.email) || '',
        secondaryLabel: `${hasName ? u?.email : ''}${(Boolean(u.organizations?.length) && hasName) ? ' | ' : ' '}${
          !Boolean(u.organizations?.length)
            ? ''
            : (u?.organizations.length > 1 ? u?.organizations[0]?.name.concat(t(', + {{number}} more', { number: u?.organizations.length - 1 })) : u?.organizations[0]?.name)
        }`,
        value: u?.email || '',
      };
    });
  };

  /**
   * Validate e-mails and assigns them to the form data.
   * @param emailAddresses - A list of e-mail addresses from the chip input.
   */
  const validateEmails = (emailAddresses: string[]): void => {
    setCurrentInput('');
    // First let's remove white spaces
    emailAddresses = emailAddresses.map((email: string) => email.replace(/\s+/g, ''));

    // Let's assign values
    setEmails(emailAddresses);

    // Check if we should activate the move user checkbox
    const lastAdded = emailAddresses[emailAddresses.length - 1];
    if (users.find((u) => u.email === lastAdded) && !exisitingUserEmails.find((u) => u === lastAdded)) {
      setExisitingUserEmails([...exisitingUserEmails.filter((e) => emailAddresses.includes(e)), lastAdded]);
    } else {
      setExisitingUserEmails([...exisitingUserEmails.filter((e) => emailAddresses.includes(e))]);
    }
  };

  /**
   * Validates an e-mail address against a valid e-mail address format.
   * @param email - Email address which needs to be validated.
   * @returns Boolean value indicating if the e-mail is a valid e-mail address.
   */
  const validEmail = (email: string): boolean => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(email);
  };

  const isAlreadyInOrganization = (email: string) => {
    if (members.findIndex((m: OrganizationMember) => m.user.email === email && m.user.identityId) > -1) {
      return true;
    }
    return false;
  };

  const checkForAlreadyInvited = (emailAddresses: string[]): boolean => {
    var hasAlreadyInvited: string[] = [];
    emailAddresses.forEach((element) => {
      if (isAlreadyInOrganization(element)) {
        hasAlreadyInvited.push(element);
      }
    });
    setHasAlreadyInvited(hasAlreadyInvited);
    return hasAlreadyInvited.length > 0;
  };

  return (
    <>
      <InputLabel inputId="emails" text={t('Select people')} />
      <ChipDropdownInput
        inputId="inviteEmails"
        list={getUserList()}
        onValueChange={validateEmails}
        values={emails}
        placeholder={t('Search by name or email address')}
        suggestion={currentInput && currentInput.includes('@') ? { label: currentInput, value: currentInput } : undefined}
        onInputChange={(input) => setCurrentInput(input)}
        messageOnNoResults={t('No results found')}
        loading={loading}
      />
      {hasAlreadyInvited && (
        <>
          {hasAlreadyInvited.map((email: string) => (
            <AlreadyInvitedWarning>
              <SystemIcons.Attention size="20px" color={COLORS.warning_400} />
              <span>{t('{{email}} is already a part of this organization or has been invited', { email: email })}</span>
            </AlreadyInvitedWarning>
          ))}
        </>
      )}

      <CheckboxWrapper>
        {existsInSF && (
          <Checkbox id="CreateSFContactsCheckbox" select={setCreateSFContactsCheckbox} selected={createSFContactsCheckbox} label={t('Create as contacts in Salesforce')} />
        )}
        <Checkbox
          id="MoveUserCheckbox"
          select={(value) => setAddUsersCheckbox(value)}
          selected={addUsersCheckbox}
          label={'Remove selected user(s) from all other organizations'}
          disabled={exisitingUserEmails.length === 0}
        />
        {addUsersCheckbox && (
          <AddUsersWarningBox>
            <p>{t('The selected users will be removed from all other organizations and invited solely to this one.')}</p>
            <p>{t('This action cannot be undone.')}</p>
          </AddUsersWarningBox>
        )}
      </CheckboxWrapper>
    </>
  );
};
