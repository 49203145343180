import React from 'react';
import styled from 'styled-components';
import {COLORS, ComponentTextStyle, ComponentXLStyling} from '@laerdal/life-react-components';

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const InputWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const WarningMessage = styled.div`
  font-size: 14px;
  line-height: 140%;

  & * {
    vertical-align: middle;
    display: inline-block;
  }

  svg {
    margin-right: 5px;
  }
`;

export const ValidationError = styled(WarningMessage)`
  color: ${COLORS.critical_400};
`;



export const FormGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
`;

export const FormGroupHeader = styled.div`
  ${ComponentXLStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
`;
