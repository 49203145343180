import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { Button, Table, SystemIcons, ToastContext } from '@laerdal/life-react-components';
import { TableColumn } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { ScenarioAccessSelectorModal } from './ScenarioAccessSelectorModal';
import { ConfirmScenarioDeleteModal } from './ConfirmScenarioDeleteModal';
import { ScenarioSearchModal } from './ScenarioSearchModal';
import { AccessLevel, Organization, OrganizationService, ScenarioWhitelistItemDTO } from '../../../../types';
import * as constants from '../../../../constants';
import Api from '../../../../utils/api';
import { formatDateOnly } from '../../../../utils/functions';
import { useToastContext, useUserContext } from '../../../../userContext';

interface ScenarioCloudSettings {
  service: OrganizationService | undefined;
  organization: Organization;
}

export interface ScenarioAccessDetails {
  unlimited: boolean;
  validTo: Date | undefined;
}

const formatWhitelistItem = (item: ScenarioWhitelistItemDTO, unlimitedDisplay: string): ScenarioWhitelistItemDTO => {
  item.validToDisplay = item.validTo ? formatDateOnly(item.validTo) : unlimitedDisplay;
  return item;
};

export const ScenariosCloudSettings = ({ service, organization }: ScenarioCloudSettings) => {
  const { t } = useTranslation('Settings');
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const [deleteCandidate, setDeleteCandidate] = useState<any>(null);
  const [editCandidate, setEditCandidate] = useState<ScenarioWhitelistItemDTO>();
  const [scenarios, setScenarios] = useState<ScenarioWhitelistItemDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast } = useToastContext();
  const { accessLevel } = useUserContext();
  const readOnly = accessLevel == AccessLevel.ReadOnly;

  const loadScenarios = useCallback(() => {
    setLoading(true);
    Api.GetOrganizationScenarios(organization?.id as string)
      .then((resp) => {
        setScenarios(resp.map((x) => formatWhitelistItem(x, t('Unlimited'))));
        setLoading(false);
      })
      .catch(() => {
        addToast(t('Error occured during scenarios search for organization'), constants.ErrorToastOptions);
        setScenarios([]);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useEffect(() => {
    loadScenarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        key: 'smsNumber',
        name: t('SMS'),
        sortable: true,
        width: 120,
      },
      {
        key: 'name',
        name: t('Title'),
        sortable: true,
      },
      {
        key: 'validToDisplay',
        name: t('Valid To'),
        width: 120,
        sortable: true,
      },
      {
        key: 'edit',
        name: t('Edit'),
        sortable: false,
        type: 'icon',
        icon: <SystemIcons.Edit />,
        width: 64,
        action: (item: ScenarioWhitelistItemDTO) => {
          setEditCandidate(item);
        },
        disabled: readOnly,
      },
      {
        key: 'delete',
        name: t('Delete'),
        sortable: false,
        type: 'icon',
        icon: <SystemIcons.Delete />,
        width: 90,
        action: (item: ScenarioWhitelistItemDTO) => {
          setDeleteCandidate(item);
        },
        disabled: readOnly,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleDelete = async () => {
    if (!organization) return;
    try {
      await Api.DeleteScenarioItem(organization.id, deleteCandidate.id);
      setScenarios(scenarios.filter((x) => x !== deleteCandidate));
      addToast(t('Item successfully deleted'), constants.SuccessToastOptions);
    } catch (error) {
      console.log(error);
      addToast(t('Error occured during deleting scenario item'), constants.ErrorToastOptions);
    }
    setDeleteCandidate(null);
  };

  const handleEditAccess = async (details: ScenarioAccessDetails) => {
    if (!organization || !editCandidate) return;
    const patchObject = { id: editCandidate.id, type: details.unlimited ? 0 : 1, validTo: details.validTo };
    try {
      await Api.EditScenarioItem(organization.id, patchObject);
      setScenarios(
        scenarios.map((x) =>
          x !== editCandidate
            ? x
            : formatWhitelistItem(
                {
                  ...editCandidate,
                  validTo: details.validTo,
                  type: details.unlimited ? 0 : 1,
                },
                t('Unlimited'),
              ),
        ),
      );
      addToast(t('Item successfully edited'), constants.SuccessToastOptions);
    } catch (error) {
      console.log(error);
      addToast(t('Error occured during editing scenario item'), constants.ErrorToastOptions);
    }
    setEditCandidate(undefined);
  };

  const handleAddScenariosClick = () => setShowSearchModal(true);

  const handleCloseSearch = () => {
    setShowSearchModal(false);
    loadScenarios();
  };

  return (
    <>
      <Button disabled={readOnly} icon={<SystemIcons.Add />} onClick={handleAddScenariosClick}>
        {t('Add scenarios')}
      </Button>
      <Table showLoadingIndicator={loading} rows={scenarios} columns={columns} />
      {showSearchModal && <ScenarioSearchModal existingScenarios={scenarios} organizationId={organization?.id} closeModal={handleCloseSearch} />}
      {deleteCandidate && <ConfirmScenarioDeleteModal organizationName={organization.name} closeModal={() => setDeleteCandidate(null)} confirm={handleDelete} />}
      {editCandidate && (
        <ScenarioAccessSelectorModal
          initialAccessDetails={editCandidate ? { unlimited: editCandidate.type === 0, validTo: new Date(editCandidate.validTo || '') } : undefined}
          label={t('Change expiration')}
          close={() => setEditCandidate(undefined)}
          selectAccess={handleEditAccess}
        />
      )}
    </>
  );
};
