import React, { Dispatch, FunctionComponent, useContext, useEffect, useState } from 'react';
import {
  COLORS,
  DropdownFilter, InputLabel,
  InputWrapper,
  NoteMessage,
  SystemIcons,
  TextField
} from "@laerdal/life-react-components";
import { AccessLevel, Address, CountryDto, CreateOrganization, Organization, State } from '../../types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../userContext';
import { Controller, useFormContext } from 'react-hook-form';
import { FormTextField } from '../../components/formComponents/FormTextField';

const DropdownWrapper = styled.div``;

interface AddressFieldsProps {
  addressType: 'address' | 'billingAddress';
  countries: CountryDto[];
  prefix: string;
  fieldsAreDisabled?: boolean;
  fieldsAreLocked?: boolean;
}

const AddressFields: FunctionComponent<AddressFieldsProps> = ({
  addressType,
  countries,
  prefix,
  fieldsAreLocked,
  fieldsAreDisabled
}: AddressFieldsProps) => {
  const { t } = useTranslation('Organization');
  const { accessLevel } = useUserContext();
  const methods = useFormContext<CreateOrganization>();
  const address = methods.getValues(addressType);
  const countryWatch = methods.watch<string>(addressType + '.country.codeAlpha2');
  const readOnly = accessLevel == AccessLevel.ReadOnly || fieldsAreLocked;

  const [states, setStates] = useState<State[]>([]);

  useEffect(() => {
    setStates(countries?.find((c) => c.codeAlpha2 == countryWatch)?.states || []);
  }, [countries, countryWatch]);

  const renderTextField = (id: string, placeholder: string) => {
    if (readOnly) {
      return (
        <TextField
          id={prefix + '.' + id}
          disabled={fieldsAreDisabled && !readOnly}
          value={methods.getValues(prefix + '.' + id)}
          readOnly={readOnly}
        />
      );
    } else {
      return <FormTextField control={methods.control}
                            id={prefix + '.' + id}
                            placeholder={placeholder}
                            disabled={fieldsAreDisabled && !readOnly}
                            autoComplete="none" />;
    }
  };

  return (
    <>
      <DropdownWrapper>
        <InputLabel inputId={prefix + '.country.codeAlpha2'} text={t('Country / Region')}/>
          <Controller
            control={methods.control}
            render={({field: { onChange, ...field}, ...rest}) => (
              <DropdownFilter
                id={prefix + '.country.codeAlpha2'}
                scrollable={true}
                list={countries
                  ?.filter((a) => a.hasAccess || a.codeAlpha2 === address?.country?.codeAlpha2)
                  .map((country) => ({ value: country.codeAlpha2, displayLabel: country.name }))}
                placeholder={t('Select...')}
                activeValidationMessage={rest.fieldState.error?.message}
                messageOnNoResults={t('No result')}
                readOnly={readOnly || fieldsAreDisabled}
                onSelect={onChange}
                {...field}
              />
            )}
            name={prefix + '.country.codeAlpha2'}
          />
      </DropdownWrapper>
      <DropdownWrapper>
        <InputLabel inputId={prefix + '.addressLine'} text={t('Address')}/>
        {renderTextField('addressLine', t('e.g. 167 Myers Corners Rd'))}
      </DropdownWrapper>
      <DropdownWrapper>
        <InputLabel inputId={prefix + '.city'} text={t('City')}/>
        {renderTextField('city', t('e.g. Wappingers Falls'))}
      </DropdownWrapper>
      {states.length > 0 && (
        <>
          <DropdownWrapper>
            <InputLabel inputId={prefix + '.state'} text={t('State')}/>
              <Controller
                name={prefix + '.state'}
                control={methods.control}
                render={({field: { onChange, ...field}, ...stateRest}) => (
                  <DropdownFilter
                    id={prefix + '.state'}
                    list={states.map((state) => ({ value: state.code, displayLabel: state.name }))}
                    placeholder={t('Select...')}
                    scrollable={true}
                    messageOnNoResults={t('No result')}
                    readOnly={readOnly || fieldsAreDisabled}
                    onSelect={onChange}
                    {...field}
                  />
                )}
              />
          </DropdownWrapper>
        </>
      )}
      <DropdownWrapper>
        <InputLabel inputId={prefix + '.zipCode'} text={t('Zip / Postal code')}/>
        {renderTextField('zipCode', t('e.g. 12590'))}
      </DropdownWrapper>

      <DropdownWrapper>
        <InputLabel inputId={prefix + '.phoneNumber'} text={t('Telephone number (optional)')}/>
        {renderTextField('phoneNumber', t('e.g. +1 877-523-7325'))}
      </DropdownWrapper>
    </>
  );
};

export default AddressFields;
