import React, { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {ModalDialog, Size, SystemIcons, ComponentS, ComponentM, COLORS, ComponentTextStyle, LoadingIndicator} from '@laerdal/life-react-components';

import {Organization, OrganizationMember, User} from '../../../types';
import Api from '../../../utils/api';

import styled from 'styled-components';

type MemberDetailsModalProps = {
  closeModal: any;
  isModalOpen: boolean;
  organization: Organization;
  member: OrganizationMember | null;
  contact?: User | null;
};

const DataTable = styled.div`
  margin-top: 20px;
  
  &.bottom-borders {
    > div {
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      padding: 8px 0px;

      svg {
        vertical-align: middle;
      }
    }
  }
`;

const TableLine = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  border: 1px solid ${COLORS.neutral_100};
  padding: 8px;

  + div {
    border-top: 0px;
  }

  .inline {
    display: inline;
  }
`;

const DetailsContainer = styled.div`
  margin-top: -20px;
`;

const ContactContainer = styled.div`
  margin-top: -20px;

  > div:first-child {
    margin-bottom: 20px;
  }
`

const MemberDetailsModal: React.FunctionComponent<MemberDetailsModalProps> = ({
                                                                         closeModal,
                                                                         isModalOpen,
                                                                         organization,
                                                                         member,
                                                                         contact
                                                                       }: MemberDetailsModalProps) => {
  const {t} = useTranslation('Organization');
  const [lastLogin, setLastLogin] = useState<string>('');
  const [invitedAddedDate, setInvitedAddedDate] = useState<string>('');
  const [invitedAddedBy, setInvitedAddedBy] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Let's clear the old values
    setInvitedAddedBy('');
    setInvitedAddedDate('');

    // Let's try to get the new values
    if (member && member.user.id) {
      setLoading(true);

      // Let's retrieve added or invite event for the user
      Api.GetUserAuditLogs(member.user.id, 1, 100, undefined, undefined, undefined, 'Create')
        .then(data => {
          const addInviteEvents = data.records.filter(record => 
            record.action ===  `Added user "${member.user.email}" to "${organization.name}" (customerNo: ${organization.customerNo})`
            || record.action === `Sent invite to "${member.user.email}" to join "${organization.name}" (customerNo: ${organization.customerNo})`
          );

          if (addInviteEvents.length > 0) {
            // Let's get latest event
            let event = addInviteEvents[0];

            // Let's check if we have sent invite event
            // Invite events that are accepted will have add event with no email, so we have to switch to the next event
            if (!extractEmail(event.initiatedBy) && addInviteEvents[1]) {
              event = addInviteEvents[1];
            }

            // Let's set added / invitation date and who added / invited user.
            setInvitedAddedBy(extractEmail(event.initiatedBy));
            setInvitedAddedDate(formatDate(event.occuredAt.toString()));
            
          }
        })
        .finally(() => {
          setLoading(false);
        });

      // Let's get last login date
      Api.getUserLastLogin(member.user.id)
        .then(data => {
          if (data.length > 0) {
            const lastLoginDate = data[0].eventTime;

            setLastLogin(formatDate(lastLoginDate))
          } else {
            setLastLogin('-');
          };
        });
    }
  }, [member]);

  /**
   * Extracts email from a string.
   * @param string - String from which email should be extracted.
   * @returns String containing email or empty string.
   */
  const extractEmail = (string: string): string => {
    const email = string.match(/(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi);

    if (!email) {
      return '';
    } else {
      return email[0];
    }
  }

  /**
   * Formats date to show Today, Yesterday and date according to date.
   * @param date - Date which needs to be formatted.
   * @returns Formatted date for the display in the modal.
   */
  const formatDate = (date: string): string => {
    if (new Date(date).toDateString() === new Date().toDateString()) {
      return t('Today, ') + moment(date).format('hh:mm A');
    } else if (new Date(date).toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()) {
      return t('Yesterday, ') + moment(date).format('hh:mm A');
    } else {
      return moment(date).format('MMM Do, YYYY, hh:mm A');
    }
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {
      }}
      closeModalAndClearInput={closeModal}
      closeAction={closeModal}
      size={Size.Large}
      title={contact ? contact.email : member?.user?.identityId ? `${member?.user?.firstName} ${member?.user?.lastName}` : member?.user?.email}
      leftFooterAction={{
        icon: <SystemIcons.OpenNewWindow />,
        actionType: 'hyperlink',
        text: t('View full profile'),
        href: `user/${contact ? contact.id : member?.user?.id}`
      }}
      buttons={
        [
          {
            id: 'close',
            variant: 'primary',
            text: t('Close'),
            action: closeModal,
          },
        ] as any
      }>
      {
        loading ?
          <LoadingIndicator />
        : contact ?
          <ContactContainer>
            <ComponentS color={COLORS.neutral_600}>@ { organization.name } { organization.customerNo }</ComponentS>
            <ComponentM color={COLORS.neutral_500}>
              { t('This person is a contact in Salesforce, but has not been added or invited to join this organization as a member.') }
            </ComponentM>
          </ContactContainer>
        :
          <DetailsContainer>
            <ComponentS color={COLORS.neutral_600}>@ { organization.name } { organization.customerNo }</ComponentS>
            <DataTable className="bottom-borders">
              <TableLine>
                <ComponentS color={COLORS.black} textStyle={ComponentTextStyle.Bold}>{ t('Member status') }</ComponentS>
                <ComponentS color={COLORS.black}>
                  {member?.permissions?.find(permission => permission.permissionId === 'account.owner') !== undefined ? t('Admin') : t('User')} {!member?.user?.identityId && t('(pending)')}
                </ComponentS>
              </TableLine>
              <TableLine>
                <ComponentS color={COLORS.black} textStyle={ComponentTextStyle.Bold}>{ t('Salesforce Contact') }</ComponentS>
                <ComponentS color={COLORS.black}>
                  {organization?.contacts?.find(contact => contact.user.id === member?.user.id) !== undefined ? t('Yes') : t('No')}
                </ComponentS>
              </TableLine>
            </DataTable>

            <DataTable className="bottom-borders">
              <TableLine>
                <ComponentS color={COLORS.black} textStyle={ComponentTextStyle.Bold}>{ t('Added/invited to organization on') }</ComponentS>
                <ComponentS color={COLORS.black}>
                  {invitedAddedDate ? invitedAddedDate : '-' }
                </ComponentS>
              </TableLine>
              <TableLine>
                <ComponentS color={COLORS.black} textStyle={ComponentTextStyle.Bold}>{ t('Added by') }</ComponentS>
                <ComponentS color={COLORS.black}>
                  {invitedAddedBy ? invitedAddedBy : '-' }
                </ComponentS>
              </TableLine>
            </DataTable>

            <DataTable className="bottom-borders">
              <TableLine>
                <ComponentS color={COLORS.black} textStyle={ComponentTextStyle.Bold}>{ t('Joined as a member on') }</ComponentS>
                <ComponentS color={COLORS.black}>
                  { !member?.user?.identityId ? '-' : moment(member.created).format('MMM Do, YYYY hh:mm A') }
                </ComponentS>
              </TableLine>
              <TableLine>
                <ComponentS color={COLORS.black} textStyle={ComponentTextStyle.Bold}>{ t('Last signed in') }</ComponentS>
                <ComponentS color={COLORS.black}>
                  { !member?.user?.identityId ? '-' : lastLogin }
                </ComponentS>
              </TableLine>
            </DataTable>
          </DetailsContainer>
      }
    </ModalDialog>
  );
};

export default MemberDetailsModal;
