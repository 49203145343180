import Api from '../../../../utils/api';
import {LanguageDto, TermsOfServiceDocument} from '../../../../types';
import {ToSDocumentForm} from '../AddNewToSVersion';
import {UseControllerReturn} from 'react-hook-form';


export const GetLanguageCodes = (): Promise<LanguageDto[]> => {
  return Api.GetLanguages()
    .then((languages) => {

      const langs = languages.map((x) => ({
        ...x,
        locale: x.locale.replace(/-.*$/, ''),
        name: x.name.replace(/\(.*\)$/, ''),
      }));
      const unique: LanguageDto[] = [];
      langs.forEach((x) => {
        if (!unique.find(y => y.locale === x.locale)) {
          unique.push(x);
        }
      });
      return Promise.resolve(unique);
    });
};

export const CreateToS = async (
  tosDocument: ToSDocumentForm,
  allToSDocs: TermsOfServiceDocument[],
  serviceId: string,
  tosDocId?: string,
) => {
  const formData = new FormData();
  try {

    // Upload new files
    const uploadThis = tosDocument.files.filter((x) => !x.fileId);
    uploadThis.map((x, index) => formData.append(`tos-document-${index + 1}`, x.file, x.file?.name));
    const newIds = await Api.UploadFiles(formData);
    uploadThis.map((x, index) => {
      x.fileId = newIds[index];
    });

    // Clone files
    const cloneThis = tosDocument.files.filter((x) => !uploadThis.find((y) => y === x));
    const clonedIds = await Api.CloneFiles(cloneThis.map((x, index) => x.fileId));
    cloneThis.map((x, index) => {
      x.fileId = clonedIds[index];
    });

    if (!tosDocId) {
      const createToSDto = {
        serviceId: serviceId,
        name: tosDocument.name,
        default: false,
        salesOrgCode: tosDocument.salesOrg,
        effectiveAt: tosDocument.effectiveAt,
        gracePeriodDurationInDays: tosDocument.gracePeriod,
        majorVersion: tosDocument.majorVersion,
        minorVersion: tosDocument.minorVersion,
        reconsent: tosDocument.reconsent,

        files: tosDocument.files.map((x) => ({
          blobName: x.fileId,
          locale: x.languageCode,
          version: '',
          tosId: '',
          isFallback: x.fallback,
        })),
      };
      await Api.CreateToS(createToSDto);

      return Promise.resolve();
    } else {
      const createToSVersionDto = {
        serviceId: serviceId,
        name: tosDocument.name,
        default: false,
        salesOrgCode: tosDocument.salesOrg,
        effectiveAt: tosDocument.effectiveAt,
        gracePeriodDurationInDays: tosDocument.gracePeriod,
        majorVersion: tosDocument.majorVersion,
        minorVersion: tosDocument.minorVersion,
        reconsent: tosDocument.reconsent,

        files: tosDocument.files.map((x) => ({
          blobName: x.fileId,
          locale: x.languageCode,
          version: '',
          tosId: '',
          isFallback: x.fallback,
        })),
      };
      await Api.CreateToSVersion(tosDocId, createToSVersionDto);

      const curToS = allToSDocs.find((x) => x.id == tosDocId);
      if (tosDocument.name != curToS?.name) {
        await Api.UpdateToS(tosDocId, {
          name: tosDocument.name,
          salesOrganizationCode: tosDocument.salesOrg,
        });
      }

      return Promise.resolve();
    }
  } catch (error) {
    console.log(error);

    return Promise.reject(error);
  }
};

export const ShouldShowError = (controller: UseControllerReturn<any, any>) =>
  !!controller.fieldState.error && (controller.fieldState.isTouched || controller.formState.isSubmitted);
export const GetErrorMessage = (controller: UseControllerReturn<any, any>) =>
  ShouldShowError(controller)
  ? controller.fieldState.error?.message
  : '';

