export abstract class BaseApi {
  private static cached: { [tag: string]: { [key: string]: Promise<any> | undefined } } = {};

  protected GetCached(tag: string, cacheKey: string, request: () => Promise<any>): Promise<any> {
    if (!BaseApi.cached[tag]) {
      BaseApi.cached[tag] = {};
    }
    if (!!BaseApi.cached[tag][cacheKey]) {
      const data = BaseApi.cached[tag][cacheKey];
      return Promise.resolve(data).catch(request);
    }
    return this.SetCache(tag, cacheKey, request());
  }

  protected SetCache(tag: string, cacheKey: string, request: Promise<any>) {
    BaseApi.cached[tag][cacheKey] = request;
    request.catch(() => {
      BaseApi.cached[tag][cacheKey] = undefined;
    });
    return request;
  }

  protected Clear = (tag: string) => {
    BaseApi.cached[tag] = {};
  };

  ClearCache = () => {
    BaseApi.cached = {};
  };
}
