import React, { useEffect } from 'react';

interface PromptOnLeaveProps {
  prompt: boolean;
}

const PromptOnLeave = ({ prompt }: PromptOnLeaveProps) => {

  const promptUser = (e: Event) => {
    if(prompt)
      e.preventDefault();
  }

  useEffect(() => { 
    window.addEventListener('beforeunload', promptUser);
    return () => {
      window.removeEventListener('beforeunload', promptUser);
    };
  }, []);

  return <div className="promptonleave"></div>;
};

export default PromptOnLeave;
