import React from 'react';
import ReactModal from 'react-modal';

const ModalContainerStyles = {
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '640px',
    height: '320px',
    borderRadius: '8px',
    margin: 0,
    padding: '24px',
    overflow: 'auto',
  },
};

type ModalContainerProps = {
  showModal: boolean;
  closeModal: () => void;
  size?: string;
  children: any;
  modalHeight?: any;
  modalWidth?: any;
  modalOverflow?: string;
};

type ModalContainerState = {};

class ModalContainer extends React.Component<ModalContainerProps, ModalContainerState> {
  componentDidMount() {
    if (ReactModal.defaultStyles.overlay) {
      ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.5)';
      ReactModal.defaultStyles.overlay.zIndex = 100;
    }
  }

  GetSize = (modalHeight: string = 'auto', modalWidth: string = 'auto') => ({ height: modalHeight, width: modalWidth });
  GetOverflow = (modalOverflow: string = 'visible') => modalOverflow;
  render() {
    const { showModal, closeModal, children, modalHeight, modalWidth, modalOverflow } = this.props;
    const sizes = this.GetSize(modalHeight, modalWidth);
    const { height, width } = sizes;
    const overflow = this.GetOverflow(modalOverflow);
    const stylesConfiguration = Object.assign(ModalContainerStyles.content, { height, width, overflow });
    const styles = { content: stylesConfiguration };

    return (
      <ReactModal isOpen={showModal} onRequestClose={() => closeModal()} style={styles} appElement={document.getElementById('root') || undefined}>
        {children}
      </ReactModal>
    );
  }
}

export default ModalContainer;
