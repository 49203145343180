/**
 * Import React Libraries.
 */
import React from 'react';

/**
 * Import third-party libraries.
 */
import {
  FilterChip,
  HyperLink,
  PageWidth,
  StyledTableCellIcon,
  StyledTableCellText,
  StyledTableCellTextWrapper,
  SystemIcons,
  Table,
  TableColumn,
  TablePagination,
  ToastContext, TooltipWrapper
} from '@laerdal/life-react-components';
import styled from 'styled-components';

/**
 * Import custom types.
 */
import {User, UserRecordWrapper, UserStates} from '../../types';

/**
 * Import custom components.
 */
import UserSearch from './UserSearch';

/**
 * Import custom functions.
 */
import Api from '../../utils/api';
import {WithTranslation, withTranslation} from 'react-i18next';
import {NavigateFunction} from 'react-router';
import {ErrorToastOptions} from '../../constants';
import {TableSortingDirection, TableSortProps} from '@laerdal/life-react-components/dist/Table/TableTypes';

const StyledPageWidth = styled(PageWidth)`
  display: flex !important;
  flex-direction: column !important;
  gap: 32px !important;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FilterChipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

/**
 * Add custom types.
 */
interface UserTableRow {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  organizationId: string;
  organizationCount: number;
}

interface UserListState {
  pagination: TablePagination;
  sortDirection: number;
  sortBy: number;
  sortProps?: TableSortProps;
  query: string;
  lastSearchedQuery: string;
  stateFilter?: UserStates | null;
  columns: TableColumn[];
  rows: any[];
  loading: boolean;

}

export class UserList extends React.Component<{
  navigate: NavigateFunction;
  location: Location;
} & WithTranslation, UserListState> {
  private abortController: AbortController;

  constructor(props: any) {
    super(props);
    const {t} = props;

    this.state = {
      columns: [
        {
          key: 'email',
          name: t('Username / Email'),
          sortable: true,
          type: 'custom',
          customContent: (row, key) =>
            <>
              <StyledTableCellIcon>
                {
                  row.state === UserStates.Registered &&
                  <SystemIcons.User/>
                }
              </StyledTableCellIcon>
              <StyledTableCellTextWrapper>
                <StyledTableCellText>
                  {row[key]}
                </StyledTableCellText>
              </StyledTableCellTextWrapper>
            </>
        },
        {
          key: 'firstName',
          name: t('First Name'),
          sortable: true,
        },
        {
          key: 'lastName',
          name: t('Last Name'),
          sortable: true,
        },
        {
          key: 'organization',
          name: t('Organization (default)'),
          type: 'custom',
          customContent: (row, key) => <>
            {
              row.organization &&
              <HyperLink
                id={`${row.organization.id}`}
                href={`/organization/${row.organization.id}`}
                variant="default"
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  event.preventDefault()
                  this.props.navigate(`/organization/${row.organization.id}`);
                }}>
                {row.organization.name}
              </HyperLink>
            }
          </>,
          sortable: true,
          width: 400,
        }
      ],
      rows: [],
      loading: false,
      pagination: {
        from: 0,
        to: 0,
        currentPage: 1,
        total: 0,
        rowsPerPage: 10,
      },
      query: '',
      lastSearchedQuery: '',
      sortBy: 0,
      sortDirection: 0,
    };

    this.abortController = new AbortController();
  }

  /**
   * Retrieves initial users from the API.
   */
  componentDidMount() {
    // Let's setup search params
    this.setupSearchParams();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  componentDidUpdate(
    prevProps: Readonly<WithTranslation>,
    prevState: Readonly<UserListState>,
    snapshot?: any,
  ) {
    if (prevState.query !== this.state.query && this.state.query !== this.state.lastSearchedQuery) {
      this.abortController?.abort();
      this.abortController = new AbortController();
      this.getNewRows(this.state.query, 1, 1, this.state.pagination?.rowsPerPage);
    }
  }

  /**
   * Does all required setup and retrieves initial rows.
   */
  setupSearchParams = (): void => {
    let previousState = {...this.state};
    // Let's get search params
    const searchParams = new URLSearchParams(this.props.location?.search);

    // Let's update query if we have it in the url
    if (searchParams.get('query')) {
      previousState.query = searchParams.get('query')!;
    }

    // Let's update rows per page if we have it in the url
    if (searchParams.get('rowsPerPage')) {
      previousState.pagination = {...previousState.pagination, rowsPerPage: parseInt(searchParams.get('rowsPerPage')!)};
    }

    // Let's update sort direction and sort by
    if (searchParams.get('sortDirection') && searchParams.get('sortBy')) {
      previousState.sortDirection = parseInt(searchParams.get('sortDirection')!);
      previousState.sortBy = parseInt(searchParams.get('sortBy')!);
    }
    previousState.lastSearchedQuery = previousState.query;

    if (searchParams.get('state')) {
      previousState.stateFilter = parseInt(searchParams.get('state')!);
    }

    this.setState({...previousState});

    // Retrieve the rows
    this.getNewRows(
      searchParams.get('query') ? searchParams.get('query')! : previousState.query,
      searchParams.get('from') ? parseInt(searchParams.get('from')!) : previousState.pagination.from,
      searchParams.get('currentPage') ? parseInt(searchParams.get('currentPage')!) : previousState.pagination.currentPage,
      searchParams.get('rowsPerPage') ? parseInt(searchParams.get('rowsPerPage')!) : previousState.pagination?.rowsPerPage,
      searchParams.get('sortBy') ? parseInt(searchParams.get('sortBy')!) : previousState.sortBy,
      searchParams.get('sortDirection') ? parseInt(searchParams.get('sortDirection')!) : previousState.sortDirection,
      searchParams.get('state') ? parseInt(searchParams.get('state')!) : previousState.stateFilter);
  };

  /**
   * Does all required pre-requisites and updates the search parameters for the context.
   * @param search - Search string to use for the row retrieval.
   * @param from - From which row to retrieve the data.
   * @param to - To which row to retrieve the data.
   * @param page - Current page in the table.
   * @param newRowsPerPage - New amount of rows per page.
   * @param newSortBy - An enum value indicating the sort by column.
   * @param newSortDirection - An enum value indicating the sort direction.
   */
  updateSearchParams = (search: string,
                        from: number,
                        to: number,
                        page: number,
                        newRowsPerPage: number,
                        newSortBy?: number,
                        newSortDirection?: number,
                        state?: UserStates | null): void => {
    const params = new URLSearchParams();

    // Let's add query if it is set
    search ? params.append('query', search) : params.delete('query');
    search ? localStorage.setItem('usersQuery', search) : localStorage.removeItem('usersQuery');

    // Let's add from
    from ? params.append('from', from.toString()) : params.delete('from');
    from ? localStorage.setItem('usersFrom', from.toString()) : localStorage.removeItem('usersFrom');

    // Let's add current page
    page ? params.append('currentPage', page.toString()) : params.delete('currentPage');
    page ? localStorage.setItem('usersCurrentPage', page.toString()) : localStorage.removeItem('usersCurrentPage');

    // Let's add rows per page
    newRowsPerPage ? params.append('rowsPerPage', newRowsPerPage.toString()) : params.delete('rowsPerPage');
    newRowsPerPage ? localStorage.setItem('usersRowsPerPage', newRowsPerPage.toString()) : localStorage.removeItem('usersRowsPerPage');

    // Let's add sort by
    newSortBy !== undefined ? params.append('sortBy', newSortBy.toString()) : params.delete('sortBy');
    newSortBy !== undefined ? localStorage.setItem('usersSortBy', newSortBy.toString()) : localStorage.removeItem('userssSortBy');

    // Let's add rows per page
    newSortDirection !== undefined ? params.append('sortDirection', newSortDirection.toString()) : params.delete('sortDirection');
    newSortDirection !== undefined ? localStorage.setItem('usersSortDirection', newSortDirection.toString()) : localStorage.removeItem('usersSortDirection');


    state != undefined ? params.append('state', `${state}`) : params.delete('state');
    state != undefined ? localStorage.setItem('usersState', `${state}`) : localStorage.removeItem('usersState');

    // Let's append context
    this.props.navigate({pathname: 'user', search: params.toString()});
  };

  /**
   * Retrieves new rows from the API.
   * @param search - Search string to use for the row retrieval.
   * @param from - From which row to retrieve the data.
   * @param page - Current page in the table.
   * @param newRowsPerPage - New amount of rows per page.
   * @param sortBy - An enum value indicating the sort by column.
   * @param sortDirection - An enum value indicating the sort direction.
   */
  getNewRows = (search: string,
                from: number,
                page: number,
                newRowsPerPage?: number,
                newSortBy?: number,
                newSortDirection?: number,
                state?: UserStates | null) => {
    // Assign temporary rows per page based on the global variable
    const tmpRowsPerPage = newRowsPerPage ? newRowsPerPage : this.state.pagination?.rowsPerPage;

    // Assign temp sort by and sort direction
    const tmpSortBy = newSortBy !== undefined ? newSortBy : this.state.sortBy;
    const tmpSortDirection = newSortDirection !== undefined ? newSortDirection : this.state.sortDirection;
    const tmplState = state === undefined ? this.state.stateFilter : state;

    // Let's update search parameters
    this.updateSearchParams(search, from, from + tmpRowsPerPage - 1, page, tmpRowsPerPage, tmpSortBy, tmpSortDirection, tmplState);

    this.setState({loading: true});

    const {t} = this.props;
    const {addToast} = this.context as React.ContextType<typeof ToastContext>;


    const signal = this.abortController.signal;
    Api.FindUsers(search, from - 1 >= 0 ? from - 1 : from, tmpRowsPerPage, undefined, tmpSortBy, tmpSortDirection, tmplState, signal)
      .then((users) => this.assignUsers(users, page * tmpRowsPerPage - tmpRowsPerPage + 1, page * tmpRowsPerPage, page, tmpRowsPerPage, search))
      .catch(() => {
        if (!signal.aborted) {
          addToast(t('There was a problem finding users'), ErrorToastOptions);
        }
      })
      .finally(() => {
        this.setState({loading: false});
      });
  };

  /**
   * Assign retrieved rows and update pagination.
   * @param userRecordWrapper - User Record Wrapper containing rows and total row count.
   * @param from - From which row data was retrieved.
   * @param to - Till which row data was retrieved.
   * @param page - Current page in the table.
   * @param rowsPerPage - New rows per page that needs to be assigned.
   */
  assignUsers = (
    userRecordWrapper: UserRecordWrapper,
    from: number,
    to: number,
    page: number,
    rowsPerPage: number,
    query: string) => {
    // Let's pull out the new rows data
    const newRows = userRecordWrapper.records.map((record: User) => {
      return {
        id: record.id,
        email: record.email,
        firstName: record.firstName,
        lastName: record.lastName,
        identityId: record.identityId,
        organization: record.currentOrganization,
        state: record.state,
      };
    });

    // Let's assign new rows and update pagination
    this.setState({
      rows: newRows,
      pagination: {
        from: from < 0 ? 0 : from,
        to: to > userRecordWrapper.totalCount ? userRecordWrapper.totalCount : to,
        currentPage: page,
        total: userRecordWrapper.totalCount,
        rowsPerPage,
      },
      query,
    });
  };

  /**
   * Navigate to the next page.
   */
  onNextPage = () => {
    // Let's check if we can navigate
    if (this.state.pagination?.currentPage! * this.state.pagination?.rowsPerPage < this.state.pagination?.total!) {
      // Let's get new rows
      this.getNewRows(this.state.query, this.state.pagination?.from! + this.state.pagination?.rowsPerPage, this.state.pagination?.currentPage! + 1);
    }
  };

  /**
   * Navigate to the previous page.
   */
  onPreviousPage = () => {
    // Let's check if we can navigate
    if (this.state.pagination?.currentPage! * this.state.pagination?.rowsPerPage - this.state.pagination?.rowsPerPage >= 0) {
      // Let's get new rows
      this.getNewRows(this.state.query, this.state.pagination?.from! - this.state.pagination?.rowsPerPage, this.state.pagination?.currentPage! - 1);
    }
  };

  /**
   * Updates rows per page and retrieves new data.
   * @param newRowsPerPage - New rows per page value set in the table.
   */
  onRowsPerPageChange = (newRowsPerPage: number) => {
    // Let's retrieve fresh data
    this.getNewRows(this.state.query, 1, 1, newRowsPerPage);
  };

  /**
   * Navigates to the user details page.
   * @param row - Row which was clicked in the table.
   */
  onRowClick = (row: UserTableRow) => {
    this.props.navigate(`/user/${row.id}`);
  };

  /**
   * Searches the table based on the entered query.
   * @param searchQuery - Search query based on which to search for data.
   */
  onSearchRows = (searchQuery: string) => {
    // Let's update the query
    this.setState({query: searchQuery, lastSearchedQuery: Boolean(searchQuery) ? '' : 'blank'});
  };

  /**
   * Applies sorting for the table.
   * @param key - Column key to which sorting should be applied.
   * @param direction - Direction in which to sort data.
   */
  onApplySorting = (key: string, direction?: TableSortingDirection): void => {
    let sortBy = 0;
    let sortDirection = 0;
    if (!!key && !!direction) {
      // Let's retrieve sort by and sort direction
      sortBy = this.state.columns.findIndex((column: TableColumn) => column.key === key);
      sortDirection = direction === 'asc' ? 0 : 1;
    }

    // Set sort by and sort direction
    this.setState({sortBy, sortDirection});

    // Let's search
    this.getNewRows(this.state.query, this.state.pagination?.from!, this.state.pagination?.currentPage, this.state.pagination?.rowsPerPage, sortBy, sortDirection);
  };

  onStateFilterChange = (state?: UserStates | null) => {
    this.setState({stateFilter: state});
    this.getNewRows(this.state.query, 1, 1, this.state.pagination?.rowsPerPage, this.state.sortBy, this.state.sortDirection, state);
  }

  render() {
    return (
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
        <h1>Users</h1>

        <SearchWrapper>
          <UserSearch onSearch={(query: string) => this.onSearchRows(query)}
                      initialValue={new URLSearchParams(this.props.location?.search).get('query')!}/>
          <FilterChipsWrapper>
            <FilterChip text={'All user types'}
                        selected={this.state.stateFilter == null}
                        onClick={() => this.onStateFilterChange(null)}/>

            <TooltipWrapper position={'top'} label={'Has set a password and can login.'}>
              <FilterChip text={'Registered'}
                          selected={this.state.stateFilter == UserStates.Registered}
                          onClick={() => this.onStateFilterChange(UserStates.Registered)}/>
            </TooltipWrapper>
            <TooltipWrapper position={'top'} label={'Has been added to an organization but did not register yet. They can register if they try to log in.'}>
              <FilterChip text={'Unregistered'}
                          selected={this.state.stateFilter == UserStates.Unregistered}
                          onClick={() => this.onStateFilterChange(UserStates.Unregistered)}/>
            </TooltipWrapper>

            <TooltipWrapper position={'top'} label={'An unregistered user that is a SalesForce contact of at least one organization. They need to be added/invited to register.'}>
              <FilterChip text={'SF contact only'}
                          selected={this.state.stateFilter == UserStates.Contact}
                          onClick={() => this.onStateFilterChange(UserStates.Contact)}/>
            </TooltipWrapper>
          </FilterChipsWrapper>
        </SearchWrapper>

        <Table
          columns={this.state.columns}
          rows={this.state.rows}
          border={true}
          remoteOperations={true}
          pagination={this.state.pagination}
          sortProps={this.state.sortProps}
          onRowsPerPageChange={(newRowsPerPage: number) => this.onRowsPerPageChange(newRowsPerPage)}
          onTriggerSortingChange={(key: string, direction?: TableSortingDirection) => this.onApplySorting(key, direction)}
          onNextPageClick={() => this.onNextPage()}
          onPreviousPageClick={() => this.onPreviousPage()}
          selectable={true}
          onSelectionChange={(row: UserTableRow) => this.onRowClick(row)}
          showLoadingIndicator={this.state.loading}
        />
      </StyledPageWidth>
    );
  }
}

UserList.contextType = ToastContext;

export default withTranslation('User')(UserList);
