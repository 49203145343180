/**
 * Import React libraries.
 */
import React from "react";

/**
 * Import third-party libraries.
 */
import {BREAKPOINTS, COLORS, Size} from "@laerdal/life-react-components";
import {useTranslation} from "react-i18next";
import {ErrorToastOptions, SuccessToastOptions} from "../../../constants";
import Api from "../../../utils/api";
import {useMediaQuery} from "react-responsive";

import {ConfirmationModal} from "../../../components/modals/ConfirmationModal";
import {useToastContext} from "../../../userContext";
import moment from "moment";
import {Organization, OrganizationService, PaymentType, Service, Subscription} from "../../../types";

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmitted?: () => void;

  service: Service | undefined;
  organization: Organization | undefined;
  organizationService: OrganizationService | undefined;
  subscription: Subscription | undefined;
}

const CancelSubscriptionModal = ({
                                   visible,
                                   onClose,
                                   onSubmitted,
                                   service,
                                   organization,
                                   organizationService,
                                   subscription
                                 }: Props) => {
  const {t} = useTranslation("OrganizationServices");
  const {addToast} = useToastContext();

  const licenseName = service?.name;
  const licenseCount = organizationService!.members.length + (organizationService?.invitations?.filter((x) => x.isActive)?.length ?? 0)
  const planName = organizationService?.subscription?.plan.name;
  const tierName = organizationService?.subscription?.tier.name;


  const licenseCountText = `(${licenseCount} ${licenseCount === 1 ? "user" : "users"})`;
  const planAndTierName = planName && `- ${planName} ${tierName}`;

  const cancelSubscription = (): Promise<any> => {
    return Api.CancelSubscription(organization!.id, service!.id)
      .then(() => addToast(t("Service license successfully canceled"), SuccessToastOptions))
      .then(() => onSubmitted && onSubmitted())
      .catch(() => addToast(t("There was a problem cancelling subscription"), ErrorToastOptions))
      .finally(() => onClose());
  };

  const expirationDate = subscription?.expirationDate ? moment(subscription?.expirationDate).format("LL") : 'N/A';

  return (
    <ConfirmationModal
      visible={visible}
      cancel={onClose}
      confirm={cancelSubscription}
      modalSize={Size.Large}
      confirmTitle={t("Cancel subscription")}
      cancelTitle={t("Close")}
      modalTitle={t("Cancel subscription")}
      confirmButtonVariant="critical">
      <p>
        <strong style={{textTransform: 'capitalize'}}>
          {licenseName} {planAndTierName} {licenseCountText}
        </strong>
        <br/>
        <span style={{color: COLORS.neutral_600}}>
          <strong>
            {t("Payment method: ")}
          </strong>
          {
            subscription?.paymentType == PaymentType.Paddle &&
            t("Credit card (Paddle)")
          }
          {
            subscription?.paymentType == PaymentType.Adyen &&
            t("Credit card (Adyen)")
          }
          {
            subscription?.paymentType == PaymentType.Invoice &&
            t("Pay by invoice")
          }
        </span>
      </p>
      <br/>
      <p>
        The customer will still be able to use the subscription for the remaining time, after which it will
        not be renewed
        <br/>
        <br/>
        Subscription expiry date: <strong>{expirationDate}</strong>
      </p>
      <br/>
    </ConfirmationModal>
  );
};

export default CancelSubscriptionModal;


