import React, {useMemo} from 'react';
import {
  BasicDropdown, COLORS, ComponentMStyling, ComponentTextStyle,
  ComponentXSStyling,
  DropdownItem,
  InputLabel,
  InputWrapper,
  States,
  TextField
} from "@laerdal/life-react-components";
import {FormGroupContainer, FormGroupHeader} from "../../Common/styles";
import {useTranslation} from "react-i18next";
import {useController, useFormContext} from "react-hook-form";
import {BillingDetails, EditLicenseForm} from "../../Common/types";
import {
  AccessLevel,
  Organization,
  PaymentType,
  Subscription,
  SubscriptionStatus,
  VALID_SUBSCRIPTION_STATUSES
} from "../../../../../types";
import {useUserContext} from "../../../../../userContext";
import styled from "styled-components";


const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const DetailsRow = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
`;

const DetailsItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
`;

const DetailsItemLabel = styled.div`
  ${ComponentXSStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
`;

const DetailsItemValue = styled.div`
  ${ComponentMStyling(ComponentTextStyle.Regular, COLORS.black)}
`;


interface Props {
  billingDetails: BillingDetails;
  subscription: Subscription;
  isSelfService: boolean;
  isSalesforce: boolean;
}

export const BillingDetailsGroup = ({billingDetails, subscription, isSelfService, isSalesforce}: Props) => {
  const {t} = useTranslation('OrganizationServices');
  const {accessLevel} = useUserContext();
  const {control, watch} = useFormContext<EditLicenseForm>()

  const isTrial = watch('isTrial');
  const poNumberController = useController({control, name: 'poNumber'});

  const paymentTypes = useMemo(() => [{
    value: `${PaymentType.Paddle}`,
    displayLabel: t('Credit card (Paddle)'),
  },
    {
      value: `${PaymentType.Adyen}`,
      displayLabel: t('Credit card (Adyen)'),
    },
    {
      value: `${PaymentType.Invoice}`,
      displayLabel: t('Pay-by-invoice (Saleforce)'),
    }] as DropdownItem[], [])


  const readOnly =
    accessLevel == AccessLevel.ReadOnly ||
    subscription?.paymentType == PaymentType.Paddle && VALID_SUBSCRIPTION_STATUSES.includes(subscription.status!) ||
    !!subscription?.subscriptionNumber ||
    isSelfService && subscription?.status != SubscriptionStatus.Active ||
    isTrial;

  return (
    <FormGroupContainer>
      <FormGroupHeader>
        {t('Payment & Billing')}
      </FormGroupHeader>

      <DetailsContainer>
        <DetailsRow>
          <DetailsItem>
            <DetailsItemLabel>{t('Organization Name')}</DetailsItemLabel>
            <DetailsItemValue>{billingDetails.billingOrganizationName || 'N/A'}</DetailsItemValue>
          </DetailsItem>
          <DetailsItem>
            <DetailsItemLabel>{t('Primary Contact Person')}</DetailsItemLabel>
            <DetailsItemValue>{billingDetails.billingContactPerson || 'N/A'}</DetailsItemValue>
          </DetailsItem>
        </DetailsRow>
        <DetailsRow>
          <DetailsItem>
            <DetailsItemLabel>{t('Billing Email Address')}</DetailsItemLabel>
            <DetailsItemValue>{billingDetails.billingEmailAddress || 'N/A'} </DetailsItemValue>
          </DetailsItem>
          <DetailsItem>
            <DetailsItemLabel>{t('VAT number')}</DetailsItemLabel>
            <DetailsItemValue>{billingDetails.vatNumber || 'N/A'}</DetailsItemValue>
          </DetailsItem>
        </DetailsRow>

      </DetailsContainer>

      <InputWrapper>
        <InputLabel inputId={'paymentType'} text={'Payment method:'}/>
        <BasicDropdown list={paymentTypes}
                       id={'paymentType'}
                       value={`${subscription?.paymentType}`}
                       readOnly={true}
                       onSelect={() => {
                       }}/>
      </InputWrapper>

      <InputWrapper>
        <InputLabel inputId="PoNumber" text={t('PO Number (optional)')}/>
        <TextField
          id="PoNumber"
          readOnly={readOnly}
          {...poNumberController.field}
          validationMessage={poNumberController.fieldState.error?.message}
          state={poNumberController.fieldState.error ? States.Invalid : undefined}
        />
      </InputWrapper>

    </FormGroupContainer>
  );

}