/**
 * Import React libraries.
 */
import React, { FunctionComponent } from 'react';

/**
 * Import third-party libraries.
 */
import { COLORS } from '@laerdal/life-react-components';
import styled from 'styled-components';

/**
 * Import custom styles.
 */
import { LinkStyling } from './Links';

// Create custom PageCard styles
export const Card = styled.div`
  box-sizing: border-box;
  width: 320px;
  padding: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${COLORS.neutral_200};

  h1 {
    margin: 16px 0 0 0;
    font-size: 32px;
    font-weight: 300;
  }

  p {
    color: ${COLORS.neutral_600};
    margin: 16px 0 16px 0;
  }
  
  a {
    margin: 0 0 16px 0;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    ${LinkStyling};
  }
`;

interface PageCardProps {
  title: string;
  text: string;
  textLineTwo?: string;
  linkTo?: string;
  linkText?: string;
  image: string;
}

const PageCard: FunctionComponent<PageCardProps> = ({ title, text, textLineTwo, linkTo, linkText, image }: PageCardProps) => {
  return (
    <Card>
      <img src={image} alt={''} data-testid="PageCard-Image" />
      <h1 data-testid="PageCard-Title">{title}</h1>
      <p data-testid="PageCard-Text">{text}</p>
      {textLineTwo && <p data-testid="PageCard-LineTwoText">{textLineTwo}</p>}
      {linkTo && linkText && <a href={linkTo} data-testid="PageCard-Link">{linkText}</a>}
    </Card>
  );
};

export default PageCard;
