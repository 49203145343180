/**
 * Import React libraries.
 */
import React, { useState } from "react";

/**
 * Import third-party libraries.
 */
import { useTranslation } from "react-i18next";
import { ModalDialog } from "@laerdal/life-react-components";
import Api from "../../../../utils/api";
import { ErrorToastOptions, SuccessToastOptions } from "../../../../constants";
import { useToastContext } from "../../../../userContext";

/**
 * Custom types.
 */
export type ConfirmDeleteToSProps = {
  closeModal: () => void;
  tosName: string;
  tosId: string;
};

const ConfirmDeleteToSModal: React.FunctionComponent<ConfirmDeleteToSProps> = ({
                                                                                 closeModal,
                                                                                 tosName,
                                                                                 tosId
                                                                               }: ConfirmDeleteToSProps) => {
  // Globally used variables within the component.
  const { t } = useTranslation("OrganizationServices");
  const [inProcess, setInProcess] = useState<boolean>(false);
  const { addToast } = useToastContext();

  return (
    <ModalDialog
      isModalOpen={true}
      submitAction={() => {
      }}
      closeModalAndClearInput={closeModal}
      closeAction={closeModal}
      title={t("Delete Terms of Service")}
      buttons={
        [
          {
            id: "cancel",
            variant: "tertiary",
            text: t("No, cancel"),
            action: closeModal
          },
          {
            id: "save",
            action: () => {
              setInProcess(true);
              Api.DeleteToS(tosId)
                .then(() => {
                  addToast(t("ToS successfully deleted"), SuccessToastOptions);
                  closeModal();
                })
                .catch((error) => {
                  addToast(t("Error occured while deleting the ToS"), ErrorToastOptions);
                  closeModal();
                  console.log(error);
                });
            },
            loading: inProcess,
            variant: "critical",

            text: t("Yes, delete"),
            disabled: inProcess
          }
        ] as any
      }>
      <>
        <p><strong>{tosName}</strong></p>
        <br />
        <p>{t("Are you sure you want to delete this ToS? Consent related to the ToS will NOT be deleted")}</p>
      </>
    </ModalDialog>
  );
};

export default ConfirmDeleteToSModal;
