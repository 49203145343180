import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { BREAKPOINTS, ModalDialog, Size, ToastColor, ToastPosition } from "@laerdal/life-react-components";

import { useToastContext, useUserContext } from "../../../userContext";
import Api from "../../../utils/api";
import { useMediaQuery } from "react-responsive";

type RemoveServiceMemberProps = {
  closeModal: any;
  isModalOpen: boolean;
  orgId: string;
  organizationServiceId: string;
  memberId?: string;
  memberEmail?: string;
  memberRole?: string;
  invitationCode?: string;
  getOrganizationService: () => void;
};

const RemoveServiceMemberModal: React.FunctionComponent<RemoveServiceMemberProps> = ({
                                                                                       closeModal,
                                                                                       isModalOpen,
                                                                                       orgId,
                                                                                       organizationServiceId,
                                                                                       memberId,
                                                                                       memberEmail,
                                                                                       memberRole,
                                                                                       invitationCode,
                                                                                       getOrganizationService
                                                                                     }: RemoveServiceMemberProps) => {
  const { t } = useTranslation("OrganizationServices");
  const { authenticated } = useUserContext();
  const { addToast } = useToastContext();
  const isMediumScreen = useMediaQuery({ query: BREAKPOINTS.MEDIUM.replace("@media ", "") });

  const [busy, setBusy] = useState(false);

  const removeMember = (orgServiceId: string, memberId: string) => {
    if (authenticated) {
      if (!!invitationCode) {
        setBusy(true);
        Api.DeleteOrganizationServiceInvitation(orgId, orgServiceId, invitationCode)
          .then(() => {
            setBusy(false);
            closeModal();
            getOrganizationService();
            addToast(t("Invitation was removed successfully."), {
              color: ToastColor.GREEN,
              showCloseButton: true,
              autoClose: true,
              position: ToastPosition.TOPMIDDLE
            });
          })
          .catch(() => {
            setBusy(false);
            closeModal();
            addToast(t("There was a problem removing the invitation."), {
              color: ToastColor.RED,
              showCloseButton: true,
              autoClose: true,
              position: ToastPosition.TOPMIDDLE
            });
          });
      } else {
        setBusy(true);
        Api.RemoveMemberFromOrganizationService(orgId, orgServiceId, memberId)
          .then(() => {
            setBusy(false);
            closeModal();
            getOrganizationService();
            addToast(t("Service member was removed successfully."), {
              color: ToastColor.GREEN,
              showCloseButton: true,
              autoClose: true,
              position: ToastPosition.TOPMIDDLE
            });
          })
          .catch(() => {
            setBusy(false);
            closeModal();
            addToast(t("There was a problem removing the service member."), {
              color: ToastColor.RED,
              showCloseButton: true,
              autoClose: true,
              position: ToastPosition.TOPMIDDLE
            });
          });
      }
    }
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {
      }}
      closeModalAndClearInput={closeModal}
      closeAction={closeModal}
      title={t("Remove member from organization service")}
      size={isMediumScreen ? Size.Medium : Size.Small}
      buttons={
        [
          {
            id: "cancel",
            variant: "tertiary",
            text: t("Cancel"),
            action: closeModal
          },
          {
            id: "remove",
            action: () => {
              removeMember(organizationServiceId, memberId!);
            },
            variant: "primary",
            text: t("Remove member"),
            loading: busy,
            disabled: busy
          }
        ] as any
      }>
      <>
        <p>
          <strong>
            {memberEmail} {memberRole ? "(" + memberRole + ")" : ""}
          </strong>
        </p>
        <p>{t("Do you want to remove this user from the the organization service?")}</p>
      </>
    </ModalDialog>
  );
};

export default RemoveServiceMemberModal;
