import * as yup from 'yup';
import {INCLUDE_FEDERAL_TAX_ID_COUNTRIES, LOCAL_SEGMENT_COUNTRIES} from "./organization-creation-config";
import {TFunction} from "i18next";
import {CountryDto} from "../../../types";


export const createOrganizationSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().ensure().required(t('This field is required')!),
    customerNo: yup.string().ensure(),
    sfCorporateSegment: yup.string().when('address', ([address], schema) => {
      return LOCAL_SEGMENT_COUNTRIES.includes(address?.countryCode?.toUpperCase()) ? schema.required() : schema;
    }),
    industry: yup.number().when('address', ([address], schema) => {
      return LOCAL_SEGMENT_COUNTRIES.includes(address?.countryCode?.toUpperCase()) ? schema.required() : schema;
    }),
    taxExempt: yup.boolean(),
    vatNumber: yup.string().ensure().max(16, t('Please enter 16 characters or less')!),
    federalTaxId: yup.string().ensure().when('countryCode', ([value], schema) => INCLUDE_FEDERAL_TAX_ID_COUNTRIES.includes(value) ? schema.required(t('This field is required')!) : schema),
    billingContactPersonFirstName: yup.string().ensure().required(t('This field is required')!),
    billingContactPersonLastName: yup.string().ensure().required(t('This field is required')!),
    billingEmailAddress: yup.string().ensure().email(t('Please enter a valid email address')!).required(t('This field is required')!),
    sfLocalSegment: yup.string().when('address', ([address], schema) => {
      return LOCAL_SEGMENT_COUNTRIES.includes(address?.countryCode?.toUpperCase()) ? schema.required() : schema;
    }),
    address: yup.object().shape({
      companyName: yup.string().ensure().required(t('This field is required')!),
      additionalName: yup.string().ensure(),
      countryCode: yup.string().ensure().required(t('This field is required')!),
      addressLine: yup.string().ensure().required(t('This field is required')!),
      addressLineTwo: yup.string().ensure(),
      city: yup.string().ensure().required(t('This field is required')!),
      state: yup.string().ensure()
        .when('countryCode', ([value], schema, options) =>
          options.context.countries?.find((a: CountryDto) => a.codeAlpha2 == value)?.states
            ? schema.required(t('This field is required')!)
            : schema),
      zipCode: yup.string().ensure().required(t('This field is required')!),
      phoneNumber: yup.string().ensure().required(t('This field is required')!),
      email: yup.string().ensure().email(t('Please enter a valid email address')!),
    }),
    shippingAddressSameAsBilling: yup.boolean(),
    shippingAddress:
      yup.object().shape({
        companyName: yup.string().ensure(),
        additionalName: yup.string().ensure(),
        countryCode: yup.string().ensure(),
        addressLine: yup.string().ensure(),
        addressLineTwo: yup.string().ensure(),
        city: yup.string().ensure(),
        state: yup.string().ensure(),
        zipCode: yup.string().ensure(),
        phoneNumber: yup.string().ensure(),
        email: yup.string().ensure(),
      }).when('shippingAddressSameAsBilling', {
        is: false,
        then: () => yup.object().shape({
          companyName: yup.string().ensure(),
          additionalName: yup.string().ensure(),
          countryCode: yup.string().ensure().required(t('This field is required')!),
          addressLine: yup.string().ensure().required(t('This field is required')!),
          addressLineTwo: yup.string().ensure(),
          city: yup.string().ensure().required(t('This field is required')!),
          state: yup.string().ensure()
            .when('$countryCode', ([value], schema, options) =>
              options.context.countries?.find((a: CountryDto) => a.codeAlpha2 == value)?.states
                ? schema.required(t('This field is required')!)
                : schema),
          zipCode: yup.string().ensure().required(t('This field is required')!),
          phoneNumber: yup.string().ensure().required(t('This field is required')!),
          email: yup.string().ensure().email(t('Please enter a valid email address')!),
        }),
        otherwise: (schema) => schema,
      })
  });

