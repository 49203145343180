import React from 'react';
import styled from "styled-components";
import {
  Banner,
  COLORS,
  ComponentSStyling,
  ComponentTextStyle,
  Size,
  States,
  ToggleSwitch,
  ValidationMessage
} from "@laerdal/life-react-components";
import {UseControllerReturn, useFormContext} from "react-hook-form";
import moment from "moment/moment";
import {ServicePlan, ServicePlanTier, Subscription, SubscriptionStatus} from "../../../../types";
import {SubscriptionActionBlock} from "./SubscriptionActionBlock";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid ${COLORS.neutral_400};
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  margin-bottom: 8px;
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
`;

const Description = styled.div`
  white-space: pre-wrap;
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
`;

interface Props {
  readOnly?: boolean;
  plan?: ServicePlan;
  tier?: ServicePlanTier;
  subscription?: Subscription;
  controller: UseControllerReturn<any, any>,
  expirationController?: UseControllerReturn<any, any>
}

export const SubscriptionTrialBox = ({readOnly, tier, plan, subscription, controller, expirationController}: Props) => {

  const form = useFormContext();
  const expiration = form.watch(expirationController?.field.name)

  const duration = tier?.defaultTrialDuration ?? plan?.defaultTrialDuration ?? 0;
  const disabled = readOnly || !tier?.allowTrial && !plan?.allowTrial || expiration;
  const link = !readOnly && expiration
    ? {
      link: "Dismiss",
      linkText: "Dismiss",
      linkAction: (e: any) => {
        e.preventDefault();
        expirationController!.field.onChange(undefined);

      }
    }
    : {};

  return (
    <SubscriptionActionBlock
      title={'Start a trial'}
      description={
        <>
          This customer will be given a {duration}-day trial of the application, after which they will require an active
          subscription to continue using the application.
        </>
      }
      banner={
        expiration && subscription?.status != SubscriptionStatus.Trial
          ? <Banner
            type="positive"
            size={Size.Small}
            fullWidth={false}
            {...link}
          >
            Customer completed a trial on {moment(expirationController!.field.value).format('MMM Do, YYYY')}
          </Banner>
          : undefined}
      action={<>
        <ToggleSwitch id={'isTrial'}
                      label={'Activate trial period'}
                      size={Size.Medium}
                      onToggle={controller.field.onChange}
                      selected={controller.field.value}
                      ref={controller.field.ref}
                      onMouseUp={controller.field.onBlur}
                      disabled={disabled}/>
        {
          controller.fieldState.error &&
          <ValidationMessage type={States.Invalid}>{controller.fieldState.error?.message}</ValidationMessage>
        }
      </>}
    />
  )
}