import React, {useContext} from 'react';
import {useUserContext} from '../../userContext';


export const useRolesAuthorization = () => {
  const context = useUserContext();


  const isInRole = (roles: string[]) => {
    return roles.filter(a => context.roles.includes(a)).length !== 0;
  };

  return {isInRole};
};