import React, {useEffect, useMemo} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useUserContext} from "../../../../../userContext";
import {useController, useFormContext} from "react-hook-form";
import {
  E_COURSES_FREE_PLAN,
  EditLicenseForm,
  SubscriptionDurations,
  SubscriptionRenewalPeriods
} from "../../Common/types";
import {
  AccessLevel,
  PaymentType,
  Service,
  Subscription,
  SubscriptionStatus,
  VALID_SUBSCRIPTION_STATUSES
} from "../../../../../types";
import {
  Banner,
  BasicDropdown,
  DatepickerField,
  InputLabel,
  NumberField,
  Size,
  States,
  SystemIcons,
  TextField,
  ToggleSwitch
} from "@laerdal/life-react-components";
import {SubscriptionTrialBox} from "../SubscriptionTrialBox";
import moment from "moment/moment";
import {FormGroupContainer, FormGroupHeader, InputRow, InputWrapper} from "../../Common/styles";
import {getDefaultRenewalPeriod} from "../../Common/helpers";


interface Props {
  service: Service;
  subscription: Subscription;
  isSelfService: boolean;
  isSalesforce: boolean;
}

export const SubscriptionOptionsGroup = ({service, subscription, isSelfService, isSalesforce}: Props) => {
  const {t} = useTranslation('OrganizationServices');
  const {accessLevel} = useUserContext();
  const {control, watch, setValue} = useFormContext<EditLicenseForm>()

  const planController = useController({control: control, name: 'plan'});
  const tierController = useController({control: control, name: 'tier'});
  const maxSubscriptionInstancesController = useController({control: control, name: 'maxSubscriptionInstances'});

  const durationController = useController({control: control, name: 'duration'});
  const startDateController = useController({control: control, name: 'startDate'});
  const expirationDateController = useController({control: control, name: 'expirationDate'});

  const autoRenewController = useController({control: control, name: 'autoRenew'});
  const renewalPeriodController = useController({control: control, name: 'renewalPeriodInMonths'});

  const isTrialController = useController({control: control, name: 'isTrial'});
  const trialExpirationController = useController({control: control, name: 'trialExpirationDate'});

  const startDate = watch('startDate');
  const expirationDate = watch('expirationDate');
  const isTrial = watch('isTrial');
  const plan = watch('plan');
  const tier = watch('tier');
  const duration = watch('duration');
  const autoRenew = watch('autoRenew');

  const allowTrial = plan?.allowTrial || tier?.allowTrial;
  const plans = useMemo(() =>
    service.id.toLowerCase() == process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase()
      ? [
        E_COURSES_FREE_PLAN,
        ...service!.availablePlans!,
      ]
      : service.id.toLowerCase() == process.env.REACT_APP_SCENARIO_CLOUD_SERVICE_ID?.toLowerCase() && subscription.plan.id.toLowerCase() == process.env.REACT_APP_STARTER_PLAN_ID?.toLowerCase() ? service?.availablePlans 
      : service.id.toLowerCase() == process.env.REACT_APP_SCENARIO_CLOUD_SERVICE_ID?.toLowerCase() ? [ ...service.availablePlans!.filter(x => x.id.toLowerCase() != process.env.REACT_APP_STARTER_PLAN_ID?.toLowerCase())] 
      : service?.availablePlans ?? [], [service]);

  const tiers = useMemo(() => plan?.tiers ?? [], [plan]);

  useEffect(() => {
    if (!planController.fieldState.isTouched) {
      return;
    }

    if (!plan?.tiers?.find((a) => a.id === tier?.id)) {
      setValue('tier', plan?.tiers[0]);
    }

    setValue('startDate', new Date());
  }, [plan]);

  useEffect(() => {
    if (!planController.fieldState.isTouched && !tierController.fieldState.isTouched) {
      return;
    }

    if (!allowTrial) {
      setValue('isTrial', false);
    }
  }, [allowTrial]);

  useEffect(() => {
    if (!isTrialController.fieldState.isTouched) {
      return;
    }

    if (isTrial) {
      const trialDays = moment()
        .add(tier?.defaultTrialDuration || plan?.defaultTrialDuration || 0, "days")
        .toDate();
      setValue('duration', 'custom');
      setValue('startDate', new Date());
      setValue('expirationDate', trialDays);
    }
  }, [isTrial]);

  useEffect(() => {
    if (!durationController.fieldState.isTouched) {
      return;
    }

    if (duration !== 'custom') {
      setValue('expirationDate', moment(startDate).add(+duration, 'years').toDate());
    }
  }, [duration]);

  useEffect(() => {
    if (!autoRenewController.fieldState.isTouched) {
      return;
    }

    if (autoRenew && expirationDate) {
      setValue('renewalPeriodInMonths', getDefaultRenewalPeriod(startDate, expirationDate));
    }
  }, [autoRenew, subscription]);

  useEffect(() => {
    (isTrial || tier?.enableUnlimitedDuration) && setValue('autoRenew', false);
  }, [isTrial, tier]);

  useEffect(() => {
    !autoRenew && setValue('renewalPeriodInMonths', 0);
  }, [autoRenew]);

  useEffect(() => {
    (!plan?.allowTrial || !tier?.allowTrial) && setValue('isTrial', false);
  }, [plan, tier]);

  const showForm = subscription?.isActive ||
    (process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase() == service.id.toLowerCase() &&
      plan?.id != E_COURSES_FREE_PLAN.id);

  const readOnlyTrial =
    accessLevel == AccessLevel.ReadOnly ||
    isSelfService && !isSalesforce && VALID_SUBSCRIPTION_STATUSES.includes(subscription?.status!) && subscription?.paymentType == PaymentType.Paddle ||
    isSelfService && isSalesforce && VALID_SUBSCRIPTION_STATUSES.includes(subscription?.status!) && !!subscription.subscriptionNumber;

  const readOnlyOptions =
    accessLevel == AccessLevel.ReadOnly ||
    readOnlyTrial && !isTrial;

  const showBanner =
    isSelfService && !VALID_SUBSCRIPTION_STATUSES.includes(subscription?.status!) ||
    isSelfService && !isTrial && subscription?.status == SubscriptionStatus.Trial;

  return (
    <FormGroupContainer>
      <FormGroupHeader>{t('Subscription options')}</FormGroupHeader>
      {
        showBanner && isSalesforce &&
        <Banner type={'neutral'} icon={<SystemIcons.Information/>} size={Size.Small}>
          Currently, it is only possible to activate a trial for this application. To create a full license, please
          use Salesforce.
        </Banner>
      }
      {
        showBanner && !isSalesforce &&
        <Banner type={'neutral'} icon={<SystemIcons.Information/>} size={Size.Small}>
          Currently, it is only possible to activate a trial for this application. Customer will need to purchase the full
          license.
        </Banner>
      }

      {
        showForm &&
        <SubscriptionTrialBox controller={isTrialController}
                              expirationController={trialExpirationController}
                              readOnly={readOnlyTrial}
                              subscription={subscription}
                              plan={plan}
                              tier={tier}/>
      }

      {
        (showForm || process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase() == service.id.toLowerCase()) &&
        <InputWrapper>
          <InputLabel inputId="planType" text={t('Plan type')}/>
          <BasicDropdown
            list={plans!.map((plan) => ({value: plan.id, displayLabel: plan.name}))}
            id="planType"
            readOnly={readOnlyOptions}
            messageOnNoResults={t('No plans were found')}
            onSelect={(value: string) => {
              planController.field.onChange(plans?.find((a) => a.id === value));
            }}
            value={planController.field.value?.id}
            onBlur={planController.field.onBlur}
            ref={planController.field.ref}
            activeValidationMessage={planController.fieldState.error?.message}
            placeholder={t('Select ...')}
          />
        </InputWrapper>
      }

      {showForm && (
        <>
          {process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase() != service.id.toLowerCase() && (
            <InputWrapper>
              <InputLabel inputId="licenseTier" text={t('License tier')}/>
              <BasicDropdown
                list={tiers.map((tier) => ({value: tier.id, displayLabel: tier.name}))}
                id="licenseTier"
                readOnly={readOnlyOptions}
                messageOnNoResults={t('No tiers were found')}
                onSelect={(value: string) => {
                  tierController.field.onChange(tiers?.find((a) => a.id === value));
                }}
                value={tierController.field.value?.id}
                onBlur={tierController.field.onBlur}
                ref={tierController.field.ref}
                activeValidationMessage={tierController.fieldState.error?.message}
                placeholder={t('Select ...')}
              />
            </InputWrapper>
          )}
          {tier?.individualLicensing && (
            <InputWrapper>
              <InputLabel inputId="maxSubscriptionInstances" text={t('Number of licenses')}/>
              <NumberField
                type={'NumberField'}
                size={Size.Medium}
                id="maxSubscriptionInstances"
                readOnly={readOnlyOptions}
                state={maxSubscriptionInstancesController.fieldState.error ? States.Invalid : undefined}
                note={maxSubscriptionInstancesController.fieldState.error?.message}
                onChange={maxSubscriptionInstancesController.field.onChange}
                value={maxSubscriptionInstancesController.field.value}
                onBlur={maxSubscriptionInstancesController.field.onBlur}
                ref={maxSubscriptionInstancesController.field.ref}
                name={maxSubscriptionInstancesController.field.name}
              />
            </InputWrapper>
          )}

          <InputWrapper>
            <InputLabel inputId="duration" text={t('Duration')}/>
            <BasicDropdown
              id="duration"
              list={SubscriptionDurations}
              readOnly={readOnlyOptions || isTrial || tier?.enableUnlimitedDuration}
              placeholder={t('Select')}
              value={durationController.field.value}
              onSelect={durationController.field.onChange}
              onBlur={durationController.field.onBlur}
              ref={durationController.field.ref}
            />
          </InputWrapper>

          <InputRow>
            <InputWrapper>
              <InputLabel
                inputId="startDate"
                text={process.env.REACT_APP_SIMCAPTURE_SERVICE_ID?.toLowerCase() === service.id.toLowerCase() ? t('Install date') : t('Start date')}
              />
              <DatepickerField
                id="startDate"
                yearPicker
                readOnly={readOnlyOptions}
                onChange={startDateController.field.onChange}
                value={startDateController.field.value}
                invalid={Boolean(startDateController?.fieldState?.error?.message)}
                validationMessage={startDateController?.fieldState?.error?.message}
                onBlur={startDateController.field.onBlur}
                name={startDateController.field.name}
                ref={startDateController.field.ref}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel inputId="expiryDate" text={t('Expiry date')}/>
              {tier?.enableUnlimitedDuration &&
                <TextField id="expiryDate" placeholder={t('Never expires')} readOnly={true}/>}
              {!tier?.enableUnlimitedDuration && (
                <DatepickerField
                  id="expiryDate"
                  yearPicker
                  readOnly={readOnlyOptions}
                  onChange={expirationDateController.field.onChange}
                  value={expirationDateController.field.value}
                  invalid={Boolean(expirationDateController?.fieldState?.error?.message)}
                  validationMessage={expirationDateController?.fieldState?.error?.message}
                  onBlur={expirationDateController.field.onBlur}
                  name={expirationDateController.field.name}
                  ref={expirationDateController.field.ref}
                />
              )}
            </InputWrapper>
          </InputRow>
          {
            startDate && moment().isBefore(moment(startDate)) &&
            <Banner size={Size.Small} fullWidth={false} type={"neutral"} icon={<SystemIcons.Information/>}>
              <Trans i18nKey="StartDateInFutureBanner" ns="OrganizationServices"></Trans>
            </Banner>
          }
          {
            process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase() != service.id.toLowerCase() && (
              <>
                <InputWrapper>
                  <ToggleSwitch
                    id="automaticRenewal"
                    label={t('AUTOMATIC_RENEWAL_CHECKBOX_DESCRIPTION')}
                    disabled={readOnlyOptions || expirationDate == null || isTrial}
                    onToggle={autoRenewController.field.onChange}
                    selected={autoRenewController.field.value ?? false}
                  />
                </InputWrapper>

                {
                  autoRenew &&
                  <InputWrapper>
                    <InputLabel inputId="renewalPeriodInMonths" text={t('Renewal period in months')}/>
                    <BasicDropdown
                      readOnly={readOnlyOptions || !autoRenew}
                      list={SubscriptionRenewalPeriods}
                      value={renewalPeriodController.field.value + ''}
                      onSelect={renewalPeriodController.field.onChange}
                      onBlur={renewalPeriodController.field.onBlur}
                      ref={renewalPeriodController.field.ref}
                      activeValidationMessage={renewalPeriodController.fieldState.error?.message}
                      placeholder={t('Select')}
                      id="renewalPeriodInMonths"
                    />
                  </InputWrapper>
                }
              </>
            )}

        </>
      )}
    </FormGroupContainer>
  );

}