import { Checkbox, COLORS, ComponentLStyling, ComponentTextStyle } from "@laerdal/life-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { UpdateOrganizationServiceDTO } from "../../types";

const Title = styled.div`
  ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
`;

const Content = styled.p`
`;

const NoteContent = styled.p`
    font-weight: 700;
`;

type OrgPartnerAccessProps = {
  serviceProps?: UpdateOrganizationServiceDTO;
  setServiceProps: React.Dispatch<React.SetStateAction<UpdateOrganizationServiceDTO>>;
}

export const OrgPartnerAccess = ({serviceProps, setServiceProps}: OrgPartnerAccessProps) => {
  const {t} = useTranslation('Organization');

  return (
    <>
      <Title>{t('Partner access')}</Title>

      <Content>
        {t('People using this license can access content performance reports and data metrics when signed in.')}
      </Content>
      <br/>
      <NoteContent>
        {t('Use only for internal teams and Laerdal partner organizations.')}
      </NoteContent>

      <Checkbox label={t(`Give all license users access to Reports when signed in`)}
                selected={serviceProps?.isPartner ?? false}
                select={(selected) => setServiceProps({...serviceProps, isPartner: selected})}/>
    </>
  )
}