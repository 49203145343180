import React, {useEffect, useState} from 'react';
import {COLORS, PageWidth, BackButton, Size, BREAKPOINTS} from '@laerdal/life-react-components';
import {useNavigate} from 'react-router-dom';
import {
  CountryDto,
  CreateOrganization,
  CreateOrganizationForm,
  LocalSegment
} from '../../types';
import OrganizationDetailsCreateForm from './forms/OrganizationDetailsCreateForm';
import styled from 'styled-components';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from 'react-i18next';
import Api from '../../utils/api';
import {SuccessToastOptions, ErrorToastOptions, getCodeTitle} from '../../constants';
import {useUserContext, useToastContext} from '../../userContext';
import {createOrganizationSchema} from "./forms/organization-creation-schema";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background: ${COLORS.neutral_50};
`;


const StyledPageWidth = styled(PageWidth)`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  margin-top: 16px;

  ${BREAKPOINTS.MEDIUM} {
    margin-top: 32px;
  }

  ${BREAKPOINTS.LARGE} {
    width: 100%;
    margin-top: 64px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
  }
`;

const Header = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: ${COLORS.black};
`;

const BodyText = styled.p`
  color: ${COLORS.neutral_600};
  font-size: 18px;
`;


const CreateOrganizationPage = () => {
  const {t} = useTranslation('Organization');
  const {addToast} = useToastContext();
  const navigate = useNavigate();
  const {authenticated} = useUserContext();
  const [countries, setCountries] = useState<CountryDto[]>([]);
  const [localSegments, setLocalSegments] = useState<LocalSegment[]>([]);

  const methods = useForm<CreateOrganizationForm>({
    resolver: yupResolver(createOrganizationSchema(t)),
    context: {countries, localSegments},
    defaultValues: {
      name: '',
      customerNo: '',
      vatNumber: '',
      federalTaxId: '',
      taxExempt: false,
      billingContactPersonFirstName: '',
      billingContactPersonLastName: '',
      billingEmailAddress: '',
      address: {
        companyName: '',
        additionalName: '',
        countryCode: '',
        addressLine: '',
        addressLineTwo: '',
        city: '',
        state: '',
        zipCode: '',
        phoneNumber: '',
        email: ''
      },
      shippingAddressSameAsBilling: true,
      shippingAddress: {
        companyName: '',
        additionalName: '',
        countryCode: '',
        addressLine: '',
        addressLineTwo: '',
        city: '',
        state: '',
        zipCode: '',
        phoneNumber: '',
        email: ''
      }
    },
  });


  const createOrganization = async (model: CreateOrganizationForm) => {
    try {
      const primaryAddress = model.shippingAddressSameAsBilling ? model.address : model.shippingAddress;
      const billingAddress = model.address;

      const createOrg: CreateOrganization = {
        name: model.name,
        customerNo: !!model.customerNo ? model.customerNo : undefined,
        isOnboarded: false,
        industry: model.industry,
        sfCorporateSegment: model.sfCorporateSegment,
        sfLocalSegment: model.sfLocalSegment,
        taxExempt: model.taxExempt,
        billingOrganizationName: model.name,
        billingContactPersonFirstName: model.billingContactPersonFirstName,
        billingContactPersonLastName: model.billingContactPersonLastName,
        billingEmailAddress: model.billingEmailAddress,
        federalTaxId: !!model.federalTaxId ? model.federalTaxId : undefined,
        vatNumber: !!model.vatNumber ? model.vatNumber : undefined,

        useOrganizationAddressForBilling: model.shippingAddressSameAsBilling,
        address: {
          ...primaryAddress,
          companyName: model.name!,
          country: countries.find(a => a.codeAlpha2 === primaryAddress.countryCode)!
        },
        billingAddress:
          !model.shippingAddressSameAsBilling ? {
            ...billingAddress,
            companyName: model.name!,
            country: countries.find(a => a.codeAlpha2 === billingAddress.countryCode)!
          } : undefined,
      };
      const org = await Api.CreateOrganization(createOrg);
      addToast(t('Organization created successfully'), SuccessToastOptions);
      navigate(`/organization/${org.id}`);
    } catch (errorCode) {
      addToast(
        errorCode === getCodeTitle(409) ? t('An organization with the given customer id already exist') : t('There was a problem creating your organization'),
        ErrorToastOptions,
      );
    }
  };

  useEffect(() => {
    if (authenticated && Api.token) {
      Promise.all([Api.GetCountries(), Api.GetLocalSegments()])
        .then(([countries, localSegments]) => {
          setCountries(countries);
          setLocalSegments(localSegments);
        })
        .catch(() => {
          console.log('Error fetching countries and local segments')
        });
    }
  }, [authenticated]);


  return (
    <PageContainer>
      <FormProvider {...methods}>
        <StyledPageWidth>
          <form
            onSubmit={methods.handleSubmit((data) => {
              console.log('handle submit called', data);
              return createOrganization(data);
            })}>
            <OrganizationDetailsCreateForm countries={countries} localSegments={localSegments}/>
          </form>
        </StyledPageWidth>
      </FormProvider>
    </PageContainer>
  );
};

export default CreateOrganizationPage;
