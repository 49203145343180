import {RefObject} from 'react';
import {ToSVersion} from '../../../../types';
import * as yup from 'yup';
import moment from 'moment';

export const useTermsOfServiceValidator = (latestVersion: RefObject<ToSVersion>) => {
  return yup.object().shape({
    name: yup.string().required(),
    majorVersion: yup.lazy(() =>
      latestVersion.current
        ? yup.number()
          .required()
          .when('minorVersion', {
            is: (minorVersion: number) => minorVersion === latestVersion.current!.minorVersion,
            then: () => yup.number().min(latestVersion.current!.version + 1, 'Version should be increased'),
            otherwise: () => yup.number().min(latestVersion.current!.version),
          })
        : yup.number().min(1).default(1)),
    minorVersion: yup.lazy(() =>
      latestVersion.current
        ? yup.number()
          .required()
          .when('majorVersion', {
            is: (majorVersion: number) => majorVersion === latestVersion.current!.version,
            then: () => yup.number().min(latestVersion.current!.minorVersion + 1, 'Version should be increased'),
            otherwise: () => yup.number().min(0),
          })
        : yup.number().min(0).default(0)),

    gracePeriod: yup.number().positive().integer().required().default(90),
    salesOrg: yup.string().required(),
    effectiveAt: yup.lazy(() => latestVersion.current
      ? yup.date().min(
        moment(latestVersion.current.effectiveAt).add(1, 'day').startOf('day').toDate(),
        'Effective date should be increased',
      )
      : yup.date().required()),
    reconsent: yup.boolean().required(),
    files: yup.array().of(useTermsOfServiceFileValidator()).min(1).ensure().required().test(
      'Fallback',
      'Fallback language must be selected',
      (item) => !!item?.find((i) => i.fallback === true),
    ),
  });
};

export const useTermsOfServiceFileValidator = () => {
  return yup.object().shape({
    file: yup.lazy(() => yup.mixed().when('fileId', {
      is: (fileId: string) => !fileId,
      then: () => yup.mixed().required().label('File'),
      otherwise: () => yup.mixed().label('File'),
    })),
    fileId: yup.lazy(() => yup.string().when('file', {
      is: (file: any) => !file,
      then: () => yup.string().required().label('File'),
      otherwise: () => yup.string().label('File'),
    })),
    fileName: yup.string(),
    languageCode: yup.string().required().label('Language'),
    languageLabel: yup.string(),
    fallback: yup.boolean(),
  });
};