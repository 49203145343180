import { ToastContext } from '@laerdal/life-react-components';
import React, { useContext } from 'react';
import {AccessLevel, CountryConfiguration, CountryDto, Permission, SalesOrgDto} from './types';

export interface UserContextValueType {
  authenticated: boolean;
  firstName: string;
  lastName: string;
  accessLevel: AccessLevel;
  email: string;
  onboarder: boolean;
  isAccountAndCustomerViewer: boolean;
  countries: CountryDto[],
  salesOrgs: SalesOrgDto[],
  memberPermissions: Permission[],
  roles: string[],
  setRoles: (roles: string[]) => void;
  setAccessLevel: (level: AccessLevel) => void;
  setAuthenticated: (auth: boolean) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setEmail: (email: string) => void;
  setOnboarder: (onboarder: boolean) => void;
  setIsAccountAndCustomerViewer: (isAccountAndCustomerViewer: boolean) => void;
  setCountries: (countries: CountryDto[]) => void;
  setSalesOrgs: (salesOrgs: SalesOrgDto[]) => void;
  setMemberPermissions: (permissions: Permission[]) => void;
}

const UserContext = React.createContext({
  authenticated: false,
  firstName: '',
  lastName: '',
  accessLevel: AccessLevel.ReadOnly,
  email: '',
  onboarder: false,
  isAccountAndCustomerViewer: false,
  countries: [] as CountryDto[],
  salesOrgs: [] as SalesOrgDto[],
  memberPermissions: [] as Permission[],
  roles: [] as string[],
  setRoles: (roles: string[]) => {},
  setAccessLevel: (level: AccessLevel) => {},
  setAuthenticated: (auth: boolean) => {},
  setFirstName: (firstName: string) => {},
  setLastName: (lastName: string) => {},
  setEmail: (email: string) => {},
  setOnboarder: (onboarder: boolean) => {},
  setIsAccountAndCustomerViewer: (isAccountAndCustomerViewer: boolean) => {},
  setCountries: (countries: CountryDto[]) =>{},
  setSalesOrgs: (salesOrgDto: SalesOrgDto[]) =>{},
  setMemberPermissions: (permissions: Permission[]) => {},

  selfServiceServices: [] as string[],
  setSelfServiceServices: (services: string[]) => {},
  countryConfigurations: {} as {[key: string]: CountryConfiguration},
  setCountryConfigurations: (a: {[key: string]: CountryConfiguration}) => {},
}); // Create a context object

export const UserProvider = UserContext.Provider;

export const useUserContext = () => useContext(UserContext);

export const useToastContext = () => useContext(ToastContext);

export {
  UserContext, // Export it so it can be used by other Components
};
