import React from 'react';
import { COLORS, ModalDialog, Size, SystemIcons, ToastColor, ToastPosition } from '@laerdal/life-react-components';
import { Organization } from '../../../types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Api from '../../../utils/api';
import { useToastContext } from '../../../userContext';

interface OrganizationDeleteConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleted: () => void;
  organization?: Organization;
}

const OrganizationDeleteConfirmModal: React.FunctionComponent<OrganizationDeleteConfirmModalProps> = (props) => {
  const { t } = useTranslation('Organization');
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { addToast } = useToastContext();

  const notifyError = (msg: string) => {
    addToast(msg, {
      color: ToastColor.RED,
      showCloseButton: true,
      autoClose: true,
      position: ToastPosition.TOPMIDDLE,
    });
  };

  const notifySuccess = (msg: string) => {
    addToast(msg, {
      color: ToastColor.GREEN,
      showCloseButton: true,
      autoClose: true,
      position: ToastPosition.TOPMIDDLE,
    });
  };

  const submit = async () => {
    setLoading(true);

    try {
      const inSF = !props.organization!.customerNo ? false : await Api.CheckOrganizationCreatedFromSalesforce(props.organization!.customerNo);
      if (inSF) {
        notifyError(t('Failed to delete: organization found in Salesforce'));
        return;
      } else {
        await Api.DeleteOrganization(props.organization!.id);
        props.onDeleted();
        notifySuccess(t('Organization deleted'));
      }
    } catch (e) {
      notifyError(t('Failed to delete organization'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalDialog
      isModalOpen={props.isOpen}
      closeModalAndClearInput={props.onClose}
      closeAction={props.onClose}
      submitAction={() => submit()}
      size={Size.Medium}
      note={t('This action is permanent and cannot be undone.')}
      icon={<SystemIcons.TechnicalWarning color={COLORS.critical_500} />}
      title={t('Delete organization')}
      state={'critical'}
      leftFooterAction={{
        actionType: 'button',
        text: t('Merge instead?'),
        action: () => navigate(`/organization/merge?source=${props.organization?.id}`),
        variant: 'secondary',
        disabled: loading,
      }}
      buttons={[
        {
          variant: 'tertiary',
          action: props.onClose,
          text: t('No, cancel'),
          disabled: loading,
        },
        {
          variant: 'critical',
          action: submit,
          loading: loading,
          text: t('Yes, delete'),
        },
      ]}>
      <p>
        <strong>{props.organization?.name}</strong>
        <br />
      </p>
      <p>
        {t('Once deleted, this organization record cannot be recovered. Users will be disassociated and any active service subscriptions will be immediately cancelled.')}
      </p>
      <p>{t('Are you sure you want to delete this organization?')}</p>
    </ModalDialog>
  );
};

export default OrganizationDeleteConfirmModal;
