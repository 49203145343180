/**
 * Import React libraries.
 */
import React, {FormEvent, useEffect, useState} from 'react';

/**
 * Import third-party libraries.
 */
import {useTranslation} from 'react-i18next';
import {ToastColor, ToastPosition, ModalDialog, BREAKPOINTS, Size} from '@laerdal/life-react-components';
/**
 * Import custom components.
 */
import {InviteUserStep1} from './InviteUserModalComponents/InviteUserStep1';
import {InviteUserStep2} from './InviteUserModalComponents/InviteUserStep2';

/**
 * Import custom utils.
 */
import Api from '../../../utils/api';

/**
 * Import constants.
 */
import {ErrorToastOptions} from '../../../constants';

/**
 * Import custom types.
 */
import {
  AddOrganizationMemberInvitation,
  OrganizationInvitationResponse,
  OrganizationMember,
  UserRecordWrapper,
  User
} from '../../../types';
import {useDebounce} from 'use-debounce';
import {useMediaQuery} from 'react-responsive';
import {useToastContext} from '../../../userContext';

/**
 * Custom types.
 */
type AddUserProps = {
  existsInSF: boolean;
  closeModal: (invited: boolean) => void;
  isModalOpen: boolean;
  organizationId: string;
  organizationName: string;
  members: OrganizationMember[];
};

export interface OrganizationRoleDetails {
  label: string;
  description: string;
}

const InviteUserModal: React.FunctionComponent<AddUserProps> = ({
                                                                  closeModal,
                                                                  isModalOpen,
                                                                  organizationId,
                                                                  organizationName,
                                                                  members,
                                                                  existsInSF
                                                                }: AddUserProps) => {
  // Globally used variables within the component.
  const {t} = useTranslation('Organization');
  const [step, setStep] = useState<number>(0);
  const [emails, setEmails] = useState<string[]>([]);
  const [exisitingUserEmails, setExisitingUserEmails] = useState<string[]>([]);
  const [hasAlreadyInvited, setHasAlreadyInvited] = useState<string[]>([]);
  const [busy, setBusy] = useState<boolean>(false);
  const isMediumScreen = useMediaQuery({query: BREAKPOINTS.MEDIUM.replace('@media ', '')});


  const [addUsersImmediately, setAddUsersImmediately] = useState<boolean>(true);

  const {addToast} = useToastContext();

  const [users, setUsers] = useState<User[]>([]);
  const [allSearchedUsers, setAllSearchedUsers] = useState<User[]>([]);
  const [currentInput, setCurrentInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [createSFContactsCheckbox, setCreateSFContactsCheckbox] = useState<boolean>(false);
  const [removeFromOtherOrgs, setRemoveFromOtherOrgsCheckbox] = useState<boolean>(false);
  const [debouncedCurrentInput] = useDebounce(currentInput, 500);
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [abortController, setAbortController] = useState<AbortController>();

  useEffect(() => {
    abortController?.abort();
    const controller = new AbortController();
    setAbortController(controller);

    setLoading(true);
    Api.FindUsers(currentInput, 0, 100, undefined, undefined, undefined, undefined, controller.signal)
      .then((wrapper: UserRecordWrapper) => {
        const filterExistingOrgMembers = wrapper.records.filter((r) => !r?.organizations?.find((o) => o.id === organizationId) || r.identityId == null || r.identityId != '');

        setUsers(filterExistingOrgMembers);

        const tempUsers = [...allSearchedUsers];

        wrapper.records.forEach((x) => {
          if (!tempUsers.includes(x)) {
            tempUsers.push(x);
          }
        });
        setAllSearchedUsers(tempUsers);
      })
      .catch(() => {
        if (!controller?.signal.aborted) {
          addToast(t('There was a problem finding users'), ErrorToastOptions);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCurrentInput]);

  useEffect(() => {
    if (exisitingUserEmails.length === 0 && removeFromOtherOrgs) {
      setRemoveFromOtherOrgsCheckbox(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exisitingUserEmails]);

  /**
   * Does all required pre-requisites when the modal loads.
   * @param invited - Boolean value if user has been invited.
   */
  const clearDataAndClose = (invited: boolean): void => {
    // Let's clear the old content
    setEmails([]);
    setStep(0);
    setExisitingUserEmails([]);
    setRemoveFromOtherOrgsCheckbox(false);
    setAddUsersImmediately(true);
    setSendEmail(false);
    setHasAlreadyInvited([]);

    // Close the modal
    closeModal(invited);
  };

  const backStep = (): void => {
    setStep(0);
    setAddUsersImmediately(true);
    setSendEmail(false);
  };

  /**
   * Increments step counter.
   */
  const setNextStep = (): void => {
    // Prevent the default action
    setStep(step + 1);
  };

  /**
   * Does all required pre-requisites and invites users to the organization.
   * @param event - Form event handler.
   */
  const inviteUsers = (event: FormEvent): void => {
    event.preventDefault();

    // Let's mark form as busy
    setBusy(true);

    // Create invitation object
    const invitationObject: AddOrganizationMemberInvitation = {
      acceptUrl: `${process.env.REACT_APP_ORG_INVITATION_ACCEPT_URL}`,
      emails: emails,
      sendEmail: !addUsersImmediately || sendEmail,
    };

    // Send invitation
    Api.InviteUsersToOrganization(organizationId, invitationObject, removeFromOtherOrgs, addUsersImmediately, createSFContactsCheckbox)
      .then((r: OrganizationInvitationResponse[]) => {
        setBusy(false);
        if (!addUsersImmediately) {
          r.forEach((i) => {
            if (i.isSuccessful) {
              addToast(t('{{email}} invited to the organization successfully', {email: i.email}), {
                color: ToastColor.GREEN,
                showCloseButton: true,
                autoClose: true,
                position: ToastPosition.TOPMIDDLE,
              });
            } else {
              addToast(t('Something went wrong while inviting {{email}}', {email: i.email}), {
                color: ToastColor.RED,
                showCloseButton: true,
                autoClose: true,
                position: ToastPosition.TOPMIDDLE,
              });
            }
          });
        } else {
          addToast(t('Added {{number}} people to {{organization}}', {
            number: emails.length,
            organization: organizationName
          }), {
            color: ToastColor.GREEN,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
        }
        clearDataAndClose(true);
      })
      .catch((r) => {
        setBusy(false);
        addToast(t('Something went wrong while inviting member'), {
          color: ToastColor.RED,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE,
        });
      });
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {
      }}
      closeModalAndClearInput={() => clearDataAndClose(false)}
      closeAction={() => clearDataAndClose(false)}
      title={step === 0 ? `${t('Add people to')} ${organizationName}` : t('Confirm action')}
      backButton={step === 1 ? backStep : undefined}
      size={isMediumScreen ? Size.Medium : Size.Small}
      buttons={[
        {
          id: 'cancel',
          variant: 'tertiary',
          disabled: busy,
          text: t('Cancel'),
          action: closeModal,
        },
        {
          id: 'save',
          action: (e: FormEvent) => {
            step === 0 ? setNextStep() : inviteUsers(e);
          },
          loading: busy,
          variant: 'primary',
          text: step === 0 ? t('Next') : t('Add to organization'),
          disabled: busy || emails.length === 0,
        },
      ]}>
      <>
        {step === 0 && (
          <InviteUserStep1
            removeFromOtherOrgsCheckbox={removeFromOtherOrgs}
            currentInput={currentInput}
            emails={emails}
            exisitingUserEmails={exisitingUserEmails}
            hasAlreadyInvited={hasAlreadyInvited}
            loading={loading}
            existsInSF={existsInSF}
            users={users}
            createSFContactsCheckbox={createSFContactsCheckbox}
            members={members}
            setCreateSFContactsCheckbox={setCreateSFContactsCheckbox}
            setHasAlreadyInvited={setHasAlreadyInvited}
            setEmails={setEmails}
            setExisitingUserEmails={setExisitingUserEmails}
            setRemoveFromOtherOrgsCheckbox={setRemoveFromOtherOrgsCheckbox}
            setCurrentInput={setCurrentInput}/>
        )}
        {step === 1 && (
          <InviteUserStep2
            emails={emails}
            addUsersImmediately={addUsersImmediately}
            orgName={organizationName}
            sendEmail={sendEmail}
            setAddUsersImmediately={setAddUsersImmediately}
            setSendEmail={setSendEmail}
            users={allSearchedUsers}/>
        )}
      </>
    </ModalDialog>
  );
};

export default InviteUserModal;
