import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BREAKPOINTS, ModalDialog, Size } from "@laerdal/life-react-components";
import { useMediaQuery } from "react-responsive";

interface ConfirmScenarioDeleteProps {
  closeModal: () => void;
  confirm: () => Promise<void>;
  organizationName: string;
}

export const ConfirmScenarioDeleteModal: React.FunctionComponent<ConfirmScenarioDeleteProps> = ({
                                                                                                  closeModal,
                                                                                                  confirm,
                                                                                                  organizationName
                                                                                                }: ConfirmScenarioDeleteProps) => {
  const { t } = useTranslation("Settings");
  const [confirming, setConfirming] = useState<Boolean>(false);
  const isMediumScreen = useMediaQuery({ query: BREAKPOINTS.MEDIUM.replace("@media ", "") });

  const handleConfirm = async () => {
    setConfirming(true);
    await confirm();
    setConfirming(false);
  };

  const handleCloseClick = () => {
    if (!confirming) closeModal();
  };

  return (
    <ModalDialog
      isModalOpen={true}
      submitAction={() => {
      }}
      closeModalAndClearInput={handleCloseClick}
      closeAction={handleCloseClick}
      title={t("Delete this scenario?")}
      size={isMediumScreen ? Size.Medium : Size.Small}
      buttons={
        [
          {
            id: "cancel",
            variant: "tertiary",
            text: t("No, cancel"),
            action: handleCloseClick
          },
          {
            id: "confirm",
            action: handleConfirm,
            loading: Boolean(confirming),
            variant: "critical",
            text: t("Yes, delete"),
            disabled: confirming
          }
        ] as any
      }>
      <p>{t("{{organizationName}} will no longer be able to use this scenario unless they have purchased license access.", { organizationName })}</p>
      <p>{t("Are you sure you want to continue?")}</p>
    </ModalDialog>
  );
};
