import React, {useEffect} from 'react';
import {Feature} from "../../types";
import Api from "../api";


export const FeatureContext = React.createContext<Feature[]>([]);

export const FeatureProvider = ({children}: { children: React.ReactNode }) => {

  const [features, setFeatures] = React.useState<Feature[]>([]);

  useEffect(() => {
    const abort = new AbortController();
    Api.GetFeatures(abort.signal)
      .then((features) => {
        setFeatures(features);
      });

    return () => abort.abort();
  }, []);

  return (
    <FeatureContext.Provider value={features}>
      {children}
    </FeatureContext.Provider>
  )
};