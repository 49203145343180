import React, { useEffect, useState } from "react";
import { Checkbox, COLORS, ComponentS, ComponentTextStyle } from "@laerdal/life-react-components";
import styled from "styled-components";
import Enumerable from "linq";
import { Permission } from "../types";
import { memberFullAccessPermission } from "../constants";


export type Props = {
  permissions: string[];
  allPermissions: Permission[];
  onChecked: (permissionId: string) => void;
};

const SectionContainer = styled.div`
  padding: 10px 0px;
`;
const PermissionContainer = styled.div<{ fullAccess: boolean }>`
  ${props => props.fullAccess ? `background-color: ${COLORS.neutral_50};` : ""}
  ${props => props.fullAccess ? `padding-bottom: 15px;` : ""}
`;

const PermissionsEdit = ({ permissions, allPermissions, onChecked }: Props) => {

  const [hasFullAccess, setHasFullAccess] = useState<boolean>(false);

  useEffect(() => {
    sortAndAdjustPermissions();

    setHasFullAccess(permissions.findIndex(x => x == memberFullAccessPermission) >= 0);
  }, [permissions, allPermissions]);

  /**
   * Makes sure that we sort and word permissions to match the MUA sorting and wording.
   */
  const sortAndAdjustPermissions = () => {
    // Let's make the corrections to ordering and wording to match MUA
    for (let permission of allPermissions) {
      if (permission.id === memberFullAccessPermission) {
        permission.order = 1;
        permission.name = "Set as Administrator";
      } else if (permission.section === "Organization") {
        if (permission.id === "organization.manageaccess") {
          permission.order = 2;
          permission.description = "Can invite and manage access for other people (except administrators).";
        } else if (permission.id === "organization.manageproducts") {
          permission.order = 4;
        } else if (permission.id === "organization.viewproducts") {
          permission.order = 3;
        }
      } else if (permission.id === "myaccount.viewappointments") {
        permission.order = 5;
      } else if (permission.id === "myaccount.vieworders") {
        permission.order = 6;
      } else if (permission.id === "myaccount.viewsupportcases") {
        permission.order = 7;
      } else if (permission.id === "webshop.purchaseonaccount") {
        permission.order = 8;
      }
    }

    // Let's sort permissions with the new order
    allPermissions.sort((a, b) => a.order! - b.order!);
  };

  let grouppedSections = Enumerable.from(allPermissions).groupBy((x) => x.section);
  let memberPermissionsEnumerable = Enumerable.from(permissions);
  return (
    <>
      {grouppedSections.select((x) => {
        return (
          <SectionContainer>
            <ComponentS textStyle={ComponentTextStyle.Bold} color={COLORS.neutral_600}>{x.key()}</ComponentS>
            {x.select((permission) => (
              <PermissionContainer fullAccess={permission.id == memberFullAccessPermission}>
              { permission.id === "webshop.purchaseonbehalfoforganization" && 
                <ComponentS color={COLORS.critical_500}>Changes to this setting will take effect the next time this user signs in</ComponentS>
              }
                <Checkbox disabled={hasFullAccess && permission.id != memberFullAccessPermission}
                          selected={(permission.id != memberFullAccessPermission && hasFullAccess) || memberPermissionsEnumerable.any(y => permission.id == y)}
                          select={() => onChecked(permission.id)}>
                  <ComponentS color={COLORS.black} textStyle={ComponentTextStyle.Bold}>{permission.name}</ComponentS>
                  <p color={COLORS.neutral_600}>{permission.description}</p>
                </Checkbox>
              </PermissionContainer>
            ))}
          </SectionContainer>
        );
      })}
    </>
  );
};

export default PermissionsEdit;
