/**
 * Import React libraries.
 */
import React, { useContext, useEffect, useState } from 'react';

/**
 * Import third-party libraries.
 */
import { useNavigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { AuthenticationResult } from '@azure/msal-common';
import { GlobalLoadingPage } from '@laerdal/life-react-components';

/**
 * Import custom context.
 */
import { UserContext, useUserContext } from './userContext';

/**
 * Import custom util functions.
 */
import Api from './utils/api';
import styled from 'styled-components';
import { AccessLevel } from './types';
import axios from 'axios';

type Props = {
  children: any;
};

interface RolesClaimContainer {
  roles?: string[];
}

const Content = styled.div`
  width: 100%;
  flex-grow: 1;
`;

const ReadOnlyRole = 'ReadOnly';
const GeneralRole = 'General';
const SuperuserRole = 'Superuser';
const AdminRole = 'Admin';
const OnboarderRole = 'ActiveOnboarder';
const AccountAndCustomerViewerRole = 'AccountAndCustomerViewer';

const UnauthenticatedHandler: React.FunctionComponent<Props> = (props) => {
  // Let's define global variables
  const [loaded, setLoaded] = useState(false);
  const {
    authenticated,
    setAuthenticated,
    setFirstName,
    setLastName,
    setEmail,
    setAccessLevel,
    accessLevel,
    setOnboarder,
    setIsAccountAndCustomerViewer,
    setCountries,
    salesOrgs,
    setSalesOrgs,
    setMemberPermissions,
    memberPermissions,
    roles,
    setRoles
  } = useUserContext();
  const navigate = useNavigate();
  const { children } = props;
  const { instance } = useMsal();
  const location = useLocation();

  const getRoles = (response: AuthenticationResult): string[] => {
    const container = response.account?.idTokenClaims as RolesClaimContainer;
    return container.roles ?? [];
  };

  const isAccountAndCustomerViewer = (roles: string[]): boolean => {
    return roles.some((x) => x.toUpperCase() == AccountAndCustomerViewerRole.toUpperCase() || x.toUpperCase() == AdminRole.toUpperCase()
      || x.toUpperCase() == GeneralRole.toUpperCase() || x.toUpperCase() == SuperuserRole.toUpperCase());
  };

  const isOnboarder = (roles: string[]): boolean => {
    return roles.some((x) => x.toUpperCase() == OnboarderRole.toUpperCase() || x.toUpperCase() == AdminRole.toUpperCase());
  };

  const determineCurrentUserAccessLevel = (roles: string[]): AccessLevel => {
    let accessLevel = AccessLevel.None;
    if (roles.some((x) => x.toUpperCase() == ReadOnlyRole.toUpperCase())) accessLevel = AccessLevel.ReadOnly;

    if (roles.some((x) => x.toUpperCase() == GeneralRole.toUpperCase())) accessLevel = AccessLevel.General;

    if (roles.some((x) => x.toUpperCase() == SuperuserRole.toUpperCase() || x.toUpperCase() == AdminRole.toUpperCase())) accessLevel = AccessLevel.Full;

    return accessLevel;
  };

  /**
   * Does all required changes when user is logged in.
   * @param response - Response from the Office365 login.
   */
  const handleLogin = async (response: AuthenticationResult) => {
    // Let's show loading page while we assign data
    setLoaded(false);

    let level = AccessLevel.None;
    // Let's assign first name, last name and email
    if (response && response.account) {
      setFirstName(response.account!.name!.split(' ')[0]);
      setLastName(response.account!.name!.split(' ')[1]);
      setEmail(response.account.username);
      const roles = getRoles(response);
      level = determineCurrentUserAccessLevel(roles);
      setOnboarder(isOnboarder(roles));
      setIsAccountAndCustomerViewer(isAccountAndCustomerViewer(roles));
      setAccessLevel(level);
      setRoles(roles);
    }

    // Let's retrieve and store the JWT token
    Api.storeJWT(response.idToken);

    // Let's authenticate the user
    setAuthenticated(true);

    try
    {
      setSalesOrgs(await Api.GetSalesOrgs());
    }
    catch(e)
    {
      setSalesOrgs([]);
      console.log('Error occured when loading SalesOrgs', e);
    }

    try
    {
      setCountries(await Api.GetCountries());
    }
    catch(e)
    {
      setCountries([]);
      console.log('Error occured when loading Countries', e);
    }

    try
    {
      setMemberPermissions(await Api.GetPermissions());
    }
    catch(e)
    {
      setMemberPermissions([]);
      console.log('Error occured when loading Countries and SalesOrgs', e);
    }

    // Finish loading
    setLoaded(true);

    // Let's update the URL with the previously saved redirect url
    navigate(`${sessionStorage.getItem('laerdal_redirect_url')}`);

    // Let's remove temp redirect url
    sessionStorage.removeItem('laerdal_redirect_url');
  };

  useEffect(() => {
    if (authenticated) {
      if (accessLevel == AccessLevel.None && location.pathname != '/unauthorized' && location.pathname != '/organization/onboard'
        && location.pathname != '/organization/info' && location.pathname != '/user/info') navigate('/unauthorized');
      //if (location.pathname == '/unauthorized' && accessLevel != AccessLevel.None)
      //  history.push('/');
    }
  }, [location]);

  /**
   * Checks if user needs to be authenticated.
   */
  useEffect(() => {
    (async () => {
      const redirectResponse = await instance.handleRedirectPromise();
      // Check if we are not authenticated, the page is loaded
      if (!redirectResponse) {
        // Let's capture the current url
        sessionStorage.setItem('laerdal_redirect_url', `${window.location.pathname}${window.location.search}`);

        // Try to login
        // If we are rendering in iframe, let's render a popup
        if (location.search.toLowerCase().indexOf('iframe=1') > -1) {
          // Load with popup
          instance
            .acquireTokenPopup({ scopes: ['User.Read'] })
            .then((response) => handleLogin(response))
            .catch((e) => console.error(e));
        } else {
          // Load with redirect
          instance.acquireTokenRedirect({ scopes: ['User.Read'] }).catch((e) => console.error(e));
        }
      } else if (redirectResponse) {
        handleLogin(redirectResponse);
      } else if (!instance.getActiveAccount()) {
        instance.setActiveAccount(instance.getAllAccounts()[0]);
        instance
          .acquireTokenSilent({ scopes: ['User.Read'] })
          .then((response) => handleLogin(response))
          .catch((e) => console.error(e));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Let's return Unauthenticated Handler view.
   */
  return !authenticated || !loaded ? ( // If page is not loaded yet, show loading indicator
    <GlobalLoadingPage />
  ) : (
    <Content>{children}</Content>
  ); // Let's just render child content
};

export default UnauthenticatedHandler;
