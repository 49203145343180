/**
 * Import React Libraries.
 */
import React, {useState, useEffect, useContext} from 'react';
import {useNavigate, useLocation} from 'react-router';

/**
 * Import third-party libraries.
 */
import {
  PageWidth,
  Table,
  Button,
  SystemIcons,
  Size,
  ToastContext,
  DropdownButton, DropdownFilter,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

/**
 * Import custom types.
 */
import {AccessLevel, Organization, OrganizationRecordWrapper} from '../../types';
import {TableColumn, TablePagination} from '@laerdal/life-react-components';

/**
 * Import custom components.
 */
import OrganizationSearch from './OrganizationSearch';

/**
 * Import custom functions.
 */
import Api from '../../utils/api';
import {ErrorToastOptions} from '../../constants';
import {useUserContext, useToastContext} from '../../userContext';
import {TableSortingDirection, TableSortProps} from '@laerdal/life-react-components/dist/Table/TableTypes';
import OrganizationDeleteConfirmModal from './Components/OrganizationDeleteConfirmModal';

/**
 * Add custom styles.
 */
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GridDropdown = styled.div`
  button > div {
    background: unset;
  }
`

const StyledPageWidth = styled(PageWidth)`
  display: flex !important;
  flex-direction: column !important;
  gap: 32px !important;

  table .right  {
    text-align: right;
  }
`;

/**
 * Add custom types.
 */
interface OrganizationTableRow {
  id: string;
  customerNo: string;
  name: string;
  userCount: number;
  country: string;
  city: string;
}

const OrganizationList = () => {

  const {accessLevel} = useUserContext();
  const {addToast} = useToastContext();
  // Globally used states within the page
  const [pagination, setPagination] = useState<TablePagination>({
    from: 0,
    to: 0,
    currentPage: 1,
    total: 0,
    rowsPerPage: 10,
  });
  const [sortDirection, setSortDirection] = useState<number>(0);
  const [sortBy, setSortBy] = useState<number>(0);
  const [sortProps, setSortProps] = useState<TableSortProps | undefined>();
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();
  const {t} = useTranslation('Organization');
  const [columns] = useState<TableColumn[]>([
    {
      key: 'name',
      name: t('Name'),
      sortable: true,
      icon: <SystemIcons.Institute/>
    },
    {
      key: 'customerNo',
      name: t('Customer number'),
      justify: 'right',
      sortable: true,
    },
    {
      key: 'userCount',
      name: t('Users'),
      sortable: true,
      justify: 'right',
    },
    {
      key: 'country',
      name: t('Country'),
      sortable: true,
    },
    {
      key: 'city',
      name: t('City'),
      sortable: true,
    },
    {
      key: 'id',
      type: 'custom',
      name: '',
      customContent: row =>
        <GridDropdown>
          <DropdownButton type={'icon'}
                          size={Size.Small}
                          items={[
                            {
                              value: 'merge',
                              displayLabel: t('Merge organization'),
                              disabled: accessLevel !== AccessLevel.Full
                            },
                            {
                              value: 'delete',
                              displayLabel: t('Delete organization'),
                              disabled: accessLevel !== AccessLevel.Full
                            },
                          ]}
                          onClick={(v) => handleGridAction(row, v[0])}
                          icon={<SystemIcons.MoreVertical/>}/>
        </GridDropdown>,
      width: '100px',
    }
  ]);
  const [rows, setRows] = useState<any[]>([]);
  const [lastSearchedQuery, setLastSearchedQuery] = useState<string>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const [orgToDelete, setOrgToDelete] = useState<Organization>();

  const handleGridAction = (row: any, action: string) => {
    if (action === 'merge') {
      navigate(`/organization/merge?source=${row.id}`);
    }
    if (action === 'delete') {
      setOrgToDelete(row);
    }
  };


  /**
   * Sets up search parameters for the page.
   */
  useEffect(() => {
    // Let's setup search params
    setupSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Retrieve data on query change
  useEffect(() => {
    const controller = new AbortController();
    abortController?.abort();
    setAbortController(controller);
    if (initialized && query != lastSearchedQuery) {
      getNewRows(query, 1, 1, pagination?.rowsPerPage, undefined, undefined, controller.signal);
    } else
      setInitialized(true);
    return () => controller.abort();
  }, [query])

  /**
   * Does all required setup and retrieves initial rows.
   */
  const setupSearchParams = (): void => {
    // Let's get search params

    const searchParams = new URLSearchParams(location.search);
    // Let's update query if we have it in the url
    if (searchParams.get('query')) {
      setQuery(searchParams.get('query')!);
    }

    // Let's update rows per page if we have it in the url
    if (searchParams.get('rowsPerPage')) {
      setPagination({...pagination, rowsPerPage: parseInt(searchParams.get('rowsPerPage')!)});
    }

    // Let's update sort direction and sort by
    if (searchParams.get('sortDirection') && searchParams.get('sortBy')) {
      const sortBy = parseInt(searchParams.get('sortBy')!);
      const sortDirection = parseInt(searchParams.get('sortDirection')!);
      setSortDirection(sortDirection);
      setSortBy(sortBy);
      setSortProps({
        column: columns[sortBy]?.key ?? '',
        direction: sortDirection === 0 ? 'asc' : 'desc'
      });
    }

    // Retrieve the rows
    getNewRows(
      searchParams.get('query') ? searchParams.get('query')! : query,
      searchParams.get('from') ? parseInt(searchParams.get('from')!) : pagination.from,
      searchParams.get('currentPage') ? parseInt(searchParams.get('currentPage')!) : pagination.currentPage,
      searchParams.get('rowsPerPage') ? parseInt(searchParams.get('rowsPerPage')!) : pagination.rowsPerPage,
      searchParams.get('sortBy') ? parseInt(searchParams.get('sortBy')!) : sortBy,
      searchParams.get('sortDirection') ? parseInt(searchParams.get('sortDirection')!) : sortDirection,
    );
  };

  /**
   * Does all required pre-requisites and updates the search parameters for the context.
   * @param search - Search string to use for the row retrieval.
   * @param from - From which row to retrieve the data.
   * @param page - Current page in the table.
   * @param newRowsPerPage - New amount of rows per page.
   * @param newSortBy - An enum value indicating the sort by column.
   * @param newSortDirection - An enum value indicating the sort direction.
   */
  const updateSearchParams = (search: string, from: number, page: number, newRowsPerPage: number, newSortBy?: number, newSortDirection?: number): void => {
    const params = new URLSearchParams();

    // Let's add query if it is set
    search ? params.append('query', search) : params.delete('query');
    search ? localStorage.setItem('orgsQuery', search) : localStorage.removeItem('orgsQuery');
    // Let's add from
    from ? params.append('from', from.toString()) : params.delete('from');
    from ? localStorage.setItem('orgsFrom', from.toString()) : localStorage.removeItem('orgsFrom');

    // Let's add current page
    page ? params.append('currentPage', page.toString()) : params.delete('currentPage');
    page ? localStorage.setItem('orgsCurrentPage', page.toString()) : localStorage.removeItem('orgsCurrentPage');

    // Let's add rows per page
    newRowsPerPage ? params.append('rowsPerPage', newRowsPerPage.toString()) : params.delete('rowsPerPage');
    newRowsPerPage ? localStorage.setItem('orgsRowsPerPage', newRowsPerPage.toString()) : localStorage.removeItem('orgsRowsPerPage');

    // Let's add sort by
    newSortBy !== undefined ? params.append('sortBy', newSortBy.toString()) : params.delete('sortBy');
    newSortBy !== undefined ? localStorage.setItem('orgsSortBy', newSortBy.toString()) : localStorage.removeItem('orgsSortBy');

    // Let's add rows per page
    newSortDirection !== undefined ? params.append('sortDirection', newSortDirection.toString()) : params.delete('sortDirection');
    newSortDirection !== undefined ? localStorage.setItem('orgsSortDirection', newSortDirection.toString()) : localStorage.removeItem('orgsSortDirection');

    // Let's append context
    navigate({pathname: '/organization', search: params.toString()});
  };

  /**
   * Retrieves new rows from the API.
   * @param search - Search string to use for the row retrieval.
   * @param from - From which row to retrieve the data.
   * @param page - Current page in the table.
   * @param newRowsPerPage - New amount of rows per page.
   * @param newSortBy
   * @param newSortDirection
   * @param abortSignal
   */
  const getNewRows = (
    search: string,
    from: number,
    page: number,
    newRowsPerPage?: number,
    newSortBy?: number,
    newSortDirection?: number,
    abortSignal?: AbortSignal,
  ) => {
    setLastSearchedQuery(search);
    // Assign temporary rows per page based on the global variable
    const tmpRowsPerPage = newRowsPerPage ? newRowsPerPage : pagination.rowsPerPage;

    // Assign temp sort by and sort direction
    const tmpSortBy = newSortBy !== undefined ? newSortBy : sortBy;
    const tmpSortDirection = newSortDirection !== undefined ? newSortDirection : sortDirection;

    // Let's update search parameters
    updateSearchParams(search, from, page, tmpRowsPerPage, tmpSortBy, tmpSortDirection);

    setLoading(true);
    Api.FindOrganizations(search, from - 1 >= 0 ? from - 1 : from, tmpRowsPerPage, tmpSortBy, tmpSortDirection, abortSignal)
      .then((organizations) => assignOrganizations(organizations, page * tmpRowsPerPage - tmpRowsPerPage + 1, page * tmpRowsPerPage, page, tmpRowsPerPage))
      .catch(() => {
        if (!abortSignal?.aborted) {
          addToast(t('There was a problem loading organizations'), ErrorToastOptions);
        }
      })
      .finally(() => setLoading(false));
  };

  /**
   * Assign retrieved rows and update pagination.
   * @param organizationRecordWrapper - Organizations Record Wrapper containing rows and total row count.
   * @param from - From which row data was retrieved.
   * @param to - Till which row data was retrieved.
   * @param page - Current page in the table.
   * @param rowsPerPage - New rows per page that needs to be assigned.
   */
  const assignOrganizations = (organizationRecordWrapper: OrganizationRecordWrapper, from: number, to: number, page: number, rowsPerPage: number) => {
    // Let's pull out the new rows data
    const newRows = organizationRecordWrapper.records.map((record: Organization) => {
      return {
        id: record.id,
        name: record.name,
        userCount: record.memberCount,
        customerNo: record.customerNo,
        country: record.address?.country?.name ? record.address?.country?.name : '',
        city: record.address?.city ? record.address?.city : '',
      };
    });

    // Let's update pagination
    setPagination({
      from: from < 0 ? 0 : from,
      to: to > organizationRecordWrapper.totalCount ? organizationRecordWrapper.totalCount : to,
      currentPage: page,
      total: organizationRecordWrapper.totalCount,
      rowsPerPage,
    });

    // Let's assign new rows
    setRows(newRows);
  };

  /**
   * Navigate to the next page.
   */
  const onNextPage = () => {
    // Let's check if we can navigate
    if (pagination?.currentPage! * pagination?.rowsPerPage < pagination?.total!) {
      // Let's get new rows
      getNewRows(query, pagination?.from! + pagination?.rowsPerPage, pagination?.currentPage! + 1);
    }
  };

  /**
   * Navigate to the previous page.
   */
  const onPreviousPage = () => {
    // Let's check if we can navigate
    if (pagination?.currentPage! * pagination?.rowsPerPage - pagination?.rowsPerPage >= 0) {
      // Let's get new rows
      getNewRows(query, pagination?.from! - pagination?.rowsPerPage, pagination?.currentPage! - 1);
    }
  };

  /**
   * Updates rows per page and retrieves new data.
   * @param newRowsPerPage - New rows per page value set in the table.
   */
  const onRowsPerPageChange = (newRowsPerPage: number) => {
    // Let's retrieve fresh data
    getNewRows(query, 1, 1, newRowsPerPage);
  };

  /**
   * Navigates to the organization details page.
   * @param row - Row which was clicked in the table.
   */
  const onRowClick = (row: OrganizationTableRow) => {
    navigate(`/organization/${row.id}`);
  };

  /**
   * Searches the table based on the entered query.
   * @param searchQuery - Search query based on which to search for data.
   */
  const onSearchRows = (searchQuery: string) => {
    // Let's update the query
    setQuery(searchQuery);
  };

  /**
   * Applies sorting for the table.
   * @param key - Column key to which sorting should be applied.
   * @param direction - Direction in which to sort data.
   */
  const onApplySorting = (key: string, direction?: TableSortingDirection): void => {
    let sortBy = 0;
    let sortDirection = 0;

    if (!!key && !!direction) {
      // Let's retrieve sort by and sort direction
      sortBy = columns.findIndex((column: TableColumn) => column.key === key);
      sortDirection = direction === 'asc' ? 0 : 1;
    }

    // Set sort by.
    setSortBy(sortBy);

    // Set sort direction
    setSortDirection(sortDirection);

    // Let's search
    getNewRows(query, pagination?.from!, pagination?.currentPage, pagination?.rowsPerPage, sortBy, sortDirection);
  };

  const onOrganizationDeleted = () => {
    setOrgToDelete(undefined);
    getNewRows(query, pagination?.from!, pagination?.currentPage, pagination?.rowsPerPage);

  };

  return (
    <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
      <HeaderWrapper>
        <h1>{t('Organizations')}</h1>
        <Button
          icon={<SystemIcons.Institute/>}
          variant="secondary"
          disabled={accessLevel == AccessLevel.ReadOnly}
          size={Size.Large}
          onClick={() => {
            navigate('/organization/create');
          }}>
          {t('Create new organization')}
        </Button>
      </HeaderWrapper>
      <OrganizationSearch onSearch={(query: string) => onSearchRows(query)}
                          initialValue={new URLSearchParams(location.search).get('query')!}/>
      <Table
        columns={columns}
        border={true}
        rows={rows}
        sortProps={sortProps}
        remoteOperations={true}
        pagination={pagination}
        onRowsPerPageChange={(newRowsPerPage: number) => onRowsPerPageChange(newRowsPerPage)}
        onTriggerSortingChange={(key: string, direction?: TableSortingDirection) => onApplySorting(key, direction)}
        onNextPageClick={() => onNextPage()}
        onPreviousPageClick={() => onPreviousPage()}
        selectable={true}
        onSelectionChange={(row: OrganizationTableRow) => onRowClick(row)}
        showLoadingIndicator={loading}
      />
      <OrganizationDeleteConfirmModal isOpen={!!orgToDelete}
                                      onClose={() => setOrgToDelete(undefined)}
                                      onDeleted={onOrganizationDeleted}
                                      organization={orgToDelete}/>
    </StyledPageWidth>
  );
};

export default OrganizationList;
