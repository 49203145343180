import { COLORS, SystemIcons } from '@laerdal/life-react-components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { User as UserType } from '../../../../types';

type InviteListProps = {
  emails: string[];
  users: UserType[];
};

type InviteListUser = {
  isExistingUser: boolean;
  mainLabel: string;
  secondaryLabel: string;
  action: string;
};
const UserList = styled.div<{ expanded: boolean }>`
  max-height: ${(props) => (props.expanded ? '230px' : 'auto')};
  overflow-y: ${(props) => (props.expanded ? 'auto' : 'none')};

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLORS.white};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.neutral_500};
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.neutral_700};
    cursor: pointer;
  }
`;

const UserRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
  border-bottom: 1px solid ${COLORS.neutral_200};

  .user-actions {
    display: flex;
    position: relative;
    align-items: center;
    div {
      top: 40px;
      right: 0;
      &::-webkit-scrollbar {
        width: 0;
      }
      overflow-y: auto;
    }
  }
`;
const MoreRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
  cursor: pointer;
`;

const UserSection = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  align-items: center;
  svg {
    margin-right: 12px;
  }
`;

const NameAndMail = styled.div`
  margin: auto 1em auto 0;
  display: flex;
  flex-direction: column;
  pointer-events: none;
`;

const UserInfo = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
  pointer-events: none;
  color: ${(props: any) => props.color};
`;

const UserInfoEmail = styled(UserInfo)`
  font-size: 14px;
  color: ${COLORS.neutral_600};
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 0;

  color: ${COLORS.neutral_600};
  font-size: 14px;
  margin: auto 16px auto 16px;
  line-height: 100%;
`;

export const InviteList: React.FunctionComponent<InviteListProps> = ({ emails, users }: InviteListProps) => {
  const inviteUsers = emails
    .map((e) => {
      const tempUser = users.find((u) => u.email === e);
      return {
        isExistingUser: !!tempUser,
        mainLabel: !!tempUser ? `${tempUser.firstName ?? ''} ${tempUser.lastName ?? ''}` : e,
        secondaryLabel: !!tempUser ? tempUser.email : '',
        action: !!tempUser ? 'Move user' : 'Invite to join',
      } as InviteListUser;
    })
    .slice(0, emails.length > 3 ? 3 : emails.length);

  const [userDetails, setUserDetails] = useState<InviteListUser[]>(inviteUsers);
  const [showHasMore, setShowHasMore] = useState<boolean>(emails.length > 3);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notShowingCount, setNotShowingCount] = useState<number>(emails.length - 3);

  const showMoreUsers = (): void => {
    setShowHasMore(false);

    var allUsers = emails.map((e) => {
      const tempUser = users.find((u) => u.email === e);
      return {
        isExistingUser: !!tempUser,
        mainLabel: !!tempUser ? `${tempUser.firstName ?? ''} ${tempUser.lastName ?? ''}` : e,
        secondaryLabel: !!tempUser ? tempUser.email : '',
        action: !!tempUser ? 'Move user' : 'Invite to join',
      } as InviteListUser;
    });
    setUserDetails(allUsers);
  };

  return (
    <UserList expanded={!showHasMore}>
      {userDetails.map((u, index: number) => (
        <UserRow key={index}>
          <UserSection>
            {!u.isExistingUser && <SystemIcons.Mail size="22px" color={COLORS.neutral_600} className="somePaddingForAlignment" />}
            {u.isExistingUser && <SystemIcons.User size="22px" color={COLORS.neutral_600} className="somePaddingForAlignment" />}

            <NameAndMail>
              <UserInfo color={COLORS.neutral_600}> {u.mainLabel} </UserInfo>
              <UserInfoEmail>{u.secondaryLabel}</UserInfoEmail>
            </NameAndMail>
          </UserSection>

          <Action>{u.action}</Action>
        </UserRow>
      ))}
      {showHasMore && (
        <MoreRow onClick={() => showMoreUsers()}>
          <UserSection>
            <SystemIcons.Team size="22px" color={COLORS.neutral_600} className="somePaddingForAlignment" />
            <NameAndMail>
              <UserInfo color={COLORS.neutral_600}> +{notShowingCount} more </UserInfo>
            </NameAndMail>
          </UserSection>
        </MoreRow>
      )}
    </UserList>
  );
};
