/**
 * Import React Libraries.
 */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

/**
 * Import third-party libraries.
 */
import { COLORS, Table, Tag } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

/**
 * Import custom types.
 */
import { Service, MultiServiceUser, ServiceUserRecordWrapper } from '../../../types';
import { TableColumn, TablePagination, HyperLink } from '@laerdal/life-react-components';

/**
 * Import custom components.
 */
import ServiceUserSearch from './ServiceUserSearch';

/**
 * Import custom functions.
 */
import Api from '../../../utils/api';
import {TableSortingDirection, TableSortProps} from '@laerdal/life-react-components/dist/Table/TableTypes';

/**
 * Add custom styles.
 */
const OrganizationWrapper = styled.div`
  a {
    max-width: calc(100% - 40px);
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

/**
 * Add custom types.
 */
interface ServiceUserTableRow {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  organizationId: string;
  serviceRoleName: string;
  status: string;
}

interface ServiceUsersPageProps {
  service: Service;
}

const ServiceUsersPage = ({ service }: ServiceUsersPageProps) => {
  // Globally used states within the page
  const [pagination, setPagination] = useState<TablePagination>({
    from: 0,
    to: 0,
    currentPage: 1,
    total: 0,
    rowsPerPage: 10,
  });
  const [sortProps, setSortProps] = useState<TableSortProps | undefined>();
  const [sortDirection, setSortDirection] = useState<number>(0);
  const [sortBy, setSortBy] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation('Services');
  const [columns, setColumns] = useState<TableColumn[]>([
    {
      key: 'firstName',
      name: t('First name'),
      sortable: true,
    },
    {
      key: 'lastName',
      name: t('Last name'),
      sortable: true,
    },
    {
      key: 'email',
      name: t('Email address'),
      sortable: true,
    },
    {
      key: 'organization',
      name: t('Organization'),
      type: 'custom',
      customContent: (row, key) => {
        return (
          row.membership.length > 0 && (
            <OrganizationWrapper>
              <HyperLink
                id={`${row.membership[0].organizationId}`}
                href={`/organization/${row.membership[0].organizationId}`}
                variant="default"
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  event.preventDefault()
                  navigate(`/organization/${row.membership[0].organizationId}`);
                }}>
                {row.membership[0].organizationName}
              </HyperLink>
              {row.membership.length > 1 && <span>+{row.membership.length - 1}</span>}
            </OrganizationWrapper>
          )
        );
      },
      sortable: true
    },
    {
      key: 'state',
      name: t('State'),
      type: 'custom',
      customContent: (row, key) => 
        (
          row.identityId ? <Tag label='Registered' variant='positive' /> : undefined
        )
      ,
      sortable: false
    },
    {
      key: 'serviceRoleName',
      name: t('Service role'),
      sortable: false,
    }
  ]);
  const [rows, setRows] = useState<any[]>([]);
  const location = useLocation();

  /**
   * Sets up search parameters for the page.
   */
  useEffect(() => {
    // Let's setup search params
    setupSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Does all required setup and retrieves initial rows.
   */
  const setupSearchParams = (): void => {
    // Let's get search params
    const searchParams = new URLSearchParams(location.search);

    // Let's update query if we have it in the url
    if (searchParams.get('query')) {
      setQuery(searchParams.get('query')!);
    }

    // Let's update rows per page if we have it in the url
    if (searchParams.get('rowsPerPage')) {
      setPagination({ ...pagination, rowsPerPage: parseInt(searchParams.get('rowsPerPage')!) });
    }

    // Let's update sort direction and sort by
    if (searchParams.get('sortDirection') && searchParams.get('sortBy')) {
      setSortDirection(parseInt(searchParams.get('sortDirection')!));
      setSortBy(parseInt(searchParams.get('sortBy')!));
    }

    // Retrieve the rows
    getNewRows(
      searchParams.get('query') ? searchParams.get('query')! : query,
      searchParams.get('from') ? parseInt(searchParams.get('from')!) : pagination.from,
      searchParams.get('currentPage') ? parseInt(searchParams.get('currentPage')!) : pagination.currentPage,
      searchParams.get('rowsPerPage') ? parseInt(searchParams.get('rowsPerPage')!) : pagination?.rowsPerPage,
      searchParams.get('sortBy') ? parseInt(searchParams.get('sortBy')!) : sortBy,
      searchParams.get('sortDirection') ? parseInt(searchParams.get('sortDirection')!) : sortDirection,
    );
  };

  /**
   * Does all required pre-requisites and updates the search parameters for the context.
   * @param search - Search string to use for the row retrieval.
   * @param from - From which row to retrieve the data.
   * @param page - Current page in the table.
   * @param newRowsPerPage - New amount of rows per page.
   * @param newSortBy - An enum value indicating the sort by column.
   * @param newSortDirection - An enum value indicating the sort direction.
   */
  const updateSearchParams = (search: string, from: number, page: number, newRowsPerPage: number, newSortBy?: number, newSortDirection?: number): void => {
    const params = new URLSearchParams();

    // Let's add query if it is set
    search ? params.append('query', search) : params.delete('query');

    // Let's add from
    from ? params.append('from', from.toString()) : params.delete('from');

    // Let's add current page
    page ? params.append('currentPage', page.toString()) : params.delete('currentPage');

    // Let's add rows per page
    newRowsPerPage ? params.append('rowsPerPage', newRowsPerPage.toString()) : params.delete('rowsPerPage');

    // Let's add sort by
    newSortBy !== undefined ? params.append('sortBy', newSortBy.toString()) : params.delete('sortBy');

    // Let's add rows per page
    newSortDirection !== undefined ? params.append('sortDirection', newSortDirection.toString()) : params.delete('sortDirection');

    // Let's append context
    navigate({ pathname: '../users', search: params.toString() });
  };

  /**
   * Retrieves new rows from the API.
   * @param search - Search string to use for the row retrieval.
   * @param from - From which row to retrieve the data.
   * @param page - Current page in the table.
   * @param newRowsPerPage - New amount of rows per page.
   * @param sortBy - An enum value indicating the sort by column.
   * @param sortDirection - An enum value indicating the sort direction.
   */
  const getNewRows = (search: string, from: number, page: number, newRowsPerPage?: number, newSortBy?: number, newSortDirection?: number) => {
    // Assign temporary rows per page based on the global variable
    const tmpRowsPerPage = newRowsPerPage ? newRowsPerPage : pagination?.rowsPerPage;

    // Assign temp sort by and sort direction
    const tmpSortBy = newSortBy !== undefined ? newSortBy : sortBy;
    const tmpSortDirection = newSortDirection !== undefined ? newSortDirection : sortDirection;

    // Let's update search parameters
    updateSearchParams(search, from, page, tmpRowsPerPage, tmpSortBy, tmpSortDirection);
    setLoading(true);
    Api.FindServiceUsers(service.id, search, from - 1 >= 0 ? from - 1 : from, tmpRowsPerPage, tmpSortBy, tmpSortDirection)
      .then((users) => assignUsers(users, page * tmpRowsPerPage - tmpRowsPerPage + 1, page * tmpRowsPerPage, page, tmpRowsPerPage))
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  /**
   * Assign retrieved rows and update pagination.
   * @param serviceUserRecordWrapper - Service Users Record Wrapper containing rows and total row count.
   * @param from - From which row data was retrieved.
   * @param to - Till which row data was retrieved.
   * @param page - Current page in the table.
   * @param rowsPerPage - New rows per page that needs to be assigned.
   */
  const assignUsers = (serviceUserRecordWrapper: ServiceUserRecordWrapper, from: number, to: number, page: number, rowsPerPage: number) => {
    // Let's pull out the new rows data
    const newRows = serviceUserRecordWrapper.records.map((record: MultiServiceUser) => {
      return {
        id: record.id,
        firstName: record.firstName,
        lastName: record.lastName,
        email: record.email,
        membership: record.memberDtos,
        identityId: record.identityId,
        serviceRoleName: (record.memberDtos.length > 0 && record.memberDtos[0]?.serviceRoleName) ?? ''
      };
    });

    // Let's update pagination
    setPagination({
      from: from < 0 ? 0 : from,
      to: to > serviceUserRecordWrapper.totalCount ? serviceUserRecordWrapper.totalCount : to,
      currentPage: page,
      total: serviceUserRecordWrapper.totalCount,
      rowsPerPage,
    });

    // Let's assign new rows
    setRows(newRows);
  };

  /**
   * Navigate to the next page.
   */
  const onNextPage = () => {
    // Let's check if we can navigate
    if (pagination?.currentPage! * pagination?.rowsPerPage < pagination?.total!) {
      // Let's get new rows
      getNewRows(query, pagination?.from! + pagination?.rowsPerPage, pagination?.currentPage! + 1);
    }
  };

  /**
   * Navigate to the previous page.
   */
  const onPreviousPage = () => {
    // Let's check if we can navigate
    if (pagination?.currentPage! * pagination?.rowsPerPage - pagination?.rowsPerPage >= 0) {
      // Let's get new rows
      getNewRows(query, pagination?.from! - pagination?.rowsPerPage, pagination?.currentPage! - 1);
    }
  };

  /**
   * Updates rows per page and retrieves new data.
   * @param newRowsPerPage - New rows per page value set in the table.
   */
  const onRowsPerPageChange = (newRowsPerPage: number) => {
    // Let's retrieve fresh data
    getNewRows(query, 1, 1, newRowsPerPage);
  };

  /**
   * Navigates to the organization details page.
   * @param row - Row which was clicked in the table.
   */
  const onRowClick = (row: ServiceUserTableRow) => {
    if (row.email) {
      window.open(`/user/${encodeURIComponent(row.email)}`, '_blank');
    }
  };

  /**
   * Searches the table based on the entered query.
   * @param searchQuery - Search query based on which to search for data.
   */
  const onSearchRows = (searchQuery: string) => {
    // Let's update the query
    setQuery(searchQuery);

    // Let's retrieve updated data
    getNewRows(searchQuery, 1, 1, pagination?.rowsPerPage);
  };

  /**
   * Applies sorting for the table.
   * @param key - Column key to which sorting should be applied.
   * @param direction - Direction in which to sort data.
   */
  const onApplySorting = (key: string, direction?: TableSortingDirection): void => {
    let sortBy = 0;
    let sortDirection = 0;
    
    if (!!key && !!direction) {
      // Let's retrieve sort by and sort direction
      sortBy = columns.findIndex((column: TableColumn) => column.key === key);
      sortDirection = direction === 'asc' ? 0 : 1;
    }

    // Set sort by.
    setSortBy(sortBy);

    // Set sort direction
    setSortDirection(sortDirection);

    // Let's search
    getNewRows(query, pagination?.from!, pagination?.currentPage, pagination?.rowsPerPage, sortBy, sortDirection);
  };
  return (
    <>
      <h6>{t('People using this service')}</h6>
      <ServiceUserSearch onSearch={(query: string) => onSearchRows(query)} initialValue={new URLSearchParams(location.search).get('query')!} />
      <Table
        columns={columns}
        rows={rows}
        remoteOperations={true}
        sortProps={sortProps}
        pagination={pagination}
        onRowsPerPageChange={(newRowsPerPage: number) => onRowsPerPageChange(newRowsPerPage)}
        onTriggerSortingChange={(key: string, direction?: TableSortingDirection) => onApplySorting(key, direction)}
        onNextPageClick={() => onNextPage()}
        onPreviousPageClick={() => onPreviousPage()}
        selectable={true}
        onSelectionChange={(row: ServiceUserTableRow) => onRowClick(row)}
        showLoadingIndicator={loading}
      />
    </>
  );
};

export default ServiceUsersPage;
