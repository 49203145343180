import React from "react";
import { AccessLevel, FeatureNames, Organization } from "../../types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  BackButton,
  Banner,
  BREAKPOINTS,
  Button,
  Checkbox,
  COLORS,
  InputLabel,
  InputWrapper,
  Size,
  SystemIcons,
  TextField,
  ToastColor,
  ToastPosition
} from "@laerdal/life-react-components";
import { useNavigate } from "react-router";
import { useController, useForm, UseFormReset } from "react-hook-form";
import Api from "../../utils/api";
import { useToastContext, useUserContext } from "../../userContext";
import { FormCheckboxField } from "../../components/formComponents/FormCheckboxField";
import { useFeatures } from "../../utils/features/FeaturesHook";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 560px;

  ${BREAKPOINTS.MEDIUM} {
    gap: 24px;
  }

  ${BREAKPOINTS.LARGE} {
    gap: 32px;
  }
`;


const Section = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h6 {
    color: ${COLORS.neutral_600};
  }
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SectionFooter = styled.div`
  display: flex;
  gap: 16px;
`;

interface Props {
  savedOrganization: Organization;
  setSavedOrganization: (org: Organization) => void;
}

export const OrganizationSettingsPage = ({ savedOrganization, setSavedOrganization }: Props) => {
  const { t } = useTranslation("Organization");
  const { addToast } = useToastContext();
  const navigate = useNavigate();
  const { accessLevel } = useUserContext();
  const readOnly = accessLevel == AccessLevel.ReadOnly;

  const [checkFeature] = useFeatures();

  const { handleSubmit, reset, formState, control } = useForm<{
    isFederatedSsoEnabled?: boolean;
    idpName?: string
  }>({ defaultValues: savedOrganization });

  const {
    handleSubmit: permissionsHandleSubmit,
    reset: permissionsReset,
    formState: permissionsFormState,
    control: permissionsControl
  } = useForm<Organization>({ defaultValues: savedOrganization });

  const idpNameController = useController({ control, name: "idpName" });
  const federateSsoEnabledController = useController({ control, name: "isFederatedSsoEnabled" });

  const submit = (value: { isFederatedSsoEnabled?: boolean; idpName?: string } | {
    allowProducts: boolean,
    allowOrders: boolean,
    allowServiceAppointments: boolean
  }, reset: UseFormReset<Organization> | UseFormReset<{ isFederatedSsoEnabled?: boolean; idpName?: string }>) => {
    const newOrg = { ...savedOrganization, ...value };
    return Api.UpdateOrganization(newOrg)
      .then(() => {
        setSavedOrganization(newOrg);
        addToast(t("Organization updated successfully"), {
          color: ToastColor.GREEN,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE
        });
        reset(newOrg);
      })
      .catch(() => {
        addToast(t("There was a problem updating your organization"), {
          color: ToastColor.RED,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE
        });
      });
  };

  return (
    <>
      <BackButton size={Size.Small} onClick={() => navigate("/organization")}>
        {t("Back to organizations")}
      </BackButton>
      <Wrapper>
        <h3>{t("Settings")}</h3>
        {
          checkFeature(FeatureNames.OrganizationPermissions) &&
          <Section id="permissions" onSubmit={permissionsHandleSubmit((val) => submit(val, permissionsReset))}>
            <h6>{t("Basic settings")}</h6>
            <SectionContent>
              <span>Control what people at this organization can access when they sign in</span>
              <InputWrapper>
                <FormCheckboxField control={permissionsControl} id="allowProducts" disabled={readOnly}>
                  Display <b>My Products</b> in Connect
                </FormCheckboxField>
              </InputWrapper>
              <InputWrapper>
                <FormCheckboxField control={permissionsControl} id={"allowOrders"} disabled={readOnly}>
                  Enable access to <b>Order, Invoice & Shipment</b> information
                </FormCheckboxField>
              </InputWrapper>
              <InputWrapper>
                <FormCheckboxField control={permissionsControl} id={"allowServiceAppointments"} disabled={readOnly}>
                  Enable access to view <b>Service Appointments</b>
                </FormCheckboxField>
              </InputWrapper>
              <Banner icon={<SystemIcons.Information size="24px" />} type="neutral" size={Size.Small}>
                You can control specific access for a person from the <b>User Management</b> tab.
              </Banner>
            </SectionContent>
            <SectionFooter>
              <Button variant={"primary"} size={Size.Large} type={"submit"} disabled={readOnly || !permissionsFormState.isDirty}
                      loading={formState.isSubmitting || permissionsFormState.isSubmitting}>
                {t("Save changes")}
              </Button>
              <Button variant={"tertiary"} size={Size.Large} disabled={readOnly || !permissionsFormState.isDirty}
                      onClick={() => permissionsReset()}>
                {t("Cancel")}
              </Button>
            </SectionFooter>
          </Section>
        }

        <Section id={"ssoForm"} onSubmit={handleSubmit((val) => submit(val, reset))}>
          <h6>{t("Federated SSO")}</h6>
          <SectionContent>
            <InputWrapper>
              <InputLabel inputId={"idpName"} text={"Identity provider name"} />
              <TextField id={"idpName"} {...idpNameController.field} readOnly={true} />
            </InputWrapper>
            <InputWrapper>
              <Checkbox
                label={t("Enable federated SSO")}
                id={"isFederatedSsoEnabled"}
                readOnly={readOnly}
                selected={federateSsoEnabledController.field.value ?? false}
                select={federateSsoEnabledController.field.onChange}
                onBlur={federateSsoEnabledController.field.onBlur}
                ref={federateSsoEnabledController.field.ref}
              />
            </InputWrapper>
          </SectionContent>
          <SectionFooter>
            <Button variant={"primary"} size={Size.Large} type={"submit"} disabled={!formState.isDirty || readOnly}
                    loading={formState.isSubmitting || permissionsFormState.isSubmitting}>
              {t("Save changes")}
            </Button>
            <Button variant={"tertiary"} size={Size.Large}  disabled={!formState.isDirty || readOnly} onClick={() => reset()}>
              {t("Cancel")}
            </Button>
          </SectionFooter>
        </Section>
      </Wrapper>
    </>
  );
};
