import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { COLORS } from '@laerdal/life-react-components';

interface LinkProps extends React.LinkHTMLAttributes<HTMLLinkElement> {
  fontSize?: string;
}

export const LinkStyling = css`
  color: ${COLORS.primary_600};

  &:hover {
    color: ${COLORS.primary_700};
    text-decoration: underline;
  }
  &:active {
    color: ${COLORS.primary_800};
    text-decoration: underline;
  }
`;

export const StyledLink = styled(Link)<LinkProps>`
  font-size: ${(props) => props.fontSize || '16px'};
  line-height: 2;
  color: ${COLORS.primary};
  font-weight: bold;
  text-decoration: none;
  margin: 0 12px 0 auto;

  ${LinkStyling};
`;

export const StyledExternalLink = styled.a<LinkProps>`
  font-size: ${(props) => props.fontSize || '16px'};
  line-height: 2;
  color: ${COLORS.primary};
  font-weight: bold;
  text-decoration: none;
  margin: 0 12px 0 auto;

  ${LinkStyling};
`;
