import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GlobalNavigationBar } from '@laerdal/life-react-components';
import useClickOutside from '../../hooks/useClickOutside';
import {
  mainNavigationOptions,
} from './GlobalNavigationConfig';

import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../userContext';
import { useMsal } from '@azure/msal-react';
import { AccessLevel } from '../../types';

const Header = (): React.ReactElement => {
  const [showMenu, setShowMenu] = useState(false);
  const [showNavigationBar, setShowNavigationBar] = useState(true);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { authenticated, firstName, lastName, email, accessLevel } = useUserContext();

  const menuRef = useRef(null);
  const location = useLocation();
  
  const { t } = useTranslation('Header');

  useClickOutside(menuRef, () => (showMenu ? setShowMenu(false) : null));

  useEffect(() => {
    function getList() {
      return Array.prototype.filter.call(document.querySelectorAll('.UserMenuLink'), function (item) {
        return item.tabIndex >= '0';
      });
    }
    function handleKeyPress(e: any) {
      if (e.keyCode === 38) {
        e.preventDefault();
        var list = getList();
        var index = list.indexOf(document.activeElement);
        var next = list[index - 1] || list[0];

        if (next) {
          next.focus();
        }
      } else if (e.keyCode === 40) {
        e.preventDefault();
        list = getList();
        index = list.indexOf(document.activeElement);
        next = list[index + 1] || list[0];

        if (next) {
          next.focus();
        }
      }
    }

    document.addEventListener('keydown', handleKeyPress, false);
    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, []);

  /**
   * Check if page is shown in an iframe, if so - hide the nav bar.
   */
  useEffect(() => {
    // Let's check if we are showing page in iframe, if so then let's hide the global nav bar
    if (location.search.toLowerCase().indexOf('iframe=1') > -1) {
      setShowNavigationBar(false);
    }
  }, [location]);

  return (
    <div className="header">
      {
        showNavigationBar &&
        <GlobalNavigationBar
          name="Cloud Control"
          desktop={{ buttons: [{type: 'profile'}], items: accessLevel == AccessLevel.None ? [] : mainNavigationOptions}}
          mobile={{ buttons: [{type: 'profile'}], items: accessLevel == AccessLevel.None ? [] : mainNavigationOptions}}
          // secondaryButton={!authenticated ? secondaryButton : undefined}
          profile={{
            label: 'Account',
            user: {
              firstName: firstName,
              lastName: lastName,
              email: email
            },
            sections: [],
            signOut: {
              label: 'Sign Out',
              action: () => {
                // Log out from office365
                instance.logout({ account: instance.getActiveAccount() });
                navigate('/logout');
              }
            }
          }}
          logo={{
            to: '/',
            showBetaTag: false
          }}
          isAuthenticated={authenticated}
        />
      }
    </div>
  );
};

export default Header;
