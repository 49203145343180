import React from 'react';
import {FeatureContext} from "./FeatureProvider";
import {FeatureNames} from "../../types";


export const useFeatures = () => {

  const features = React.useContext(FeatureContext);

  const check = (feature: FeatureNames) => {
    return features.filter(a => a.name === feature)?.[0]?.enabled ?? false;
  };

  return [check];
};