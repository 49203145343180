import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router';
import {useTranslation} from 'react-i18next';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import {
  ContentIcons,
  GlobalLoadingPage,
  PageWidth,
  ComponentL,
  ComponentS,
  COLORS,
  ComponentTextStyle,
  SystemIcons,
  HyperLink
} from '@laerdal/life-react-components';
import moment from 'moment';

import Api from '../../utils/api';
import { Organization, Subscription } from '../../types';
import { useUserContext } from '../../userContext';
import UnauthorizedMessage from '../../components/UnauthorizedMessage';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
`;

const OrganizationInfoDetails = styled.div`
  width: 100%;

  .vertical-middle {
    > svg {
      vertical-align: middle;
    }
  }
`;

const DataTable = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  
  &.bottom-borders {
    > div {
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      padding: 8px 0px;

      svg {
        vertical-align: middle;
      }
    }
  }
`;

const TableLine = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  border: 1px solid ${COLORS.neutral_100};
  padding: 8px;

  + div {
    border-top: 0px;
  }
`;

interface OrgUser {
  email: string;
  lastLogin: string;
}

interface OrgSubscription {
  name: string;
  status: string;
}

const OrganizationInfo = () => {
  const {t} = useTranslation('Organization');
  const location = useLocation();
  const [customerNo, setCustomerNo] = useState<string>('');
  const [organization, setOrganization] = useState<Organization>();
  const [organizationOnboarded, setOrganizationOnboarded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [orgUsers, setOrgUsers] = useState<OrgUser[] | undefined>(undefined);
  const [orgSubscriptions, setOrgSubscriptions] = useState<OrgSubscription[]>([]);
  const { isAccountAndCustomerViewer } = useUserContext();

  /**
   * Retrieves URL parameters and stores them in a state.
   */
  const getURLParameters = (): void => {
    // Let's get URL params
    const parameters = new URLSearchParams(location.search);

    // Let's retrieve parameters from the url
    if (parameters.get('customerNo')) {
      // Let's update customer number
      setCustomerNo(parameters.get('customerNo')!);
    }
  };

  /**
   * Retrieves a specific organization data.
   * @param id - ID of the organization which to retrieve.
   */
  const getOrganization = (id: string): void => {
    Api.GetOrganization(id).then((organization: Organization) => {
      // Set organization
      setOrganization(organization);

      // Let's check if organization is onboarded
      if (organization.members.filter(member => member.user.identityId).length > 0) {
        setOrganizationOnboarded(true);
      }
      
      getOrgSubscriptions(organization);

      getOrgAdmins(organization);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  /**
   * Retrieves organization subscriptions.
   * @param organization - Organization for which to retrieve subscriptions.
   */
  const getOrgSubscriptions = (organization: Organization) => {
    const subscriptions: OrgSubscription[] = [];

    organization.services!
      .filter(service => service.subscription)
      .forEach(service => {
        // Let's check 
        subscriptions.push({
          name: service.name,
          status: getSubscriptionStatus(service.subscription)
        });
      });
    
    setOrgSubscriptions(subscriptions);
  };

  /**
   * Retrieves the status of the subscription.
   * @param subscription - Subscription data from which to retrieve status.
   * @returns Status row text for the subscription.
   */
  const getSubscriptionStatus = (subscription: Subscription): string => {
    if (!subscription.expirationDate) {
      return '-';
    } else if (subscription.autoRenew && new Date(subscription.expirationDate!) > new Date()) {
      return t('Renews on ') + moment(subscription.expirationDate).format('yyyy-MM-DD');
    } else if (!subscription.autoRenew && new Date(subscription.expirationDate!) > new Date()) {
      return t('Expires on ') + moment(subscription.expirationDate).format('yyyy-MM-DD');
    } else {
      return t('Expired on ') + moment(subscription.expirationDate).format('yyyy-MM-DD');
    }
  };

  /**
   * Retrieves organization admins.
   * @param organization - Organization for which to retrieve admins.
   */
  const getOrgAdmins = async (organization: Organization) => {
    // Let's create list of admins
    let orgAdmins: OrgUser[] = [];

    for (const member of organization.members) {
      if (member.permissions!.find(permission => permission.permissionId === 'account.owner') !== undefined)  {
        // Let's get last login date
        const data = await Api.getUserLastLogin(member.user.id);

        let lastLogin = '-';

        if (data.length > 0) {
          const lastLoginDate = data[0].eventTime;

          if (new Date(lastLoginDate).toDateString() === new Date().toDateString()) {
            lastLogin = t('Today, ') + moment(lastLoginDate).format('hh:mm A');
          } else if (new Date(lastLoginDate).toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()) {
            lastLogin = t('Yesterday, ') + moment(lastLoginDate).format('hh:mm A');
          } else {
            lastLogin = moment(lastLoginDate).format('MMM Do, YYYY, hh:mm A');
          }
        }

        orgAdmins.push({
          email: member.user.email,
          lastLogin
        });
      }
    };

    // Sort alphabetically
    orgAdmins.sort((a, b) => {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    });

    setOrgUsers(orgAdmins);
  };

  /**
   * Retrieves all passed parameters for the page.
   */
  useEffect(() => {
    // Let's retrieve parameters
    getURLParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Retrieves organization details from the API.
   */
  useEffect(() => {
    if (customerNo) {
      Api.GetOrganizationByCustomerNo(customerNo)
        .then((organization: Organization) => {
          // Let's retrieve organization details and members
          getOrganization(organization.id);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [customerNo]);

  return (
    <>
      <Helmet>
        <title>{t('Organization Information')}</title>
      </Helmet>
      {
        loading ? (
          <GlobalLoadingPage/>
        ) : (
          isAccountAndCustomerViewer ? (
            <PageWidth useMaxWidth={true} maxWidth={1088}>
              <Row>
                <ComponentL textStyle={ComponentTextStyle.Bold}>{ organization ? `${t('Organization')} - ${organization.name}` : t('No Organization Found')}</ComponentL>
                <ContentIcons.Institute size='32' />
              </Row>
              { organization && 
                <OrganizationInfoDetails>
                  <DataTable className="bottom-borders">
                    <TableLine>
                      <ComponentS textStyle={ComponentTextStyle.Bold}>{t('Account number')}</ComponentS>
                      <ComponentS>{organization.customerNo}</ComponentS>
                    </TableLine>
                    <TableLine>
                      <ComponentS textStyle={ComponentTextStyle.Bold}>{t('Digital status')}</ComponentS>
                      <ComponentS color={organizationOnboarded ? COLORS.black : COLORS.critical_500} >
                        {organizationOnboarded 
                          ? <><SystemIcons.CheckMark size='20' /> { t('Onboarded to Connect') }</>
                          : t('Not onboarded to Connect')}
                      </ComponentS>
                    </TableLine>
                  </DataTable>

                  { orgUsers !== undefined &&
                    <>
                      <ComponentS textStyle={ComponentTextStyle.Bold}>{t('Admins')} ({orgUsers.length})</ComponentS>
                      <DataTable>
                        {
                          orgUsers.map(user => (
                            <TableLine key={user.email}>
                            <ComponentS>{user.email}</ComponentS>
                            <ComponentS>{t('Last login:')} {user.lastLogin}</ComponentS>
                            </TableLine>
                          ))
                        }
                      </DataTable>
                    </>
                  }

                  { orgSubscriptions !== undefined &&
                    <>
                      <ComponentS textStyle={ComponentTextStyle.Bold}>{t('Subscriptions')} ({orgSubscriptions.length})</ComponentS>
                      <DataTable>
                        {
                          orgSubscriptions.map(subscription => (
                            <TableLine key={subscription.name}>
                              <ComponentS>{subscription.name}</ComponentS>
                              <ComponentS color={subscription.status.indexOf('Expired') === -1 ? COLORS.black : COLORS.critical_500}>{subscription.status}</ComponentS>
                            </TableLine>
                          ))
                        }
                      </DataTable>
                    </>
                  }

                  <HyperLink id="UserInfo" variant="default" href={`/organization/${organization.id}`} className="vertical-middle">
                    {t('View in Cloud Control')} <SystemIcons.OpenNewWindow />
                  </HyperLink>
                </OrganizationInfoDetails>
              }
            </PageWidth>
          ) : (
            <UnauthorizedMessage>
              <>
                You don't have access to Active Onboarding functionality <br />
                Contact a member of the Laerdal Support Center team to request access.
              </>
            </UnauthorizedMessage>
          )
        )}
    </>
  );
};

export default OrganizationInfo;
