import React, {useEffect, useState} from "react";
import {
  BasicDropdown,
  Button,
  Checkbox,
  COLORS,
  DropdownFilter,
  InputLabel, NoteMessage,
  Size,
  SystemIcons,
  TextField,
  ToggleSwitch
} from "@laerdal/life-react-components";
import styled from "styled-components";
import {Trans, useTranslation} from "react-i18next";

import {AccessLevel, CountryDto, Industry, Organization, OrganizationType} from "../../../types";
import {yupResolver} from "@hookform/resolvers/yup";
import AddressFields from "../AddressFields";
import {useUserContext} from "../../../userContext";
import * as yup from "yup";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {FormTextField} from "../../../components/formComponents/FormTextField";

const DropdownWrapper = styled.div``;
const MultiInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  & > div {
    flex: 1;
  }

  input {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface OrganizationDetailsFormProps {
  savedOrganization: Organization;
  countries: CountryDto[];
  onUpdate: (section: string, organization: Organization) => Promise<boolean>;
  isSaving?: string;
  isSalesforceAccount: boolean;
}

const generalDetailsValidator = () => {
  return yup.object().shape({
    name: yup.string().required("Please provide organization name"),
    customerNo: yup.string(),
    industry: yup.string()
  });
};

const primaryAddressValidator = () => {
  return yup.object().shape({
    address: yup.object().shape({
      country: yup.object().shape({
        codeAlpha2: yup.string().required("Please select country")
      })
    })
  });
};

const billingDetailsValidator = () => {
  return yup.object().shape({
    billingEmailAddress: yup.string().email("Please enter a valid email")
  });
};

const billingAddressValidator = () => {
  return yup.object().shape({
    useOrganizationAddressForBilling: yup.boolean(),
    billingAddress: yup.object().when("useOrganizationAddressForBilling", {
      is: true,
      then: (schema) =>
        schema.shape({
          country: yup.object()
        }),
      otherwise: (schema) =>
        schema.shape({
          country: yup.object().shape({
            codeAlpha2: yup.string().required("Please select country")
          })
        })
    })
  });
};

const OrganizationDetailsForm = ({
                                   savedOrganization,
                                   countries,
                                   onUpdate,
                                   isSaving,
                                   isSalesforceAccount
                                 }: OrganizationDetailsFormProps) => {
  const {t} = useTranslation("Organization");
  const {accessLevel} = useUserContext();
  const readOnly = accessLevel == AccessLevel.ReadOnly;
  const [allowCopy, setAllowCopy] = useState<boolean>(Boolean(savedOrganization.customerNo));

  const generalDetailsForm = useForm<Organization>({
    defaultValues: savedOrganization,
    resolver: yupResolver(generalDetailsValidator())
  });
  const primaryAddressForm = useForm<Organization>({
    defaultValues: savedOrganization,
    resolver: yupResolver(primaryAddressValidator())
  });
  const billingDetailsForm = useForm<Organization>({
    defaultValues: savedOrganization,
    resolver: yupResolver(billingDetailsValidator())
  });
  const billingAddressForm = useForm<Organization>({
    defaultValues: savedOrganization,
    resolver: yupResolver(billingAddressValidator())
  });

  const organizationAddressWatch = billingAddressForm.watch("useOrganizationAddressForBilling");

  useEffect(() => {
    if (savedOrganization?.address) billingAddressForm.setValue("address", savedOrganization.address);
  }, [savedOrganization]);

  const industries = [
    {displayLabel: t("Healthcare"), value: Industry.Healthcare.toString()},
    {displayLabel: t("Educational Provider"), value: Industry.EducationalProvider.toString()},
    {displayLabel: t("Emergency Medical Service"), value: Industry.EmergencyMedicalService.toString()},
    {displayLabel: t("Gov/Federal and Military"), value: Industry.GovFederalAndMilitary.toString()},
    {displayLabel: t("Other"), value: Industry.Other.toString()}
  ];

  const watch = generalDetailsForm.watch("customerNo");
  useEffect(() => {
    const subscription = generalDetailsForm.watch((value) => {
      setAllowCopy(Boolean(value.customerNo));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const orgTypes = [
    {value: OrganizationType.Individual.toString(), displayLabel: 'Individual'},
    {value: OrganizationType.Organization.toString(), displayLabel: 'Organization'}
  ];

  return (
    <>

      <GroupContainer>
        <h6>{t("General details")}</h6>
        <DropdownWrapper>
          <InputLabel inputId="name" text="Organization name" size={Size.Medium}/>
          <FormTextField control={generalDetailsForm.control}
                         id="name"
                         required={true}
                         disabled={readOnly}
                         readOnly={isSalesforceAccount}/>
        </DropdownWrapper>
        <DropdownWrapper>
          <InputLabel inputId="customerNo" text="Account number (Customer ID)" size={Size.Medium} required={false}
                      showCopyButton={allowCopy}/>
          <FormTextField control={generalDetailsForm.control}
                         id="customerNo"
                         disabled={readOnly}
                         readOnly={isSalesforceAccount}/>
        </DropdownWrapper>
        <DropdownWrapper>
          <InputLabel inputId="organizationCode" text="Sales region" size={Size.Medium} required={false}/>
          <TextField id="organizationCode" value={savedOrganization.organizationCode ?? ""} readOnly={true}/>
        </DropdownWrapper>
        <DropdownWrapper>
          <InputLabel inputId="sfCorporateSegment" text="Salesforce Corporate Segment" size={Size.Medium}
                      required={false}/>
          <TextField id="sfCorporateSegment" value={savedOrganization.sfCorporateSegment ?? ""} readOnly={true}/>
        </DropdownWrapper>
        <DropdownWrapper>
          <InputLabel inputId="sfLocalSegment" text="Salesforce Local Segment" size={Size.Medium} required={false}/>
          <TextField id="sfLocalSegment" value={savedOrganization.sfLocalSegment ?? ""} readOnly={true}/>
        </DropdownWrapper>
        <DropdownWrapper>
          <InputLabel inputId="industry" text="Industry" size={Size.Medium} required={false}/>
          <Controller<Organization>
            control={generalDetailsForm.control}
            name="industry"
            render={({field: {value: fieldVal, ...fieldRest}}) => (
              <DropdownFilter
                id="industry"
                disabled={readOnly}
                list={industries}
                placeholder={t("Select...")}
                onSelect={(value: string) => {
                  fieldRest.onChange(value);
                }}
                value={fieldVal?.toString()}
                {...fieldRest}
                messageOnNoResults={t("No result")}
              />
            )}
          />
        </DropdownWrapper>
        <DropdownWrapper>
          <Controller<Organization>
            control={generalDetailsForm.control}
            name="internalAccount"
            render={({field: {value: fieldVal, ...fieldRest}}) => (
              <ToggleSwitch
                id="internalAccount"
                disabled={readOnly}
                selected={fieldVal}
                label={t("Internal account")}
                onToggle={(state) => {
                  fieldRest.onChange(state);
                }}
                {...fieldRest}
              />
            )}
          />
        </DropdownWrapper>
        <ButtonWrapper>
          <Button
            onClick={() =>
              generalDetailsForm.handleSubmit(
                async (data) => {
                  if (await onUpdate("generalDetails", data)) generalDetailsForm.reset(generalDetailsForm.getValues());
                },
                (error) => {
                  console.log(error);
                }
              )()
            }
            size={Size.Large}
            variant="primary"
            loading={isSaving === "generalDetails"}
            disabled={readOnly || !generalDetailsForm.formState.isDirty}>
            {t("Save changes")}
          </Button>
          <Button onClick={() => generalDetailsForm.reset()}
                  variant="tertiary"
                  size={Size.Large}
                  disabled={readOnly || isSaving === "generalDetails"}>
            {t("Cancel")}
          </Button>
        </ButtonWrapper>
      </GroupContainer>

      <GroupContainer>
        <h6>{t("Primary address")}</h6>
        <FormProvider {...primaryAddressForm}>
          <AddressFields
            addressType="address"
            countries={countries}
            prefix="address"
            fieldsAreLocked={isSalesforceAccount}
          />
        </FormProvider>
        <ButtonWrapper>
          <Button
            size={Size.Large}
            variant="primary"
            onClick={() => {
              primaryAddressForm.handleSubmit(
                async (data) => {
                  if (await onUpdate("primaryAddress", data)) primaryAddressForm.reset(primaryAddressForm.getValues());
                },
                (error) => {
                  console.log(error);
                }
              )();
            }}
            loading={isSaving === "primaryAddress"}
            disabled={readOnly || isSaving === "primaryAddress" || !primaryAddressForm.formState.isDirty || isSalesforceAccount}>
            {t("Save changes")}
          </Button>
          <Button onClick={() => primaryAddressForm.reset()}
                  size={Size.Large}
                  variant="tertiary"
                  disabled={readOnly || isSaving === "primaryAddress" || isSalesforceAccount}>
            {t("Cancel")}
          </Button>
        </ButtonWrapper>
      </GroupContainer>

      <GroupContainer>
        <h6>{t("Billing details")}</h6>
        <DropdownWrapper>
          <InputLabel inputId={'billingOrganizationName'} text={t('Billing organization name')}/>
          <FormTextField control={billingDetailsForm.control}
                         id="billingOrganizationName"
                         disabled={readOnly}
                         readOnly={isSalesforceAccount}/>
        </DropdownWrapper>
        <DropdownWrapper>
          <InputLabel inputId={'billingContactPersonFirstName'} text={t('Primary contact person')}/>
          <MultiInputWrapper>
            <FormTextField control={billingDetailsForm.control}
                           id="billingContactPersonFirstName"
                           disabled={readOnly}/>
            <FormTextField control={billingDetailsForm.control}
                           id="billingContactPersonLastName"
                           disabled={readOnly}/>
          </MultiInputWrapper>
          {
            !billingDetailsForm.getFieldState('billingContactPersonFirstName').invalid &&
            !billingDetailsForm.getFieldState('billingContactPersonLastName').invalid &&
            <NoteMessage>{t('The name of a person who handles invoices.')}</NoteMessage>
          }
        </DropdownWrapper>
        <DropdownWrapper>
          <InputLabel inputId={'billingEmailAddress'} text={t('Billing email address')}/>
          <FormTextField control={billingDetailsForm.control}
                         id="billingEmailAddress"
                         note={{
                           icon: <SystemIcons.Information/>,
                           message: 'Invoices will be sent to this email address.'
                         }}
                         disabled={readOnly}/>
        </DropdownWrapper>
        <DropdownWrapper>
          <InputLabel inputId={'vatNumber'} text={t('VAT Number')}/>
          <FormTextField control={billingDetailsForm.control}
                         id="vatNumber"
                         disabled={readOnly}
                         readOnly={isSalesforceAccount}/>
        </DropdownWrapper>
        <ButtonWrapper>
          <Button
            size={Size.Large}
            variant="primary"
            onClick={() => {
              if (!isSaving)
                billingDetailsForm.handleSubmit(
                  async (data) => {
                    if (await onUpdate("billingDetails", data)) billingDetailsForm.reset(billingDetailsForm.getValues());
                  },
                  (error) => console.log(error)
                )();
            }}
            loading={isSaving === "billingDetails"}
            disabled={readOnly || isSaving === "billingDetails" || !billingDetailsForm.formState.isDirty}>
            {t("Save changes")}
          </Button>
          <Button onClick={() => billingDetailsForm.reset()}
                  size={Size.Large}
                  variant="tertiary"
                  disabled={readOnly || isSaving === "billingDetails"}>
            {t("Cancel")}
          </Button>
        </ButtonWrapper>
      </GroupContainer>

      <GroupContainer>
        <h6>{t("Billing address")}</h6>
        <FormProvider {...billingAddressForm}>
          <Controller
            control={billingAddressForm.control}
            name="useOrganizationAddressForBilling"
            render={({field}) => (
              <Checkbox
                id="useOrganizationAddressForBilling"
                {...field}
                disabled={readOnly}
                readOnly={isSalesforceAccount}
                selected={field.value}
                select={(e) => field.onChange(e)}
                label={t("Same as primary address?")}
              />
            )}
          />

          <AddressFields
            addressType={organizationAddressWatch ? "address" : "billingAddress"}
            countries={countries}
            prefix={organizationAddressWatch ? "address" : "billingAddress"}
            fieldsAreDisabled={organizationAddressWatch}
            fieldsAreLocked={isSalesforceAccount}
          />
        </FormProvider>
        <ButtonWrapper>
          <Button
            size={Size.Large}
            variant="primary"
            onClick={() => {
              if (!isSaving)
                billingAddressForm.handleSubmit(
                  async (data) => {
                    if (await onUpdate("billingAddress", data)) billingAddressForm.reset(billingAddressForm.getValues());
                  },
                  (error) => console.log(error)
                )();
            }}
            loading={isSaving === "billingAddress"}
            disabled={readOnly || isSaving === "billingAddress" || !billingAddressForm.formState.isDirty || isSalesforceAccount}>
            {t("Save changes")}
          </Button>
          <Button onClick={() => billingAddressForm.reset()}
                  size={Size.Large}
                  variant="tertiary"
                  disabled={readOnly || isSaving === "billingAddress" || isSalesforceAccount}>
            {t("Cancel")}
          </Button>
        </ButtonWrapper>
      </GroupContainer>
    </>
  );
};

export default OrganizationDetailsForm;
