import React, {useEffect, useRef} from 'react';
import {ToSFile} from '../AddNewToSVersion';
import {
  BasicDropdown, Button,
  DropdownItem, ErrorMessage, IconButton,
  InputLabel,
  ListRow,
  ModalDialog,
  Size,
  SystemIcons, ToggleSwitch,
} from '@laerdal/life-react-components';
import {LanguageDto} from '../../../../types';
import {useController, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTermsOfServiceFileValidator} from '../helpers/TermsOfServiceValidator';
import styled from 'styled-components';


const FileInfoContainer = styled.div`
  position: relative;

  & > button {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .list-item-note {
    margin-right: 50px;
  }
`;

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: (file: ToSFile) => void;
  languages: LanguageDto[];
  fallback?: boolean;
  files: ToSFile[];
}

export const TermsOfServiceFileUploadModal: React.FC<Props> = ({
                                                                 isModalOpen,
                                                                 onClose,
                                                                 onSubmit,
                                                                 languages,
                                                                 files,
                                                               }) => {

  const fileInputRef = useRef<HTMLInputElement>(null);
  const schema = useTermsOfServiceFileValidator();

  const {control, watch, reset, setValue, handleSubmit} = useForm<ToSFile>({
    resolver: yupResolver(schema),
    defaultValues: {
      file: '',
      fallback: false,
    },
  });

  const fileController = useController({name: 'file', control: control});
  const fileIdController = useController({name: 'fileId', control: control});
  const fallbackController = useController({name: 'fallback', control: control});
  const languageCodeController = useController({name: 'languageCode', control: control});

  const file = watch('file');
  const languageCode = watch('languageCode');

  useEffect(() => {
    setValue('languageLabel', languages.find((x) => x.locale == languageCode)?.name ?? '');
  }, [languageCode]);

  useEffect(() => {
    reset({
      file: '',
      fallback: !files?.length,
    });
  }, [files]);

  const close = () => {
    reset();
    onClose();
  };

  const submit = async (value: ToSFile) => {
    onSubmit(value);
    close();
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      title="Add document"
      tooltip="The primary document is displayed when user language preference does not equal to language of any other file in version."
      submitAction={handleSubmit(submit)}
      closeAction={close}
      closeModalAndClearInput={close}
      buttons={[
        {
          variant: 'tertiary',
          text: 'Cancel',
          action: close,
        },
        {
          text: 'Add',
          action: handleSubmit(submit),
        },
      ]}>
      <InputLabel inputId="" text="Language"/>
      <BasicDropdown
        id="languagesDropdown"
        list={languages
          .filter((y) => !files.some((z) => z.languageCode == y.locale))
          .map(
            (x) =>
              ({
                value: x.locale,
                displayLabel: x.name,
                noteLabel: x.locale,
              } as DropdownItem),
          )}
        pinTopItem={true}
        itemsType="normal"
        scrollable={true}
        placeholder="Select language ..."
        messageOnNoResults="No results found"
        size={Size.Medium}

        activeValidationMessage={languageCodeController.fieldState.error?.message}
        ref={languageCodeController.field.ref}
        value={languageCodeController.field.value}
        onSelect={languageCodeController.field.onChange}
        onBlur={languageCodeController.field.onBlur}
      />

      {file && (
        <FileInfoContainer>
          <ListRow mainText={file.name} icon={<SystemIcons.Document/>} size={Size.Small}/>
          <IconButton className="clearFile" variant="secondary" shape="circular"
                      action={() => setValue('file', '')}>
            <SystemIcons.Clear size="24px"/>
          </IconButton>
        </FileInfoContainer>
      )}

      {!file && (
        <>
          <Button variant="secondary" ref={fileController.field.ref} onClick={() => fileInputRef.current?.click()}>
            Choose file
          </Button>
          <input
            type="file"
            accept=".pdf"
            style={{display: 'none'}}
            {...fileController.field}
            onChange={(e) => fileController.field.onChange(e.target.files?.[0] ?? '')}
            ref={fileInputRef}
          />
          {
            (!!fileController.fieldState.error?.message || !!fileIdController.fieldState.error?.message) &&
              <ErrorMessage>
                {fileController.fieldState.error?.message || fileIdController.fieldState.error?.message}
              </ErrorMessage>
          }


        </>
      )}

      <ToggleSwitch
        id="TogglerWithoutLabelNormal"
        label="Set as primary"
        disabled={!files?.length}
        ref={fallbackController.field.ref}
        selected={fallbackController.field.value}
        onBlur={fallbackController.field.onBlur}
        onToggle={fallbackController.field.onChange}
      />
    </ModalDialog>
  );
};
