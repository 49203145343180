/**
 * Import React libraries.
 */
import React, {useEffect, useState} from "react";

/**
 * Import third-party libraries.
 */
import {Button, DropdownButton, Size, SystemIcons, Table} from "@laerdal/life-react-components";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {formatDateOnly} from "../../../utils/functions";

/**
 * Import custom types.
 */
import {TermsOfServiceDocument} from "../../../types";

import Api from "../../../utils/api";

import {useUserContext} from "../../../userContext";
import {TableColumn, TableColumnTypes} from "@laerdal/life-react-components/dist/Table/TableTypes";
import {useNavigate} from "react-router";
import ConfirmDeleteToSModal, {ConfirmDeleteToSProps} from "./modals/ConfirmDeleteToSModal";
import moment from "moment";
import {Roles} from "../../../components/authorization/Roles";
import {RoleAuthComponent} from "../../../components/authorization/RoleAuthComponent";

/**
 * Add custom types.
 */
export interface Props {
  serviceId: string;
}

/**
 * Add custom styles.
 */
const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  table .right  {
    text-align: right;
  }
`;

const ServiceDocumentsPage = ({ serviceId }: Props) => {
  // Globally used variables within the page
  const { t } = useTranslation("Services");

  //const [documents, setDocuments] = useState<TermsOfServiceDocument[]>([]);
  const { salesOrgs } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState<ConfirmDeleteToSProps | null>(null);
  const navigate = useNavigate();
  const [allToSDocs, setAllTosDocs] = useState<TermsOfServiceDocument[]>([]);

  const columns: TableColumn[] = [
    {
      key: "name",
      name: "Name",
      sortable: true,
      type: "text" as TableColumnTypes
    },

    {
      key: "version",
      name: "Version",
      sortable: true,
      type: "text" as TableColumnTypes
    },

    {
      key: "languages",
      name: "Languages",
      sortable: true,
      justify: "right",
      type: "number" as TableColumnTypes
    },

    {
      key: "effectiveDateOnly",
      name: "Effective from",
      sortable: true,
      type: "text" as TableColumnTypes
    },

    {
      key: "salesOrgName",
      name: "Sales Organization",
      sortable: false,
      type: "text" as TableColumnTypes
    },

    {
      key: "actions",
      name: "",
      sortable: false,
      type: "custom" as TableColumnTypes,
      customContent: (row: any, key: string) => (
        <DropdownButton
          type={"icon"}
          items={[
            {
              value: "Upload new version"
            },
            {
              value: "Delete",
              disabled: !row.canDelete
            }
          ]}
          size={Size.Small}
          width="300px"
          icon={<SystemIcons.MoreVertical size="20px" />}
          onClick={(id) => {
            switch (id[0]) {
              case "Upload new version":
                navigate(`/service/${serviceId}/tos/${row.tosId}`);
                break;
              case "Delete":
                setDeleteCandidate({
                  tosId: row.tosId,
                  tosName: row.name,
                  closeModal: () => {
                    setDeleteCandidate(null);
                    reloadToS();
                  }
                });
                break;
            }
          }}
        />
      )
    }
  ];

  useEffect(() => {
    reloadToS();
  }, []);

  const reloadToS = () => {
    setLoading(true);
    Api.GetToS(serviceId)
      .then((response) => {
        setAllTosDocs(response);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <RoleAuthComponent roles={[Roles.ToSManager, Roles.Admin]} />
      <HeaderContainer>
        <h6>{t("Service terms")}</h6>
        <Button variant="primary" size={Size.Large} onClick={() => navigate(`/service/${serviceId}/tos/`)}>
          Upload new terms
        </Button>
      </HeaderContainer>
      <TableContainer>
        <Table
          showLoadingIndicator={loading}
          rows={allToSDocs.map((x) => {
            {
              const latestToS = new TermsOfServiceDocument(x).getLatestToSVersion();
              return {
                name: x.name,
                tosId: x.id,
                version: `${latestToS?.version}.${latestToS?.minorVersion}`,
                languages: latestToS?.files.length ?? 0,
                effectiveAt: latestToS?.effectiveAt,
                effectiveDateOnly: formatDateOnly(latestToS?.effectiveAt),
                salesOrgName: salesOrgs.find((salesOrg) => salesOrg.code == x.salesOrganizationCode)?.name,
                canDelete: !x.versions?.filter(a => moment(a.effectiveAt).isBefore(moment())).length
              };
            }
          })}
          columns={columns}
        />
      </TableContainer>
      {deleteCandidate && <ConfirmDeleteToSModal closeModal={deleteCandidate.closeModal} tosId={deleteCandidate.tosId}
                                                 tosName={deleteCandidate.tosName} />}
    </>
  );
};

export default ServiceDocumentsPage;
