import {TFunction} from "i18next";
import {Industry} from "../../../types";

export const INCLUDE_FEDERAL_TAX_ID_COUNTRIES = ['IT'];
export const LOCAL_SEGMENT_COUNTRIES = ['US', 'CA', 'JP'];

export const EXCLUDE_VAT_COUNTRIES = ['DE'];

export const CORPORATE_SEGMENT_OPTIONS = (t: TFunction) => [
  {displayLabel: t('Healthcare'), value: 'HC', industry: Industry.Healthcare.toString()},
  {displayLabel: t('Educational Provider'), value: 'EDU', industry: Industry.EducationalProvider.toString()},
  {displayLabel: t('Emergency Medical Service'), value: 'EMS', industry: Industry.EmergencyMedicalService.toString()},
  {displayLabel: t('Gov/Federal and Military'), value: 'GOV', industry: Industry.GovFederalAndMilitary.toString()},
  {displayLabel: t('Individual'), value: 'PRIV', industry: Industry.Other.toString()},
  {displayLabel: t('Volunteer'), value: 'VOL', industry: Industry.Other.toString()},
  {displayLabel: t('Other'), value: 'OTH', industry: Industry.Other.toString()}
];

export const INDUSTRY_OPTIONS = (t: TFunction) => [
  {displayLabel: t('Healthcare'), value: Industry.Healthcare.toString()},
  {displayLabel: t('Educational Provider'), value: Industry.EducationalProvider.toString()},
  {displayLabel: t('Emergency Medical Service'), value: Industry.EmergencyMedicalService.toString()},
  {displayLabel: t('Gov/Federal and Military'), value: Industry.GovFederalAndMilitary.toString()},
  {displayLabel: t('Other'), value: Industry.Other.toString()}
];