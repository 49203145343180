import { useEffect, MutableRefObject } from 'react';
/**
 * Perform action if clicked on outside of referred element
 */
export default function useClickOutside(ref: MutableRefObject<Element | null>, action: () => void) {
  function handleClickOutside(e: any) {
    if (ref.current && !ref.current.contains(e.target)) {
      action();
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  });
}
