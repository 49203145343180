import React, {useContext} from 'react';
import {Navigate, Route, RouteProps} from 'react-router';
import {UserContext} from '../../userContext';
import {useRolesAuthorization} from './RolesAuthorization';

export const RoleAuthComponent = ({
  roles
}: { roles: string[] }) => {

  const {isInRole} = useRolesAuthorization();

  const validateAndRender = () => {
    return !isInRole(roles) 
           ? <Navigate to={'/unauthorized'} replace/> 
           : <></>;
  };

  return validateAndRender();
};