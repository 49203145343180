import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { useParams } from 'react-router';
import {
  ActionChip,
  Button,
  Banner,
  COLORS,
  DatepickerField,
  DropdownButton,
  DropdownFilter,
  InputLabel,
  ListRow,
  ModalDialog,
  Note,
  PageWidth,
  Size,
  SystemIcons,
  Table,
  TableColumn,
  TablePagination,
  ToastColor,
  ToastPosition,
  UpperCase,
  TooltipWrapper,
} from '@laerdal/life-react-components';
import styled from 'styled-components';
import { AccessLevel, AuditLogEvent, AuditLogEventType, CountryDto, EntityDbEventType, LanguageDto, Organization, UpdateUserLocalizationDTO, User } from '../../types';
import { useToastContext, useUserContext } from '../../userContext';
import Api from '../../utils/api';
import { useNavigate } from 'react-router';
import { ErrorToastOptions } from '../../constants';
import moment from 'moment';
import { DateParam, NumberParam, NumericArrayParam, StringParam, useQueryParams } from 'use-query-params';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;

  h6 {
    color: ${COLORS.neutral_600};
  }
`;

const ActionSectionWrapper = styled.div`
  position: relative;

  .refreshchip {
    position: absolute;
    right: 5px;
  }
`;

const FieldValWrapper = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DateInputBlockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
`;

const DateInputInnerBlockWrapper = styled.div`
  width: 49%;
  display: block;
`;

interface OrganizationHistoryPage {
  organization: Organization;
}

type UserHistoryPageParams = {
  page?: string;
  pageSize?: string;
};

interface FormStateInterface {
  rangeStart?: Date;
  rangeEnd?: Date;
  selectedDateOption?: string;
  eventTypes: number[];
  entityEventType: EntityDbEventType;
  pagination: TablePagination;
}

const OrganizationHistoryPage = ({ organization }: OrganizationHistoryPage) => {
  const { t } = useTranslation('Preferences');

  const { addToast } = useToastContext();
  const [loading, setLoading] = useState<boolean>();
  const [rows, setRows] = useState<AuditLogEvent[]>([]);
  const [showRangeModal, setShowRangeModal] = useState<boolean>(false);
  const [selectedRangeOption, setSelectedRangeOption] = useState<string>('');
  const [formRangeStart, setRangeStart] = useState<Date>(new Date(new Date().setUTCHours(0,0,0,0)));
  const [formRangeEnd, setRangeEnd] = useState<Date>(new Date());
  const [wrongDateError, setWrongDateError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [formState, setFormState] = useState<FormStateInterface>();
  const { accessLevel } = useUserContext();
  const [query, setQuery] = useQueryParams({
    from: DateParam,
    to: DateParam,
    selectedDateOption: StringParam,
    eventTypes: NumericArrayParam,
    page: NumberParam,
    pageSize: NumberParam,
    count: NumberParam,
    entityEventType: StringParam,
  });

  useEffect(() => {
    const newState = readState();
    reloadLogs(newState);
  }, [query]);

  useEffect(() => {}, [formState]);

  const readState = (): FormStateInterface => {
    const page = query.page ? Number(query.page) : 1;
    const pageSize = query.pageSize ? Number(query.pageSize) : 10;
    const newState = {
      rangeStart: query.from ?? undefined,
      rangeEnd: query.to ?? undefined,
      selectedDateOption: query.selectedDateOption ?? undefined,
      eventTypes: [],
      entityEventType: (query.entityEventType ?? 0) as EntityDbEventType,
      pagination: {
        currentPage: page,
        rowsPerPage: pageSize,
        total: query.count ?? 0,
        from: (page - 1) * pageSize + 1,
        to: page * pageSize,
      },
    };

    setFormState(newState);
    setSelectedRangeOption(newState.selectedDateOption ?? '');
    return newState;
  };

  const reloadLogs = (state: FormStateInterface) => {
    setLoading(true);

    Api.GetOrganizationAuditLogs(
      organization.id,
      state?.pagination.currentPage ?? 1,
      state?.pagination.rowsPerPage ?? 10,
      undefined,
      state?.rangeStart,
      state?.rangeEnd,
      state?.entityEventType,
    )
      .then((data) => {
        setRows(data.records);
        setFormState({ ...state, pagination: { ...state.pagination, total: data.totalCount } });
      })
      .catch((error) => {
        addToast('Error occured while loading events. Please try again later', ErrorToastOptions);
      })
      .finally(() => setLoading(false));
  };

  const [columns, setColumns] = useState<TableColumn[]>([
    {
      key: 'occuredAt',
      name: t('Timestamp'),
      sortable: false,
      type: 'custom',
      customContent: (row: AuditLogEvent, key) => {
        return <span>{moment(row.occuredAt).format('MMMM Do YYYY, h:mm:ss')}</span>;
      },
    },
    {
      key: 'action',
      name: t('Action'),
      sortable: false,
    },
    {
      key: 'initiatedBy',
      name: t('Initiated by'),
      width: '150px',
      sortable: false,
    },
    {
      key: 'originalValue',
      name: t('Before'),
      sortable: false,
      shortenText: true,
      width: '150px',
      type: 'custom',
      customContent: (row: AuditLogEvent, key) =>
        row.onEntityCreate || row.onEntityDelete ? (
          ''
        ) : (
            <FieldValWrapper>
              {row.originalValue}
            </FieldValWrapper>
        ),
    },
    {
      key: 'updatedValue',
      name: t('After'),
      sortable: false,
      shortenText: true,
      width: '150px',
      type: 'custom',
      customContent: (row: AuditLogEvent, key) => (row.onEntityCreate || row.onEntityDelete ? '' : <FieldValWrapper>{row.updatedValue}</FieldValWrapper>),
    },
  ]);

  /**
   * Does all required pre-requisites and updates the search parameters for the context.
   * @param search - Search string to use for the row retrieval.
   * @param from - From which row to retrieve the data.
   * @param page - Current page in the table.
   * @param newRowsPerPage - New amount of rows per page.
   * @param newSortBy - An enum value indicating the sort by column.
   * @param newSortDirection - An enum value indicating the sort direction.
   */
  const updateSearchParams = (
    page?: number,
    newRowsPerPage?: number,
    events?: AuditLogEventType[],
    total?: number,
    dateRangeStart?: Date | null,
    dateRangeEnd?: Date | null,
    dateOption?: string,
    entityEventType?: string,
  ): void => {
    const newQuery = {
      ...query,
    };
    // Let's add current page
    if (page) newQuery.page = page;

    if (newRowsPerPage) newQuery.pageSize = newRowsPerPage;

    // Let's add total records count
    if (total) newQuery.count = total;

    newQuery.from = dateRangeStart;
    newQuery.to = dateRangeEnd;
    newQuery.selectedDateOption = dateOption;
    newQuery.entityEventType = entityEventType;
    // Let's append context
    setQuery(newQuery);
  };

  const onNextPage = () => {
    updateSearchParams(
      (formState?.pagination.currentPage ?? 1) + 1,
      formState?.pagination.rowsPerPage,
      undefined,
      formState?.pagination!.total,
      query.from,
      query.to,
      formState?.selectedDateOption,
      EntityDbEventType[formState?.entityEventType ?? 'Any'],
    );
  };

  const onPreviousPage = () => {
    updateSearchParams(
      (formState?.pagination.currentPage ?? 1) - 1,
      formState?.pagination.rowsPerPage,
      undefined,
      formState?.pagination!.total,
      query.from,
      query.to,
      formState?.selectedDateOption,
      EntityDbEventType[formState?.entityEventType ?? 'Any'],
    );
  };

  const onRowPerPageChange = (rowCount: number) => {
    updateSearchParams(
      1,
      rowCount,
      undefined,
      formState?.pagination!.total,
      query.from,
      query.to,
      formState?.selectedDateOption,
      EntityDbEventType[formState?.entityEventType ?? 'Any'],
    );
  };

  const dropdownTextButtons = [{ value: 'Last 24 hours' }, { value: 'Last 7 days' }, { value: 'Last 30 days' }, { value: 'Custom period' }];
  const eventTypeOptions = [
    { value: EntityDbEventType[EntityDbEventType.Any], displayLabel: 'All' },
    { value: EntityDbEventType[EntityDbEventType.Create], displayLabel: 'Create events' },
    { value: EntityDbEventType[EntityDbEventType.Update], displayLabel: 'Update events' },
    { value: EntityDbEventType[EntityDbEventType.Delete], displayLabel: 'Delete events' },
  ];

  const onRowClick = (row: AuditLogEvent) => {};

  const onDateOptionSelected = (option?: string) => {
    const newRangeStart = new Date();
    const newRangeEnd = new Date();
    switch (option) {
      case 'Custom period':
        setSelectedRangeOption(formState?.selectedDateOption ?? '');
        setShowRangeModal(true);
        return;

      case 'Last 7 days':
        newRangeStart.setDate(newRangeStart.getDate() - 7);
        break;

      case 'Last 24 hours':
        newRangeStart.setDate(newRangeStart.getDate() - 1);
        break;

      case 'Last 30 days':
        newRangeStart.setDate(newRangeStart.getDate() - 30);
        break;

      case 'Last 90 days':
        newRangeStart.setDate(newRangeStart.getDate() - 90);
        break;
    }

    updateSearchParams(
      formState?.pagination.currentPage,
      formState?.pagination.rowsPerPage,
      undefined,
      formState?.pagination.total,
      newRangeStart,
      newRangeEnd,
      option,
      EntityDbEventType[formState?.entityEventType ?? 'Any'] as string,
    );
  };

  const onDateRangeSelectionCanceled = () => {
    setShowRangeModal(false);
    setSelectedRangeOption(formState?.selectedDateOption ?? '');
  };

  const onEventTypeSelected = (options: string[]) => {
    updateSearchParams(
      1,
      formState?.pagination.rowsPerPage,
      undefined,
      formState?.pagination.total,
      formState?.rangeStart,
      formState?.rangeEnd,
      formState?.selectedDateOption,
      options[0],
    );
  };

  const setStartDate = (date: Date) => {
    setRangeStart(date);

    // Let's validate date
    validateDate(date, formRangeEnd);
  };

  const setEndDate = (date: Date) => {
    setRangeEnd(date);

    // Let's validate date
    validateDate(formRangeStart, date);
  }

  const validateDate = (startDate: Date, endDate: Date) => {
    if (startDate > endDate) {
      setWrongDateError(true);
    } else {
      setWrongDateError(false);
    }
  }

  return (
    <div>
      <Helmet>
        <title>{t('Organization history')}</title>
      </Helmet>
      <Wrapper>
        <h3>{t('Organization history')}</h3>
        <ActionSectionWrapper>
          <DropdownButton
            type="text"
            items={dropdownTextButtons}
            label={formState?.rangeStart ? `Date: ${formState.rangeStart.toDateString()} - ${formState.rangeEnd?.toDateString()}` : 'Date: '}
            value={formState?.selectedDateOption ? [formState?.selectedDateOption] : undefined}
            keepLabel={true}
            disabled={loading}
            onClick={(option) => {
              onDateOptionSelected(option.length > 0 ? option[0] : undefined);
            }}
          />

          <DropdownButton
            type="text"
            items={eventTypeOptions}
            label={`Action type: ${eventTypeOptions.find((x) => x.value == (query.entityEventType ?? 'Any'))?.displayLabel}`}
            value={[EntityDbEventType[formState?.entityEventType ?? 'Any']]}
            keepLabel={true}
            disabled={loading}
            onClick={(options) => {
              onEventTypeSelected(options);
            }}
          />

          <ActionChip
            disabled={loading}
            className="refreshchip"
            dataTestId="someCustomDataTestId"
            text="Refresh"
            icon={<SystemIcons.Refresh />}
            size={Size.Small}
            onClick={() => {
              if (formState) reloadLogs(formState);
            }}
          />
        </ActionSectionWrapper>

        <Table
          columns={columns}
          rows={rows}
          remoteOperations={true}
          pagination={formState?.pagination}
          onNextPageClick={() => onNextPage()}
          onPreviousPageClick={() => onPreviousPage()}
          onRowsPerPageChange={(rowCount: number) => onRowPerPageChange(rowCount)}
          selectable={true}
          onSelectionChange={(row: AuditLogEvent) => onRowClick(row)}
          showLoadingIndicator={loading}
        />
      </Wrapper>
      <ModalDialog
        key="rangeSelectModal"
        title={'Custom period'}
        size={Size.Large}
        isModalOpen={showRangeModal}
        closeAction={() => onDateRangeSelectionCanceled()}
        closeModalAndClearInput={() => onDateRangeSelectionCanceled()}
        submitAction={(e) => {
          e?.preventDefault();
          setShowRangeModal(false);
          updateSearchParams(
            1,
            formState?.pagination.rowsPerPage,
            undefined,
            formState?.pagination!.total,
            formRangeStart,
            formRangeEnd,
            'Custom period',
            EntityDbEventType[formState?.entityEventType ?? 'Any'],
          );
        }}
        buttons={[
          {
            action: () => onDateRangeSelectionCanceled(),
            text: 'Close',
            variant: 'tertiary',
          },
          {
            type: 'submit',
            text: 'Save',
            disabled: wrongDateError
          },
        ]}>
        <DateInputBlockWrapper>
          <DateInputInnerBlockWrapper>
            <InputLabel inputId="startDate" text={t('From')} />
            <DatepickerField yearPicker id="startDate" value={formRangeStart} validationMessage={''} placeholder="This is a datepicker" onChange={setStartDate} />
          </DateInputInnerBlockWrapper>

          <DateInputInnerBlockWrapper>
            <InputLabel inputId="endDate" text={t('To')} />
            <DatepickerField yearPicker id="endDate" value={formRangeEnd} validationMessage={wrongDateError ? 'TO date should be after FROM date' : ''}
              placeholder="This is a datepicker" invalid={wrongDateError} onChange={setEndDate} />
          </DateInputInnerBlockWrapper>
        </DateInputBlockWrapper>
        <Banner testId="deactivateLicenseBanner" type="neutral" size={Size.Small}
                icon={<SystemIcons.Information size="24px"/>}>
          {t('To view a single day, select the same date in both fields.')}
        </Banner>
      </ModalDialog>
    </div>
  );
};

export default OrganizationHistoryPage;
