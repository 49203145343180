import React, { Component, ErrorInfo } from 'react';
import { Trans } from 'react-i18next';

import styled from 'styled-components';

import { PageWidth, COLORS } from '@laerdal/life-react-components';

import illustration from './assets/500error.svg';

import { Card } from './components/PageCard';

const StyledMain = styled.main`
  flex: 1;
  background: ${COLORS.neutral_20};
`;

const StyledPage = styled(PageWidth)`
  div {
    margin: 56px auto auto auto;
  }
`;

type AppErrorHandlerProps = {
  children?: React.ReactNode;
}

type AppErrorHandlerState = {
  hasError?: boolean;
};

class AppErrorHandler extends Component<AppErrorHandlerProps, AppErrorHandlerState> {
  public state = {
    hasError: false,
  };

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, info);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <StyledMain>
          <StyledPage>
            <Card>
              <img src={illustration} alt={''} />
              <h1>
                <Trans i18nKey="Sorry, a temporary error occurred (500)" ns="ErrorPage"></Trans>
              </h1>
              <p>
                <Trans i18nKey="The server encountered an internal error." ns="ErrorPage"></Trans>
              </p>
              <p>
                <Trans i18nKey="Please try again later." ns="ErrorPage"></Trans>
              </p>
            </Card>
          </StyledPage>
        </StyledMain>
      );
    }

    return this.props.children;
  }
}

export default AppErrorHandler;
