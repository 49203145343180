import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  BasicDropdown,
  BREAKPOINTS,
  COLORS,
  DropdownButton, InputLabel,
  LoadingIndicator,
  ModalDialog,
  Size,
  ToastColor,
  ToastPosition,
} from '@laerdal/life-react-components';
import Api from '../../../../utils/api';
import {OrganizationService, ServiceRole} from '../../../../types';
import styled from 'styled-components';
import {useMediaQuery} from 'react-responsive';
import {useToastContext} from '../../../../userContext';
import {ErrorToastOptions, SuccessToastOptions} from "../../../../constants";

const ServiceDropdown = styled.div`
  width: 574px;
  box-sizing: border-box;

  div.label {
    font-size: 18px;
    line-height: 120%;
    color: ${COLORS.neutral_600};
  }
`;

const RolePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px 8px 12px;
  width: 574px;
  box-sizing: border-box;
`;

const RolePickerDetails = styled.div`
  flex: 1 0 calc(100% - 120px);
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.neutral_600};

  div.role {
    font-weight: bold;
  }
`;

const RolePickerDropdown = styled.div`
  text-align: right;
  width: 120px;
`;

type AddUserToServiceProps = {
  closeModal: any;
  isModalOpen: boolean;
  organizationId: string | null;
  userId: string;
  existingServices: OrganizationService[] | undefined;
  onSuccess: () => Promise<void>;
};

const AddUserToOrgServiceModal: React.FunctionComponent<AddUserToServiceProps> = ({
                                                                                    closeModal,
                                                                                    isModalOpen,
                                                                                    organizationId,
                                                                                    userId,
                                                                                    existingServices,
                                                                                    onSuccess,
                                                                                  }: AddUserToServiceProps) => {
  const {t} = useTranslation('User');
  const [loading, setLoading] = useState<boolean>(true);
  const [busy, setBusy] = useState<boolean>(false);
  const [orgServices, setOrgServices] = useState<OrganizationService[]>([]);
  const [roles, setRoles] = useState<ServiceRole[]>([]);
  const [serviceRole, setServiceRole] = useState<ServiceRole | undefined>(undefined);
  const [service, setService] = useState<OrganizationService | null | undefined>(null);
  const {addToast} = useToastContext();

  useEffect(() => {
    if (organizationId) {
      Api.GetOrganizationServices(organizationId)
        .then((s) => {
          setOrgServices(s.filter((os) => existingServices?.findIndex((es) => es.id === os.id) === -1));
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const addUserToService = (serviceId: string, userId: string, selectedRole: ServiceRole) => {
    setBusy(true);
    Api.AddMemberToOrganizationService(organizationId!, serviceId, userId)
      .then(() => Api.UpdateOrganizationServiceMember(organizationId!, serviceId, userId, selectedRole)
        .then(() => onSuccess().then(() => {
          addToast(t('User was successfully added to service.'), SuccessToastOptions);
        }))
      )
      .catch((error: any) => {
        console.error(error);
        addToast(t('There was a problem adding the user to service.'), ErrorToastOptions);
      })
      .finally(() => {
        setService(undefined);
        setServiceRole(undefined);
        setRoles([]);
        setBusy(false);
        closeModal();
      });
  };

  const modalButtons = [
    {
      id: 'cancel',
      variant: 'tertiary',
      text: t('Cancel'),
      disabled: busy,
      loading: false,
      action: closeModal,
    },
  ];

  if (orgServices.length === 0) {
    modalButtons.push({
      id: 'save',
      action: closeModal,
      loading: busy,
      variant: 'primary',
      text: t('Okay'),
      disabled: busy,
    });
  } else {
    modalButtons.push({
      id: 'save',
      action: (e: any) => {
        e.preventDefault();
        if (!busy && serviceRole) {
          addUserToService(service?.id ?? '', userId, serviceRole);
        }
      },
      disabled: !service || !serviceRole || busy,
      loading: busy,
      variant: 'primary',
      text: t('Add user'),
    });
  }

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {
      }}
      closeModalAndClearInput={closeModal}
      closeAction={closeModal}
      title={t('Add user to service')}
      size={Size.Large}
      buttons={modalButtons as any}>
      {(!loading || (loading && service)) && orgServices.length > 0 && (
        <ServiceDropdown>
          <InputLabel inputId={'services'} text={t('Select a product/service')}/>
          <BasicDropdown
            id="services"
            itemsType={'normal'}
            list={orgServices.map((s) => {
              return {value: s.name, displayLabel: s.name};
            })}
            placeholder="Select ..."
            onSelect={(value) => {
              if (value) {
                // Find the service

                const service = orgServices.find((s) => s.name === value);

                // Set the service
                setService(service);
                setServiceRole(undefined);

                // Let's show loading indicator
                setLoading(true);

                // Retrieve service roles
                Api.GetServiceRoles(service!.serviceId).then((result) => {
                  setRoles(result);

                  // Set loading to false
                  setLoading(false);
                });
              }
            }}
            messageOnNoResults="No results found"
          />
        </ServiceDropdown>
      )}
      {!loading && roles.length > 0 && (
        <RolePickerWrapper>
          <RolePickerDetails>
            {!serviceRole && <div className="role">{t('No access level selected')}</div>}
            {serviceRole && <div className="role">{serviceRole.name}</div>}
            {serviceRole &&
              <div className="description">{serviceRole.description ?? serviceRole.parentRoleDescription}</div>}
          </RolePickerDetails>
          <RolePickerDropdown>
            <DropdownButton
              type={'text'}
              items={roles?.map((r) => ({value: r.name}))}
              label="Change"
              keepLabel={true}
              onClick={(values: string[]) => {
                setServiceRole(roles?.find((r) => r.name === values[0]));
              }}/>
          </RolePickerDropdown>
        </RolePickerWrapper>
      )}
      {loading && <LoadingIndicator/>}
      {!loading && orgServices.length === 0 &&
        <p>{t('This person already has access to all available services at this organization.')}</p>}
    </ModalDialog>
  );
};

export default AddUserToOrgServiceModal;
