import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {formatDateOnly, getDateDiffInDays} from "../utils/functions";

import {
  Banner,
  COLORS,
  ComponentLStyling,
  ComponentTextStyle,
  LinearProgress,
  Size,
  SystemIcons,
  Tile
} from "@laerdal/life-react-components";

import {
  AccessLevel,
  InstanceLimitType,
  Organization,
  OrganizationService,
  PaymentType,
  Service,
  SessionDTO,
  Subscription,
  SubscriptionStatus,
  VALID_SUBSCRIPTION_STATUSES
} from "../types";
import CancelSubscriptionModal from "../features/organization/modals/CancelSubscriptionModal";
import {useUserContext} from "../userContext";
import {useNavigate} from "react-router";
import {memberFullAccessPermission} from "../constants";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const RowInfo = styled.div`
  display: flex;
  gap: 8px;
  ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
`;


const getContactEmail = (service?: Service, organizationService?: OrganizationService, organization?: Organization) => {

  if (organizationService?.serviceId.toLowerCase() == process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase()) {
    const ownerMembers = organization?.members.filter(x => (x?.permissions?.findIndex(y => y?.permissionId == memberFullAccessPermission) ?? -1) >= 0).map(x => x.user.email);
    if (ownerMembers && ownerMembers.length > 0) {
      let owner = ownerMembers[0];
      const others = ownerMembers.length - 1;
      if (others > 0) {
        owner = owner.concat(`, + ${others} more...`);
      }
      return owner;
    }
  }


  const ownerRoles = service
    ?.serviceRoles?.filter((s) => s.id === process.env.REACT_APP_SERVICE_OWNER_ID?.toLowerCase() || s.parentRoleId === process.env.REACT_APP_SERVICE_OWNER_ID?.toLowerCase())
    .map((s) => s.name);
  const owners = organizationService?.members?.filter((m) => (m.role?.name ? ownerRoles?.includes(m.role.name) : false));
  if (owners && owners.length > 0) {
    let owner = owners[0]?.user?.email;
    const others = owners.length - 1;
    if (others > 0) {
      owner = owner.concat(`, + ${others} 'more...'`);
    }
    return owner;
  }

  const invitedOwners = organizationService?.invitations?.filter((m) => (m.role?.name ? ownerRoles?.includes(m.role.name) : false) && m.isActive);
  if (invitedOwners && invitedOwners.length > 0) {
    let owner = invitedOwners[0]?.email;
    const others = invitedOwners.length - 1;
    if (others > 0) {
      owner = owner.concat(`, + ${others} more...`);
    }
    return owner;
  }

  return undefined;
};


export type ServicePageCardProps = {
  service: Service;
  sessions: SessionDTO[];
  subscription?: Subscription;
  organization?: Organization;
  organizationService?: OrganizationService;
  servicePageUrl: string;
  linkLabel: string;
  onLicenseCanceled(): void;
};

const ServicePageCard = ({
                           service,
                           organization,
                           organizationService,
                           sessions,
                           servicePageUrl,
                           subscription,
                           linkLabel,
                           onLicenseCanceled
                         }: ServicePageCardProps) => {
  const {t} = useTranslation("OrganizationServices");
  const [maxSubscriptionInstances, setMaxSubscriptionInstances] = useState<number | undefined>(undefined);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const {accessLevel} = useUserContext();
  const navigate = useNavigate();
  const readOnly = accessLevel == AccessLevel.ReadOnly;

  const hasSessionLimit = service?.instanceLimitType == InstanceLimitType.Session;


  let memberCount = organizationService!.members.length + (organizationService?.invitations?.filter((x) => x.isActive)?.length ?? 0)
  if (service?.id.toLowerCase() == process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase()) {
    memberCount = organization?.members.length ?? 0;
  }

  const contactEmail = getContactEmail(service, organizationService, organization);


  const hideUserCount =
    process.env.REACT_APP_SIMCAPTURE_SERVICE_ID?.toLowerCase() === service.id.toLowerCase() ||
    process.env.REACT_APP_TEAMREPORTER_SERVICE_ID?.toLowerCase() === service.id.toLowerCase() ||
    process.env.REACT_APP_SUPPORT_CENTER_SERVICE_ID?.toLowerCase() === service.id.toLowerCase() ||
    process.env.REACT_APP_ECOMMERCE_SERVICE_ID?.toLowerCase() === service.id.toLowerCase();

  const instanceCount = hasSessionLimit ? sessions.length : memberCount;

  useEffect(() => {
    if (subscription?.maxSubscriptionInstances) {
      // Get max count from subscription instance
      setMaxSubscriptionInstances(subscription.maxSubscriptionInstances);
    }
  }, [subscription]);

  const dropdownList = [
    {
      value: "delete-license",
      displayLabel: t("Cancel subscription"),
      disabled: readOnly ||
        subscription?.paymentType != PaymentType.Paddle ||
        !VALID_SUBSCRIPTION_STATUSES.includes(subscription?.status ?? SubscriptionStatus.Deactivated) ||
        subscription?.status === SubscriptionStatus.Canceled,
    }
  ];

  const onDropdownAction = (id: string) => {
    if (id === "delete-license") {
      setIsDeleteModalVisible(true);
    }
  };

  const formatUserCount = (sub: Subscription) => {
    if (sub) {
      let userCountString;
      if (maxSubscriptionInstances) {
        userCountString = `(${maxSubscriptionInstances} ${hasSessionLimit ? t("seats") : t("users")})`;
      } else {
        userCountString = `${t("individual licenses")}`;
      }
      return userCountString;
    } else {
      return "";
    }
  };

  const renderWarningBanner = () => {
    if (!subscription) {
      return undefined;
    }

    if (!subscription.isActive) {
      let label = t("License is deactivated");
      if (subscription.deactivationDate) {
        label = t("License was deactivated on {{deactivationDate}}", {deactivationDate: formatDateOnly(subscription.deactivationDate)});
      }
      return (
        <Banner testId="deactivateLicenseBanner" type="warning" size={Size.Small}
                icon={<SystemIcons.TechnicalWarning size="24px"/>}>
          {label}
        </Banner>
      );
    }

    const todayDateOnly = new Date();
    todayDateOnly.setHours(0, 0, 0, 0);
    if (subscription.startDate) {
      const startDateDateOnly = new Date(subscription.startDate);
      startDateDateOnly.setHours(0, 0, 0, 0);
      if (startDateDateOnly > todayDateOnly) {
        const label = t("License set to activate on {{startDate}}", {startDate: formatDateOnly(subscription.startDate)});
        return (
          <Banner testId="licenseWillActivateBanner" type="warning" size={Size.Small}
                  icon={<SystemIcons.TechnicalWarning size="24px"/>}>
            {label}
          </Banner>
        );
      }
    }

    if (subscription.expirationDate) {
      const expirationDateDateOnly = new Date(subscription.expirationDate);
      expirationDateDateOnly.setHours(0, 0, 0, 0);

      if (!subscription.actualExpiredDate && subscription.trialExpirationDate) {
        const trialExpirationDateDateOnly = new Date(subscription.trialExpirationDate);
        trialExpirationDateDateOnly.setHours(0, 0, 0, 0);

        if (trialExpirationDateDateOnly.getTime() >= expirationDateDateOnly.getTime() && trialExpirationDateDateOnly <= todayDateOnly) {
          const label = t("Trial period expired {{diff}} days ago", {diff: getDateDiffInDays(todayDateOnly, trialExpirationDateDateOnly)});
          return (
            <Banner testId="trialExpiredBanner" size={Size.Small} icon={<SystemIcons.TimeLimited size="24px"/>}>
              {label}
            </Banner>
          );
        }
      }

      if (expirationDateDateOnly <= todayDateOnly) {
        const label = t("Subscription expired {{diff}} days ago", {diff: getDateDiffInDays(todayDateOnly, expirationDateDateOnly)});
        return (
          <Banner testId="subscriptionExpired" type="critical" size={Size.Small}
                  icon={<SystemIcons.TimeLimited size="24px"/>}>
            {label}
          </Banner>
        );
      }
    }

    return undefined;
  };

  const warningBanner = renderWarningBanner();
  return (
    <>
      <CancelSubscriptionModal
        visible={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onSubmitted={onLicenseCanceled}
        organization={organization}
        service={service}
        organizationService={organizationService}
        subscription={subscription}
      />
      <Tile size={Size.Large}
            header={{
              title: service.name,
              buttons: [{
                componentType: "dropdown",
                disabled: service.alwaysPresent || subscription?.status === SubscriptionStatus.Canceled || subscription?.status === SubscriptionStatus.Expired || subscription?.status === SubscriptionStatus.Deactivated,
                items: dropdownList,
                onClick: (ids: string[]) => ids?.length && onDropdownAction(ids[0]),
                icon: <SystemIcons.MoreVertical/>
              }]
            }}
            footer={{
              leftItem: {
                componentType: "button",
                variant: "secondary",
                buttonText: linkLabel,
                onClick: () => navigate(servicePageUrl)
              }
            }}>
        <Content>
          <RowInfo>
            <SystemIcons.Document/>
            <span>
              {subscription?.status === SubscriptionStatus.Trial && <b>{t("Trial")} </b>}
              {
                subscription != null
                  ?
                  `${subscription?.plan?.name} ${service?.id.toLowerCase() != process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase() ? subscription?.tier?.name : ""} ${formatUserCount(subscription)}`
                  : service?.id.toLowerCase() != process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase()
                    ? t("No active subscription")
                    : "Basic - free licensing"
              }
            </span>
          </RowInfo>
          {subscription != null && subscription.expirationDate && (
            <RowInfo>
              <SystemIcons.EventLog/>
              <span>{formatDateOnly(subscription.expirationDate)}</span>
            </RowInfo>
          )}
          <RowInfo>
            <SystemIcons.Mail/>
            <span>{contactEmail ?? t("No contact information")}</span>
          </RowInfo>
          {
            !hideUserCount &&
            <LinearProgress value={instanceCount}
                            max={maxSubscriptionInstances || instanceCount}
                            label={maxSubscriptionInstances
                              ? hasSessionLimit
                                ? t("{{count}}/{{max}} seats", {count: instanceCount, max: maxSubscriptionInstances})
                                : t("{{count}}/{{max}} users", {count: instanceCount, max: maxSubscriptionInstances})
                              : hasSessionLimit
                                ? t("{{count}} seats", {count: instanceCount})
                                : t("{{count}} users", {count: instanceCount})}
                            size={Size.Large}
            />
          }
          {warningBanner}
        </Content>
      </Tile>
    </>
  );
};

export default ServicePageCard;
