import {Address, Organization, PaymentType, ServicePlan, ServicePlanTier, ServiceRole} from '../../../../types';

export interface GeneralLicenseProps {
  owner: string[],
  role: ServiceRole,
}

export interface SubscriptionProps {
  isTrial: boolean,
  trialExpirationDate?: Date;

  meteorDomain?: string;
  plan?: ServicePlan;
  tier?: ServicePlanTier;
  numberOfLicenses?: number;

  startDate: Date;
  expirationDate: Date;
  duration: string;
  autoRenew?: boolean;
  renewalPeriod?: string;
}

export interface BillingDetailsProps {
  paymentType?: PaymentType;
  orderNumber?: string,

  useOrganizationAddressForBilling: boolean;
  address: Address;
  billingAddress: Address;
  billingOrganizationName: string;
  vatNumber: string;
  billingContactPersonLastName: string;
  billingContactPersonFirstName: string;
  billingEmailAddress: string;
}

export interface CreateLicenseForm {
  general: GeneralLicenseProps;
  subscription: SubscriptionProps;
  billingDetails: BillingDetailsProps;
}

export interface EditLicenseForm {
  poNumber?: string,

  plan?: ServicePlan;
  tier?: ServicePlanTier;

  duration: string;
  startDate: Date;
  expirationDate?: Date;

  maxSubscriptionInstances?: number;

  autoRenew?: boolean;
  renewalPeriodInMonths: number;

  isTrial: boolean,
  trialExpirationDate?: Date;
}

export interface BillingDetails {
  billingOrganizationName: string;
  billingContactPerson: string;
  billingEmailAddress: string;
  vatNumber: string;
}


export const SubscriptionDurations = [
  {
    value: '1',
    displayLabel: '1 year / 12 months',
  },
  {
    value: '2',
    displayLabel: '2 years / 24 months',
  },
  {
    value: '3',
    displayLabel: '3 years / 36 months',
  },
  {
    value: '5',
    displayLabel: '5 years / 60 months',
  },
  {
    value: 'custom',
    displayLabel: 'Custom',
  },
];


export const SubscriptionRenewalPeriods = [
  {
    value: '1',
    displayLabel: '1 month',
  },
  {
    value: '12',
    displayLabel: '1 year / 12 months',
  },
  {
    value: '36',
    displayLabel: '3 year / 36 months',
  },
  {
    value: '60',
    displayLabel: '5 year / 60 months',
  },
];


export const E_COURSES_FREE_PLAN = {
  id: 'freeAccess',
  name: 'Basic - free access',
  tiers: [] as ServicePlanTier[],
  allowTrial: false,
  enableUnlimitedDuration: false,
  defaultTrialDuration: 0,
} as ServicePlan;