import React, { useState } from 'react';
import { COLORS, Checkbox, ComponentL, ComponentS, ComponentTextStyle, ModalDialog, RadioButton, Size, ToastColor, ToastPosition } from '@laerdal/life-react-components';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AddOrgMemberResponse, AddOrganizationMemberInvitation, Invitation, InvitationResponse, Organization, User } from '../../types';
import { useToastContext } from '../../userContext';
import Api from '../../utils/api';
import { ErrorToastOptions, SuccessToastOptions } from '../../constants';
import { use } from 'i18next';
import { boolean } from 'yup';

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  p {
    margin: 0;
    margin-bottom: 1rem;
  }
`;

const AddUsersImmediatelyWrapper = styled.div`
  background-color: ${COLORS.neutral_20};
  padding: 5px 10px 10px 10px;
  margin-top: 15px;

  border-radius: 4px;
  border: 1px solid ${COLORS.neutral_200};
`;

const SendEmailRadioWrapper = styled.div`
  padding: 5px 10px 10px 10px;
`;

const LeftMarginWrapper = styled.div`
  margin-left: 35px;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccessSentInvitation?: (invitation: Invitation) => Promise<void>;
  onSuccessAddedImmediately?: (memberResponse: AddOrgMemberResponse) => Promise<void>;
  user?: User | null;
  organization?: Organization;
  canAddImmediately: boolean;
}

export const SendInvitationModal = ({
  user,
  onClose,
  onSuccessSentInvitation: onSuccess = () => Promise.resolve(),
  onSuccessAddedImmediately = (memberResponse: AddOrgMemberResponse) => Promise.resolve(),
  isOpen,
  organization,
  canAddImmediately,
}: Props) => {
  const { t } = useTranslation('Organization');
  const { addToast } = useToastContext();
  const [loading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [addImmediately, setAddImmediately] = useState(false);

  const close = () => {
    onClose();
  };

  const submit = () => {
    setLoading(true);

    Api.AddUserToOrganization(organization!.id, user?.id!, user?.email!, sendEmail, addImmediately, false)
      .then((data) => {
        if (data && 'invitation' in data) {
          const invitationResponse = data as InvitationResponse;
          onSuccess({
            organization: organization!,
            email: invitationResponse.invitation.email,
            id: invitationResponse.invitation.code,
            createdDate: new Date(),
            code: invitationResponse.invitation.code,
            isActive: true,
            permissions: [],
          }).then(() => {
            addToast(t('{{email}} invited to the organization successfully', { email: user?.email }), SuccessToastOptions);
            close();
          });
        } else {
          const orgMemberResponse = data as AddOrgMemberResponse;
          onSuccessAddedImmediately(orgMemberResponse).then(() => {
            addToast(t('{{email}} added to the organization successfully', { email: user?.email }), SuccessToastOptions);
            close();
          });
        }
      })
      .catch((error) => {
        addToast(t('Something went wrong while inviting member'), ErrorToastOptions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalDialog
      isModalOpen={isOpen}
      closeModalAndClearInput={close}
      closeAction={close}
      submitAction={(e) => {
        e?.preventDefault();
        submit();
      }}
      size={Size.Medium}
      title={t('Add to organization')}
      buttons={[
        {
          text: t('Cancel'),
          variant: 'tertiary',
          disabled: loading,
          action: close,
        },
        {
          text: t('Add to organization'),
          variant: 'primary',
          action: submit,
          loading: loading,
        },
      ]}>
      <Wrapper>
        <p>
          <strong>{user?.email}</strong> will be added as a member of <br />
          <ComponentL textStyle={ComponentTextStyle.Bold}>{organization?.name}</ComponentL>
        </p>
        <RadioButtonsContainer>
          <AddUsersImmediatelyWrapper>
            <RadioButton
              id="AddUsersImmediatelyRadio"
              label={t('Add users immediately')}
              select={() => {
                setAddImmediately(true);
              }}
              selected={addImmediately}
            />
            <LeftMarginWrapper>
                <ComponentS color={COLORS.neutral_800}>{t('The user will be required to register on the first time they attempt to sign in to a Laerdal application')}</ComponentS>
                <Checkbox id="SendEmailCheckbox" select={(value) => {setSendEmail(value); if(!addImmediately && value) setAddImmediately(true); }} selected={sendEmail && addImmediately} label={'Notify user by email'} />
              </LeftMarginWrapper>
          </AddUsersImmediatelyWrapper>
          <SendEmailRadioWrapper>
            <RadioButton
              selected={!addImmediately}
              select={() => {
                setAddImmediately(false);
              }}
              label={t('Send email inviting user to join organization')}
            />
          </SendEmailRadioWrapper>
        </RadioButtonsContainer>
      </Wrapper>
    </ModalDialog>
  );
};
