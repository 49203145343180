import React, {useEffect, useMemo} from 'react';
import {
  Banner,
  Checkbox,
  DatepickerField,
  DropdownFilter,
  InputLabel,
  NumberField,
  Size,
  States,
  SystemIcons,
  TextField,
  Tile,
} from '@laerdal/life-react-components';
import {Trans, useTranslation} from 'react-i18next';
import {useController, useFormContext} from 'react-hook-form';
import moment from 'moment';
import {Service, ServicePlan, ServicePlanTier} from "../../../../../types";
import {CreateLicenseForm, SubscriptionDurations, SubscriptionRenewalPeriods} from "../../Common/types";
import {InputRow, InputsContainer, InputWrapper} from "../../Common/styles";
import {domainValidationRequired} from '../../../../../utils/functions';
import styled from 'styled-components';
import {SubscriptionTrialBox} from "../SubscriptionTrialBox";


interface Props {
  service?: Service;
  isSelfService?: boolean;
  isSalesforce?: boolean;
}

const SimCaptureHoverLabel = styled.div`
  position: absolute;
  top: 2rem;
  right: 1rem;
`;

export const SubscriptionOptionsCard = ({
                                          service,
                                          isSelfService,
                                          isSalesforce
                                        }: Props) => {

  const {t} = useTranslation('OrganizationServices');
  const {control, watch, setValue} = useFormContext<CreateLicenseForm>();

  const [plans, setPlans] = React.useState<ServicePlan[]>([]);
  const [tiers, setTiers] = React.useState<ServicePlanTier[]>([]);

  const meteorDomainController = useController({control: control, name: 'subscription.meteorDomain'});
  const planController = useController({control: control, name: 'subscription.plan'});
  const tierController = useController({control: control, name: 'subscription.tier'});
  const numberOfLicensesController = useController({control: control, name: 'subscription.numberOfLicenses'});
  const durationController = useController({control: control, name: 'subscription.duration'});
  const renewalPeriodController = useController({control: control, name: 'subscription.renewalPeriod'});
  const startDateController = useController({control: control, name: 'subscription.startDate'});
  const expirationDateController = useController({control: control, name: 'subscription.expirationDate'});
  const autoRenewController = useController({control: control, name: 'subscription.autoRenew'});
  const isTrialController = useController({control: control, name: 'subscription.isTrial'});
  const trialExpirationController = useController({control: control, name: 'subscription.trialExpirationDate'});

  const isTrial = watch('subscription.isTrial');
  const plan = watch('subscription.plan');
  const tier = watch('subscription.tier');
  const duration = watch('subscription.duration');
  const startDate = watch('subscription.startDate');
  const autoRenew = watch('subscription.autoRenew');

  const trialDuration = tier?.defaultTrialDuration ?? plan?.defaultTrialDuration;

  /**
   * update available plans based on service and trial values
   * */
  useEffect(() => {
    const plans = isTrial
      ? service?.availablePlans!.filter((plan) => plan.allowTrial) ?? []
      : service?.availablePlans && service?.id.toLowerCase() == process.env.REACT_APP_SCENARIO_CLOUD_SERVICE_ID?.toLowerCase() ? [ ...service.availablePlans.filter(x => x.id.toLowerCase() != process.env.REACT_APP_STARTER_PLAN_ID?.toLowerCase())] 
      : service?.availablePlans ?? [];
      
    setPlans(plans);
    setValue('subscription.plan', plans[0]);
  }, [isTrial, service]);

  /**
   * update available tiers based on trial and plan values
   * */
  useEffect(() => {
    const tiers = isTrial ? plan?.tiers.filter(a => a.allowTrial) ?? []
      : plan?.tiers ?? [];
    setTiers(tiers);
    setValue('subscription.tier', tiers[0]);
  }, [plan, isTrial]);

  /**
   * update duration value based on trial
   * */
  useEffect(() => {
    if (isTrial) {
      setValue('subscription.duration', 'trial');
      setValue('subscription.expirationDate', moment(startDate).add(trialDuration, 'days').toDate());
      setValue('subscription.autoRenew', false);
    } else {
      setValue('subscription.duration', '1');
    }
  }, [isTrial, trialDuration, startDate]);

  /**
   * update expiration date when start date changes if duration is not custom or if it is a trial
   * */
  useEffect(() => {
    if (duration !== 'custom' && duration != 'trial') {
      setValue('subscription.expirationDate', moment(startDate).add(+duration, 'years').toDate());
    }
  }, [startDate, duration]);

  /**
   * update value of renewal period when autoRenew changes
   * */
  useEffect(() => {
    if (autoRenew) {
      if (duration === 'custom') {
        setValue('subscription.renewalPeriod', SubscriptionRenewalPeriods[0].value);
      } else {
        setValue('subscription.renewalPeriod', +duration * 12 + '');
      }
    } else {
      setValue('subscription.renewalPeriod', undefined);
    }
  }, [autoRenew]);

  const durations = useMemo(() => {
    let durations = [...SubscriptionDurations]
      .map((duration) => ({
          ...duration,
          disabled: isTrial && duration.value !== 'custom'
        })
      );
    if (isTrial) {
      const duration = tier?.defaultTrialDuration ?? plan?.defaultTrialDuration;
      durations.push({
        value: `trial`,
        displayLabel: t(`${duration} days (trial)`),
        disabled: false
      });
    }

    return durations;
  }, [isTrial]);

  return (
    <Tile header={{title: t('Subscription options')}} className={'tile'} size={Size.Large}>
      <InputsContainer>
        {
          isSelfService && isSalesforce &&
          <Banner type={'neutral'} icon={<SystemIcons.Information/>} size={Size.Small}>
            Currently, it is only possible to activate a trial for this application. To create a full license, please
            use Salesforce.
          </Banner>
        }
        {
          isSelfService && !isSalesforce &&
          <Banner type={'neutral'} icon={<SystemIcons.Information/>} size={Size.Small}>
            Currently, it is only possible to activate a trial for this application. Customer will need to purchase the full
            license
          </Banner>
        }
        <SubscriptionTrialBox controller={isTrialController}
                              expirationController={trialExpirationController}
                              plan={plan}
                              tier={tier}/>
        {
          domainValidationRequired(service?.id) &&
          <InputWrapper>
            <InputLabel inputId="meteorDomain"
                        text={process.env.REACT_APP_SIMCAPTURE_SERVICE_ID?.toLowerCase() === service?.id.toLowerCase() ? t('SimCapture domain') : t('Meteor domain')}/>

            <TextField id="meteorDomain"
                       placeholder={process.env.REACT_APP_SIMCAPTURE_SERVICE_ID?.toLowerCase() === service?.id.toLowerCase() ? t('Enter SimCapture domain name') : t('Enter meteor domain')}
                       maxLength={service?.id == process.env.REACT_APP_TEAMREPORTER_SERVICE_ID?.toLowerCase() ? 8 : 30}
                       validationMessage={meteorDomainController.fieldState.error?.message}
                       state={meteorDomainController.fieldState.error ? States.Invalid : undefined}
                       {...meteorDomainController.field}/>
            <SimCaptureHoverLabel>.simcapture.com</SimCaptureHoverLabel>
          </InputWrapper>
        }
        <InputWrapper>
          <InputLabel inputId="planType" text={t('Plan type')}/>
          <DropdownFilter
            list={plans!.map((plan) => ({value: plan.id, displayLabel: plan.name}))}
            id="planType"
            placeholder={t('Select')}
            messageOnNoResults={t('No plans were found')}
            ref={planController.field.ref}
            onBlur={planController.field.onBlur}
            value={planController.field.value?.id || ''}
            onSelect={(value) => planController.field.onChange(plans!.find((plan) => plan.id === value))}
            name={planController.field.name}
            activeValidationMessage={planController.fieldState.error?.message}
          />
        </InputWrapper>

        <InputWrapper>
          <InputLabel inputId="licenseTier" text={t('License tier')}/>
          <DropdownFilter
            list={tiers.map((tier) => ({value: tier.id, displayLabel: tier.name}))}
            id="licenseTier"
            placeholder={t('Select')}
            messageOnNoResults={t('No tiers were found')}
            ref={tierController.field.ref}
            onBlur={tierController.field.onBlur}
            name={tierController.field.name}
            value={tierController.field.value?.id || ''}
            onSelect={(value) => tierController.field.onChange(tiers.find((tier) => tier.id === value))}
            activeValidationMessage={tierController.fieldState.error?.message}
          />
        </InputWrapper>

        {tier?.individualLicensing && (
          <InputWrapper>
            <InputLabel inputId="numberOfLicenses" text={t('Number of licenses')}/>
            <NumberField
              type={'NumberField'}
              id="numberOfLicenses"
              size={Size.Medium}
              placeholder={t('Enter number')}
              state={numberOfLicensesController.fieldState.error ? States.Invalid : undefined}
              note={numberOfLicensesController?.fieldState.error?.message}
              value={numberOfLicensesController.field.value}
              onChange={(value) => numberOfLicensesController.field.onChange(+value)}
              ref={numberOfLicensesController.field.ref}
              onBlur={numberOfLicensesController.field.onBlur}
              name={numberOfLicensesController.field.name}
            />
          </InputWrapper>
        )}
        {!tier?.enableUnlimitedDuration && (
          <InputWrapper>
            <InputLabel inputId="duration" text={t('Duration')}/>
            <DropdownFilter
              list={durations}
              placeholder={t('Select')}
              id="duration"
              value={durationController.field.value}
              onSelect={(value) => durationController.field.onChange(value)}
              onBlur={durationController.field.onBlur}
              name={durationController.field.name}
              activeValidationMessage={durationController.fieldState.error?.message}
            />
          </InputWrapper>
        )}

        <InputRow>
          <InputWrapper>
            <InputLabel inputId="startDate"
                        text={process.env.REACT_APP_SIMCAPTURE_SERVICE_ID?.toLowerCase() === service?.id.toLowerCase() ? t('Install date') : t('Start date')}/>
            <DatepickerField id="startDate"
                             yearPicker
                             readOnly={isTrial}
                             validationMessage={startDateController.fieldState.error?.message}
                             {...startDateController.field}/>
          </InputWrapper>
          <InputWrapper>
            <InputLabel inputId="expiryDate" text={t('Expiry date')}/>
            {tier?.enableUnlimitedDuration &&
              <TextField id="expiryDate" placeholder={t('Never expires')} disabled={true}/>}
            {!tier?.enableUnlimitedDuration && (
              <DatepickerField id="expiryDate" yearPicker
                               readOnly={duration !== 'custom'}
                               validationMessage={expirationDateController.fieldState.error?.message}
                               {...expirationDateController.field}/>
            )}
          </InputWrapper>
        </InputRow>

        {startDate.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) && (
          <Banner type={'neutral'} icon={<SystemIcons.Information/>} size={Size.Small}>
            <Trans i18nKey="StartDateInFutureBanner" ns="OrganizationServices"/>
          </Banner>
        )}

        {(!isTrial || !!tier?.autoUpgradeTrial) && !tier?.enableUnlimitedDuration && (
          <InputWrapper>
            <Checkbox
              id="automaticRenewal"
              disabled={isTrial}
              label={t('AUTOMATIC_RENEWAL_CHECKBOX_DESCRIPTION')}
              select={autoRenewController.field.onChange}
              selected={autoRenewController.field.value || false}
              onBlur={autoRenewController.field.onBlur}
              ref={autoRenewController.field.ref}
            />
          </InputWrapper>
        )}
        {autoRenew && (!isTrial || !!tier?.autoUpgradeTrial) && !tier?.enableUnlimitedDuration && (
          <InputWrapper>
            <InputLabel inputId="renewalPeriod" text={t('Renewal Period')}/>
            <DropdownFilter
              list={SubscriptionRenewalPeriods}
              disabled={isTrial && !tier?.autoUpgradeTrial}
              placeholder={t('Select')}
              id="renewalPeriod"
              value={renewalPeriodController.field.value + ''}
              onSelect={renewalPeriodController.field.onChange}
            />
          </InputWrapper>
        )}
      </InputsContainer>
    </Tile>
  );
};