/**
 * Import React libraries.
 */
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

/**
 * Import third-party libraries.
 */
import { useTranslation } from 'react-i18next';
import { Paginator, ToastPosition, ToastColor, ToastContext, ContentAccordion, ComponentS, COLORS, ToastEntryDirection } from '@laerdal/life-react-components';
import styled from 'styled-components';

/**
 * Import custom components.
 */
import RemoveMemberModal from '../modals/RemoveMemberModal';
import MemberDetailsModal from '../modals/MemberDetailsModal';
import { ActionType } from '../OrganizationPage';

/**
 * import custom types.
 */
import { AddOrgMemberResponse, Invitation, Organization, OrganizationContact, OrganizationInvitation, OrganizationMember, User } from '../../../types';
import EditPermissionsModal from '../../../components/modals/EditPermissionsModal';
import { useToastContext, useUserContext } from '../../../userContext';
import moment from 'moment';
import { SendInvitationModal } from '../../../components/modals/SendInvitationModal';
import Api from '../../../utils/api';
import { SuccessToastOptions, memberFullAccessPermission } from '../../../constants';
import UserManagementMembershipCategoryAccordion from './UserManagementMembershipCategoryAccordion';

/**
 * Create custom styles
 */
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaginatorContainer = styled.div`
  nav {
    width: 100%;
  }
`;

export interface OrganizationMemberEntry {
  type: 'member';
  data: OrganizationMember;
}

export interface OrganizationInvitationEntry {
  type: 'invitation';
  data: Invitation;
}

export interface OrgnaizationContactEntry {
  type: 'contact';
  data: OrganizationContact;
}

export type OrganizationMemberType = OrganizationMemberEntry | OrganizationInvitationEntry | OrgnaizationContactEntry;

/**
 * Create custom types.
 */
export type Props = {
  organization: Organization;
  searchQuery?: string;
  dispatch: Dispatch<ActionType>;
  getOrganization: () => void;
  resultsPerPage: number;
};

export type Params = {
  page?: string;
  invite?: string;
};

const UserManagementList = ({ organization, searchQuery, dispatch, getOrganization, resultsPerPage = 10 }: Props) => {
  // Globally used variables within the component
  const { t } = useTranslation('Organization');
  const { addToast } = useToastContext();
  const { memberPermissions } = useUserContext();

  const [entriesTotal, setEntriesTotal] = useState<number>(0);
  const [userToBeInvited, setUserToBeInvited] = useState<OrganizationMemberType | null>(null);
  const [memberToBeRemoved, setMemberToBeRemoved] = useState<OrganizationMember | null>(null);
  const [memberToDisplay, setMemberToDisplay] = useState<OrganizationMember | null>(null);
  const [contactToDisplay, setContactToDisplay] = useState<User | null>(null);
  const [showMemberDetails, setShowMemberDetails] = useState<boolean>(false);
  const [memberToEditPermissions, setMemberToEditPermissions] = useState<OrganizationMember | null>(null);
  const [invitedMemberPermissions, setInvitedMemberPermissions] = useState<Invitation | null>(null);

  const baseUrl = `/organization/${organization?.id}/user-management`;

  const match = useParams<Params>();
  
  const [members, setMembers] = useState<OrganizationMemberType[]>([]);

  const handleInvitationRemoved = (invitation: Invitation) => {
    const newInvitations = organization?.invitations.filter((m) => m.email !== invitation.email);
    dispatch({ type: 'invitations', value: [...newInvitations], field: 'invitations' });
  };

  const handleInvitationAdded = (invitation?: Invitation) => {
    if(invitation)
    {
      dispatch({ type: 'invitations', value: [...organization?.invitations, invitation], field: 'invitations' });
      dispatch({
        type: 'members',
        value: [...organization?.members.filter((a) => a.user.email != invitation.email)],
        field: 'members',
      });
    }

    return Promise.resolve();
  };

  const handleAddedImmediately = (orgMemberResponse: AddOrgMemberResponse) => {
    
    if(!userToBeInvited || userToBeInvited.type != 'contact')
      return Promise.resolve();

      dispatch({
        type: 'contacts',
        value: [...(organization.contacts ?? []).filter((a) => a.userId != userToBeInvited.data.userId)],
        field: 'contacts',
      });
      
      dispatch({
        type: 'members',
        value: [...organization.members, {
          id: orgMemberResponse.id,
          organizationId: organization.id,
          organization: organization.id,
          permissions: orgMemberResponse.permissions,
          user: userToBeInvited.data.user,
          current: orgMemberResponse.current,
          isProfilingComplete: false,
          created: orgMemberResponse.created,
        } as OrganizationMember],
        field: 'members',
      });
    
    return Promise.resolve();
  };

  const handleInviteAction = (user: OrganizationMemberType) => {
    setUserToBeInvited(user);
  };

  const handleShowMemberDetails = (member: OrganizationMemberType) => {
    if (member.type === 'member') {
      setContactToDisplay(null);
      setMemberToDisplay(member.data);
      setShowMemberDetails(true);
    } else if (member.type === 'contact') {
      setMemberToDisplay(null);
      setContactToDisplay(member.data.user);
      setShowMemberDetails(true);
    } else if (member.type === 'invitation') {
      const orgMemberData: OrganizationMember = {
        id: member.data.id,
        organizationId: member.data.organization.id,
        organization: member.data.organization.name,
        permissions: member.data.permissions.map(permission => {
          return {
            permissionId: permission
          }
        }) as any[],
        user: {
          email: member.data.email,
          id: null,
          identityId: null,
          firstName: member.data.firstName ? member.data.firstName : null,
          lastName: member.data.lastName ? member.data.lastName : null
        } as any,
        current: true,
        created: member.data.createdDate,
        isProfilingComplete: false
      };

      setContactToDisplay(null);
      setMemberToDisplay(orgMemberData);
      setShowMemberDetails(true);
    }
  };

  /**
   * Tries to remove a member from an organization.
   * @param member - Organization member which needs to be removed.
   */
  const tryRemoveMember = (member: OrganizationMember) => {
    if (!!member.permissions?.find((a) => a.permissionId === memberFullAccessPermission)) {
      addToast(t('Cannot remove organization administrator'), {
        color: ToastColor.RED,
        showCloseButton: true,
        autoClose: true,
        position: ToastPosition.TOPMIDDLE,
      });
    } else {
      setMemberToBeRemoved(member);
    }
  };

  /**
   * Filters member list based on the search query.
   * @param members - Member list which needs to be filtered.
   * @returns Filtered member list.
   */
  const filterMembers = (members: OrganizationMemberType[]): OrganizationMemberType[] => {
    // Let's check if we have provided search query.
    if (!searchQuery) {
      return members;
    }

    // Return filtered members
    // Search by first name, last name and email
    // If user is not found - don't return
    return members.filter((member: OrganizationMemberType) => {
      let searchString = '';
      switch (member.type) {
        case 'member':
          searchString = `${member.data.user?.firstName?.toLowerCase()} ${member.data.user?.lastName?.toLowerCase()} ${member.data.user?.email?.toLowerCase()}`;
          return searchString.indexOf(searchQuery.toLowerCase()) !== -1;
        case 'invitation':
          searchString = `${member.data.email?.toLowerCase()} ${member.data.firstName?.toLowerCase()} ${member.data.lastName?.toLowerCase()}`;
          break;
        case 'contact':
          searchString = `${member.data.user?.firstName?.toLowerCase()} ${member.data.user?.lastName?.toLowerCase()} ${member.data.user.email?.toLowerCase()}`;
      }

      return searchString.indexOf(searchQuery.toLowerCase()) !== -1;
    });
  };

  const sortMembers = (members: OrganizationMemberType[]): OrganizationMemberType[] => {
    members = members.sort((a, b) => {
      if (a.type === 'contact') return 1;
      if (b.type === 'contact') return -1;

      let aDate = a.type === 'member' ? a.data.created : a.data.createdDate;
      let bDate = b.type === 'member' ? b.data.created : b.data.createdDate;

      if (moment(aDate).isAfter(bDate)) return -1;
      if (moment(aDate).isBefore(bDate)) return 1;

      return 0;
    });

    return members;
  };

  /**
   * Initializes the member list.
   */
  useEffect(() => {
    if (organization) {
      let entries: OrganizationMemberType[] = [];

      entries = organization.members.filter((nm) => nm?.user?.email !== null).map((m) => ({ type: 'member', data: m }));

      if (organization?.invitations) {
        //displaying invitation for the user without identity. removing member from the list if invitation exists
        entries = entries.filter(x => (x.type == 'member' && (x.data.user.identityId != null || organization.invitations.find(invit => invit.email == x.data.user.email) == null)));
        entries = entries.concat(organization.invitations.filter((i) => i.isActive).map((i) => ({ type: 'invitation', data: { ...i, organization: organization } })));
      }

      if (organization?.contacts) {
        entries = entries.concat(
          organization.contacts.filter((a) => !organization.invitations?.find((b) => b.email === a.user?.email && b.isActive)).map((c) => ({ type: 'contact', data: c })),
        );
      }

      setEntriesTotal(entries.length);
      // Let's filter members
      entries = filterMembers(entries);
      entries = sortMembers(entries);

      setMembers(entries || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, searchQuery]);

  const setInvitationForEditing = (code: string) => {
    const invitation = organization.invitations.find((x) => x.code == code)!;
    invitation.organization = {
      ...organization,
      invitations: [],
    };
    setInvitedMemberPermissions(invitation);
  };

  const userManagementListProps = {
    organizationType: organization.type,
    setMemberToEditPermissions: setMemberToEditPermissions,
    setMemberToBeDeleted: tryRemoveMember,
    onInvitationRemoved: handleInvitationRemoved,
    onShowMemberDetails: handleShowMemberDetails,
    setInvitedMemberPermissions: setInvitationForEditing,
    onInvite: handleInviteAction,
  };

  return (
    <>
      <SendInvitationModal
        canAddImmediately={userToBeInvited?.type != "member"}
        isOpen={!!userToBeInvited}
        onClose={() => setUserToBeInvited(null)}
        onSuccessAddedImmediately={handleAddedImmediately}
        onSuccessSentInvitation={handleInvitationAdded}
        user={userToBeInvited?.type == "contact" ? userToBeInvited.data.user : 
            userToBeInvited?.type == "member" ? userToBeInvited.data.user : null}
        organization={organization}
      />

      <MemberDetailsModal
        organization={organization}
        closeModal={() => setShowMemberDetails(false)}
        isModalOpen={showMemberDetails}
        member={memberToDisplay}
        contact={contactToDisplay}
      />

      <RemoveMemberModal
        organization={organization}
        dispatch={dispatch}
        isModalOpen={memberToBeRemoved !== null}
        closeModal={() => setMemberToBeRemoved(null)}
        organizationId={organization.id}
        member={memberToBeRemoved}
      />
      {memberToEditPermissions != null && (
        <EditPermissionsModal
          isModalOpen={true}
          closeModal={() => setMemberToEditPermissions(null)}
          saveUpdatedPermissions={async (newPermissions: string[]) => {
            await Api.UpdateOrganizationMemberPermissions(memberToEditPermissions!.id, newPermissions);
            addToast(t('Member permissions updated successfully'), SuccessToastOptions);
            memberToEditPermissions!.permissions = newPermissions.map((x) => ({
              organizationMemberId: memberToEditPermissions!.id,
              permissionId: x,
            }));
          }}
          currentPermissions={memberToEditPermissions?.permissions?.map((x) => x.permissionId) ?? []}
          allPermissions={memberPermissions}
        />
      )}

      {invitedMemberPermissions != null && (
        <EditPermissionsModal
          isModalOpen={true}
          closeModal={() => setInvitedMemberPermissions(null)}
          saveUpdatedPermissions={async (newPermissions: string[]) => {
            await Api.UpdateOrganizationInvitation(invitedMemberPermissions.organization.id, invitedMemberPermissions.code, false, false, true, newPermissions);
            addToast(t('Invitation permissions updated successfully'), SuccessToastOptions);
            invitedMemberPermissions.permissions = newPermissions;
          }}
          allPermissions={memberPermissions}
          currentPermissions={invitedMemberPermissions.permissions ?? []}
        />
      )}

      {organization && (
        <>

          <ComponentS color={COLORS.neutral_600}>Showing {members.length} of {entriesTotal}</ComponentS>
          <UserManagementMembershipCategoryAccordion
            title="Members"
            header="People who have created a user profile and are part of this organization."
            members={members.filter((x) => x.type == 'member' && x.data.user?.identityId)}
            rowsPerPage={10}
            initialState={true}
            managementListProps={userManagementListProps}
          />
          <UserManagementMembershipCategoryAccordion
            title="Pending"
            header="People who are invited to the organization, or added but have not yet created profile."
            members={members.filter((x) => x.type == 'invitation' || (x.type == 'member' && !x.data.user.identityId))}
            rowsPerPage={10}
            initialState={false}
            managementListProps={userManagementListProps}
          />
          <UserManagementMembershipCategoryAccordion
            title="Salesforce contacts"
            header="People without a user profile who are set as a Contact Person in Salesforce."
            members={members.filter((x) => x.type == 'contact')}
            rowsPerPage={10}
            initialState={false}
            managementListProps={userManagementListProps}
          />
        </>
      )}
    </>
  );
};

export default UserManagementList;
