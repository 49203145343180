import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

import {BREAKPOINTS, Button, COLORS, Size, ToastColor, ToastPosition} from "@laerdal/life-react-components";

import Api from "../../utils/api";
import {
  AccessLevel,
  CountryConfiguration,
  InstanceLimitType,
  Organization,
  OrganizationService,
  Service,
  SessionDTO
} from "../../types";
import {useToastContext, useUserContext} from "../../userContext";
import ServicePageCard from "../../components/ServicePageCard";
import LoadingIndicatorBanner from "../../components/LoadingIndicatorBanner";

/**
 * Import custom components.
 */
import CreateLicenseModal from "./modals/CreateLicenseModal";
import {ErrorToastOptions, memberFullAccessPermission} from "../../constants";

const Wrapper = styled.div`

  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 560px;

  ${BREAKPOINTS.MEDIUM} {
    gap: 24px;
  }

  ${BREAKPOINTS.LARGE} {
    gap: 32px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  h6{
    flex: 1;
    color: ${COLORS.neutral_600};
  }
`;

type TParams = { id: string };

const OrganizationServicesPage = () => {
  const {t} = useTranslation('OrganizationServices');
  const {authenticated, selfServiceServices, countryConfigurations} = useUserContext();
  const {addToast} = useToastContext();
  const params = useParams<TParams>();
  const [isCreateLicenseModalOpen, setIsCreateLicenseModalOpen] = useState<boolean>(false);
  const [services, setServices] = useState<Service[]>([]);
  const [sessions, setSessions] = useState<{ [subscriptionId: string]: SessionDTO[] }>({});
  const [organizationServices, setOrganizationServices] = useState<OrganizationService[] | null>(null);
  const [organization, setOrganization] = useState<Organization>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const organizationId = params.id!;

  /**
   * Retrieves a list of all services from the organization directory.
   */
  const getServices = (signal: AbortSignal) =>
    Api.GetServices(false, signal).catch(() => {
      if (!signal.aborted) {
        addToast(t('Services could not be fetched.'), ErrorToastOptions);
      }

      return [];
    });

  /**
   * Retrieves a list of all sessions for the subscription
   */
  const getSessions = (subscriptionId: string, signal: AbortSignal) =>
    Api.GetSessions(organizationId, subscriptionId, signal).catch(() => {
      if (!signal.aborted) {
        addToast(t('Sessions could not be fetched.'), {
          color: ToastColor.RED,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE,
        });
      }

      return [];
    });

  const getOrganization = (signal: AbortSignal) =>
    Api.GetOrganization(organizationId, signal).catch(() => {
      if (!signal.aborted) {
        addToast(t('Organization could not be fetched.'), {
          color: ToastColor.RED,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE,
        });
      }
    });

  /**
   * Retrieves a list of organization services for the current organization.
   */
  const getOrganizationServices = (signal?: AbortSignal) =>
    Api.GetOrganizationServices(organizationId, signal).catch(() => {
      if (!signal?.aborted) {
        addToast(t('Organization services could not be fetched.'), ErrorToastOptions);
      }
      return [];
    });

  /**
   * Does all required actions upon the page load.
   */
  useEffect(() => {
    if (!authenticated) {
      return;
    }
    const abortController = new AbortController();

    Promise.all([getServices(abortController.signal)])
      .then(([services]) => {
        setServices(services);
        return Promise.all([getOrganization(abortController.signal), getOrganizationServices(abortController.signal)]).then(([organization, organizationServices]) => {
          setOrganization(organization || undefined);
          setOrganizationServices(organizationServices);

          const sessionBasedServices = services.filter((a) => a.instanceLimitType == InstanceLimitType.Session).map((a) => a.id);
          const sessionRequests = organizationServices
            ?.filter((a) => sessionBasedServices.includes(a.serviceId) && a.subscription != null)
            .map((a) =>
              getSessions(a.subscription.id, abortController.signal).then((r) => ({
                subscriptionId: a.subscription.id,
                sessions: r,
              })),
            );

          return Promise.all(sessionRequests).then((sessionResponses) => {
            setSessions(sessionResponses.reduce((p, c) => ({...p, [c.subscriptionId]: c.sessions}), {}));
          });
        });
      })
      .finally(() => setLoading(false));

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  /**
   * Does all required actions upon closing of the license creation modal.
   * @param selectedService - String value in case user selected service in the modal.
   */
  const onCreateLicenseClose = (selectedService?: string) => {
    // If we have closed the modal with finishing the form
    // Let's navigate user to create license page
    if (selectedService) {
      navigate(`../license-management/new/${services.find((service) => service.name === selectedService)!.id}`);
    }

    // Let's close the modal
    setIsCreateLicenseModalOpen(false);
  };

  const availableServices =
    services.filter((service) => !organizationServices?.some((orgService) => orgService.serviceId === service.id))
      .filter((service) => service.canAddFromBackoffice);

  const country = organization?.address?.country?.codeAlpha2 || '';

  return (
    <Wrapper>
      <h3>{t('License management')}</h3>
      <CreateLicenseModal
        isModalOpen={isCreateLicenseModalOpen}
        closeModal={(selectedService?: string) => onCreateLicenseClose(selectedService)}
        selfServiceServices={ selfServiceServices}
        useSalesforce={!!countryConfigurations[country]?.useSalesforce}
        services={availableServices}
      />
      <Header>
        <h6>{t('Services used by this organization')}</h6>
        <Button id="createLicense"
                size={Size.Large}
                variant="primary"
                onClick={() => setIsCreateLicenseModalOpen(true)}>
          {t('Create new license')}
        </Button>
      </Header>
      {loading && <LoadingIndicatorBanner/>}
      {!loading && !organizationServices?.length && t('This organization does not have any services connected')}
      {
        !loading &&
        !!organizationServices?.length &&
        organizationServices.map((service) => (
          <ServicePageCard
            organization={organization}
            organizationService={service}
            key={service.id}
            service={services.find((a) => a.id == service.serviceId)!}
            sessions={sessions[service.subscription?.id]}
            servicePageUrl={'/organization/' + organizationId + '/license-management/' + service.id}
            subscription={service.subscription}
            linkLabel={t('View license')}
            onLicenseCanceled={() => {
              setOrganizationServices(null);
              getOrganizationServices().then(setOrganizationServices);
            }}
          />
        ))}
    </Wrapper>
  );
};

export default OrganizationServicesPage;
