import React, {Dispatch, useContext} from 'react';
import {ModalDialog, ToastColor, ToastContext, ToastPosition} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {ActionType} from '../../UserPage';
import {User} from '../../../../types';
import Api from '../../../../utils/api';
import { useToastContext } from '../../../../userContext';

interface VerifyEmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  dispatch: Dispatch<ActionType>;
}

const VerifyEmailModal: React.FunctionComponent<VerifyEmailModalProps> = ({
  isOpen,
  onClose,
  user,
  dispatch,
}) => {
  const [loading, setLoading] = React.useState(false);

  const {t} = useTranslation('User');
  const {addToast} = useToastContext();

  const verify = () => {
    setLoading(true);
    Api.VerifyAccount(user.identityId).then(() => {
      addToast(t('Successfully verified email'), {
        color: ToastColor.GREEN,
        showCloseButton: true,
        autoClose: true,
        position: ToastPosition.TOPMIDDLE,
      });
      dispatch({type: 'boolean', value: true, field: 'isVerified'});
      onClose();
    }).catch(() => {
      addToast(t('Could not verify emails'), {
        color: ToastColor.RED,
        showCloseButton: true,
        autoClose: true,
        position: ToastPosition.TOPMIDDLE,
      });

    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <ModalDialog isModalOpen={isOpen}
                 closeModalAndClearInput={onClose}
                 closeAction={onClose}
                 submitAction={verify}
                 title={t('Verify email')}
                 buttons={[
                   {
                     variant: 'tertiary',
                     text: t('Cancel'),
                     action: onClose,
                     disabled: loading,
                   },
                   {
                     variant: 'positive',
                     text: t('Verify'),
                     action: verify,
                     loading: loading,
                   },
                 ]}>
      <p>
        {t(
          'Upon verification, this user will be able to sign in using the email and password combination they set during registration.')}
      </p>
    </ModalDialog>
  );
};

export default VerifyEmailModal;