import React, { useEffect, useState, useMemo, useContext } from 'react';
import { TableColumn } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Table, SearchBar, Checkbox, ToastContext, ModalDialog, BREAKPOINTS, Size } from '@laerdal/life-react-components';
import * as constants from '../../../../constants';
import { ScenarioAccessSelectorModal } from './ScenarioAccessSelectorModal';
import Api from '../../../../utils/api';
import { ScenarioDTO, ScenarioWhitelistItemDTO } from '../../../../types';
import { ScenarioAccessDetails } from './ScenarioCloudSettings';
import { useMediaQuery } from 'react-responsive';
import { useToastContext } from '../../../../userContext';

interface ScenarioSearchModal {
  organizationId: string;
  existingScenarios: ScenarioWhitelistItemDTO[];
  closeModal: () => void;
}

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 0;

  button {
    width: fit-content;
    margin: 5px;
  }
`;

const TableContainer = styled.div`
  margin: 10px;
  min-width: 570px;
  overflow-y: scroll;
  max-height: 400px;
`;

export const ScenarioSearchModal = ({ organizationId, closeModal, existingScenarios }: ScenarioSearchModal) => {
  const { t } = useTranslation('Settings');
  const [query, setQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<ScenarioDTO[]>([]);
  const [checkedScenarios, setCheckedScenarios] = useState<ScenarioDTO[]>([]);
  const [showAccessTypeSelector, setShowAccessTypeSelector] = useState<boolean>(false);
  const [loadingScenarios, setLoadingScenarios] = useState<boolean>(false);
  const [disabledScenarios, setDisabledScenarios] = useState<ScenarioWhitelistItemDTO[]>(existingScenarios);

  const { addToast } = useToastContext();
  const handleCloseClick = () => closeModal();
  const handleNextClick = () => setShowAccessTypeSelector(true);
  const handleCloseAccessTypeSelector = () => setShowAccessTypeSelector(false);
  const handleSelectAccess = async (accessDetails: ScenarioAccessDetails) => {
    const addThis = checkedScenarios.map((x) => {
      return { id: x._id, type: accessDetails.unlimited ? 0 : 1, validTo: accessDetails.validTo?.toDateString() };
    });
    try {
      await Api.AddScenarioItems(organizationId, addThis);
      const disabled = [
        ...disabledScenarios,
        ...checkedScenarios.map((x) => {
          return { id: '', idInSanity: x._id, scenarioId: '', type: 0, smsNumber: '', validToDisplay: '' } as ScenarioWhitelistItemDTO;
        }),
      ];
      setDisabledScenarios(disabled);
      setCheckedScenarios([]);
      addToast(t('Scenarios successfully added to the organization'), constants.SuccessToastOptions);
    } catch (error) {
      console.log(error);
      addToast(t('Error occured while adding scenarios to the organization'), constants.ErrorToastOptions);
    }
    setShowAccessTypeSelector(false);
  };

  const findScenarios = async () => {
    if (!query) setSearchResults([]);
    else {
      setLoadingScenarios(true);
      try {
        const scenarios = await Api.SearchScenarios(query);
        setSearchResults(scenarios);
        setLoadingScenarios(false);
      } catch (error) {
        console.log(error);
        addToast(t('Error occured during scenarios search'), constants.ErrorToastOptions);
        setLoadingScenarios(false);
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(findScenarios, 1000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const searchResultColumns = useMemo<TableColumn[]>(
    () => [
      {
        key: 'check',
        name: '',
        type: 'custom',
        customContent: (row, key) => {
          const existing = disabledScenarios.some((x) => x.idInSanity == row._id);
          const selected = checkedScenarios.includes(row) || existing;
          const handleSelect = () => {
            if (selected) setCheckedScenarios(checkedScenarios.filter((x) => x != row));
            else setCheckedScenarios([...checkedScenarios, row]);
          };
          return <Checkbox id={row?._id} selected={selected} disabled={existing} select={handleSelect} />;
        },
      },
      {
        key: 'smsNumber',
        name: t('SMS number'),
        sortable: true,
        width: 120,
      },
      {
        key: 'name',
        name: t('Title'),
        sortable: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedScenarios, disabledScenarios],
  );

  return (
    <>
      <ModalDialog
        isModalOpen={true}
        submitAction={() => {}}
        closeModalAndClearInput={handleCloseClick}
        closeAction={handleCloseClick}
        title={t('Add new scenarios')}
        size={Size.Medium}
        buttons={
          [
            {
              id: 'cancel',
              variant: 'tertiary',
              text: t('Close'),
              action: handleCloseClick,
            },
            {
              id: 'save',
              action: handleNextClick,
              disabled: !checkedScenarios || checkedScenarios.length === 0,
              variant: 'primary',
              text: t('Add scenario(s)'),
            },
          ] as any
        }>
        <SearchBar
          id="scenarioSearch"
          searchTerm={query}
          placeholder={t('Search by SMS number or title')}
          setSearchTerm={setQuery}
          enterSearch={() => {}}
          removeSearch={() => setQuery('')}
        />
        <TableContainer>
          <Table rows={searchResults} columns={searchResultColumns} showLoadingIndicator={loadingScenarios} />
        </TableContainer>
      </ModalDialog>
      {/* unmounting ScenarioAccessSelectorModal when modal is hidden is necessary, do not remove this - '{showAccessTypeSelector &&' */}
      {showAccessTypeSelector && <ScenarioAccessSelectorModal
        selectAccess={handleSelectAccess}
        label={t('Are you sure you want to add scenarios?')}
        close={handleCloseAccessTypeSelector}
      />}
    </>
  );
};
