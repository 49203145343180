/**
 * Import React libraries.
 */
import React, {Dispatch, useState} from "react";

/**
 * Import third-party libraries.
 */
import {BREAKPOINTS, Button, COLORS, SearchBar, Size, SystemIcons} from "@laerdal/life-react-components";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

/**
 * Import custom components.
 */
import InviteUserModal from "./modals/InviteUserModal";
import UserManagementList from "./usermanagement/UserManagementList";

/**
 * Import custom types.
 */
import {ActionType} from "./OrganizationPage";
import {AccessLevel, Organization, OrganizationType} from "../../types";
import {useUserContext} from "../../userContext";

/**
 * Create custom styles
 */
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 560px;

  ${BREAKPOINTS.MEDIUM} {
    gap: 24px;
  }

  ${BREAKPOINTS.LARGE} {
    gap: 32px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  h6 {
    color: ${COLORS.neutral_600};
    flex: 1;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const NotFound = styled.div`
  margin-top: 24px;

  h3 {
    color: ${COLORS.neutral_600};
    font-size: 24px;
    line-height: 28px;
    margin: 0;
    padding: 0;
  }

  div {
    margin-top: 8px;
    color: ${COLORS.neutral_600};
    font-size: 18px;
    line-height: 28px;
  }
`;

/**
 * Create custom types.
 */
interface OrganizationMembersPageProps {
  organization: Organization;
  getOrganization: () => void;
  dispatch: Dispatch<ActionType>;
  existsInSF: boolean;
}

const OrganizationMembersPage = ({
                                   organization,
                                   getOrganization,
                                   dispatch,
                                   existsInSF
                                 }: OrganizationMembersPageProps) => {
  // Globally used variables within the page
  const { t } = useTranslation("Organization");
  const { accessLevel } = useUserContext();
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  /**
   * Pre-requisite functions after user has been invited.
   * @param refreshData - Boolean value if organization data should be refreshed.
   */
  const onUserInvited = (refreshData: boolean): void => {
    // Let's check if we have to refresh the modal
    if (refreshData) {
      getOrganization();
    }

    // Let's close the modal
    setIsInviteUserModalOpen(false);
  };

  const haveUsersToShow = organization?.members?.length > 0 || organization?.invitations?.filter((x) => x.isActive).length > 0 || !!organization?.contacts?.length;

  return (
    <Wrapper>
      <InviteUserModal
        isModalOpen={isInviteUserModalOpen}
        closeModal={(invited: boolean) => onUserInvited(invited)}
        organizationId={organization.id}
        organizationName={organization.name}
        existsInSF={existsInSF}
        members={organization.members.concat(
          organization.invitations
            .filter((i) => i.isActive)
            .map((i, index) => {
              return {
                current: true,
                id: index + "",
                organization: organization.name,
                organizationId: organization.id,
                role: i.role,
                isProfilingComplete: false,
                created: i.createdDate,
                user: {
                  firstName: "Invited",
                  lastName: "User",
                  email: i.email,
                  id: "i:" + i.email,
                  identityId: "1",
                  currentOrganization: null,
                  organizations: [],
                  services: [],
                  isInvited: true,
                  invitationCode: i.code
                }
              };
            })
        )}
      />
      <h3>{t("User management")}</h3>

      <ContentContainer>


        <Header>
          <h6>{t("People at this organization")}</h6>
          <Button id="inviteUserBtn"
                  disabled={accessLevel == AccessLevel.ReadOnly || organization.type === OrganizationType.Individual}
                  icon={<SystemIcons.AddUser />}
                  size={Size.Large}
                  variant="primary"
                  onClick={() => setIsInviteUserModalOpen(true)}>
            {t("Invite users")}
          </Button>
        </Header>
        <SearchBar
          id="OrganizationMemberSearchBar"
          placeholder="Search by name or email"
          setSearchTerm={(q: string) => setSearchQuery(q)}
          enterSearch={() => {
          }}
          removeSearch={() => setSearchQuery("")}
          searchTerm={searchQuery}
          disabled={!haveUsersToShow}
          size={Size.Small}
        />
        {haveUsersToShow && (
          <UserManagementList resultsPerPage={10}
                              organization={organization}
                              searchQuery={searchQuery}
                              dispatch={dispatch}
                              getOrganization={getOrganization} />
        )}

        {!haveUsersToShow && (
          <NotFound>
            <h3 id="nothingFound">{t("Nothing found")}</h3>
            <div>{t("There is no data to display here")}</div>
          </NotFound>
        )}
      </ContentContainer>

    </Wrapper>
  );
};

export default OrganizationMembersPage;
