import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {COLORS, LoadingIndicator, Size} from '@laerdal/life-react-components';

const LoadingIndicatorWrapper = styled.div<{ backgroundColor?: string; color?: string; maxWidth?: string }>`
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '648px')};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : COLORS.neutral_100)};
  margin: 20px 0;
  padding: 12px 16px 12px 20px;
  border-radius: 2px;

  span {
    position: relative;
    top: 2px;
    left: 12px;
    color: ${(props) => (props.color ? props.color : COLORS.neutral_700)};
    font-size: 16px;
    line-height: 20px;
  }
`;

interface LoadingIndicatorBannerProps {
  backgroundColor?: string;
  textColor?: string;
  maxWidth?: string;
  message?: string;
}

const LoadingIndicatorBanner = ({
  backgroundColor = COLORS.neutral_100,
  textColor = COLORS.neutral_700,
  maxWidth = '648px',
  message = undefined,
}: LoadingIndicatorBannerProps) => {
  const {t} = useTranslation('Common');
  const label = message ?? t('Loading information...');

  return (
    <LoadingIndicatorWrapper backgroundColor={backgroundColor} color={textColor} maxWidth={maxWidth}>
      <LoadingIndicator size={Size.Small} color={textColor}/>
      <span>{label}</span>
    </LoadingIndicatorWrapper>
  );
};

export default LoadingIndicatorBanner;
