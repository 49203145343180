import {domainValidationRequired, hasStates} from '../../../../utils/functions';
import * as yup from 'yup';
import {CountryDto, ServicePlanTier} from '../../../../types';
import Api from '../../../../utils/api';
import {GeneralLicenseProps, SubscriptionProps} from './types';
import moment from 'moment';
import exp from 'constants';

export const addressSchema = yup.object().shape({
  addressLine: yup.string().ensure().required('Address is required'),
  zipCode: yup.string().ensure().required('Zip code is required'),
  city: yup.string().ensure().required('City is required'),
  country: yup.object().required('Country is required'),
  state: yup.string().nullable(),
  phoneNumber: yup.string().ensure(),
});

export const generalSchema = yup.object().shape({
  owner: yup.array().of(yup.string()).when(['$serviceId'], {
    is: (serviceId: string) => serviceId == process.env.REACT_APP_SIMCAPTURE_SERVICE_ID?.toLowerCase(),
    then: schema => schema,
    otherwise: schema => schema.ensure().length(1, 'Please select an owner').required(),
  }),
  role: yup.object().required('Role is required')
});

const meteorDomainSchema = yup
  .string()
  .required('Meteor domain is required')
  .matches(/^[a-zA-Z0-9-]+$/, 'Domain name can only use letters, numbers and hyphens.')
  .when('$serviceId', {
    is: (serviceId: string) => serviceId == process.env.REACT_APP_TEAMREPORTER_SERVICE_ID?.toLowerCase(),
    then: (schema) => schema.max(8),
    otherwise: (schema) => schema.max(30),
  })
  .when('tier', {
    is: (tier: ServicePlanTier) => tier?.name.toLowerCase() == 'pro',
    then: (schema) => schema.matches(/-pro$/i, "Domains for Pro tiers should end with '-pro'"),
    otherwise: (schema) => schema.matches(/(?<!-pro)$/i, "Only Pro tier domains should end with '-pro'"),
  });

export const subscriptionSchema = yup.object().shape({
  meteorDomain: yup.string().when(['$serviceId', "$isReactivating"], {
    is: (serviceId: string, isReactivating: boolean) => domainValidationRequired(serviceId) && !isReactivating,
    then: (schema) => schema.concat(meteorDomainSchema)
      .test('exists', 'Domain name already in use.', async (value: string | undefined) => {
        if (await meteorDomainSchema.isValid(value)) {
          return !!value?.length
            ? Api.ValidateDomain(value!.toLowerCase())
              .then((response) => !response.exists)
              .catch(() => false)
            : false;
        }
        return true;
      }),
    otherwise: (schema) => schema.nullable(),
  }),

  plan: yup.object().required('Plan is required'),
  tier: yup.object().required('Tier is required'),
  numberOfLicenses: yup.number().when('tier', {
    is: (tier: ServicePlanTier) => tier?.individualLicensing,
    then: () => yup.number().min(1).required('Number of licenses is required'),
    otherwise: () => yup.number().nullable(),
  }),
  startDate: yup
    .date()
    .required('Start date is required')
    .test('minStartDate', "Start date can't be in the past.", (value: Date | undefined) => !!value && moment().startOf('day').isSameOrBefore(value)),
  expirationDate: yup
    .date()
    .required('Expiration date is required')
    .test('minExpirationDate', 'Expiration date must be after start date.', function (value: Date | undefined) {
      return !!value && this.parent.startDate < value;
    }),
  duration: yup.string(),
  autoRenew: yup.boolean(),
  renewalPeriod: yup.number().when('autoRenew', {
    is: true,
    then: () => yup.number().required('Renewal period is required'),
    otherwise: () => yup.number().nullable(),
  }),
  isTrial: yup.boolean().when('$isSelfService', {
    is: (isSf: boolean,) => isSf,
    then: () => yup.boolean().oneOf([true], 'To create a full license, please use Salesforce'),
    otherwise: () => yup.boolean().nullable(),
  }),
});

export const billingDetailsSchema = yup.object().shape({
  useOrganizationAddressForBilling: yup.boolean(),
  billingAddress: yup.object().when('useOrganizationAddressForBilling', {
    is: true,
    then: () => yup.object().nullable(),
    otherwise: () => addressSchema.label('billingAddress'),
  }),
  address: yup.object().when('useOrganizationAddressForBilling', {
    is: true,
    then: () => addressSchema.label('address'),
    otherwise: () => yup.object().nullable(),
  }),
  billingOrganizationName: yup.string().ensure().required('Organization name is required'),
  vatNumber: yup.string().nullable(),
  billingContactPersonFirstName: yup.string().ensure().required('Primary contact person is required'),
  billingContactPersonLastName: yup.string().ensure().required('Primary contact person is required'),
  billingEmailAddress: yup.string().ensure().required('Billing email address is required'),
});

export const subscriptionBillingDetailsSchema = yup.object().shape({
  useOrganizationAddressForBilling: yup.boolean(),
  billingAddress: yup.object().when('useOrganizationAddressForBilling', {
    is: true,
    then: () => yup.object().nullable(),
    otherwise: () => addressSchema.label('billingAddress'),
  }),
  address: yup.object().when('useOrganizationAddressForBilling', {
    is: true,
    then: () => addressSchema.label('address'),
    otherwise: () => yup.object().nullable(),
  }),
  billingOrganizationName: yup.string().ensure().required('Organization name is required'),
  vatNumber: yup.string().nullable(),
  billingContactPersonFirstName: yup.string().ensure().required('Primary contact person is required'),
  billingContactPersonLastName: yup.string().ensure().required('Primary contact person is required'),
  billingEmailAddress: yup.string().ensure().required('Billing email address is required'),
  orderNumber: yup.string().test('required', 'Order number is required', (value, context) => {
    return context.from![1].value.subscription.isTrial || context.options.context?.serviceId == process.env.REACT_APP_SIMCAPTURE_SERVICE_ID?.toLowerCase() || !!value;
  }),
});


export const createLicenseSchema = yup.object().shape({
  general: generalSchema,

  subscription: yup.object().when('$requiresSubscription', {
    is: (requiresSubscription: boolean) => requiresSubscription,
    then: () => subscriptionSchema,
    otherwise: () => yup.object().nullable(),
  }),

  billingDetails: yup.object().when(['subscription', '$requiresSubscription'], {
    is: (subscription: SubscriptionProps, requireSubscription: boolean) => subscription.isTrial || !requireSubscription,
    then: () => yup.object().nullable(),
    otherwise: () => subscriptionBillingDetailsSchema,
  }),
});

export const editLicenseSchema = yup.object().shape({
  poNumber: yup.string().when(['isTrial', '$serviceId'], {
    is: (isTrial: boolean, serviceId: string) => isTrial || (serviceId.toLowerCase() == process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase()),
    then: () => yup.string().nullable(),
    otherwise: () => yup.string().ensure().required('Order number is required'),
  }),
  plan: yup.object().required('Plan is required'),
  tier: yup.object().required('Tier is required'),
  maxSubscriptionInstances: yup.number().when('tier', {
    is: (tier: ServicePlanTier) => tier?.individualLicensing,
    then: () => yup.number().min(1).required('Number of licenses is required'),
    otherwise: () => yup.number().nullable(),
  }),
  startDate: yup.date().required('Start date is required'),
  expirationDate: yup
    .date()
    .when('tier', {
      is: (tier: ServicePlanTier) => !tier?.enableUnlimitedDuration,
      then: () => yup.date().required('Expiration date is required')
        .test('minExpirationDate', 'Expiration date must be after start date.', function (value: Date | undefined) {
          return !!value && this.parent.startDate < value;
        }),
      otherwise: () => yup.date().nullable()
    }),
  isTrial: yup.boolean().when('$isSelfService', {
    is: (isSf: boolean,) => isSf,
    then: () => yup.boolean().oneOf([true], 'To create a full license, please use Salesforce'),
    otherwise: () => yup.boolean().nullable(),
  }),
  autoRenew: yup.boolean(),
  duration: yup.string(),
  renewalPeriodInMonths: yup.number().when('autoRenew', {
    is: true,
    then: () => yup.number().min(1, 'Renewal period is required').required('Renewal period is required'),
    otherwise: () => yup.number().nullable(),
  }),
});
