import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RadioButton, ToastColor, ToastPosition, ModalDialog, BREAKPOINTS, Size } from "@laerdal/life-react-components";
import { ServiceRole } from "../../../types";
import Api from "../../../utils/api";
import { useToastContext, useUserContext } from "../../../userContext";
import { useMediaQuery } from "react-responsive";

const RadioButtonList = styled.ul`
  padding: 0;
  padding-left: 14px;

  > div {
    height: 56px;
  }
`;

type InviteUserProps = {
  closeModal: any;
  isModalOpen: boolean;
  orgId: string;
  serviceId: string;
  memberId: string;
  memberEmail?: string;
  memberRole?: string;
  memberName: string | null;
  invitationCode?: string;
  serviceRoles?: ServiceRole[];
  getOrganizationService: () => void;
};

const EditServiceMemberModal: React.FunctionComponent<InviteUserProps> = ({
                                                                            memberId,
                                                                            memberEmail,
                                                                            memberName,
                                                                            memberRole,
                                                                            closeModal,
                                                                            isModalOpen,
                                                                            orgId,
                                                                            serviceId,
                                                                            invitationCode,
                                                                            serviceRoles,
                                                                            getOrganizationService
                                                                          }) => {
  const [selectedRole, setSelectedRole] = useState<ServiceRole | null | undefined>(serviceRoles?.find((role) => role?.name === memberRole));
  const { t } = useTranslation("Organization");
  const { addToast } = useToastContext();
  const { authenticated } = useUserContext();

  const [busy, setBusy] = useState<boolean>(false);
  const isMediumScreen = useMediaQuery({ query: BREAKPOINTS.MEDIUM.replace("@media ", "") });

  useEffect(() => {
    if (memberRole) {
      setSelectedRole(serviceRoles?.find((role) => role?.name === memberRole));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, serviceRoles]);

  const changeRole = () => {
    if (authenticated && memberId && memberRole !== selectedRole && selectedRole) {
      setBusy(true);
      Api.UpdateOrganizationServiceMember(orgId, serviceId, memberId, selectedRole)
        .then(() => {
          setBusy(false);
          closeModal();
          getOrganizationService();
          addToast(t("Service member was edited successfully."), {
            color: ToastColor.GREEN,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE
          });
        })
        .catch(() => {
          setBusy(false);
          closeModal();
          addToast(t("There was a problem editing the service member."), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE
          });
        });
    }
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {
      }}
      closeModalAndClearInput={closeModal}
      closeAction={closeModal}
      title={t("Edit access")}
      size={isMediumScreen ? Size.Medium : Size.Small}
      buttons={
        [
          {
            id: "cancel",
            variant: "tertiary",
            text: t("Cancel"),
            action: closeModal
          },
          {
            id: "save",
            action: (e: any) => {
              e.preventDefault();
              changeRole();
            },
            loading: busy,
            variant: "primary",
            text: t("Save changes"),
            disabled: busy
          }
        ] as any
      }>
      <>
        {invitationCode && (
          <p><strong>{t("The user needs to accept the invitation before access level can be edited.")}</strong></p>
        )}
        <p>{memberName ? `${memberName} (${memberEmail})` : `${memberEmail}`}</p>
        <RadioButtonList>
          {serviceRoles?.map((role) => (
            <RadioButton
              key={role.id}
              id={`${role.name}_Radio`}
              label={role.name}
              additionalLabel={role.description}
              select={() => {
                setSelectedRole(role);
              }}
              selected={selectedRole?.name === role.name}
              disabled={!!invitationCode}
            />
          ))}
        </RadioButtonList>
      </>
    </ModalDialog>
  );
};

export default EditServiceMemberModal;
