/**
 * Import React libraries.
 */
import React, { useEffect, useState } from 'react';

/**
 * Import third-party libraries.
 */
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  ToastColor,
  ToastPosition,
  COLORS,
  InputLabel,
  DropdownFilter,
  DatepickerField,
  ModalDialog,
  Size,
  BREAKPOINTS,
  LoadingIndicator,
  SystemIcons,
} from '@laerdal/life-react-components';

/**
 * Import custom components.
 */
import { InputWrapper } from '../../../../components/modals/ModalStyles';

/**
 * Import custom utils.
 */
import Api from '../../../../utils/api';

/**
 * Import custom types.
 */
import { Organization, OrganizationService, Subscription } from "../../../../types";
import { useMediaQuery } from 'react-responsive';
import { useToastContext } from '../../../../userContext';

/**
 * Custom types.
 */
type ServiceQuickDetailsProps = {
  closeModal: () => void;
  isModalOpen: boolean;
  organizationId: string;
  serviceId: string;
};

/**
 * Custom styles.
 */
const Description = styled.p`
  color: ${COLORS.black};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

const ModifiedInputWrapper = styled(InputWrapper)`
  margin-top: 8px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    width: 49%;

    > div {
      min-width: auto;

      > div {
        min-width: auto;
      }
    }
  }
`;

const OrgServiceQuickDetailsModal: React.FunctionComponent<ServiceQuickDetailsProps> = ({ closeModal, isModalOpen, organizationId, serviceId }: ServiceQuickDetailsProps) => {
  // Globally used variables within the component.
  const { t } = useTranslation('Services');
  const [validForm, setValidForm] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [service, setService] = useState<OrganizationService>();
  const [subscription, setSubscription] = useState<Subscription>();
  const [organization, setOrganization] = useState<Organization>();
  const { addToast } = useToastContext();
  const isMediumScreen = useMediaQuery({ query: BREAKPOINTS.MEDIUM.replace('@media ', '') });

  /**
   * Let's retrieve the organization.
   */
  useEffect(() => {
    if (organizationId) {
      setBusy(true);
      Api.GetOrganization(organizationId)
        .then((org: Organization) => {
          setOrganization(org);
          setBusy(false);
        })
        .catch(() => {
          setBusy(false);
          addToast(t('Organization could not be found.'), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  /**
   * Let's retrieve the service.
   */
  useEffect(() => {
    if (serviceId && organizationId) {
      setBusy(true);
      Api.GetOrganizationServices(organizationId)
        .then((services: OrganizationService[]) => {
          const service = services.find((s) => s.serviceId === serviceId);
          setService(service);
          setSubscription(service?.subscription);
          setBusy(false);
        })
        .catch(() => {
          addToast(t('Service could not be found.'), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
          setBusy(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, organizationId]);

  /**
   * Does all required pre-requisites when the modal is closed.
   */
  const clearDataAndClose = (): void => {
    // Let's make for invalid
    setValidForm(false);

    // Close the modal
    closeModal();
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {}}
      closeModalAndClearInput={clearDataAndClose}
      closeAction={closeModal}
      title={organization?.name}
      size={isMediumScreen ? Size.Medium : Size.Small}
      buttons={
        [
          {
            id: 'navigateToFullLicense',
            position: 'left',
            icon: <SystemIcons.OpenNewWindow size="24px" />,
            variant: 'secondary',
            action: () => window.open('/organization/' + organizationId + '/license-management/' + service?.id, '_blank'),
            text: t('View full license details'),
          },
          {
            id: 'cancel',
            variant: 'tertiary',
            text: t('Cancel'),
            disabled: busy,
            action: closeModal,
          },
          {
            id: 'save',
            action: (e: any) => {},
            disabled: !validForm,
            loading: busy,
            variant: 'primary',
            text: t('Save changes'),
          },
        ] as any
      }>
      {busy ? (
        <LoadingIndicator />
      ) : (
        <>
          <Description>{t('{{serviceName}} license information for this organization.', { serviceName: service?.name })}</Description>

          {/* Plan type section */}
          <ModifiedInputWrapper>
            <InputLabel inputId="planType" text={t('Plan type')} />
            <DropdownFilter list={[{value:(subscription?.plan.name || '')}]} id="planType" onSelect={(value: string) => {}} readOnly={true} value={subscription?.plan.name} />
          </ModifiedInputWrapper>

          {/* License Tier Section */}
          <ModifiedInputWrapper>
            <InputLabel inputId="licenseTier" text={t('License tier')} />
            <DropdownFilter list={[{value:(subscription?.tier.name || '')}]} id="licenseTier" onSelect={(value: string) => {}} readOnly={true} value={subscription?.tier.name} />
          </ModifiedInputWrapper>

          {/* Date Row section */}
          <InputRow>
            <ModifiedInputWrapper>
              <InputLabel inputId="startDate" text={t('Start date')} />
              <DatepickerField id="startDate" yearPicker readOnly={true} value={subscription?.createdDate ? new Date(subscription?.createdDate) : undefined} />
            </ModifiedInputWrapper>
            <ModifiedInputWrapper>
              <InputLabel inputId="expiryDate" text={t('Expiry date')} />
              <DatepickerField id="expiryDate" yearPicker readOnly={true} value={subscription?.expirationDate ? new Date(subscription?.expirationDate) : undefined} />
            </ModifiedInputWrapper>
          </InputRow>
        </>
      )}
    </ModalDialog>
  );
};

export default OrgServiceQuickDetailsModal;
