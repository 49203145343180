/**
 * Import React libraries.
 */
import React, { FunctionComponent, useEffect } from 'react';

/**
 * Import third-party libraries.
 */
import { useTranslation } from 'react-i18next';
import { ComponentS, ComponentSStyling, DropdownItem, HyperLink, ListRow, Size, SystemIcons } from '@laerdal/life-react-components';

import Api from '../../../utils/api';

/**
 * Add custom types.
 */
import { AccessLevel, Invitation, OrganizationMember, OrganizationType, User } from '../../../types';
import { useToastContext, useUserContext } from '../../../userContext';
import { OrganizationMemberType } from './UserManagementList';
import { ErrorToastOptions, SuccessToastOptions, memberFullAccessPermission } from '../../../constants';
import styled from 'styled-components';

export interface UserManagementListProps {
  member: OrganizationMemberType;
  setMemberToBeDeleted: (member: OrganizationMember) => void;
  setMemberToEditPermissions: (member: OrganizationMember) => void;
  onShowMemberDetails: (member: OrganizationMemberType) => void;
  onInvitationRemoved?: (invitation: Invitation) => void;
  onInvite?: (user: OrganizationMemberType) => void;
  setInvitedMemberPermissions: (code: string) => void;
  showDropdown?: boolean;
  organizationType?: OrganizationType;
}

const AddAsMemberLink = styled.div`
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
`;

const Container = styled.div`
  position: relative;
`;

const UserManagementUserLine: FunctionComponent<UserManagementListProps> = ({
  member,
  setMemberToBeDeleted,
  onInvitationRemoved,
  setMemberToEditPermissions,
  setInvitedMemberPermissions,
  onShowMemberDetails,
  onInvite,
  showDropdown,
  organizationType,
}: UserManagementListProps) => {
  // Globally used variables within the component.
  const { t } = useTranslation('Organization');
  const { accessLevel } = useUserContext();
  const readOnly = accessLevel == AccessLevel.ReadOnly;
  const { addToast } = useToastContext();

  const [dropdown, setDropdown] = React.useState<{ items: DropdownItem[]; onClick: (values: string[]) => void }>();

  const [data, setData] = React.useState<{ icon?: any; mainText: string; secondaryText?: string; note?: string; noteAction?: () => void }>();

  useEffect(() => {
    if (member && showDropdown && member.type != 'contact') {
      let options: DropdownItem[] = [];
      switch (member.type) {
        case 'member':
          options = [
            {
              value: 'edit-permissions',
              displayLabel: t('Change member permissions'),
              disabled: readOnly || organizationType == OrganizationType.Individual,
            },
            {
              value: 'remove-from-org',
              displayLabel: t('Remove from organization'),
            },
            {
              icon: <SystemIcons.OpenNewWindow />,
              value: 'view-profile',
              displayLabel: t('View user profile'),
            },
          ];
          break;
        case 'invitation':
          options = [
            {
              value: 'resend-invitation-email',
              displayLabel: t('Resend invitation e-mail'),
              disabled: readOnly,
              icon: <SystemIcons.SendTo />
            },
            {
              value: 'revoke-invitation',
              displayLabel: t('Revoke invitation'),
              disabled: readOnly,
            },
            {
              value: 'edit-invitation-permissions',
              displayLabel: t('Edit invitation permissions'),
              disabled: readOnly,
            },
          ];
          break;
      }

      setDropdown({
        items: options,
        onClick: (ids: string[]) => onDropdownAction(ids[0], member),
      });
    } else {
      setDropdown(undefined);
    }
  }, [member, showDropdown]);

  useEffect(() => {
    switch (member?.type) {
      case 'member':
        setData({
          icon: member.data.user?.identityId ? <SystemIcons.User /> : undefined,
          mainText:
            member.data.user.firstName || member.data.user.lastName ? (member.data.user?.firstName || '') + ' ' + (member.data.user?.lastName || '') : member.data.user.email,
          secondaryText: member.data.user.firstName || member.data.user.lastName ? member.data.user?.email : !member.data.user?.identityId ? 'Added' : undefined,
          note: member.data.permissions?.find((a) => a.permissionId === memberFullAccessPermission) ? t('Admin') : undefined,
        });
        break;
      case 'invitation':
        setData({
          icon: <SystemIcons.Mail />,
          mainText: member.data.email,
          secondaryText: member.data.isActive ? t('Invited') : t('Invitation expired'),
          note: member.data.permissions?.find((a) => a === memberFullAccessPermission) ? t('Admin') : undefined,
        });
        break;
      case 'contact':
        setData({
          mainText:
            member.data.user?.firstName || member.data.user?.lastName ? (member.data?.user?.firstName || '') + ' ' + (member.data?.user?.lastName || '') : member.data?.user?.email,
          secondaryText: member.data.user?.firstName || member.data.user?.lastName ? member.data?.user?.email : undefined,
          note: '',
        });
        break;
    }
  }, [member]);

  const resentInvitation = (invitation: Invitation) => {
    Api.UpdateOrganizationInvitation(invitation.organization.id, invitation.code || '', true, false)
      .then(() => addToast(t('Invitation e-mail successfully resent'), SuccessToastOptions))
      .catch(() => addToast(t('Resending of invitation e-mail failed'), ErrorToastOptions));
  };

  const revokeInvitation = (invitation: Invitation) => {
    Api.UpdateOrganizationInvitation(invitation.organization.id, invitation.code || '', false, true)
      .then(() => {
        onInvitationRemoved && onInvitationRemoved(invitation);
        addToast(t('Invitation successfully revoked'), SuccessToastOptions);
      })
      .catch(() => addToast(t('Revoking of invitation failed'), ErrorToastOptions));
  };

  const onDropdownAction = (action: string, member: OrganizationMemberType) => {
    switch (member.type) {
      case 'member':
        switch (action) {
          case 'view-profile':
            window.open(`/user/${member.data?.user?.id}`, '_blank');
            break;
          case 'edit-permissions':
            setMemberToEditPermissions(member.data);
            break;
          case 'remove-from-org':
            setMemberToBeDeleted(member.data);
            break;
          case 'invite-member':
            onInvite && onInvite(member);
        }
        break;
      case 'invitation':
        switch (action) {
          case 'resend-invitation-email':
            resentInvitation(member.data);
            break;
          case 'revoke-invitation':
            revokeInvitation(member.data);
            break;
          case 'edit-invitation-permissions':
            setInvitedMemberPermissions(member.data.code ?? '');
            break;
        }
        break;
      case 'contact':
        switch (action) {
          case 'invite-contact':
            onInvite && onInvite(member);
            break;
        }
        break;
    }
  };

  return (
    <Container>
      <ListRow
        size={Size.Large}
        icon={data?.icon ?? ' '}
        action={() => onShowMemberDetails(member)}
        mainText={data?.mainText || ''}
        secondaryText={data?.secondaryText}
        note={data?.note}
        dropdown={dropdown}
      />

      {member.type == 'contact' && (
        <AddAsMemberLink>
          <HyperLink
            id={`data`}
            href={`/organization`}
            variant="default"
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              event.preventDefault();
              onInvite && member.type == 'contact' && onInvite(member);
            }}>
            Add as member
          </HyperLink>
        </AddAsMemberLink>
      )}
    </Container>
  );
};

export default UserManagementUserLine;
