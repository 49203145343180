import { Checkbox, COLORS, RadioButton } from '@laerdal/life-react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { User } from '../../../../types';
import { InviteList } from './InviteList';

type InviteUserStep2Props = {
  addUsersImmediately: boolean;
  emails: string[];
  users: User[];
  orgName: string;
  sendEmail: boolean;
  setAddUsersImmediately: (data: boolean) => void;
  setSendEmail: (data: boolean) => void;
};

const AddUsersImmediatelyWrapper = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props.selected ? COLORS.neutral_20 : 'none')};
  padding: 5px 10px 10px 10px;
  margin-top: 15px;

  border-radius: 4px;
  border: 1px solid ${(props) => (props.selected ? COLORS.neutral_200 : 'none')};
`;

const InviteUsersByMailWrapper = styled.div`
  padding: 10px;
`;

const LeftMarginWrapper = styled.div`
  margin-left: 35px;
`;

export const InviteUserStep2: React.FunctionComponent<InviteUserStep2Props> = ({
  addUsersImmediately,
  emails,
  users,
  orgName,
  sendEmail,
  setAddUsersImmediately,
  setSendEmail,
}: InviteUserStep2Props) => {
  const { t } = useTranslation('Organization');
  return (
    <>
      <p>{t('These people will be given default permissions to {{organizationName}}', { organizationName: orgName })}</p>
      <InviteList emails={emails} users={users}></InviteList>

      <AddUsersImmediatelyWrapper selected={addUsersImmediately}>
        <RadioButton
          id="AddUsersImmediatelyRadio"
          label={t('Add users immediately')}
          select={() => {
            setAddUsersImmediately(true);
          }}
          selected={addUsersImmediately}
        />
        {addUsersImmediately && (
          <LeftMarginWrapper>
            <Checkbox id="SendEmailCheckbox" select={(value) => setSendEmail(value)} selected={sendEmail} label={'Send email notification to selected people'} />
          </LeftMarginWrapper>
        )}
      </AddUsersImmediatelyWrapper>
      <InviteUsersByMailWrapper>
        <RadioButton
          id="InviteUsersByEmailRadio"
          label={t('Invite users by email')}
          select={() => {
            setAddUsersImmediately(false);
            setSendEmail(false);
          }}
          selected={!addUsersImmediately}
        />
      </InviteUsersByMailWrapper>
    </>
  );
};
