import styled from 'styled-components';
import { COLORS } from '@laerdal/life-react-components';

export const InputField = styled.input`
  height: 28px;
  font-size: 16px;
  appearance: none;
  border: 1px solid ${COLORS.neutral_300};
  padding: 8px;

  ::placeholder {
    font-style: italic;
    color: ${COLORS.neutral_400};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const ModalTitle = styled.h5`
  margin: 0;
  font-size: 1.25em;
  font-weight: bold;
`;

export const ModalBody = styled.section`
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

export const ModalFooter = styled.section`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const ModalFooterSplit = styled.section`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ModalFooterButtons = styled.div`
  /* Add spacing when buttons are next to eachother */
  button + button {
    margin-left: 8px;
  }
`;

export const ModalFooterErrorMessage = styled.div<{ isVisible: boolean }>`
  color: ${COLORS.critical_700};
  font-weight: 700;
  font-size: 0.8rem;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const IconWrapper = styled.span`
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledInputField = styled(InputField)`
  width: auto;
`;

export const CheckboxWrapper = styled.div`
  display: inline;
`;

export const IconPointer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  p {
    padding-left: 5px;
  }
`;
