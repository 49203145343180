/**
 * Import React libraries.
 */
import React, {useEffect, useMemo, useState} from 'react';

/**
 * Import third-party libraries.
 */
import {
  BasicDropdown,
  Button,
  COLORS,
  ComponentLStyling,
  ComponentTextStyle,
  InputLabel,
  Size,
  TextField
} from '@laerdal/life-react-components';
import {ScenariosCloudSettings} from './scenario-cloud/ScenarioCloudSettings';
import styled from 'styled-components';
import {
  AccessLevel,
  AccountPaymentType,
  Organization,
  OrganizationService,
  UpdateOrganizationServiceDTO
} from '../../../types';
import Api from '../../../utils/api';
import axios from 'axios';
import {useToastContext, useUserContext} from '../../../userContext';
import {ErrorToastOptions, SuccessToastOptions} from '../../../constants';
import {OrgPartnerAccess} from '../OrgPartnerAccess';
import {domainValidationRequired} from '../../../utils/functions';

/**
 * Add custom styling.
 */
const SettingsTitle = styled.h3`
  font-size: 24px;
  color: ${COLORS.neutral_600};
  font-weight: 700;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`;


const Wrapper = styled.div`
`;

const EmptyStateContainer = styled.div`
  display: none;
  padding: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${COLORS.neutral_200};
  border-radius: 8px;
  ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
    
  
  &:only-child {
    display: flex;
  }
`;

const DropDownStyle = styled.div`
  & > div {
    z-index: 2;
  }
`;

interface OrgSettingsPageParams {
  organization: Organization | undefined;
  service: OrganizationService | undefined;
  getOrganizationService: () => void;
}

const paymentTypes = [
  {
    value: 0,
    displayLabel: 'None',
  },
  {
    value: 1,
    displayLabel: 'On-account payment',
  },
  {
    value: 2,
    displayLabel: 'Pre-Pay',
  },
];

const OrgSettingsPage = ({ organization, service, getOrganizationService }: OrgSettingsPageParams) => {
  const tokenSource = useMemo(() => axios.CancelToken.source(), []);
  const [serviceProps, setServiceProps] = useState<UpdateOrganizationServiceDTO>({
    onAccountPayment: service?.onAccountPayment,
    commerceMarketCode: service?.commerceMarket?.code,
    isPartner: service?.isPartner,
  });
  const [availableMarkets, setAvailableMarkets] = useState<{ value: string; displayLabel: string }[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { addToast } = useToastContext();
  const { accessLevel } = useUserContext();

  useEffect(() => {
    const abortController = new AbortController();
    if (process.env.REACT_APP_ECOMMERCE_SERVICE_ID?.toLowerCase() === service?.serviceId.toLowerCase()) {
      Api.GetMarkets(abortController.signal).then((result) =>
        setAvailableMarkets(
          result.map((a) => ({
            value: a.code,
            displayLabel: a.name,
          })),
        ),
      );
    }
    return () => abortController.abort();
  }, [service]);

  useEffect(() => {
    return () => tokenSource.cancel();
  }, []);

  useEffect(() => {
    setServiceProps({ onAccountPayment: service?.onAccountPayment, commerceMarketCode: service?.commerceMarket?.code, isPartner: service?.isPartner });
  }, [service]);

  const updateServiceSpecificSettings = () => {
    setIsSaving(true);

    const marketCode = serviceProps.commerceMarketCode;
    const settings: UpdateOrganizationServiceDTO = {
      onAccountPayment: serviceProps.onAccountPayment ?? AccountPaymentType.None,
      commerceMarketCode: marketCode,
      isPartner: serviceProps.isPartner,
    };

    Api.UpdateServiceSpecificSettings(organization?.id!, service?.id ?? '', settings)
      .then(() => {
        addToast('Licence settings saved', SuccessToastOptions);
        getOrganizationService();
      })
      .catch(() => {
        addToast('Saving licence settings failed', ErrorToastOptions);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onCancel = () => {
    setServiceProps({ onAccountPayment: service?.onAccountPayment, commerceMarketCode: service?.commerceMarket?.code, isPartner: service?.isPartner });
  };

  const wasTouched = () => {
    return (
      serviceProps.onAccountPayment !== service?.onAccountPayment ||
      (serviceProps.commerceMarketCode ?? '') !== (service?.commerceMarket?.code ?? '') ||
      Boolean(serviceProps.isPartner) !== !!service?.isPartner
    );
  };

  const touched = wasTouched();
  return (
    <>
      <Wrapper>
        <EmptyStateContainer>
          No settings available
        </EmptyStateContainer>
        {domainValidationRequired(service?.serviceId) && (
          <InputWrapper>
            <InputLabel inputId="meteorDomain" text={'Meteor domain'} />
            <TextField disabled={accessLevel == AccessLevel.ReadOnly} readOnly={true} id="meteorDomain" value={service?.meteorDomain
                ? (service?.serviceId == process.env.REACT_APP_TEAMREPORTER_SERVICE_ID?.toLowerCase() ? 'tr-' + service?.meteorDomain : service?.meteorDomain) 
                : ''} />
          </InputWrapper>
        )}
        {process.env.REACT_APP_ECOMMERCE_SERVICE_ID?.toLowerCase() === service?.serviceId.toLowerCase() && (
          <>
            <InputWrapper>
              <InputLabel inputId="marketCode" text={'Market'} />
              <DropDownStyle>
                <BasicDropdown
                  id={'marketCode'}
                  itemsType={'normal'}
                  disabled={accessLevel == AccessLevel.ReadOnly}
                  list={availableMarkets}
                  onSelect={(value: string) => setServiceProps({ ...serviceProps, commerceMarketCode: value })}
                  value={serviceProps.commerceMarketCode}
                />
              </DropDownStyle>
            </InputWrapper>
          </>
        )}
        {process.env.REACT_APP_SCENARIO_CLOUD_SERVICE_ID?.toLowerCase() === service?.serviceId.toLowerCase() && (
          <>
            <OrgPartnerAccess serviceProps={serviceProps} setServiceProps={setServiceProps} />
            <SettingsTitle>{'Purchased scenarios'}</SettingsTitle>
            <ScenariosCloudSettings service={service} organization={organization as Organization} />
          </>
        )}
      </Wrapper>
        
      <ButtonWrapper>
        <Button variant="tertiary" disabled={accessLevel == AccessLevel.ReadOnly || !touched} onClick={onCancel}>
          {'Cancel'}
        </Button>
        <Button id="submit" variant="primary" size={Size.Large} onClick={updateServiceSpecificSettings} loading={isSaving} disabled={accessLevel == AccessLevel.ReadOnly || !touched || isSaving}>
          {'Save changes'}
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default OrgSettingsPage;
