import React from 'react';
import {ModalDialog, Size, SystemIcons} from '@laerdal/life-react-components';
import {Organization} from '../../../types';
import {useTranslation} from 'react-i18next';
import {OrganizationMergeInstanceLimitError} from '../OrganizationMergeHelper';

interface OrganizationMergeResolveModalProps {
  isOpen: boolean;
  onClose: () => void;
  targetOrganization?: Organization;
  errors: OrganizationMergeInstanceLimitError[];
}

const OrganizationMergeResolveModal: React.FunctionComponent<OrganizationMergeResolveModalProps> = ({
                                                                                                      isOpen,
                                                                                                      onClose,
                                                                                                      targetOrganization,
                                                                                                      errors,
                                                                                                    }) => {
  const {t} = useTranslation('Organization');

  return (
    <ModalDialog closeAction={onClose}
                 isModalOpen={isOpen}
                 closeModalAndClearInput={onClose}
                 submitAction={onClose}
                 size={Size.Medium}
                 note={t('Use the button below to jump to the target organization and edit subscriptions.')}
                 icon={<i/>}
                 leftFooterAction={{
                   actionType: 'button',
                   text: t('Edit subscriptions'),
                   icon: <SystemIcons.OpenNewWindow/>,
                   action: () => window.open(`/organization/${targetOrganization?.id}/license-management/`, '_blank'),
                   variant: 'secondary',
                 }}
                 buttons={[
                   {
                     variant: 'tertiary',
                     action: onClose,
                     text: t('Close'),
                   },
                 ]}
                 title={t('Unable to merge')}>
      <p>
        <span>
          {t(
            '{{targetOrgName}} subscriptions for the following services need to be upgraded in order to complete this merge action.',
            {
              targetOrgName: targetOrganization?.name,
            },
          )}        
        </span>
        <ul>
          {errors.map(error => (
            <li key={error.serviceName}>
              {t(
                '{{serviceName}} - {{expectedInstances}}/{{instanceLimit}} users.',
                {
                  serviceName: error.serviceName,
                  instanceLimit: error.instanceLimit,
                  expectedInstances: error.expectedInstances,
                },
              )}
            </li>
          ))}

        </ul>
      </p>
    </ModalDialog>
  );
};

export default OrganizationMergeResolveModal;