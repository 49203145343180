/**
 * Import React libraries.
 */
import React, {FormEvent, useState} from 'react';

/**
 * Import third-party libraries.
 */
import {useTranslation} from 'react-i18next';
import {InputLabel, DropdownFilter, ModalDialog, Size, BREAKPOINTS} from '@laerdal/life-react-components';

/**
 * Import custom components.
 */
import {Service} from '../../../types';
import {useMediaQuery} from 'react-responsive';

/**
 * Custom types.
 */
type CreateLicenseProps = {
  closeModal: (service?: string) => void;
  isModalOpen: boolean;
  services: Service[];
  useSalesforce?: boolean;
  selfServiceServices?: string[];
};

const CreateLicenseModal: React.FunctionComponent<CreateLicenseProps> = ({
                                                                           closeModal,
                                                                           isModalOpen,
                                                                           services,
                                                                           useSalesforce,
                                                                           selfServiceServices
                                                                         }: CreateLicenseProps) => {
  // Globally used variables within the component.
  const {t} = useTranslation('OrganizationServices');
  const [service, setService] = useState<string>('');
  const [configError, setConfigError] = useState<boolean>(false);
  const isMediumScreen = useMediaQuery({query: BREAKPOINTS.MEDIUM.replace('@media ', '')});

  /**
   * Does all required pre-requisites when the modal closes.
   * @param service - Service which was selected in the dropdown.
   */
  const clearDataAndClose = (service?: string): void => {
    // Let's clear the old content
    setService('');
    setConfigError(false);

    // Close the modal
    closeModal(service);
  };

  /**
   * Does all required pre-requisites and continues to create license step.
   * @param event - Form event handler.
   */
  const createLicense = (event: FormEvent): void => {
    // Prevent the default action
    event.preventDefault();

    const selectedService = services.find((s) => s.name === service);
    const ownerRoles = selectedService?.serviceRoles?.filter((r) => r.parentRoleName === 'Service Owner');
    if (!ownerRoles?.length) {
      setConfigError(true);
    } else {
      // Let's close the modal and pass
      clearDataAndClose(service);
    }
  };

  const modalButtons = [
    {
      id: 'cancel',
      variant: 'tertiary',
      text: configError ? t('Close') : t('Cancel'),
      action: () => clearDataAndClose(),
    },
  ] as any;

  if (!configError) {
    modalButtons.push({
      id: 'save',
      action: (e: FormEvent) => {
        createLicense(e);
      },
      disabled: !service,
      variant: 'primary',
      text: t('Continue'),
    });
  }

  const dropdownList = services.map((s) => ({
    value: s.name,
    displayLabel: s.name,
    noteLabel: selfServiceServices?.includes(s.id)
      ? useSalesforce
        ? 'Trial only, use Salesforce to create a full license.'
        : 'Trial only, customer will need to purchase a full license'
      : undefined
  }));

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {
      }}
      closeModalAndClearInput={() => clearDataAndClose()}
      closeAction={() => clearDataAndClose()}
      title={configError ? t('Configuration error') : t('Create new license')}
      size={isMediumScreen ? Size.Medium : Size.Small}
      buttons={modalButtons}>
      <>
        <InputLabel inputId="service" text={t('Select a product/service')}/>
        <DropdownFilter id="service" list={dropdownList} onSelect={(value: string) => setService(value)}
                        placeholder={!services.length ? 'No services available' : ''}
                        readOnly={!services.length}
        />
      </>
    </ModalDialog>
  );
};

export default CreateLicenseModal;
