/**
 * Import React libraries.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route } from 'react-router-dom';

/**
 * Import custom components
 */
import App from './App';

/**
 * Import third-party libraries.
 */
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import {FeatureProvider} from "./utils/features/FeatureProvider";


// Setup MSAL Office365 instance
const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_OFFICE365_CLIENT_ID!,
    authority: process.env.REACT_APP_OFFICE365_AUTHORITY!,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
});

// import registerServiceWorker from "./registerServiceWorker";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <FeatureProvider>
          <App />
        </FeatureProvider>
      </QueryParamProvider>
    </MsalProvider>
  </BrowserRouter>
);

// registerServiceWorker();
