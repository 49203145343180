import React, {useEffect, useMemo} from 'react';
import {
  ChipDropdownInput,
  COLORS,
  DropdownButton,
  InputLabel,
  InputWrapper, Size,
  States,
  SystemIcons,
  TextField,
  Tile,
  ToggleSwitch,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useController, useFormContext} from 'react-hook-form';
import {Organization, Service, ServiceRole} from "../../../../../types";
import {CreateLicenseForm} from "../../Common/types";
import {InputsContainer, ValidationError} from "../../Common/styles";

const RolePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px 8px 12px;
`;

const RolePickerDetails = styled.div`
  flex: 1 0 calc(100% - 120px);
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.neutral_600};
  div.role {
    font-weight: bold;
  }
`;

const RolePickerDropdown = styled.div`
  text-align: right;
  width: 120px;
`;

const NoteDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${COLORS.neutral_600};

  svg {
    vertical-align: middle;
    margin-right: 4px;
  }
`;

interface Props {
  organization?: Organization;
  roles?: ServiceRole[];
}

export const GeneralSubscriptionPropertiesCard = ({
  organization,
  roles,
}: Props) => {

  const {t} = useTranslation('OrganizationServices');

  const {control, watch, trigger, formState} = useFormContext<CreateLicenseForm>();

  const licenseOwnerController = useController({control: control, name: 'general.owner'});
  const roleController = useController({control: control, name: 'general.role'});

  return (
    <Tile header={{title: t('General')}} size={Size.Large} className={'tile'}>
      <InputsContainer>

        <InputWrapper>
          <InputLabel inputId="licenseOwner" text={t('Select a license owner')}/>
          <ChipDropdownInput
            inputId="licenseOwner"
            list={organization?.members?.map((member) => member.user.email) ?? []}
            maxSelectedItems={1}
            placeholder={t('Select a license owner')}
            messageOnNoResults={t('No organization members found')}
            icon={<SystemIcons.User/>}
            onValueChange={licenseOwnerController.field.onChange}
            values={licenseOwnerController.field.value}
            onBlur={licenseOwnerController.field.onBlur}
            ref={licenseOwnerController.field.ref}
            name={licenseOwnerController.field.name}
            validationMessage={licenseOwnerController.fieldState.error?.message}
          />
          {roles && roles.length === 1 && (
            <NoteDescription>
              <SystemIcons.Information color={COLORS.neutral_600} size="16px"/>
              {t('This person will be able to manage license details and grant access to other users.')}
            </NoteDescription>
          )}
          {(!roles || roles.length === 0) && (
            <ValidationError>
              <SystemIcons.Attention size="20px" color={COLORS.critical_500}/>
              <span>{t(
                'Configuration error: Cannot create a license. The service owner role is undefined for this service.')}</span>
            </ValidationError>
          )}
          {roles && roles.length > 1 && (
            <RolePickerWrapper>
              <RolePickerDetails>
                {
                  !roleController.field.value &&
                  <div className="role"
                       style={{color: roleController.fieldState?.error ? COLORS.critical_500 : undefined}}>
                    {t('No access level selected')}
                  </div>}
                {roleController.field.value && <div className="role">{roleController.field.value.name}</div>}
                {roleController.field.value &&
                  <div className="description">{t(roleController.field.value.description)}</div>}
              </RolePickerDetails>
              <RolePickerDropdown>
                <DropdownButton type={'text'}
                                label={'Change'}
                                keepLabel={true}
                                value={[roleController.field.value?.id ?? '']}
                                items={roles?.map((r) => ({value: r.id!, displayLabel: r.name}))}
                                onClick={(values) => {
                                  roleController.field.onChange(roles?.find((r) => r.id === values[0]));
                                }}
                                ref={roleController.field.ref}
                />
              </RolePickerDropdown>

            </RolePickerWrapper>
          )}

        </InputWrapper>

      </InputsContainer>
    </Tile>
  );
};