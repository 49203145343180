import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {BREAKPOINTS, ModalDialog, Size} from '@laerdal/life-react-components';

import Api from '../../utils/api';
import {OrganizationMember, Permission} from '../../types';
import {useMediaQuery} from 'react-responsive';
import {ErrorToastOptions, SuccessToastOptions, memberFullAccessPermission} from '../../constants';
import {useToastContext} from '../../userContext';
import PermissionsEdit from "../../components/MemberPermissionsEdit";

type EditPermissionsModalProps = {
  closeModal: any;
  isModalOpen: boolean;
  currentPermissions?: string[];
  saveUpdatedPermissions: (newPermissions: string[]) => Promise<void>;
  allPermissions: Permission[];
  headerLabel?: string;
  title?: string;
};

const ContentContainer = styled.div`
  padding-right: 15px;
  height: calc(276px + 28px);
`;

const EditPermissionsModal: React.FunctionComponent<EditPermissionsModalProps> = ({
  closeModal,
  isModalOpen,
  currentPermissions,
  allPermissions,
  title,
  saveUpdatedPermissions,
  headerLabel
}: EditPermissionsModalProps) => {
  const { t } = useTranslation('OrganizationServices');

  
  const [permissions, setPermissions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { addToast } = useToastContext();

  useEffect(() => {
    setPermissions(currentPermissions ?? []);
  }, [currentPermissions]);
  
  const onChecked = (arg1: string) => {
    if(permissions.find(x => x == arg1))
      setPermissions([...permissions.filter(x => x != arg1)]);
    else
      setPermissions([...permissions, arg1]);
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {}}
      contentOverflow={'auto'}
      closeModalAndClearInput={closeModal}
      closeAction={closeModal}
      title={title ?? t('Edit permissions')}
      size={Size.Large}
      buttons={
        [
          {
            id: 'cancel',
            variant: 'tertiary',
            text: t('Cancel'),
            action: closeModal,
          },
          {
            id: 'save',
            action: async (e: any) => {
              e.preventDefault();
              setLoading(true);
              try {
                let saveThis = permissions;
                if(permissions.findIndex(x => x == memberFullAccessPermission) > 0)
                  saveThis = [memberFullAccessPermission];

                await saveUpdatedPermissions(saveThis);
                closeModal();
              }
              catch(ex) {
                addToast(t('Updating permissions failed'), ErrorToastOptions);
              }
              finally {
                setLoading(false);
              }
            },
            loading: loading,
            variant: 'primary',
            text: t('Save changes'),
            disabled: loading
          },
        ] as any
      }>
      <ContentContainer>
        <p>{headerLabel ?? t('Control what this person can see and do at his organization')}</p>
        <PermissionsEdit permissions={permissions} allPermissions={allPermissions} onChecked={onChecked} />
      </ContentContainer>
    </ModalDialog>
  );
};

export default EditPermissionsModal;
