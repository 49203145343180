import React from "react";
import styled from "styled-components";

import { COLORS, SystemIcons } from "@laerdal/life-react-components";
import { useNavigate } from "react-router";

const Container = styled.div`
  background: ${COLORS.neutral_100};
  height: 100%;
  position: fixed;
  width: 100%;
`;

const MessageBlock = styled.div`
  background: ${COLORS.white};
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  margin: 32px 56px 0px 56px;

  .flexalign {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    svg {
      margin-right: 7px;
    }
  }

  .lineheight {
    line-height: 28px;
  }
`;

const CustomTextStyle = styled.div`
  line-height: 28px;
`;

const ForbiddenPage = () => {
  
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 5000);
  }, []);

  return <Container>
    <MessageBlock>
      <h3 className="flexalign">
        <SystemIcons.TechnicalWarning size="24px" />
        <div>Forbidden</div>
      </h3>
      <CustomTextStyle>
        <p color={COLORS.neutral_600}>
          {<>You don't have permissions to view this page.<br />
            Send a message to appsupport@laerdal.com to request access.</>}
        </p>
      </CustomTextStyle>
    </MessageBlock>
  </Container>;
};

export default ForbiddenPage;
