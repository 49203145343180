import React from 'react';
import styled from 'styled-components';
import { ModalHeader, ModalBody, ModalFooter } from './ModalStyles';
import { Button, SystemIcons, COLORS } from '@laerdal/life-react-components';

/**
 * Modal styles
 */
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
`;

export const ModalCloseButton: React.FunctionComponent<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick} variant="tertiary" title="Close">
      <SystemIcons.Close size="24px" color={COLORS.black} />
    </StyledButton>
  );
};

type ModalProps = {
  children: any;
};

const Modal = ({ children }: ModalProps) => <Column>{children}</Column>;

Modal.Header = ({ children }: ModalProps) => <ModalHeader>{children}</ModalHeader>;

Modal.Body = ({ children }: ModalProps) => <ModalBody>{children}</ModalBody>;

Modal.Footer = ({ children }: ModalProps) => <ModalFooter>{children}</ModalFooter>;

export default Modal;
