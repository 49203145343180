import { ToastColor, ToastPosition } from '@laerdal/life-react-components';

export const SuccessToastOptions = {
  color: ToastColor.GREEN,
  showCloseButton: true,
  autoClose: true,
  position: ToastPosition.TOPMIDDLE,
};
export const ErrorToastOptions = {
  color: ToastColor.RED,
  showCloseButton: true,
  autoClose: true,
  position: ToastPosition.TOPMIDDLE,
};

export const memberFullAccessPermission = 'account.owner';

const httpCodes = [
  {
    code: 409,
    title: 'Conflict',
  },
];

export const getCodeTitle = (code: number): string => {
  const index = httpCodes.findIndex((x) => x.code === code);
  if (index === -1) return '';
  else return httpCodes[index].title;
};
