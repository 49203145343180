import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { COLORS, PageWidth } from '@laerdal/life-react-components';

import PageCard from './PageCard';

import illustration from '../assets/404illustration.svg';

const NotFoundMain = styled.main`
  flex: 1;
  background: ${COLORS.neutral_20};
`;

const StyledNotFoundPage = styled(PageWidth)`
  div {
    margin: 56px auto auto auto;
  }
`;

const NotFoundPage = () => {
  const { t } = useTranslation('NotFoundPage');

  return (
    <NotFoundMain>
      <StyledNotFoundPage>
        <PageCard
          title={t('Sorry, we can’t find that page (404)')}
          text={t('The link you have followed may be broken, or the page may have been removed.')}
          textLineTwo={t('Contact an account administrator at your organization for assistance.')}
          linkTo="/"
          linkText={t('Return to homepage')}
          image={illustration}
        />
      </StyledNotFoundPage>
    </NotFoundMain>
  );
};

export default NotFoundPage;
