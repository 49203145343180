/**
 * Import React libraries.
 */
import React, { useEffect, useState } from 'react';

/**
 * Import custom utils.
 */
import Api from '../../../utils/api';

/**
 * Import third-party libraries.
 */
import { BREAKPOINTS, Banner, Button, COLORS, ComponentLStyling, ComponentTextStyle, LinearProgress, LoadingIndicator, SearchBar, Size, SystemIcons } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as constants from '../../../constants';
import { useMediaQuery } from 'react-responsive';

/**
 * Import custom components.
 */
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal';
import UserList, { UserOption } from '../../../components/UserList';
import {
  AccessLevel,
  AddOrganizationServiceMemberInvitation,
  InstanceLimitType,
  Organization,
  OrganizationService,
  Service,
  ServiceRole,
  SessionDTO,
  Subscription,
  User,
} from '../../../types';
import InviteUsersToOrgServiceModal from '../modals/InviteUsersToOrgServiceModal';
import EditServiceMemberModal from '../modals/EditServiceMemberModal';
import RemoveServiceMemberModal from '../modals/RemoveServiceMemberModal';
import { useToastContext, useUserContext } from '../../../userContext';

/**
 * Add custom styles.
 */

const Title = styled.div`
  flex: 1 0 auto;
  ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
`;

const ActionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type OrgUserAccessPageProps = {
  service?: Service;
  organizationService?: OrganizationService;
  url: string;
  organization: Organization | undefined;
  getOrganizationService: () => void;
  subscription?: Subscription;
  serviceRoles?: ServiceRole[];
  sessions: SessionDTO[] | undefined;
  existsInSF: boolean | undefined;
};

const OrgUserAccessPage = ({
  organizationService,
  url,
  organization,
  getOrganizationService,
  subscription,
  serviceRoles,
  service,
  sessions,
  existsInSF,
}: OrgUserAccessPageProps) => {
  // Globally used parameters within the component
  const { t } = useTranslation('OrganizationServices');
  const [query, setQuery] = useState<string>('');
  const { accessLevel } = useUserContext();
  const [members, setMembers] = useState<UserOption[]>([]);

  const { addToast } = useToastContext();
  const [addUsersModalOpen, setAddUsersModalOpen] = useState<boolean>(false);
  const [memberToBeRemoved, setMemberToBeRemoved] = useState<User | null>(null);
  const [memberToBeEdited, setMemberToBeEdited] = useState<User | null>(null);
  const [memberToResendInvitation, setMemberToResendInvitation] = useState<User | null>(null);
  const isMediumScreen = useMediaQuery({ query: BREAKPOINTS.MEDIUM.replace('@media ', '') });

  const readOnly = accessLevel == AccessLevel.ReadOnly;
  const hasSessionLimit = service?.instanceLimitType == InstanceLimitType.Session;

  /**
   * Populates the members list which will be shown in the user list
   */
  useEffect(() => {
    if (organizationService) {
      const list: UserOption[] = [];
      organizationService.members?.forEach((m) => {
        list.push({
          user: m.user,
          lastSeen: sessions
            ?.filter((s) => s.identityId == m.user.identityId)
            .map((s) => s.updated)
            .sort()
            .reverse()[0],
          serviceRole: m?.role || undefined,
          setDropdownActive: () => {},
          setMemberToBeRemoved,
          setMemberToBeEdited,
          setMemberToResendInvitation,
        });
      });
      if (organizationService.invitations) {
        organizationService.invitations
          ?.filter((i) => i.isActive || (!i.isActive && !i.acceptedDate))
          .forEach((i) => {
            let user = organization?.members?.find((m) => m?.user?.email === i?.email)?.user;
            if (!user) {
              user = {
                currentOrganization: null,
                email: i.email,
                firstName: '',
                lastName: '',
                id: '',
                identityId: '',
                organizations: [],
                services: [],
              };
            }
            user.invitationCode = i.code;

            list.push({
              user: user,
              serviceRole: {
                id: '',
                name: i.isActive ? t('Invited') : t('Invitation expired'),
              } as ServiceRole,
              setDropdownActive: () => {},
              setMemberToBeRemoved,
              setMemberToBeEdited,
              setMemberToResendInvitation,
            });
          });
      }

      setMembers(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationService, sessions]);

  /**
   * Does all required pre-requisites and clears the search.
   */
  const clearSearch = (): void => {
    setQuery('');
  };

  /**
   * Resends invitation for the user. Method will finish successfully only if invitation already expired or not exist.
   * Success or Failure toast will shown to the user in the end of processing.
   * @returns Promise object that will finish after receiving request from the server
   */
  const resendInvitation = async () => {
    try {
      if (!organizationService) {
        return;
      }

      const invitation = organizationService?.invitations?.find((a) => a.email === memberToResendInvitation?.email && !a.isActive && !a.acceptedDate);

      const inviteObject: AddOrganizationServiceMemberInvitation = {
        emails: [memberToResendInvitation?.email ?? ''],
        role: { name: invitation?.role?.name ?? '' } as ServiceRole,
        sendEmail: true,
        createSFContact: Boolean(invitation?.createSFContact),
      };
      await Api.InviteMemberToOrganizationService(organization?.id!, organizationService.id, inviteObject);
      addToast(t('Invitation successfully resent'), constants.SuccessToastOptions);
      getOrganizationService();
    } catch (err) {
      addToast(t('Error occured while sending invitation email'), constants.ErrorToastOptions);
    }
    setMemberToResendInvitation(null);
  };

  const dropdownList = [
    {
      value: 'view-profile',
      displayLabel: t('View user profile'),
    },
    {
      value: 'edit-access',
      displayLabel: t('Edit access'),
      disabled: readOnly,
    },
    {
      value: 'remove-from-service',
      displayLabel: t('Remove from service'),
      disabled: readOnly,
    },
  ];

  const expiredInvitationDropdownList = [
    {
      value: 'resend-invitation',
      displayLabel: t('Resend invitation'),
      disabled: readOnly,
    },
    {
      value: 'remove-from-service',
      displayLabel: t('Remove from service'),
      disabled: readOnly,
    },
  ];

  const getDropdownListForMember = (user: UserOption) => {
    const list = user.serviceRole?.name === t('Invitation expired') ? expiredInvitationDropdownList : dropdownList;
    return !!user.user.id ? list : list.filter((l) => l.value !== 'view-profile');
  };

  const getRoleForUser = (user: User) => {
    return (user && members.find((m) => m.user.email === user.email)?.serviceRole?.name) || '';
  };

  const getMembersCount = () => {
    if(service?.id?.toLowerCase() == process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase())
        return (organization?.members?.length || 0) + (organization?.invitations?.filter((i) => i.isActive)?.length || 0);

    return (organizationService?.members?.length || 0) + (organizationService?.invitations?.filter((i) => i.isActive)?.length || 0);
  };

  const isMembersLimitExceeded = () => {
    return !hasSessionLimit && getMembersCount() > (subscription?.maxSubscriptionInstances || Number.MAX_VALUE);
  };

  const renderActionContainer = () => {
    return (
      <ActionContainer>
        {hasSessionLimit && <Title>{t('People using this service')}</Title>}
        {!hasSessionLimit && (
          <Title>
            {t('Number of users')}: {getMembersCount()}
            {subscription?.maxSubscriptionInstances && `/${subscription?.maxSubscriptionInstances}`}
          </Title>
        )}

        {service?.id?.toLowerCase() != process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase() && (
          <Button icon={<SystemIcons.AddUser />} size={Size.Large} variant="secondary" onClick={() => setAddUsersModalOpen(true)} disabled={readOnly || isMembersLimitExceeded()}>
            {t('Add users')}
          </Button>
        )}
      </ActionContainer>
    );
  };
  const renderSearchBarContainer = () => {
    return (
      <SearchBar
        id="serviceUserSearchBar"
        placeholder={t('SEARCH_BY_NAME_OR_EMAIL')}
        setSearchTerm={(q) => setQuery(q)}
        enterSearch={() => {}}
        removeSearch={() => clearSearch()}
        searchTerm={query}
      />
    );
  };

  const showLoader = service?.instanceLimitType == InstanceLimitType.Session && sessions == undefined;

  return (
    <>
      {showLoader && <LoadingIndicator />}
      {!showLoader && (
        <>
          <InviteUsersToOrgServiceModal
            isModalOpen={addUsersModalOpen}
            closeModal={() => setAddUsersModalOpen(false)}
            organization={organization}
            existsInSF={existsInSF}
            organizationServiceId={organizationService?.id || ''}
            getOrganizationService={getOrganizationService}
            organizationService={organizationService}
            service={service}
            roles={serviceRoles ?? []}
            invitationLimit={subscription?.maxSubscriptionInstances && !hasSessionLimit ? subscription!.maxSubscriptionInstances - getMembersCount() : undefined}
          />
          <EditServiceMemberModal
            orgId={organization?.id!}
            isModalOpen={memberToBeEdited !== null}
            closeModal={() => setMemberToBeEdited(null)}
            memberId={memberToBeEdited?.id || ''}
            memberName={
              memberToBeEdited?.firstName && memberToBeEdited?.lastName
                ? `${memberToBeEdited?.firstName} ${memberToBeEdited?.lastName}`
                : memberToBeEdited?.firstName && !memberToBeEdited?.lastName
                ? `${memberToBeEdited?.firstName}`
                : !memberToBeEdited?.firstName && memberToBeEdited?.lastName
                ? `${memberToBeEdited?.lastName}`
                : null
            }
            memberEmail={memberToBeEdited?.email}
            memberRole={memberToBeEdited ? getRoleForUser(memberToBeEdited) : ''}
            serviceId={organizationService?.id || ''}
            getOrganizationService={getOrganizationService}
            invitationCode={memberToBeEdited?.invitationCode}
            serviceRoles={serviceRoles}
          />

          <RemoveServiceMemberModal
            orgId={organization?.id!}
            organizationServiceId={organizationService?.id || ''}
            memberId={memberToBeRemoved?.id || ''}
            memberEmail={memberToBeRemoved?.email}
            memberRole={memberToBeRemoved ? getRoleForUser(memberToBeRemoved) : ''}
            getOrganizationService={getOrganizationService}
            isModalOpen={memberToBeRemoved !== null}
            closeModal={() => setMemberToBeRemoved(null)}
            invitationCode={memberToBeRemoved?.invitationCode}
          />
          <ConfirmationModal
            visible={memberToResendInvitation != null}
            cancel={() => setMemberToResendInvitation(null)}
            confirm={resendInvitation}
            modalSize={isMediumScreen ? Size.Medium : Size.Small}
            confirmTitle={t('Yes, confirm')}
            cancelTitle={t('No, cancel')}
            modalTitle={t('Confirm resending invitation')}
            confirmButtonVariant="primary">
            <b>{memberToResendInvitation?.email}</b>
            <p>{t('Do you confirm resending invitation email?')}</p>
          </ConfirmationModal>
          <Wrapper>
            {/* Render Action Bar */}
            {renderActionContainer()}
            {/* Render Search Bar */}
            {service?.id?.toLowerCase() != process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase() &&
                <>
                    {renderSearchBarContainer()}
                    <UserList baseUrl={url} members={members} searchQuery={query} getDropdownList={getDropdownListForMember} service={service} serviceRoles={serviceRoles} />
                </>
            }
            {service?.id?.toLowerCase() == process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase() &&
                <Banner type="neutral" icon={<SystemIcons.Information size="24px" />} size={Size.Small}>
                    Everyone at this organization has basic access to Knowledge Hub content
                </Banner>
            }
          </Wrapper>
        </>
      )}
    </>
  );
};

export default OrgUserAccessPage;
