/**
 * Import React libraries.
 */
import React, {useEffect, useState} from 'react';

/**
 * Import third-party libraries.
 */
import {Banner, Button, COLORS, LoadingIndicator, Size, SystemIcons,} from '@laerdal/life-react-components';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import CancelSubscriptionModal from '../modals/CancelSubscriptionModal';

/**
 * Import custom libraries.
 */
import {
  Organization,
  OrganizationService,
  PaymentType,
  Service,
  Subscription,
  SubscriptionStatus,
  SubscriptionUpdate, VALID_SUBSCRIPTION_STATUSES,
} from '../../../types';

/**
 * Import custom utils.
 */
import Api from '../../../utils/api';
import {dateOnlyMidnightUtc} from '../../../utils/functions';
import {ErrorToastOptions, SuccessToastOptions} from '../../../constants';
import {useNavigate} from 'react-router';
import {useToastContext, useUserContext} from '../../../userContext';
import {FormProvider, useForm} from 'react-hook-form';
import {BillingDetails, E_COURSES_FREE_PLAN, EditLicenseForm, SubscriptionDurations,} from './Common/types';
import {yupResolver} from '@hookform/resolvers/yup';
import {editLicenseSchema} from './Common/validations';
import {SubscriptionActionBlock} from "./Components/SubscriptionActionBlock";
import {GeneralDetailsGroup} from "./Components/EditForm/GeneralDetailsGroup";
import {SubscriptionOptionsGroup} from "./Components/EditForm/SubscriptionOptionsGroup";
import {BillingDetailsGroup} from "./Components/EditForm/BillingDetailsGroup";

/**
 * Add custom styling.
 */
const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.neutral_100};
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

interface OrgLicenseDetailsPage {
  organizationService: OrganizationService | undefined;
  organization: Organization | undefined;
  service: Service;
  subscription?: Subscription;
  setSubscription: (subscription: Subscription | undefined) => void;
}

const OrgLicenseDetailsPage = ({
                                 organizationService,
                                 organization,
                                 subscription,
                                 service,
                                 setSubscription
                               }: OrgLicenseDetailsPage) => {
  const {t} = useTranslation('OrganizationServices');
  const navigate = useNavigate();
  const {addToast} = useToastContext();
  const {selfServiceServices, countryConfigurations } = useUserContext();

  const [loading, setLoading] = useState<boolean>(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [isSelfService, setIsSelfService] = useState<boolean>();
  const [isSalesforce, setIsSalesforce] = useState<boolean>();
  const [billingDetails, setBillingDetails] = useState<BillingDetails>();

  const form = useForm<EditLicenseForm>({
    resolver: yupResolver(editLicenseSchema),
    defaultValues: subscription,
    context: {
      isSelfService: isSelfService,
      serviceId: service?.id,
    },
  });
  const {handleSubmit, watch, reset, formState} = form;

  const isTrial = watch('isTrial');
  const plan = watch('plan');

  const resetForm = (subscription?: Subscription) => {
    if (!subscription) {
      if (service.id.toLowerCase() == process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase()) {
        reset({
          plan: E_COURSES_FREE_PLAN,
        });
      }
      return;
    }
    const plan = service.availablePlans?.find((p) => p.id === subscription.plan?.id);
    const tier = plan?.tiers?.find((t) => t.id === subscription.tier?.id);
    const startDate = new Date(subscription.startDate).getTime() > 0 ? subscription.startDate : (subscription.createdDate as Date);
    const expirationDate =
      !!subscription.expirationDate
        ? new Date(subscription.expirationDate)
        : undefined;

    reset({
      ...subscription,
      isTrial: subscription.status === SubscriptionStatus.Trial || subscription.status === SubscriptionStatus.DraftTrial,
      plan: plan,
      tier: tier,
      startDate: moment(startDate).toDate(),
      expirationDate: expirationDate,
      duration: SubscriptionDurations[4].value,
    });
  };

  useEffect(() => {
    if (!organization || !organizationService) return;

    const abort = new AbortController();

    if (subscription?.subscriptionNumber || subscription?.paymentType == PaymentType.Paddle) {
      setIsSelfService(!!subscription?.subscriptionNumber || subscription?.paymentType == PaymentType.Paddle);
      setIsSalesforce(!!subscription?.subscriptionNumber);
      setBillingDetails({
          billingOrganizationName: organization?.billingOrganizationName ?? '',
          billingContactPerson: organization?.billingContactPerson ?? '',
          billingEmailAddress: organization?.billingEmailAddress ?? '',
          vatNumber: organization?.vatNumber ?? '',
        });
      setLoaded(true);

    } else {
      const country = organization?.address?.country?.codeAlpha2;

      setBillingDetails({
        billingOrganizationName: organization?.billingOrganizationName ?? '',
        billingContactPerson: organization?.billingContactPerson ?? '',
        billingEmailAddress: organization?.billingEmailAddress ?? '',
        vatNumber: organization?.vatNumber ?? '',
      });
      setIsSalesforce(countryConfigurations[country]?.useSalesforce)
      setIsSelfService(selfServiceServices.includes(organizationService.serviceId))
      setLoaded(true);
    }
    return () => abort.abort()
  }, [service, organization, organizationService, subscription, selfServiceServices, countryConfigurations]);


  useEffect(() => resetForm(subscription), [subscription]);

  const submitForm = (value: EditLicenseForm) => {
    if (!formState.isDirty) {
      return;
    }

    // Submit the form
    setLoading(true);

    // Let's create subscription object
    const newSubscription: SubscriptionUpdate = {
      startDate: dateOnlyMidnightUtc(value.startDate)!,
      expirationDate: value.tier!.enableUnlimitedDuration ? undefined : dateOnlyMidnightUtc(value.expirationDate)!,
      poNumber: value.poNumber,

      autoRenew: value.autoRenew ? value.autoRenew : false,
      renewalPeriodInMonths: value.autoRenew ? value.renewalPeriodInMonths : 0,

      status:
        value.startDate > new Date()
          ? value.isTrial
            ? SubscriptionStatus.DraftTrial
            : SubscriptionStatus.Draft
          : value.isTrial
            ? SubscriptionStatus.Trial
            : SubscriptionStatus.Active,

      paymentType: PaymentType.Invoice,

      billingInterval: undefined,

      organizationServiceId: organizationService!.id,
      planId: value.plan!.id,
      planTierId: value.tier!.id,
      isActive: true,
      meteorDomain: '',
    };
    // Set max subscription instances if tier allows it
    if (value.maxSubscriptionInstances && value.tier!.individualLicensing) {
      newSubscription.maxSubscriptionInstances = value.maxSubscriptionInstances;
    }

    // Let's make sure we show correctly trial banner
    if (!value.isTrial && subscription?.status == SubscriptionStatus.Trial) {
      value.trialExpirationDate = new Date();
    }

    if (!subscription) {
      Api.CreateOrganizationServiceSubscription(organization?.id!, organizationService!.id, newSubscription)
        .then((data) => {
          reset(value);

          setSubscription({...subscription!, ...value, id: data.id});

          addToast(t('Organization service subscription created.'), SuccessToastOptions);
        })
        .catch(() => {
          addToast(t('Organization service subscription create failed.'), ErrorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Api.UpdateOrganizationServiceSubscription(organization?.id!, subscription!.id, newSubscription)
        .then(() => {
          reset(value);

          setSubscription({...subscription!, ...value, ...newSubscription});

          addToast(t('Organization service subscription updated.'), SuccessToastOptions);
        })
        .catch(() => {
          addToast(t('Organization service subscription update failed.'), ErrorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const deleteSubscription = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    if (subscription?.id && organization?.id) {
      setLoading(true);

      // Let's delete the subscription
      Api.DeleteSubscription(organization?.id, subscription.id)
        .then(() => {
          addToast(t('Organization subscription deleted successfully.'), SuccessToastOptions);
          setSubscription(undefined);
        })
        .catch(() => {
          addToast(t('There was an error. Subscription not deleted.'), ErrorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const showForm = subscription?.isActive || process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase() == service.id.toLowerCase();

  const disableSave = !formState.isDirty || isSelfService && !isTrial;

  return (
    <>
      <CancelSubscriptionModal
        visible={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onSubmitted={() => {
          navigate(`/organization/${organization?.id}/license-management/`);
        }}
        subscription={subscription}
        organization={organization}
        organizationService={organizationService}
        service={service}
      />
      {!loaded && <LoadingIndicator/>}
      {loaded && (
        <FormProvider {...form}>
          <StyledForm onSubmit={plan == E_COURSES_FREE_PLAN ? deleteSubscription : handleSubmit(submitForm)}>
            {subscription?.status === SubscriptionStatus.Canceled && (
              <Banner size={Size.Small} type="warning">
                {t('Subscription cancelled on {{date}}', {date: moment(subscription?.cancellationDate).format('LL')})}
              </Banner>
            )}
            {subscription?.status === SubscriptionStatus.Deactivated && (
              <Banner
                size={Size.Small}
                type="neutral"
                icon={<SystemIcons.Information/>}
                linkText={t('Create new subscription')}
                link={`#`}
                linkAction={() =>
                  navigate(`/organization/${organization?.id}/license-management/new/${service.id}?subscriptionId=${subscription?.id}&orgServiceId=${organizationService?.id}`)
                }>
                {t('Subscription deactivated on {{date}}', {date: moment(subscription?.deactivationDate).format('LL')})}
                <br/>
                {t('To restore access')}
              </Banner>
            )}

            {
              !!subscription?.subscriptionNumber &&
              <SubscriptionActionBlock title={t('Manage in Salesforce')}
                                       description={t('Use Salesforce to manage or cancel this subscription.')}/>
            }

            <GeneralDetailsGroup service={service}
                                 organization={organization!}
                                 organizationService={organizationService!}/>
            {
              showForm &&
              <SubscriptionOptionsGroup service={service}
                                        subscription={subscription!}
                                        isSalesforce={!!isSalesforce}
                                        isSelfService={!!isSelfService}/>
            }
            {
              showForm &&
              <BillingDetailsGroup billingDetails={billingDetails!}
                                   subscription={subscription!}
                                   isSalesforce={!!isSalesforce}
                                   isSelfService={isSelfService!}/>
            }

            {
              (subscription?.subscriptionNumber || subscription?.paymentType == PaymentType.Paddle) &&
              VALID_SUBSCRIPTION_STATUSES.includes(subscription.status!) &&
              subscription.status !== SubscriptionStatus.Canceled &&
              <SubscriptionActionBlock title={t('Cancel subscription')}
                                       description={t('If you choose to cancel this subscription, then the customer will still have access until the end of the current subscription term.')}
                                       banner={
                                         !!subscription?.subscriptionNumber
                                           ? <Banner type={'neutral'}
                                                     size={Size.Small}
                                                     icon={<SystemIcons.Information/>}>{
                                             t('Use Salesforce to cancel this subscription.')}
                                           </Banner>
                                           : undefined
                                       }
                                       action={
                                         <Button variant={'critical'}
                                                 width={'max-content'}
                                                 onClick={() => setIsDeleteModalVisible(true)}
                                                 disabled={!!subscription?.subscriptionNumber}>
                                           {t('Cancel subscription')}
                                         </Button>}
              />
            }

            <SeparatorLine/>

            {/* Render Action Buttons */}
            <ActionsContainer>
              <Button
                id="cancelBtn"
                variant="tertiary"
                size={Size.Large}
                onClick={() => {
                  resetForm();
                  navigate(`/organization/${organization?.id}/license-management/`);
                }}>
                {t('Cancel')}
              </Button>
              <Button id="saveChangesBtn"
                      type="submit"
                      size={Size.Large}
                      loading={loading}
                      disabled={disableSave}>
                {t('Save changes')}
              </Button>
            </ActionsContainer>
          </StyledForm>
        </FormProvider>
      )}
    </>
  );
};

export default OrgLicenseDetailsPage;
