/**
 * Import React libraries.
 */
import React from 'react';

/**
 * Import third-party libraries.
 */
import {COLORS, ComponentLStyling, ComponentTextStyle} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

/**
 * Import custom types.
 */
import {Service} from '../../../types';

/**
 * Add custom styles.
 */


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.black)}
  
  div {
    display: flex;
    flex-direction: column;
  }
`;

const Label = styled.span`
  color: ${COLORS.neutral_600};
`;

/**
 * Add custom types.
 */
interface GeneralDetailsProps {
  service: Service;
}

const ServiceGeneralDetailsPage = ({service}: GeneralDetailsProps) => {
  // Globally used variables within the page
  const {t} = useTranslation('Services');

  return (
    <Wrapper>
      <h6>{t('General details')}</h6>

      <div>
        <Label>{t('Official name')}</Label>
        <span>{service.name}</span>
      </div>
    </Wrapper>
  );
};

export default ServiceGeneralDetailsPage;
