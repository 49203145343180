import { Dispatch, useRef, useState } from 'react';
import { Organization } from '../../../types';
import React from 'react';
import { OrganizationMemberType } from './UserManagementList';
import { ComponentS, ComponentSStyling, ComponentTextStyle, ContentAccordion, ContentAccordionItem, ContentAccordionItemContent, ContentAccordionWrapper } from '@laerdal/life-react-components';
import UserManagementUserLine, { UserManagementListProps } from './UserManagementUserLine';
import { COLORS, IconButton, StyledTableFooter, SystemIcons, Table } from '@laerdal/life-react-components';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

/**
 * Create custom types.
 */
export type Props = {
  searchQuery?: string;
  title: string;
  header: string;
  members: OrganizationMemberType[];
  rowsPerPage: number;
  managementListProps: Omit<UserManagementListProps, 'member'>;
  initialState: boolean;
};

const NavigationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AccordionHeader = styled.div`
  padding: 0px 16px 8px 16px;
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
`;

const Container = styled.div`
  ${ContentAccordionItemContent} {
    padding: 0px 0px !important;
  }

  ${ContentAccordionItem} {
    border: 1px solid ${COLORS.neutral_200};
    border-radius: 4px;
  }
`;

const UserManagementMembershipCategoryAccordion = ({ managementListProps, members, title, header, rowsPerPage = 10, initialState }: Props) => {
  
  const queryParamName = `${title}_page`;
  const [searchParams, setSearchParams] = useSearchParams();
  const [curPage, setCurPage] = useState<number>(parseInt(searchParams.get(queryParamName) ?? '1'));
  const [touched, setTouched] = useState(false);
  const ref = useRef();

  const from = (curPage - 1) * rowsPerPage;
  const to = curPage * rowsPerPage;
  const total = members.length;  

  const prevPage = () => {
    setCurPage(curPage - 1);
    setSearchParams(params => {
      params.set(`${title}_page`, (curPage - 1) + '');
      return params;
    });
  };
  const nextPage = () => 
  {
    setCurPage(curPage + 1);
    setSearchParams(params => {
      params.set(`${title}_page`, (curPage + 1) + '');
      return params;
    });
  }

  return (
    <Container>
      <ContentAccordion
        onItemStateChanged={() => setTouched(true)}
        items={[
          {
            id: `accordion_item_${title}`,
            title: `${title} (${members?.length ?? 0})`,
            disabled: members?.length == 0,
            active: !!members?.length && (!!searchParams.get(queryParamName) || (initialState && !touched)),
            body: (
              <div>
                <AccordionHeader>{header}</AccordionHeader>
                {members.slice(from, to).map((member: OrganizationMemberType, index: number) => (
                  <UserManagementUserLine {...managementListProps} key={index} member={member} showDropdown={true} />
                ))}
                <NavigationContainer>
                  <ComponentS color={COLORS.neutral_600}>
                    {total === 0 ? 0 : from + 1}-{to} of {total}
                  </ComponentS>
                  <IconButton variant="secondary" shape="circular" disabled={from === 0} action={prevPage}>
                    <SystemIcons.ChevronLeft size="24" color={COLORS.neutral_600} />
                  </IconButton>
                  <IconButton variant="secondary" shape="circular" disabled={to >= members.length} action={nextPage}>
                    <SystemIcons.ChevronRight size="24" color={COLORS.neutral_600} />
                  </IconButton>
                </NavigationContainer>
              </div>
            ),
          },
        ]}
      />
    </Container>
  );
};

export default UserManagementMembershipCategoryAccordion;
