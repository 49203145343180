/**
 * Import React libraries.
 */
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';

/**
 * Import third-party libraries.
 */
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {DropdownItem, ListRow, Paginator, Size, SystemIcons} from '@laerdal/life-react-components';

/**
 * import custom types.
 */
import {InstanceLimitType, Service, ServiceRole, User} from '../types';
import moment from 'moment';

/**
 * Create custom styles.
 */
const Wrapper = styled.div`
  nav {
    width: 100% !important;
  }
`;

export interface UserOption {
  user: User;
  lastSeen?: Date;
  serviceRole: ServiceRole | undefined;
  setDropdownActive: () => void;
  setMemberToBeEdited: (user: User) => void;
  setMemberToBeRemoved: (user: User) => void;
  setMemberToResendInvitation: (user: User) => void;
}

/**
 * Create custom types.
 */
type Props = {
  searchQuery?: string;
  members: UserOption[];
  baseUrl: string;
  getDropdownList: (option: UserOption) => DropdownItem[];
  serviceRoles?: ServiceRole[];
  service?: Service
};

type Params = {
  page?: string;
  invite?: string;
};

const UserManagementList = ({searchQuery, members, baseUrl, getDropdownList, serviceRoles, service}: Props) => {
  // Globally used variables within the component
  const [memberList, setMemberList] = useState<UserOption[]>(members);

  const {t} = useTranslation('Organization');
  const navigate = useNavigate();

  const hasSessionLimit = service?.instanceLimitType == InstanceLimitType.Session;

  const resultsPerPage = 10;

  const match = useParams<Params>();
  let page = Number(match.page) || 1;

  const [from, setFrom] = useState<number>(0);
  const [to, setTo] = useState<number>(0);

  /**
   * Initializes the pagination for the list.
   */
  useEffect(() => {
    setFrom((page - 1) * resultsPerPage);
    setTo((page - 1) * resultsPerPage + resultsPerPage);
  }, [page]);

  useEffect(() => {
    setMemberList(filterMembers(members));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, members]);

  /**
   * Filters member list based on the search query.
   * @param members - Member list which needs to be filtered.
   * @returns Filtered member list.
   */
  const filterMembers = (members: UserOption[]): UserOption[] => {
    // Let's check if we have provided search query.
    if (!searchQuery) {
      return members;
    }

    // Return filtered members
    // Search by first name, last name and email
    // If user is not found - don't return
    return members.filter((member: UserOption) => {
      if (`${member.user.firstName.toLowerCase()} ${member.user.lastName.toLowerCase()} `.indexOf(searchQuery.toLowerCase()) !== -1) {
        // Search by first and last name
        return true;
      } else if (member.user.email.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
        // Search by email
        return true;
      } else {
        return false;
      }
    });
  };

  /**
   * Does a specific action on dropdown item selection.
   * @param id - ID of the selected item from the dropdown.
   * @param member - Indicating the member for which action needs to be executed.
   */
  const onDropdownAction = (id: string, member: UserOption) => {
    // Let's find the correct action
    if (id === 'view-profile') {
      // Navigate to users profile
      navigate(`/user/${member?.user?.id}`);
    } else if (id === 'edit-access') {
      // It is change permissions, let's show modal
      member.setMemberToBeEdited(member?.user);
    } else if (id === 'resend-invitation') {
      // If invitation is expired, resend invitation
      member.setMemberToResendInvitation(member?.user);
    } else {
      // It is remove from org action, let's execute it
      member.setMemberToBeRemoved(member?.user);
    }
  };

  const dropdownList = (member: UserOption) => {
    let options = getDropdownList(member);
    return {
      items: options,
      onClick: (ids: string[]) => ids?.length && onDropdownAction(ids[0], member),
    };
  };

  const getTooltip = (role: ServiceRole | undefined) => {
    const foundRole = serviceRoles?.find((r) => r.id === role?.id);
    if (foundRole?.parentRoleName === 'Service Owner') {
      return t(
        'Can edit any license details and control access to service');
    }

    if (foundRole?.parentRoleName === 'Service Admin') {
      return t('Can add and remove others to this service/license');
    }

    if (foundRole?.parentRoleName === 'Service User') {
      return t('Standard access to this service.');
    } else {
      return undefined;
    }
  };

  return (
    <Wrapper>
      {memberList.slice(from, to).map((member: UserOption, index: number) => {
        let secondaryText = (hasSessionLimit
          ? member.lastSeen && (t('Last online') + ': ' + moment(member.lastSeen).format('ll')) || t('Offline')
          : !!member?.user?.firstName || !!member?.user?.lastName ? member?.user?.email : undefined);

        if (secondaryText) 
          secondaryText = secondaryText + (member?.user?.identityId ? ' (Registered)' : '')

        let mainText =  member?.user?.email === ''
        ? `${member?.user?.firstName} ${member?.user?.lastName} ${t('(You)')}`
        : !member?.user?.firstName && !member?.user?.lastName
          ? `${member?.user?.email}` + (member?.user?.identityId ? ' (Registered)' : '')
          : `${member?.user?.firstName} ${member?.user?.lastName}`;

        return <ListRow
          key={index}
          icon={member?.user?.invitationCode ? <SystemIcons.Mail/> : <SystemIcons.User/>}
          size={Size.Large}
          mainText={mainText}
          secondaryText={secondaryText}
          note={member?.serviceRole
                ? (member?.serviceRole.name !== 'User' ? member?.serviceRole.name : undefined)
                : undefined}
          noteTooltip={getTooltip(member?.serviceRole)}
          dropdown={dropdownList(member)}
        />
      })}

      <Paginator pageCount={Math.ceil(members.length / resultsPerPage)} currentPage={page} baseUrl={baseUrl}/>
    </Wrapper>
  );
};

export default UserManagementList;
