import { FieldValues } from 'react-hook-form';

export interface WidgetData {
  byId: {
    [id: string]: any;
  };
  allIds: string[];
  initialized: boolean;
}

export interface Product {
  id: string;
  name: string;
  type: string;
  image: string;
}

export interface Recipient {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface AccountsGetJwtData {
  apiVersion: string;
  id_token: string;
  operation: string;
  requestParams: object;
  errorCode: number;
  errorMessage: string;
  callId: string;
  context: object;
}

export interface Jwt {
  apiKey: string;
  iss: string;
  iat: number;
  exp: number;
  sub: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface Product {
  family: string;
  group: string;
  line: string;
  category: string;
  sku: string;
  skuName: string;
  assets: Asset[];
  productLinks: ProductLinks;
}

export interface ProductLinks {
  found: boolean;
  imageCDN: string;
  imageHosted: string;
  productSKUNiceName: string;
  productSKUNiceNameEN: string;
  productSKUShopURL: string;
  productSystemName: string;
  productMarketingURL: string;
  productShopURL: string;
  productDownloadsURL: string;
  productKnowledgebaseURL: string;
  productShopAccessoriesURL: string;
}

export interface Asset {
  sku: string;
  serialNumber: string;
  assetLink: string;
  purchaseDate: string;
  installDate: string;
  warrantExpirationDate: string;
  status: string;
  orderLink: string;
  virtualSerialNumber: string;
}

export interface Language {
  language: string;
  label: string;
}

export interface LanguageDto {
  id: string;
  name: string;
  locale: string;
}

export interface Country {
  language: string;
  label: string;
  ISO: string;
  id: string;
  name: string;
}

export interface LocalSegment {
  localSegment: string;
  description: string;
  countryCode: string;
  corporateSegment: string;
}

export interface CountryDto {
  name: string;
  codeAlpha2: string;
  codeAlpha3?: string;
  states: State[];
  hasAccess?: boolean;
}

export interface UpdateToSDto {
  name: string;
  salesOrganizationCode: string;
}

export interface CreateTermsOfServiceDto {
  serviceId: string;
  name: string;
  default: boolean;

  salesOrgCode: string;

  effectiveAt: Date;
  gracePeriodDurationInDays: number;
  majorVersion: number;
  minorVersion: number;
  reconsent: boolean;

  files: ToSVersionFile[];
}

export interface SalesOrgDto {
  name: string;
  code: string;
}

export interface State {
  name: string;
  code: string;
}

export interface UserRecordWrapper {
  totalCount: number;
  records: User[];
}

export interface CompanyRole {
  name: string;
}

export interface User {
  id: string;
  email: string;
  identityId: string;
  firstName: string;
  lastName: string;
  portalAccess?: boolean;
  hasOrganizationInvitations?: boolean;
  currentOrganization: Organization | null;
  organizations: Organization[];
  contactOrganizations?: Organization[];
  services: Service[];
  isProfilingComplete?: boolean;
  isInvited?: boolean;
  invitationCode?: string;
  userCompanyRoles?: CompanyRole[];
  hasGigyaAccount?: boolean;
  isVerified?: boolean;
  state?: UserStates;
  invitations?: { invitation: Invitation; organization: Organization }[];
  switchOrganizationEnabled?: boolean;
}

export interface UserProfile {
  lscMedicalSpecialities: string[];
  lscNursingSpecialities: string[];
  lscNursingCourses: string[];
  lscPatientType: string[];
  lscSimulators: string[];
  switchOrganizationEnabled: boolean;
}

export interface UpdateUser {
  uid: string;
  email?: string;
  firstName: string;
  lastName: string;
}

export enum Industry {
  Healthcare = 0,
  EducationalProvider = 1,
  EmergencyMedicalService = 2,
  GovFederalAndMilitary = 3,
  Other = 4,
}

export interface Address {
  companyName?: string;
  additionalName?: string;

  addressLine?: string;
  addressLineTwo?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  country: CountryDto;

  phoneNumber?: string;
  email?: string;
}

export interface OrganizationRole {
  id: string | undefined;
  parentRoleId?: string;
  name: string;
  description?: string;
}

export interface OrganizationMember {
  id: string;
  organizationId: string;
  organization: string;
  permissions?: PermissionToMember[];
  user: User;
  current: boolean;
  isProfilingComplete: boolean;
  invitationCode?: string;
  created: Date;
}

export interface PermissionToMember {
  organizationMemberId: string;
  permissionId: string;
}

export interface Permission {
  id: string;
  name: string;
  description: string;
  section: string;
  inSectionDisplayOrder: number;
  order?: number;
}

export interface OrganizationRecordWrapper {
  totalCount: number;
  records: Organization[];
}

export interface CreateOrganization extends FieldValues {
  id?: string;
  customerNo?: string;
  organizationCode?: string;
  name: string;
  industry?: Industry;
  address: Address;
  billingOrganizationName: string;
  vatNumber?: string;
  isOnboarded: boolean;
  billingContactPersonLastName: string;
  billingContactPersonFirstName: string;
  billingEmailAddress: string;
  useOrganizationAddressForBilling: boolean;
  billingAddress?: Address;
}

export interface OrganizationContact {
  id: string;
  organizationId: string;
  userId: string;
  user: User;
}

export enum OrganizationType {
  Organization,
  Individual,
}

export interface Organization extends FieldValues {
  id: string;
  parent?: Organization;
  customerNo: string;
  organizationCode?: string;
  name: string;
  logoUrl?: string;
  industry?: Industry;
  address: Address;
  memberCount?: number;
  isOnboarded?: boolean;
  defaultLanguage: string;
  billingOrganizationName: string;
  vatNumber: string;
  billingContactPersonLastName: string;
  billingContactPersonFirstName: string;
  billingEmailAddress: string;
  useOrganizationAddressForBilling: boolean;
  billingAddress: Address;
  members: OrganizationMember[];
  contacts?: OrganizationContact[];
  invitations: Invitation[];
  services?: OrganizationService[];
  hasAccess?: boolean;
  isFederatedSsoEnabled?: boolean;
  idpName?: string;
  type: OrganizationType;
  sfCorporateSegment?: string;
  sfLocalSegment?: string;

  internalAccount?: boolean;
  allowProducts: boolean;
  allowOrders: boolean;
  allowServiceAppointments: boolean;
}

export interface Invitation {
  id: string;
  email: string;
  organization: Organization;
  permissions: string[];
  inviter?: string;
  createdDate: Date;
  createdBy?: string;
  acceptedDate?: Date;
  createSFContact?: boolean;
  isActive: boolean;
  code: string;
  role?: OrganizationRole;
  firstName?: string;
  lastName?: string;
}

export interface InvitationResponse {
  email: string;
  isSuccessful: boolean;
  errorMessage: string;
  invitation: Invitation;
}

export interface AddOrgMemberResponse {
  id: string;
  permissions: PermissionToMember[];
  current: boolean;
  created: Date;
}

export interface Service {
  id: string;
  serviceId?: string;
  name: string;
  serviceUrl: string;
  iconUrl?: string;
  alwaysPresent: boolean;
  alwaysListInSwitcher?: boolean;
  switcherDisplayName?: string;
  serviceRoles?: ServiceRole[];
  availablePlans?: ServicePlan[];
  activeLicenceCount?: number;
  activeUserCount?: number;
  totalInstancesCount?: number;
  invitedUserCount?: number;
  allowImpersonation: boolean;
  impersonationServiceId?: string;
  impersonationFullfilmentUrl: string;
  canAddFromBackoffice: boolean;
  instanceLimitType: InstanceLimitType;
}

export interface ServicePlan {
  id: string;
  name: string;
  tiers: ServicePlanTier[];
  allowTrial: boolean;
  enableUnlimitedDuration: boolean;
  defaultTrialDuration: number;
  subBrandName: string;
  partNumber: string;
}

export interface ServicePlanTier {
  id: string;
  name: string;
  allowTrial: boolean;
  enableUnlimitedDuration: boolean;
  defaultTrialDuration: number;
  maxSubscriptionInstances: number;
  individualLicensing?: boolean;
  prices: ServicePlanTierPrice[];
  autoUpgradeTrial: boolean;
  autoStartTrial: boolean;
}

export interface ServicePlanTierPrice {
  id: string;
  currency: string;
  basePrice: number;
  unitPrice: number;
}

export interface ServiceRole {
  id: string | undefined;
  name: string;
  description: string;
  parentRoleId?: string;
  parentRoleName?: string;
  parentRoleDescription?: string;
}

export interface OrganizationInvitation {
  code: string;
  role?: OrganizationRole;
  email: string;
  isActive: boolean;
  acceptedDate?: Date;
  inviter: string;
  organizationName: string;
  hasAccount: boolean;
}

export enum InvitationStatus {
  ACTIVE = 'active',
  PENDING_AUTHENTICATION = 'pending authentication',
  COMPLETE = 'complete',
}

export interface Role {
  id?: string;
  name: string;
  selected: boolean;
  isPredefined: boolean;
}

export interface AddOrganizationMemberInvitation {
  emails: string[];
  sendEmail: boolean;
  acceptUrl: string;
}

export interface AddOrganizationServiceMemberInvitation {
  emails: string[];
  role: ServiceRole | undefined;
  sendEmail: boolean;
  acceptUrl?: string;
  createSFContact: boolean;
}

export interface OrganizationService {
  id: string;
  serviceId: string;
  name: string;
  serviceUrl: string;
  members: OrganizationServiceMember[];
  subscription: Subscription;
  invitations?: Invitation[];
  meteorDomain: string;
  alwaysPresent: boolean;
  hasPreapprovedCredit?: boolean;
  onAccountPayment: AccountPaymentType;
  commerceMarket: MarketDTO;
  isPartner: boolean;
}

export interface OrganizationServiceMember {
  id?: string;
  created?: Date;
  user: User;
  role?: ServiceRole;
}

export interface Subscription {
  id: string;
  poNumber?: string;
  subscriptionNumber?: string;

  plan: ServicePlan;
  tier: ServicePlanTier;
  status: SubscriptionStatus;
  billingInterval?: BillingInterval;
  paymentType?: PaymentType;

  createdDate?: Date;
  updatedDate?: Date;

  startDate: Date;
  expirationDate?: Date;
  nextBillingDate?: Date;

  trialExpirationDate?: Date;
  deactivationDate?: Date;
  cancellationDate?: Date;
  actualExpiredDate?: Date;

  gracePeriodInDays?: number;

  isActive: boolean;

  autoRenew?: boolean;
  renewalPeriodInMonths?: number;

  maxSubscriptionInstances?: number;
}

export interface SubscriptionCreation {
  startDate: Date;
  expirationDate?: Date;
  poNumber?: string;

  autoRenew: boolean;
  renewalPeriodInMonths: number;

  status: SubscriptionStatus;
  billingInterval?: BillingInterval;
  paymentType?: PaymentType;

  organizationServiceId: string;

  planId: string;
  planTierId: string;

  maxSubscriptionInstances?: number;

  meteorDomain: string;
}

export interface SubscriptionUpdate extends SubscriptionCreation {
  isActive: boolean;
}

export enum AuditLogEventType {
  OrgServiceAdded,
  OrgServiceEdited,
  OrgServiceDeleted,
  OrgMemberAdded,
  OrgMemberEdited,
  OrgMemberDeleted,
  OrgMemberPermissionsAdded,
  OrgMemberPermissionsEdited,
  OrgMemberPermissionsDeleted,
  OrgServiceMemberAdded,
  OrgServiceMemberEdited,
  OrgServiceMemberDeleted,
  OrgInvitationAdded,
  OrgInvitationRevoked,
  OrgInvitationAccepted,
  OrgInvitationResent,
  OrgInvitationEdited,
  OrgServiceInvitationAdded,
  OrgServiceInvitationRevoked,
  OrgServiceInvitationAccepted,
  SubscriptionAdded,
  SubscriptionEdited,
  SubscriptionDeleted,
  OrganizationUpdated,
  OrganizationAdded,
  OrganizationDeleted,
}

export interface AuditLogEvent {
  occuredAt: Date;
  action: string;
  message: string;
  appName: string;
  requesterName: string;
  requesterType: string;
  initiatedBy: string;
  originalValue: string;
  onEntityDelete: boolean;
  onEntityCreate: boolean;
  updatedValue: string;
  type: AuditLogEventType;
}

export interface MultiServiceUser {
  id?: string;
  identityId: string;
  email: string;
  firstName: string;
  lastName: string;
  memberDtos: ServiceMember[];
}

export class TermsOfServiceDocument {
  id: string = '';
  name: string = '';
  salesOrganizationCode: string = '';
  versions: ToSVersion[] = [];

  constructor(tosBase: TermsOfServiceDocument) {
    this.id = tosBase.id;
    this.name = tosBase.name;
    this.salesOrganizationCode = tosBase.salesOrganizationCode;
    this.versions = tosBase.versions;
  }

  getLatestToSVersion(): ToSVersion | null {
    let latestVersion = {} as ToSVersion | undefined;
    if (this.versions?.length ?? 0 > 0) {
      latestVersion = this.versions.sort(function (a, b) {
        return b.version * 10000 + b.minorVersion - (a.version * 10000 + a.minorVersion);
      })[0];
      return latestVersion;
    }
    return null;
  }
}

export interface ToSVersion {
  version: number;
  minorVersion: number;
  effectiveAt: Date;
  cutOffDate: Date;
  files: ToSVersionFile[];
}

export interface ToSVersionFile {
  blobName: string;
  locale: string;
  version: string;
  tosId: string;
  isFallback: boolean;
}

export interface ServiceMember {
  organizationServiceId: string;
  organizationName: string;
  organizationId: string;
  serviceRoleName: string;
  serviceRoleId: string;
}

export interface ServiceUserRecordWrapper {
  totalCount: number;
  records: MultiServiceUser[];
}

export interface ServiceOrganization {
  id: string;
  name: string;
  activeUsers: number;
  activeServiceSubscription: Subscription;
}

export interface ServiceOrganizationRecordsWrapper {
  totalCount: number;
  records: ServiceOrganization[];
}

export interface AuditLogEventsRecordsWrapper {
  totalCount: number;
  records: AuditLogEvent[];
}

export interface OrganizationInvitationResponse {
  email: string;
  isSuccessful: boolean;
  errorMessage: string;
  invitation: OrganizationInvitation;
}

export interface UpdateUserLocalizationDTO {
  uid: string;
  locale: string;
  country: string;
  additionalLanguages: string;
}

export interface ImpersonateUserRequestDTO {
  id?: string;
  requestOwnerEmail: string;
  impersonatedUserId: string;
  impersonatedUser?: User;
  originServiceId?: string;
  originService?: Service;
  targetServiceId?: string;
  targetService?: Service;
  impersonationServiceId?: string;
  impersonationService?: Service;
  created?: Date;
  isComplete?: boolean;
  isValid?: boolean;
  reason: string;
  details?: string;
}

export interface DomainValidationDTO {
  isValid: boolean;
  exists: boolean;
}

export interface ScenarioDTO {
  _id: string;
  name: string;
  smsNumber: string;
}

export interface ScenarioWhitelistItemDTO {
  id: string;
  idInSanity: string;
  scenarioId: string;
  type: number;
  name: string;
  smsNumber: string;
  validTo?: Date;
  validToDisplay: string;
}

/**
 * id - id of the whitelist item
 */
export interface ScenarioItemPatchDTO {
  id: string;
  type: number;
  validTo?: Date;
}

/**
 * id - id of the scenario that we want to add to the whitelist
 */
export interface ScenarioItemDTO {
  id: string;
  type: number;
  validTo?: string;
}

export interface UpdateOrganizationServiceDTO {
  onAccountPayment?: AccountPaymentType;
  commerceMarketCode?: string;
  isPartner?: boolean;
}

export interface MarketDTO {
  id: string;
  code: string;
  name: string;
}

export enum EntityDbEventType {
  Any = 'Any',
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

export enum AccountPaymentType {
  None,
  PreApproved,
  PrePayment,
}

export enum InstanceLimitType {
  User,
  Session,
  Role,
}

export interface SessionDTO {
  identityId: string;
  deviceId: string;
  subscriptionId: string;
  firstName: string;
  lastName: string;
  email: string;
  created: Date;
  updated: Date;
  state: SessionState;
  deviceInfo: string;
  softwareInfo: string;
}

export enum SessionState {
  Active,
  LogoutRequested,
}

export enum AccessLevel {
  None,
  ReadOnly,
  General,
  Full,
}

export interface OrganizationMergeInvitation {
  email: string;
  permissions?: string[];
}

export interface OrganizationMergeUser {
  userId: string;
  permissions?: string[];
}

export interface OrganizationMergeServiceInvitation {
  email: string;
  serviceRoleId?: string;
}

export interface OrganizationMergeServiceUser {
  userId: string;
  serviceRoleId?: string;
}

export interface OrganizationMergeService {
  serviceId: string;
  mergeUsers: OrganizationMergeServiceUser[];
  mergeInvitations: OrganizationMergeServiceInvitation[];
}

export interface OrganizationMergeRequest {
  sourceId: string;
  targetId: string;
  mergeUsers: OrganizationMergeUser[];
  mergeInvitations: OrganizationMergeInvitation[];
  mergeServices: OrganizationMergeService[];
}

export enum MergeErrorType {
  Error,
  Warning,
}

export interface OrganizationMergeError {
  type: MergeErrorType;
  message: string;
  code: MergeValidationErrorCodes;
  context: any;
}

export interface OrganizationMergeValidationResponse {
  success: boolean;
  errors: OrganizationMergeError[];
}

export type MergeValidationErrorCodes = 'LimitExceeded' | undefined;

export enum FeatureNames {
  OrganizationPermissions = 'OrganizationPermissions',
}

export interface Feature {
  name: FeatureNames;
  enabled: boolean;
}

export enum UserStates {
  Default,
  Registered,
  Contact,
  Unregistered,
}

export enum SubscriptionStatus {
  Draft,
  Active,
  Canceled,
  Expired,
  PastDue,
  DraftTrial,
  Trial,
  Deactivated,
}

export enum PaymentType {
  Invoice,
  Paddle,
  Adyen,
}

export enum BillingInterval {
  Monthly,
  Yearly,
  ThreeYear,
  FiveYear,
}

export const DRAFT_SUBSCRIPTION_STATUSES = [SubscriptionStatus.Draft, SubscriptionStatus.DraftTrial];

export const VALID_SUBSCRIPTION_STATUSES = [
  SubscriptionStatus.Active,
  SubscriptionStatus.Canceled,
  SubscriptionStatus.PastDue,
  SubscriptionStatus.Trial,
];

export interface CountryConfiguration{
  countryCode: string;
  countryName: string;
  useSalesforce: boolean;
  salesOrganization: string;
  currency: string;
}

export enum SubscriptionAmendmentType {
  None = 0,
  Upgrade = 1,
  Downgrade = 2,
  QuantityIncrease = 4,
  QuantityDecrease = 8,
  TermChange = 16,
  AutoRenewChange = 32
}

export interface SubscriptionPrice {
  currency: string;
  intervalSubtotal?: number;
  subtotal: number;
  intervalDiscount?: number;
  discount?: number;
  discountRate?: number;
  intervalTax?: number;
  tax: number;
  taxRate: number;
  intervalTotal?: number;
  total: number;
}

export interface SubscriptionBillingDetails {
  firstName: string;
  lastName: string;
  email: string;
}

export enum CardType {
  Visa,
  MasterCard,
  AmericanExpress,
  Maestro,
  Discover,
  DinersClub,
  Unknown = 99

}
export interface SubscriptionCard {
  type: CardType;
  lastFour?: string;
  expireMonth: number;
  expireYear: number;
  holderName?: string;
}

export interface SubscriptionAmendment {
  subscriptionIdentifier: string;
  amendmentDate: Date;
  planId: string;
  planTierId: string;
  maxSubscriptionInstances?: number;
  billingInterval: BillingInterval;
  duration: number;
  paymentType: PaymentType;
  amendments: SubscriptionAmendmentType;
  price: SubscriptionPrice;
}

export interface SubscriptionDetails {
  subscription: Subscription;
  amendment?: SubscriptionAmendment;
  price?: SubscriptionPrice;
  card?: SubscriptionCard;
  billingDetails: SubscriptionBillingDetails;
}

export interface CreateOrganizationAddress{
  companyName?: string;
  additionalName?: string;

  addressLine?: string;
  addressLineTwo?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  countryCode?: string;

  phoneNumber?: string;
  email?: string;
}
export interface CreateOrganizationForm {
  name: string;
  customerNo: string;
  industry?: Industry;
  taxExempt?: boolean;
  vatNumber: string;
  federalTaxId: string;
  sfCorporateSegment?: string;
  sfLocalSegment?: string;
  billingContactPersonLastName: string;
  billingContactPersonFirstName: string;
  billingEmailAddress: string;
  shippingAddressSameAsBilling: true,
  address: CreateOrganizationAddress
  shippingAddress: CreateOrganizationAddress
}
