import React from 'react';
import UnauthorizedMessage from './components/UnauthorizedMessage';
import { useUserContext } from './userContext';
import { AccessLevel } from './types';
import { useNavigate } from 'react-router';

const UnauthorizedPage = () => {

    const {
        accessLevel,
      } = useUserContext();
      const navigate = useNavigate();

      React.useEffect(() => {
        if(accessLevel != AccessLevel.None)
            navigate('/');
      }, [accessLevel]);

     return <UnauthorizedMessage />;
}

export default UnauthorizedPage;
