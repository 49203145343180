import React, {useEffect, useMemo, useState} from 'react';
import {
  Banner, BasicDropdown,
  COLORS,
  ComponentMStyling,
  ComponentTextStyle,
  ComponentXSStyling, DropdownItem, InputLabel,
  Size, States, SystemIcons, TextField,
  Tile,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {useController, useFormContext} from 'react-hook-form';
import styled from 'styled-components';
import {CountryDto, Organization, PaymentType} from "../../../../../types";
import {CreateLicenseForm} from "../../Common/types";
import {UpdateOrganizationBillingDetailsModal} from "../UpdateOrganizationBillingDetailsModal";
import {InputWrapper} from "../../Common/styles";

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const DetailsRow = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
`;

const DetailsItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
`;

const DetailsItemLabel = styled.div`
  ${ComponentXSStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
`;

const DetailsItemValue = styled.div`
  ${ComponentMStyling(ComponentTextStyle.Regular, COLORS.black)}
`;

interface Props {
  savedOrganization: Organization;
  setSavedOrganization: (org: Organization) => void;
  countries: CountryDto[];
}

export const OrganizationBillingDetailsCard = ({
                                                 countries,
                                                 savedOrganization,
                                                 setSavedOrganization,
                                               }: Props) => {

  const {t} = useTranslation('OrganizationServices');

  const {setValue, formState, watch, control, trigger} = useFormContext<CreateLicenseForm>();
  const paymentTypeController = useController({control, name: 'billingDetails.paymentType'});
  const orderNumberController = useController({control, name: 'billingDetails.orderNumber'});

  const [openBillingDetailsModal, setOpenBillingDetailsModal] = useState(false);

  const billingDetails = watch('billingDetails');

  const isTrial = watch('subscription.isTrial');

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger('billingDetails.orderNumber');
    }
  }, [isTrial])


  const paymentTypes = useMemo(() => [{
    value: `${PaymentType.Paddle}`,
    displayLabel: t('Credit card (Paddle)'),
  },
    {
      value: `${PaymentType.Adyen}`,
      displayLabel: t('Credit card (Adyen)'),
    },
    {
      value: `${PaymentType.Invoice}`,
      displayLabel: t('Pay-by-invoice (Saleforce)'),
    }] as DropdownItem[], [])

  /**
   * update billing details when organization is changed
   * */
  useEffect(() => {
    setValue('billingDetails', savedOrganization, {shouldValidate: true});
  }, [savedOrganization]);

  const renderErrorBanner = () => {
    return (
      <>
        {
          showBillingDetailsError &&
          <Banner type={'warning'}
                  size={Size.Small}
                  link={'#'}
                  linkAction={ev => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    setOpenBillingDetailsModal(true);
                  }}
                  linkText={t('Set billing details')}>
            {t('Some of the required billing details are missing.')}
          </Banner>
        }
        {
          showOrgAddressNotComplete &&
          <Banner type={'warning'}
                  size={Size.Small}>
            {t('This organization does not have a complete address. Please fill in the organization details.')}
          </Banner>
        }
      </>
    );
  };

  const showOrgAddressNotComplete = !!formState.errors.billingDetails?.address;
  const showBillingDetailsError =
    !showOrgAddressNotComplete &&
    !!formState.errors.billingDetails &&
    Object.keys(formState.errors.billingDetails).find(a => a != 'orderNumber');

  return (
    <>
      <UpdateOrganizationBillingDetailsModal isOpen={openBillingDetailsModal}
                                             onClose={() => setOpenBillingDetailsModal(false)}
                                             savedOrganization={savedOrganization}
                                             setSavedOrganization={setSavedOrganization}
                                             countries={countries}/>
      <Tile header={{title: t('Payment & Billing')}} size={Size.Large} className={'tile'}>
        <DetailsContainer>
          {renderErrorBanner()}
          <DetailsRow>
            <DetailsItem>
              <DetailsItemLabel>{t('Organization Name')}</DetailsItemLabel>
              <DetailsItemValue>{billingDetails.billingOrganizationName || 'N/A'}</DetailsItemValue>
            </DetailsItem>
            <DetailsItem>
              <DetailsItemLabel>{t('Primary Contact Person')}</DetailsItemLabel>
              {
                billingDetails.billingContactPersonFirstName && billingDetails.billingContactPersonLastName  &&
                <DetailsItemValue>{billingDetails.billingContactPersonFirstName + ' ' + billingDetails.billingContactPersonLastName}</DetailsItemValue>
              }
              {
                (!billingDetails.billingContactPersonFirstName || !billingDetails.billingContactPersonLastName)  &&
                <DetailsItemValue>{'N/A'}</DetailsItemValue>
              }
            </DetailsItem>
          </DetailsRow>
          <DetailsRow>
            <DetailsItem>
              <DetailsItemLabel>{t('Billing Email Address')}</DetailsItemLabel>
              <DetailsItemValue>{billingDetails.billingEmailAddress || 'N/A'} </DetailsItemValue>
            </DetailsItem>
            <DetailsItem>
              <DetailsItemLabel>{t('VAT number')}</DetailsItemLabel>
              <DetailsItemValue>{billingDetails.vatNumber || 'N/A'}</DetailsItemValue>
            </DetailsItem>
          </DetailsRow>
          <DetailsRow>
            <InputWrapper>
              <InputLabel inputId={'paymentType'} text={'Payment method:'}/>
              <BasicDropdown list={paymentTypes}
                             id={'paymentType'}
                             value={`${paymentTypeController.field.value}`}
                             ref={paymentTypeController.field.ref}
                             readOnly={true}
                             onBlur={paymentTypeController.field.onBlur}
                             onSelect={value => paymentTypeController.field.onChange(+value)}/>
            </InputWrapper>
          </DetailsRow>
          <DetailsRow>
            <InputWrapper>
              <InputLabel inputId="orderNumber" text={t('Order number')}/>
              <TextField id="orderNumber"
                         placeholder={t('Enter a number, if available')}
                         {...orderNumberController.field}
                         note={{message: 'Not required for trials', icon: <SystemIcons.Information/>}}
                         validationMessage={orderNumberController.fieldState.error?.message}
                         state={orderNumberController.fieldState.error ? States.Invalid : undefined}/>
            </InputWrapper>
          </DetailsRow>
        </DetailsContainer>
      </Tile>
    </>
  );
};