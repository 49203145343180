import React, {useEffect, useState} from 'react';
import {CountryDto, Organization, State} from '../../../../types';
import Api from '../../../../utils/api';
import {
  BREAKPOINTS, Checkbox, DropdownFilter,
  InputLabel,
  ModalDialog,
  scrollBarStyling,
  Size,
  States,
  TextField,
  ToastColor,
  ToastPosition,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import styled from 'styled-components';
import {useController, useForm} from 'react-hook-form';
import {yupResolver} from "@hookform/resolvers/yup";
import {BillingDetailsProps} from "../Common/types";
import {billingDetailsSchema} from "../Common/validations";
import {InputWrapper} from "../Common/styles";
import { useToastContext } from '../../../../userContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-right: 8px;
  ${scrollBarStyling(Size.Small)}
`;

const MultiInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  & > div {
    flex: 1;
  }

  input {
    width: 100%;
  }
`;


interface Props {
  isOpen: boolean,
  onClose: () => void,
  setSavedOrganization: (org: Organization) => void;
  savedOrganization: Organization;
  countries: CountryDto[];
}

export const UpdateOrganizationBillingDetailsModal = ({
  isOpen,
  onClose,
  savedOrganization,
  countries,
  setSavedOrganization,
}: Props) => {

  const {t} = useTranslation('OrganizationServices');
  const {addToast} = useToastContext();
  const isMediumScreen = useMediaQuery({query: BREAKPOINTS.MEDIUM.replace('@media ', '')});

  const [states, setStates] = useState<State[]>([]);

  const [submitting, setSubmitting] = useState(false);

  const {control, handleSubmit, watch, reset, setValue} = useForm<BillingDetailsProps>({
    resolver: yupResolver(billingDetailsSchema),
  });

  const organizationNameController = useController({control, name: 'billingOrganizationName'});
  const contactPersonFirstNameController = useController({control, name: 'billingContactPersonFirstName'});
  const contactPersonLastNameController = useController({control, name: 'billingContactPersonLastName'});
  const emailController = useController({control, name: 'billingEmailAddress'});
  const vatController = useController({control, name: 'vatNumber'});
  const useOrgAddressController = useController({control, name: 'useOrganizationAddressForBilling'});
  const countryController = useController({control, name: 'billingAddress.country'});
  const addressLineController = useController({control, name: 'billingAddress.addressLine'});
  const cityController = useController({control, name: 'billingAddress.city'});
  const stateController = useController({control, name: 'billingAddress.state'});
  const zipCodeController = useController({control, name: 'billingAddress.zipCode'});
  const phoneNumberController = useController({control, name: 'billingAddress.phoneNumber'});

  const country = watch('billingAddress.country');
  const state = watch('billingAddress.state');
  const useOrgAddress = watch('useOrganizationAddressForBilling');

  useEffect(() => {
    const states = countries.find(a => a.codeAlpha2 === country?.codeAlpha2)?.states;
    setStates(states || []);
    if(!states?.find(a => a.code === state)) {
      setValue('billingAddress.state', '', {shouldValidate: true});
    }
  }, [country, countries, state]);

  useEffect(() => {
    reset(savedOrganization);
  }, [savedOrganization, isOpen]);

  const cancel = () => {
    reset(savedOrganization);
    onClose();
  };

  /**
   * Updates the billing details
   */
  const submit = (value: BillingDetailsProps) => {
    setSubmitting(true);
    const newOrganization = {...savedOrganization};

    newOrganization.billingOrganizationName = value.billingOrganizationName;
    newOrganization.billingContactPersonFirstName = value.billingContactPersonFirstName;
    newOrganization.billingContactPersonLastName = value.billingContactPersonLastName;
    newOrganization.billingEmailAddress = value.billingEmailAddress;
    newOrganization.vatNumber = value.vatNumber;
    newOrganization.useOrganizationAddressForBilling = value.useOrganizationAddressForBilling;
    newOrganization.billingAddress = {...value.billingAddress};

    return Api.UpdateOrganization(newOrganization)
      .then(() => {
        setSavedOrganization(newOrganization);
        onClose();
      })
      .catch(() => {
        addToast(t('There was a problem updating your organization'), {
          color: ToastColor.RED,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <ModalDialog isModalOpen={isOpen}
                      closeModalAndClearInput={cancel}
                      closeAction={cancel}
                      submitAction={handleSubmit(submit)}
                      title={t('Update billing details')}
                      size={isMediumScreen ? Size.Medium : Size.Small}
                      buttons={[
                        {
                          variant: 'tertiary',
                          text: t('Cancel'),
                          action: cancel,
                        },
                        {
                          variant: 'primary',
                          text: t('Save'),
                          action: handleSubmit(submit),
                          loading: submitting,
                        },
                      ]}>
    <Wrapper>
      <h3>{t('Billing details')}</h3>
      <InputWrapper>
        <InputLabel inputId={'BillingOrganizationName'} text={t('Billing organization name')}/>
        <TextField
          id="BillingOrganizationName"
          validationMessage={organizationNameController.fieldState.error?.message}
          state={organizationNameController.fieldState.error ? States.Invalid : undefined}
          {...organizationNameController.field}/>
      </InputWrapper>
      <InputWrapper>
        <InputLabel inputId={'PrimaryContactPerson'} text={t('Primary contact person')}/>
        <MultiInputWrapper>
          <TextField
            id="PrimaryContactPerson"
            validationMessage={contactPersonFirstNameController.fieldState.error?.message}
            state={contactPersonFirstNameController.fieldState.error ? States.Invalid : undefined}
            {...contactPersonFirstNameController.field}/>
          <TextField
            id="PrimaryContactLastNamePerson"
            validationMessage={contactPersonLastNameController.fieldState.error?.message}
            state={contactPersonLastNameController.fieldState.error ? States.Invalid : undefined}
            {...contactPersonLastNameController.field}/>
        </MultiInputWrapper>
      </InputWrapper>
      <InputWrapper>
        <InputLabel inputId={'BillingEmailAddress'} text={t('Billing email address')}/>
        <TextField
          id="BillingEmailAddress"
          validationMessage={emailController.fieldState.error?.message}
          state={emailController.fieldState.error ? States.Invalid : undefined}
          {...emailController.field}/>
      </InputWrapper>
      <InputWrapper>
        <InputLabel inputId={'OrganizationVatNumber'} text={t('VAT number')}/>
        <TextField
          id="OrganizationVatNumber"
          validationMessage={vatController.fieldState.error?.message}
          state={vatController.fieldState.error ? States.Invalid : undefined}
          {...vatController.field}/>
      </InputWrapper>

      <h3>{t('Billing address')}</h3>
      <Checkbox
        id="UseOrganizationAddressForBilling"
        selected={useOrgAddressController.field.value || false}
        select={useOrgAddressController.field.onChange}
        onBlur={useOrgAddressController.field.onBlur}
        ref={useOrgAddressController.field.ref}
        label={t('Same as primary address?')}
      />

      <InputWrapper>
        <InputLabel inputId={'BillingAddressCountry'} text={t('Country / Region')}/>
        <DropdownFilter
          id={'BillingAddressCountry'}
          scrollable={true}
          list={countries?.filter(a => a.hasAccess || a.codeAlpha2 === country?.codeAlpha2)
            .map((country) => ({value: country.codeAlpha2, displayLabel: country.name}))}
          placeholder={t('Select...')}
          onSelect={(value) => {
            countryController.field.onChange(countries.find((country) => country.codeAlpha2 === value));
          }}
          activeValidationMessage={countryController.fieldState.error?.message}
          value={countryController.field.value?.codeAlpha2 || ''}
          ref={countryController.field.ref}
          onBlur={countryController.field.onBlur}
          name={countryController.field.name}
          messageOnNoResults={t('No result')}
          disabled={useOrgAddress}/>
      </InputWrapper>
      <InputWrapper>
        <InputLabel inputId={'BillingAddressStreet'} text={t('Address')}/>
        <TextField
          id={'BillingAddressStreet'}
          {...addressLineController.field}
          validationMessage={addressLineController.fieldState.error?.message}
          state={addressLineController.fieldState.error ? States.Invalid : undefined}
          disabled={useOrgAddress}
          placeholder={t('e.g. 167 Myers Corners Rd')}
          autoComplete="none"
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel inputId={'BillingAddressCity'} text={t('City')}/>
        <TextField
          id={'BillingAddressCity'}
          {...cityController.field}
          validationMessage={cityController.fieldState.error?.message}
          state={cityController.fieldState.error ? States.Invalid : undefined}
          disabled={useOrgAddress}
          placeholder={t('e.g. Wappingers Falls')}
          autoComplete="none"
        />
      </InputWrapper>
      {
        !!states?.length &&
        <InputWrapper>
          <InputLabel inputId={'BillingAddressState'} text={t('State')}/>
          <DropdownFilter
            id={'BillingAddressState'}
            list={states.map((state) => ({value: state.code, displayLabel: state.name}))}
            placeholder={t('Select...')}
            scrollable={true}
            onSelect={stateController.field.onChange}
            activeValidationMessage={stateController.fieldState.error?.message}
            value={stateController.field.value || ''}
            ref={stateController.field.ref}
            onBlur={stateController.field.onBlur}
            name={stateController.field.name}
            messageOnNoResults={t('No result')}
            disabled={useOrgAddress}
          />
        </InputWrapper>
      }
      <InputWrapper>
        <InputLabel inputId={'BillingAddressZipCode'} text={t('Zip / Postal code')}/>
        <TextField
          id={'BillingAddressZipCode'}
          {...zipCodeController.field}
          validationMessage={zipCodeController.fieldState.error?.message}
          state={zipCodeController.fieldState.error ? States.Invalid : undefined}
          disabled={useOrgAddress}
          placeholder={t('e.g. 12590')}
          autoComplete="none"
        />
      </InputWrapper>

      <InputWrapper>
        <InputLabel inputId={'BillingAddressPhoneNumber'} text={t('Telephone number (optional)')}/>
        <TextField
          id={'BillingAddressPhoneNumber'}
          {...phoneNumberController.field}
          disabled={useOrgAddress}
          placeholder={t('e.g. +1 877-523-7325')}
          autoComplete="none"
        />
      </InputWrapper>

    </Wrapper>
  </ModalDialog>;

};