import React, { Dispatch, useContext, useEffect, useState } from "react";
import {
  BREAKPOINTS,
  COLORS,
  LoadingIndicator, Note,
  ToastColor,
  ToastContext,
  ToastPosition
} from "@laerdal/life-react-components";
import { CountryDto, Industry, Organization } from "../../types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Api from "../../utils/api";
import { ActionType } from "./OrganizationPage";
import OrganizationDetailsEditForm from "./forms/OrganizationDetailsEditForm";
import { useLocation } from "react-router";
import { UserContext, useToastContext } from "../../userContext";
import PromptOnLeave from "../../components/PromptOnLeave";

const OrgDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 560px;

  ${BREAKPOINTS.MEDIUM} {
    gap: 24px;
  }

  ${BREAKPOINTS.MEDIUM} {
    gap: 32px;
  }

  h6 {
    color: ${COLORS.neutral_600};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${Note} {
    color: ${COLORS.neutral_600};
  }
`;

interface Props {
  countries: CountryDto[];
  savedOrganization: Organization;
  setSavedOrganization: (org: Organization) => void;
  organizationFromSalesforce: boolean;
}

const OrganizationDetailsPage = ({
                                   savedOrganization,
                                   countries,
                                   setSavedOrganization,
                                   organizationFromSalesforce
                                 }: Props) => {
  const { t } = useTranslation("Organization");
  const { addToast } = useToastContext();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<string>("");

  const updateOrganization = async (section: string, editedOrganization: Organization) => {
    const newOrganization = { ...savedOrganization! };
    if (newOrganization) {
      setIsSaving(section);
      switch (section) {
        case "generalDetails":
          if (!editedOrganization.name) {
            addToast(t("A name is mandatory for an organization."), {
              color: ToastColor.RED,
              showCloseButton: true,
              autoClose: true,
              position: ToastPosition.TOPMIDDLE
            });

            setIsSaving("");
            return false;
          }
          newOrganization.name = editedOrganization.name;
          newOrganization.customerNo = editedOrganization.customerNo;
          newOrganization.industry = editedOrganization.industry ? parseInt(editedOrganization.industry.toString()) : undefined;
          newOrganization.internalAccount = editedOrganization.internalAccount;
          break;
        case "primaryAddress":
          editedOrganization.address.country = countries.find(country => country.codeAlpha2 === editedOrganization.address.country.codeAlpha2) ?? editedOrganization.address.country;
          newOrganization.address = editedOrganization.address;
          break;
        case "billingDetails":
          newOrganization.billingOrganizationName = editedOrganization.billingOrganizationName;
          newOrganization.billingContactPersonFirstName = editedOrganization.billingContactPersonFirstName;
          newOrganization.billingContactPersonLastName = editedOrganization.billingContactPersonLastName;
          newOrganization.billingEmailAddress = editedOrganization.billingEmailAddress;
          newOrganization.vatNumber = editedOrganization.vatNumber;
          break;
        case "billingAddress":
          editedOrganization.billingAddress.country = countries.find(country => country.codeAlpha2 === editedOrganization.billingAddress.country.codeAlpha2) ?? editedOrganization.billingAddress.country;
          newOrganization.useOrganizationAddressForBilling = editedOrganization.useOrganizationAddressForBilling;
          newOrganization.billingAddress = editedOrganization.billingAddress;
          break;
        default:
      }

      newOrganization.members = [];
      try {
        await Api.UpdateOrganization(newOrganization);
        newOrganization.members = savedOrganization!.members;
        setSavedOrganization(newOrganization);
        addToast(t("Organization updated successfully"), {
          color: ToastColor.GREEN,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE
        });
        setIsSaving("");
        return true;

      } catch (e) {
        addToast(t("There was a problem updating your organization"), {
          color: ToastColor.RED,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE
        });
        setIsSaving("");
        return false;
      }
    }
    return false;
  };

  const OrganizationDetailsFormProps = {
    countries: countries,
    savedOrganization: savedOrganization,
    isSaving: isSaving
  };

  useEffect(
    () => setLoaded(countries.length > 0 &&
      !!savedOrganization &&
      (organizationFromSalesforce !== null || !savedOrganization.customerNo)),
    [
      countries,
      savedOrganization,
      organizationFromSalesforce
    ]);

  return (
    <OrgDetailsWrapper>
      <Header>
        <h3>{t("Organization details")}</h3>
        {
          organizationFromSalesforce &&
          <Note>{t('Locked fields are editable in Salesforce only.')}</Note>
        }
      </Header>
      {!loaded && <LoadingIndicator />}
      {loaded && (
        <OrganizationDetailsEditForm
          {...OrganizationDetailsFormProps}
          isSalesforceAccount={organizationFromSalesforce!}
          onUpdate={updateOrganization}
        />
      )}
    </OrgDetailsWrapper>
  );
};

export default OrganizationDetailsPage;
