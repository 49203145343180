import React, {useEffect, useState} from 'react';
import {
  AccessLevel,
  Organization,
  OrganizationService,
  User
} from "../../../types";
import {
  Button,
  COLORS,
  ComponentLStyling,
  ComponentTextStyle,
  ComponentXSStyling,
  DropdownItem, ListRow,
  Size,
  SystemIcons,
  Tag,
  Tile, ToggleSwitch
} from "@laerdal/life-react-components";
import {useUserContext} from "../../../userContext";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import { memberFullAccessPermission } from '../../../constants';

const Wrapper = styled.div`
  div.large > div.large:first-child {
    background-color: ${COLORS.neutral_50};

    button:not(:hover):not(:active)[role=button] > div:first-child {
      background-color: ${COLORS.neutral_50};
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${COLORS.neutral_200};
`;

const UserInfoDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const UserInfoName = styled.div`
  ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.black)}
`;

const UserInfoGeneral = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;


const UserInfoDateJoined = styled.div`
  ${ComponentXSStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
`;

const ServiceListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServiceListHeader = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 150%;
  text-transform: uppercase;
  color: ${COLORS.neutral_600};
`;

const ServiceListEmpty = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS.neutral_300};
  padding: 12px;
`;

interface Props {
  user: User;
  organization: Organization;
  type: 'member' | 'invitation' | 'contact';
  onAction: (action: string, org: Organization) => void;
  onServiceAction: (action: string, org: Organization, service?: OrganizationService) => void;
  onCurrentOrganizationChange?: (orgId: string) => void;
}

export const UserOrganizationEntry = ({
                                        user,
                                        organization,
                                        onAction,
                                        onCurrentOrganizationChange,
                                        type,
                                        onServiceAction
                                      }: Props) => {
  const {t} = useTranslation('User');

  const {accessLevel} = useUserContext();
  const fullAccessBlocked = accessLevel != AccessLevel.Full;
  const hasGeneralAccess = accessLevel == AccessLevel.General || accessLevel == AccessLevel.Full;
  const readOnly = accessLevel == AccessLevel.ReadOnly;

  const [actions, setActions] = useState<DropdownItem[]>([]);
  const [serviceActions, setServiceActions] = useState<DropdownItem[]>([]);

  let member = organization.members?.find(a => a.user?.id === user.id);
  let invitation = user.invitations?.find(a => a.organization?.id === organization.id)?.invitation;

  useEffect(() => {
    let options = [];

    switch (type) {
      case 'member':
        options = [
          {
            value: 'signin-on-behalf',
            displayLabel: t('Sign in to Connect as this user'),
            disabled: !hasGeneralAccess || !user.hasGigyaAccount || !(user.organizations.length === 1 || organization.id === user.currentOrganization?.id),
          },
          {
            showDividerAbove: true,
            value: 'edit-permissions',
            displayLabel: t('Edit permissions'),
            disabled: readOnly,
          },
          {
            value: 'remove-from-organization',
            displayLabel: t('Remove from organization'),
            disabled: readOnly,
          },
          {
            value: 'send-welcome-to-connect',
            displayLabel: t('(Re)send email inviting user to Connect'),
            disabled: readOnly,
          },
          {
            value: 'view-organization',
            displayLabel: t('View organization'),
            icon: <SystemIcons.OpenNewWindow/>,
          },
        ];
        break;
      case 'invitation':
        options = [
          {
            value: 'signin-on-behalf',
            displayLabel: t('Sign in to Connect as this user'),
            disabled: true,
          },
          {
            showDividerAbove: true,
            value: 'edit-invitation-permissions',
            displayLabel: t('Edit permissions'),
            disabled: readOnly,
          },
          {
            value: 'revoke-invitation',
            displayLabel: t('Remove from organization'),
            disabled: readOnly,
          },
          {
            value: 'view-organization',
            displayLabel: t('View organization'),
            icon: <SystemIcons.OpenNewWindow/>,
          },
        ];

        break;
      case 'contact':
        options = [
          {
            value: 'signin-on-behalf',
            displayLabel: t('Sign in to Connect as this user'),
            disabled: true,
          },
          {
            showDividerAbove: true,
            value: 'edit-invitation-permissions',
            displayLabel: t('Edit permissions'),
            disabled: true,
          },
          {
            value: 'revoke-invitation',
            displayLabel: t('Remove from organization'),
            disabled: true,
          },
          {
            value: 'view-organization',
            displayLabel: t('View organization'),
            icon: <SystemIcons.OpenNewWindow/>,
          },
        ];
    }

    let serviceOptions = [
      {
        value: 'signin-on-behalf',
        displayLabel: t('Sign in as this user'),
        disabled: !hasGeneralAccess || !user.hasGigyaAccount || !(user.organizations.length === 1 || user.currentOrganization?.id === organization.id),
      },
      {
        showDividerAbove: true,
        value: 'edit-access',
        displayLabel: t('Edit user access'),
        disabled: readOnly,
      },
      {
        value: 'remove-from-service',
        displayLabel: t('Remove user from service'),
        disabled: readOnly,
      },
      {
        value: 'view-service',
        displayLabel: t('View service'),
        icon: <SystemIcons.OpenNewWindow/>,
      },
    ];

    setActions(options)
    setServiceActions(serviceOptions);
  }, [user, organization, readOnly, hasGeneralAccess]);

  let services = organization.services?.filter(a => a.members?.find(b => b.user?.id === user.id)) || [];

  return (
    <Wrapper>
      <Tile key={organization.id}
            header={{
              title: organization.name,
              subtitle: organization.customerNo,
              buttons: actions?.length ? [
                {
                  componentType: 'dropdown',
                  icon: <SystemIcons.MoreVertical/>,
                  items: actions,
                  onClick: (ids: string[]) => ids.length && onAction(ids[0], organization),
                  disabled: !organization.hasAccess,
                }
              ] : undefined,
            }}
            size={Size.Large}>
        <Container>
          <UserInfoWrapper>
            <UserInfoDetails>
              <UserInfoName>{user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.email}</UserInfoName>
              <UserInfoGeneral>
                {
                  type === 'member' &&
                  <Tag
                    variant={!!member?.permissions?.find(a => a.permissionId === memberFullAccessPermission) ? 'positive' : 'accent1'}
                    label={!!member?.permissions?.find(a => a.permissionId === memberFullAccessPermission) ? 'Admin' : 'User'}
                    icon={<SystemIcons.User/>}
                  />
                }
                {
                  type === 'invitation' &&
                  <Tag
                    variant={'neutral'}
                    label={'Invited'}
                    icon={<SystemIcons.Mail/>}
                  />
                }
                {
                  type === 'contact' &&
                  <Tag
                    variant={'neutral'}
                    label={'Salesforce contact'}
                  />
                }
                {
                  type === 'member' &&
                  <UserInfoDateJoined>{t('Joined {{date}}', {date: moment(member?.created).format('LL')})}</UserInfoDateJoined>
                }
                {
                  type === 'invitation' &&
                  <UserInfoDateJoined>{t('Added {{date}}', {date: moment(invitation?.createdDate).format('LL')})}</UserInfoDateJoined>
                }

              </UserInfoGeneral>
            </UserInfoDetails>
            {
              !readOnly && type === 'member' &&
              <Button variant="secondary"
                      icon={<SystemIcons.Edit/>}
                      size={Size.Medium}
                      onClick={() => onAction('edit-permissions', organization)}>
                {t('Edit permissions')}
              </Button>
            }
            {
              !readOnly && type === 'invitation' &&
              <Button variant="secondary"
                      icon={<SystemIcons.Edit/>}
                      size={Size.Medium}
                      onClick={() => onAction('edit-invitation-permissions', organization)}>
                {t('Edit permissions')}
              </Button>
            }

            {
              !readOnly && type === 'contact' &&
              <Button variant="primary"
                      icon={<SystemIcons.Add/>}
                      size={Size.Medium}
                      onClick={() => onAction('invite-contact', organization)}>
                {t('Add to organization')}
              </Button>
            }

          </UserInfoWrapper>

          {
            type !== 'contact' &&
            <ServiceListContainer>
              <ServiceListHeader>{t('Has access to')}</ServiceListHeader>
              {
                !services?.length &&
                <ServiceListEmpty>
                  {t('This person does not have access to any services at this organization.')}
                </ServiceListEmpty>
              }
              {
                services?.map(service =>
                  <ListRow key={service.id}
                           mainText={service.name}
                           size={Size.Large}
                           note={service.members.find(a => a.user.id === user.id)?.role?.name}
                           dropdown={{
                             items: serviceActions,
                             onClick: (ids: string[]) => onServiceAction(ids[0], organization, service),
                             disabled: !organization.hasAccess,
                           }}
                  />)
              }


              <ListRow mainText={t('Add to new service')}
                       icon={<SystemIcons.Add/>}
                       size={Size.Large}
                       disabled={readOnly || !organization.hasAccess || type === 'invitation'}
                       action={() => onServiceAction('add-to-new-service', organization)}
              />
            </ServiceListContainer>
          }

          {
            onCurrentOrganizationChange &&
            <ToggleSwitch
              id={organization.id + '_currentToggle'}
              className={'org-toggle'}
              size={Size.Large}
              label={t('User’s default organization when signing in')}
              disabled={fullAccessBlocked || !organization.hasAccess || type === 'invitation'}
              selected={user.organizations.length === 1 ? true : user.currentOrganization?.id === organization.id}
              onToggle={(state) => {
                if (state) {
                  onCurrentOrganizationChange(organization.id);
                }
              }}
            />
          }
        </Container>
      </Tile>
    </Wrapper>
  )
};