import React, {useMemo} from "react";
import {Organization, OrganizationService, Service, Subscription} from "../../../../../types";
import {COLORS, InputLabel, InputWrapper, TextField} from "@laerdal/life-react-components";
import {Link} from "react-router-dom";
import {FormGroupContainer, FormGroupHeader} from "../../Common/styles";
import styled from "styled-components";
import {formatOwners} from "../../Common/helpers";
import {useTranslation} from "react-i18next";

const OwnerLabel = styled.div`
  display: flex;
  flex-direction: row;

  a {
    margin: 0 0 0 auto;
    text-decoration: none;
    color: ${COLORS.primary_600};
    font-weight: bold;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

interface Props {
  service: Service;
  organization: Organization;
  organizationService: OrganizationService;
}

export const GeneralDetailsGroup = ({
                                      service,
                                      organization,
                                      organizationService,
                                    }: Props) => {
  const {t} = useTranslation('OrganizationServices');


  const [owner, other] = useMemo(() => formatOwners(organization, service.serviceRoles, organizationService), [organization, service, organizationService]);

  return (
    <FormGroupContainer>
      <FormGroupHeader>
        {t('General')}
      </FormGroupHeader>
      <InputWrapper>
        <OwnerLabel>
          <InputLabel inputId="LicenseOwner" text={t('License owner')}/>
          {other && <Link
            to={`/organization/${organization?.id}/license-management/${organizationService?.id}/user-access`}>{t('View all')}</Link>}
        </OwnerLabel>
        <TextField id="LicenseOwner" value={owner} readOnly/>
      </InputWrapper>
    </FormGroupContainer>
  )
}