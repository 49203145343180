/**
 * Import third-party libraries.
 */
import styled from 'styled-components';
import { COLORS } from '@laerdal/life-react-components';

export const NameSpan = styled.span`
  color: ${COLORS.neutral_600};
  font-size: 18px;
`;

export const EmailSpan = styled.span`
  color: ${COLORS.neutral_600};
  font-size: 14px;
`;

export const UserDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UserDetails = styled.div`
  margin-left: 10px;
`;
