import React, {useEffect, useState} from 'react';
import {Organization} from '../../../types';
import {
  InputLabel,
  ModalDialog,
  Size, TextField, 
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {ErrorToastOptions, SuccessToastOptions} from '../../../constants';
import Api from '../../../utils/api';
import { useToastContext } from '../../../userContext';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Description = styled.p`
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  organization: Organization;
}

export const OrganizationSalesForceSyncModal = ({isOpen, onClose, organization, onSuccess}: Props) => {

  const {t} = useTranslation('Organization');
  const {addToast} = useToastContext();
  const [loading, setLoading] = useState(false);

  const [validation, setValidation] = useState<string>();

  const submit = () => {
    setLoading(true);
    Api.SyncOrganizationToSalesForce(organization.id)
      .then(() => {
        onSuccess();
        addToast(t('Sync complete'), SuccessToastOptions);
      })
      .catch(() => {
        addToast(t('Unable to sync customer records. Try again later.'), ErrorToastOptions);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };
  
  useEffect(() => {
    if (organization){
      if (!organization.organizationCode){
        setValidation('Can not sync to Salesforce without organization code');
      }
    }
  }, [organization]);

  return (
    <ModalDialog isModalOpen={isOpen}
                 closeModalAndClearInput={onClose}
                 closeAction={onClose}
                 submitAction={submit}
                 title={t('Sync to SalesForce')}
                 size={Size.Medium}
                 note={validation ? validation : t('Once synced, this action cannot be undone.')}
                 state={validation ? 'critical' : 'warning'}
                 buttons={[
                   {
                     text: t('Cancel'),
                     action: onClose,
                     variant: 'tertiary',
                     disabled: loading,
                   }, {
                     text: t('Sync now'),
                     action: submit,
                     disabled: !!validation,
                     variant: 'primary',
                     loading: loading,
                   },
                 ]}>
      <Wrapper>
        <Description>{t('Manually synchronize this organization with a customer account stored in SalesForce.')}</Description>
        <div>
          <InputLabel inputId={'name'} required={false} text={t('Name')}/>
          <TextField readOnly={true} value={organization.name}/>
        </div>
        <div>
          <InputLabel inputId={'customerNo'} text={t('Account number (Customer ID)')}/>
          <TextField readOnly={true} value={organization.customerNo}/>
        </div>
      </Wrapper>
    </ModalDialog>
  );
};