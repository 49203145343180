import { memberFullAccessPermission } from "../../../../constants";
import {Organization, OrganizationService, ServiceRole} from "../../../../types";

export const getOwners = (organizationService?: OrganizationService) => {
  return organizationService?.members?.filter((m) =>
    (m?.role?.id?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ID?.toLowerCase() ||
      m?.role?.parentRoleId?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ID?.toLowerCase()))
    .map(a => a.user.email)
    .concat(
      organizationService?.invitations?.filter((m) =>
        m.isActive &&
        (m?.role?.id?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ID?.toLowerCase() ||
          m?.role?.parentRoleId?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ID?.toLowerCase()))
        .map(a => a.email) || []
    );
};

export const formatOwners = (organization?: Organization, serviceRoles?: ServiceRole[], organizationService?: OrganizationService): [string, boolean] => {

  if(organizationService?.serviceId.toLowerCase() == process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase()) {
    const ownerMembers = organization?.members.filter(x => (x?.permissions?.findIndex(y => y?.permissionId == memberFullAccessPermission) ?? -1) >= 0).map(x => x.user.email);
    if(ownerMembers && ownerMembers.length > 0)
    {
      let owner = ownerMembers[0];
      const others = ownerMembers.length - 1;
      if (others > 0) {
        owner = owner.concat(`, + ${others} more...`);
      }
      return [owner, false];
    }
  }

  const ownerRoles = serviceRoles
    ?.filter((s) => s.id === process.env.REACT_APP_SERVICE_OWNER_ID?.toLowerCase() || s.parentRoleId === process.env.REACT_APP_SERVICE_OWNER_ID?.toLowerCase())
    .map((s) => s.name);
  const owners = organizationService?.members?.filter((m) => (m?.role?.name ? ownerRoles?.includes(m.role.name) : false));
  if (owners && owners.length > 0) {
    let owner = owners[0]?.user?.email;
    const others = owners.length - 1;
    if (others > 0) {
      owner = owner.concat(`, + ${others} more...`);
    }
    return [owner, owners.length > 1];
  }

  const invitedOwners = organizationService?.invitations?.filter((m) => (m?.role?.name ? ownerRoles?.includes(m.role.name) : false) && m.isActive);
  if (invitedOwners && invitedOwners.length > 0) {
    let owner = invitedOwners[0]?.email;
    const others = invitedOwners.length - 1;
    if (others > 0) {
      owner = owner.concat(`, + ${others} more...`);
    }
    return [owner, invitedOwners.length > 1];
  }

  return ['', false];
}

export const getDefaultRenewalPeriod = (startDate: Date, expirationDate: Date) => {
  const deltaInDays = (new Date(expirationDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24);
  const deltaInMonths = Math.round(deltaInDays / 365) * 12;

  if (deltaInMonths > 60) return 60;

  if (deltaInMonths < 12) return 12;

  return deltaInMonths;
};