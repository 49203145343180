import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  Banner,
  BasicDropdown,
  BREAKPOINTS,
  Checkbox,
  ChipDropdownInput,
  InputLabel,
  ModalDialog,
  Size,
  SystemIcons,
  ToastColor,
  ToastPosition,
  TooltipWrapper
} from "@laerdal/life-react-components";
import Api from "../../../utils/api";
import {
  AddOrganizationServiceMemberInvitation,
  Organization,
  OrganizationService,
  Service,
  ServiceRole,
  SubscriptionStatus,
  VALID_SUBSCRIPTION_STATUSES
} from "../../../types";
import { useMediaQuery } from "react-responsive";
import { isValidEmailAddress } from "../../../utils/ValidationHelpers";
import { useToastContext } from "../../../userContext";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InvitationEmailContainer = styled.div`
  label {
    text-decoration-line: underline;
    text-decoration-style: dashed;
    text-underline-offset: 1px;
    text-decoration-thickness: 1px;
  }
`;

type AddUserToOrgServiceProps = {
  closeModal: any;
  existsInSF: boolean | undefined;
  isModalOpen: boolean;
  organization: Organization | undefined;
  organizationServiceId: string;
  getOrganizationService: () => void;
  organizationService: OrganizationService | undefined;
  service?: Service;
  roles: ServiceRole[];
  invitationLimit?: number;
};

const InviteUsersToOrgServiceModal: React.FunctionComponent<AddUserToOrgServiceProps> = ({
  closeModal,
  isModalOpen,
  existsInSF,
  organization,
  organizationServiceId,
  getOrganizationService,
  service,
  organizationService,
  roles,
  invitationLimit,
}: AddUserToOrgServiceProps) => {
  const { t } = useTranslation('OrganizationServices');

  const [selectedUsers, setSelectedUsers] = useState<{ value: string; existing: boolean }[]>([]);
  const [serviceRole, setServiceRole] = useState<ServiceRole | undefined>(undefined);
  const isMediumScreen = useMediaQuery({ query: BREAKPOINTS.MEDIUM.replace('@media ', '') });

  const [userList, setUserList] = useState<{ label: string; secondaryLabel?: string; value: string; existing: boolean }[]>([]);
  const [filterInput, setFilterInput] = useState<string>('');
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [createSFContacts, setCreateSFContacts] = useState(false);
  const [hasNonMemberUser, setHasNonMemberUser] = useState(false);
  const [shouldSendEmail, setShouldSendEmail] = useState(false);

  const [abortController, setAbortController] = useState<AbortController | undefined>(undefined);

  const [busy, setBusy] = useState(false);

  const haveAvailablePlans = (service?.availablePlans?.length ?? 0) > 0;


  useEffect(() => {
    if (selectedUsers?.length && roles?.length === 1 && !serviceRole) {
      setServiceRole(roles[0]);
    }
  }, [roles, selectedUsers]);

  useEffect(() => {
    abortController?.abort();
    const controller = new AbortController();
    setAbortController(controller);

    let options = organization?.members
      ?.filter((a) => a.user.email.toLowerCase().includes(filterInput.toLowerCase()))
      ?.map((member) => ({
        label: member.user.firstName || member.user.lastName ? `${member.user.firstName} ${member.user.lastName}`.trim() : member.user.email,
        secondaryLabel: member.user.firstName || member.user.lastName ? member.user.email : '',
        value: member.user.email,
        existing: true,
      }));

    let promise = Promise.resolve(options);

    if (!options?.length && filterInput.length > 1) {
      setIsLoadingUsers(true);

      promise = Api.FindUsers(filterInput, undefined, undefined, undefined, undefined, undefined, undefined, controller?.signal)
        .then((users) => {
          const isEmail = isValidEmailAddress(filterInput);
          setIsLoadingUsers(false);
          
          return users.records
            .filter((a) => !isEmail || a.email.includes(filterInput))
            .map((user) => ({
              label: user.firstName || user.lastName ? `${user.firstName} ${user.lastName}`.trim() : user.email,
              secondaryLabel: user.firstName || user.lastName ? user.email : '',
              value: user.email,
              existing: false,
            }));
        })
        .catch(() => []);
    }

    promise.then((options) => {
      if (controller?.signal.aborted) {
        return setUserList([]);
      }

      if (!options?.length && !!filterInput && isValidEmailAddress(filterInput)) {
        options = [
          {
            label: filterInput.toLowerCase(),
            secondaryLabel: '',
            value: filterInput.toLowerCase(),
            existing: false,
          },
        ];
      }
      options = options?.filter(
        (m) => !organizationService?.members.find((a) => a.user.email === m?.value) && !organizationService?.invitations?.find((a) => a.email === m?.value && a.isActive),
      );
      setUserList(options || []);
    });

    return () => abortController?.abort();
  }, [filterInput, organization?.members, organizationService]);

  useEffect(() => setFilterInput(''), [selectedUsers]);

  useEffect(() => setHasNonMemberUser(selectedUsers.some((a) => !a.existing)), [selectedUsers]);

  const { addToast } = useToastContext();

  const addServiceUsers = () => {
    setBusy(true);
    const userListToAdd = selectedUsers.map((a) => a.value);
    if (userListToAdd?.length) {
      const inviteObject: AddOrganizationServiceMemberInvitation = {
        emails: userListToAdd,
        role: serviceRole,
        sendEmail: haveAvailablePlans ? shouldSendEmail : true,
        createSFContact: createSFContacts,
      };
      Api.InviteMemberToOrganizationService(organization!.id!, organizationServiceId, inviteObject)
        .then((statusCode) => {
          addToast(statusCode !== 200 ? t('User already exists in organization service') : t('User(s) was added to organization service successfully'), {
            color: ToastColor.GREEN,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
          getOrganizationService();
          setSelectedUsers([]);
          if (statusCode !== 200) {
            getOrganizationService();
          }
          setBusy(false);
          closeModal();
        })
        .catch(() => {
          addToast(t('There was a problem adding the user to organization service'), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
          setBusy(false);
          closeModal();
        });
    } else {
      setBusy(false);
      closeModal();
    }
  };

  const onSelectedUsersChange = (values: string[]) => {
    const list = selectedUsers.filter((a) => values.includes(a.value));
    userList
      .filter((a) => !list.some((b) => b.value === a.value))
      .filter((a) => values.includes(a.value))
      .forEach((a) => list.push(a));
    setSelectedUsers(list);
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {}}
      closeModalAndClearInput={closeModal}
      closeAction={closeModal}
      state={'warning'}
      title={t('Invite users to service')}
      size={isMediumScreen ? Size.Medium : Size.Small}
      tooltip={t('Adding new users to this service will also create them as organization members.')}
      buttons={[
        {
          id: 'cancel',
          variant: 'tertiary',
          text: t('Cancel'),
          action: closeModal,
        },
        {
          id: 'save',
          action: addServiceUsers,
          disabled: selectedUsers.length === 0 || (roles.length > 0 && !serviceRole) || (invitationLimit !== undefined && invitationLimit < selectedUsers.length) || busy,
          variant: 'primary',
          text: t('Add users'),
          loading: busy,
        },
      ]}>
      <FormWrapper>
        <div>
          <InputLabel inputId="MemberSearchDropdown" text={t('Select people')} />
          <ChipDropdownInput
            placeholder={t('e.g. annie@laerdal.com')}
            inputId="MemberSearchDropdown"
            list={userList}
            values={selectedUsers.map((a) => a.value)}
            loading={isLoadingUsers}
            messageOnNoResults={t('No results found')}
            validationMessage={invitationLimit != undefined && invitationLimit < selectedUsers.length ? t('Adding these users will exceed the subscription limit') : undefined}
            onValueChange={onSelectedUsersChange}
            onInputChange={setFilterInput}
          />
        </div>

        {roles?.length > 1 && (
          <div>
            <InputLabel inputId="serviceOwnerRoles" text={t('Access level')} />
            <BasicDropdown
              id="serviceOwnerRoles"
              list={
                roles?.map((r) => ({
                  value: r.id!,
                  displayLabel: r.name,
                  noteLabel: r.description || r.parentRoleDescription,
                })) || []
              }
              placeholder={t('Select...')}
              value={serviceRole?.id}
              onSelect={(value) => {
                setServiceRole(roles?.find((r) => r.id === value));
              }}
              messageOnNoResults={t('No results found')}
            />
          </div>
        )}

        {haveAvailablePlans && !VALID_SUBSCRIPTION_STATUSES.includes(organizationService?.subscription?.status ?? SubscriptionStatus.Deactivated) && (
          <InvitationEmailContainer>
            <TooltipWrapper label={t('Without this flag invitation will be sent when subscription will become valid (active and not expired)')} delay="0.4s">
              <Checkbox id={'shouldSendEmail'} selected={shouldSendEmail} select={setShouldSendEmail} label={t('Immediately send invitation to selected people')} />
            </TooltipWrapper>
          </InvitationEmailContainer>
        )}

        {existsInSF && <Checkbox id={'createSFContact'} selected={createSFContacts} select={setCreateSFContacts} label={t('Create as contacts in Salesforce')} />}
        {hasNonMemberUser && (
          <Banner size={isMediumScreen ? Size.Medium : Size.Small} type={'warning'} icon={<SystemIcons.TechnicalWarning />}>
            <Trans t={t} ns={'OrganizationServices'} key={'NonMemberUsersWarning'}>
              Some users belong to another organization.
              <br />
              <br />
              <strong>{organization?.name}</strong> will become the default organization for all selected people.
            </Trans>
          </Banner>
        )}
      </FormWrapper>
    </ModalDialog>
  );
};

export default InviteUsersToOrgServiceModal;
