import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { TextField, TextFieldProps } from "@laerdal/life-react-components";

interface FormTextFieldProps<T extends FieldValues> extends TextFieldProps {
  control: Control<T, any>;
}

export const FormTextField = <T extends FieldValues>({ control, state, ...rest }: FormTextFieldProps<T>) => {
  return <Controller control={control} render={(fields) => <TextField {...fields.field}   validationMessage={fields.fieldState.error?.message} {...rest} />} name={rest.id as Path<T>}  />;
};
